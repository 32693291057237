import dashapi from './dashapi';
import FusionAPI from './fusionApi';
import PMPDeal from '../domain/pmpDeals/pmpDeal';

export const createPmpDeal = (rawDeal) => {
  const {
    _id: id,
    description,
    name,
    is_active: isActive,
    date_created: dateCreated,
    date_modified: dateModified,
    start_timestamp: startTimestamp,
    end_timestamp: endTimestamp,
    eligible_bidders: eligibleBidders,
    deal_type: dealType,
    deal_price: dealPrice,
    currency,
    demand_sub_type,
    priority_level,
    dimensions,
  } = rawDeal;
  return new PMPDeal(
    id,
    description,
    name,
    isActive,
    dateCreated,
    dateModified,
    startTimestamp,
    endTimestamp,
    eligibleBidders,
    dealType,
    dealPrice,
    currency,
    demand_sub_type,
    priority_level,
    dimensions,
  );
};

export async function getDeals(queryObject) {
  try {
    const query = Object.keys(queryObject).map(key => `${key}=${queryObject[key]}`).join('&');
    const { response: { deals: rawDeals, total } } = await dashapi.get(`pmp/deals?${query}`);
    const deals = rawDeals.filter(
      rawDeal => !rawDeal.deleted
    ).map(rawDeal => createPmpDeal(rawDeal));
    return { deals, total };
  } catch (error) {
    return [];
  }
}

export async function postDeals(body) {
  try {
    const { response: rawDeal } = await dashapi.post('pmp/deals', body);
    return createPmpDeal(rawDeal);
  } catch (error) {
    throw error;
  }
}

export async function getDeal(dealId) {
  try {
    const { response: rawDeal } = await dashapi.get(`pmp/deals/${dealId}`);
    return createPmpDeal(rawDeal);
  } catch (error) {
    throw error;
  }
}

export async function deleteDeal(id) {
  try {
    await FusionAPI.delete(id);
  } catch (error) {
    throw error;
  }
}
