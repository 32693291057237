import PropTypes from 'prop-types';
import React from 'react';
import { Button, Dropdown, Form, Grid } from 'semantic-ui-react';

import { COUNTRY_LISTS, COUNTRY_LIST_OPTIONS } from '../../shared/constants';

class PremadeCountryLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = { list: {} };
  }

  render() {
    const { addListItems, bidder, path, removeListItems } = this.props;

    const onActionClick = action => () => {
      action(bidder, path, this.state.list.countries || []);
    };

    const onListChange = (event, ownProps) => {
      const { value: listId } = ownProps;
      this.setState({ list: COUNTRY_LISTS[listId] || {} });
    };

    return (
      <div>
        <h3>Use premade lists of country by region</h3>
        <Grid textAlign="left">
          <Grid.Row columns={2}>
            <Grid.Column>
              <h4>Step 1: Choose a region</h4>
              <Dropdown
                onChange={onListChange}
                options={[{ text: 'None' }, ...COUNTRY_LIST_OPTIONS]}
                selection
              />
              <div>
                {this.state.list.description}
              </div>
            </Grid.Column>
            <Grid.Column>
              <h4>Step 2: Choose an action</h4>
              <Form.Field>
                <Button
                  onClick={onActionClick(addListItems)}
                  type="button"
                >
                  Add countries
                </Button>
              </Form.Field>
              <Form.Field>
                <Button
                  onClick={onActionClick(removeListItems)}
                  type="button"
                >
                  Remove countries
                </Button>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default PremadeCountryLists;

PremadeCountryLists.defaultProps = {
  addListItems: () => {},
  bidder: {},
  removeListItems: () => {},
  path: '',
};

PremadeCountryLists.propTypes = {
  addListItems: PropTypes.func,
  bidder: PropTypes.shape({}),
  removeListItems: PropTypes.func,
  path: PropTypes.string,
};
