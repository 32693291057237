import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'semantic-ui-react';

import PublishingAppCard from './PublishingAppCard';
import PublishingAppNoResults from './PublishingAppNoResults';


const PublishingAppCardGroup = (props) => {
  const { apps, apiValidation, approveApp, rejectApp, reReviewApp } = props;
  return (
    <Card.Group centered itemsPerRow={2}>
      {apps.length === 0 ?
        <PublishingAppNoResults /> :
        apps.map(app => (
          <PublishingAppCard
            app={app}
            apiValidation={apiValidation}
            approveApp={approveApp}
            rejectApp={rejectApp}
            reReviewApp={reReviewApp}
            key={app.app._id}
          />
        ))}
    </Card.Group>
  );
};

PublishingAppCardGroup.defaultProps = {};

PublishingAppCardGroup.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  apiValidation: PropTypes.shape({}).isRequired,
  approveApp: PropTypes.func.isRequired,
  rejectApp: PropTypes.func.isRequired,
  reReviewApp: PropTypes.func.isRequired,
};

export default PublishingAppCardGroup;
