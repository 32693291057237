import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Button, Header, Input, List, Segment, Message, Grid } from 'semantic-ui-react';
import UploadCSVList from './UploadCSVList';
import ItemToAdd from './ItemToAdd';
import AppIdList from '../../../domain/AppIdList';
import AdomainList from '../../../domain/AdomainList';

import '../../styles/CBExchangeEdit.css';

class CBExchangeEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      candidateIdToAdd: '',
      error: '',
      items: [],
    };
  }

  handleCandidateIdToAddChange(value) {
    const newState = { candidateIdToAdd: value };
    if (isEmpty(value)) {
      newState.error = '';
    }
    this.setState(newState);
  }

  handleRemoveItem(id) {
    const { removeItemToAdd } = this.props;
    const { items } = this.state;
    const index = items.findIndex(component => component.key === id);
    items.splice(index, 1);
    removeItemToAdd(id);
    this.setState({ items });
  }

  handleAddIds(data) {
    const { action, addIds, idsToAdd, editionOption, platform } = this.props;
    const { items } = this.state;
    data.forEach((id) => {
      if (!idsToAdd.includes(id)) {
        items.push(<ItemToAdd
          key={id}
          id={id}
          platform={platform}
          action={action}
          editionOption={editionOption}
          removeItemToAdd={itemId => this.handleRemoveItem(itemId)}
        />);
      }
    });
    addIds(data, false);

    this.setState({ items });
  }

  handleAddIdClick() {
    const { action, addIds, idsToAdd, editionOption, platform } = this.props;
    const { candidateIdToAdd, items } = this.state;
    const validate = editionOption === 'block';

    try {
      if (validate && action === 'bundle') {
        AppIdList.isAppIdValidToAdd(idsToAdd, candidateIdToAdd, platform);
      } else if (validate) {
        AdomainList.isAdomainValidToAdd(idsToAdd, candidateIdToAdd);
      }
      addIds([candidateIdToAdd], validate);
      items.push(<ItemToAdd
        key={candidateIdToAdd}
        id={candidateIdToAdd}
        platform={platform}
        action={action}
        editionOption={editionOption}
        removeItemToAdd={itemId => this.handleRemoveItem(itemId)}
      />);
      this.setState({ candidateIdToAdd: '', error: '', items });
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  clearInvalids() {
    const { action, idsToAdd, handleClearInvalids, platform } = this.props;
    const { items } = this.state;

    idsToAdd.forEach((id) => {
      let valid = false;
      if (action === 'bundle') {
        valid = AppIdList.isAppIdValid(platform, id);
      } else {
        valid = AdomainList.isAdomainValid(id);
      }
      if (!valid) {
        const index = items.findIndex(component => component.key === id);
        items.splice(index, 1);
      }
    });
    handleClearInvalids();
    this.setState({ items });
  }

  handleKeyPress(key) {
    const { candidateIdToAdd } = this.state;
    if (key === 'Enter' && !isEmpty(candidateIdToAdd)) {
      this.handleAddIdClick();
    }
  }

  render() {
    const {
      action,
      editionOption,
      maxRows,
      isListValid,
      allAppsExceedTheLimit,
      handleCancel,
      handleSave,
      idsToAdd,
      loading,
    } = this.props;
    const { error, candidateIdToAdd, items } = this.state;
    const header = `Add ${action} to ${editionOption}`;

    return (
      <Grid className="CBExchangeEdit">
        <Grid.Row>
          <Grid.Column>
            <Header as="h4">{header}</Header>
            <Input
              action={{
                onClick: () => this.handleAddIdClick(),
                content: 'Add',
                color: 'blue',
                disabled: isEmpty(candidateIdToAdd),
              }}
              value={candidateIdToAdd}
              placeholder={`Enter ${action} ID`}
              onChange={(e, { value }) => this.handleCandidateIdToAddChange(value)}
              onKeyPress={({ key }) => this.handleKeyPress(key)}
            />
            {!isEmpty(error) && (<Message negative>{ error }</Message>)}
            <Segment className="idsList">
              <List>
                {items}
              </List>
            </Segment>
            {
              !isListValid(true) && idsToAdd.length > 0
              && (
                <Button
                  basic
                  color="red"
                  content="Clear Invalids"
                  onClick={() => this.clearInvalids()}
                  floated="left"
                />
              )
            }
            <UploadCSVList
              addCSVList={data => this.handleAddIds(data)}
              maxRows={maxRows}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button
              floated="right"
              color="green"
              disabled={!isListValid() || allAppsExceedTheLimit}
              onClick={handleSave}
              loading={loading}
            >
              Save
            </Button>
            <Button floated="right" color="grey" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

CBExchangeEdit.defaultProps = {
  platform: null,
  loading: false,
};

CBExchangeEdit.propTypes = {
  action: PropTypes.string.isRequired,
  allAppsExceedTheLimit: PropTypes.bool.isRequired,
  editionOption: PropTypes.string.isRequired,
  maxRows: PropTypes.number.isRequired,
  removeItemToAdd: PropTypes.func.isRequired,
  handleClearInvalids: PropTypes.func.isRequired,
  addIds: PropTypes.func.isRequired,
  isListValid: PropTypes.func.isRequired,
  idsToAdd: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleCancel: PropTypes.func.isRequired,
  platform: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default CBExchangeEdit;
