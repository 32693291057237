/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Container, Divider, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { addFlexbid, fetchFlexbid, setFlexbid, saveFlexbid, parseFlexbidTSV } from '../actions';
import { JsonPreview, PageHeader, PageLoader } from '../../../../shared/components';
import { YieldMenu } from '../../shared/components/';
import { FlexbidCreateForm } from '../components';
import { selectIsLoadingFlexbids, selectFlexbidsConfig } from '../selectors';

class FlexbidCreate extends Component {
  componentDidMount() {
    if (this.props.flexbidId) {
      this.props.fetchFlexbid(this.props.flexbidId);
    } else {
      this.props.addFlexbid();
    }
  }

  render() {
    const { loading, config, saveFlexbid, setFlexbid, parseFlexbidTSV } = this.props;
    return (
      <Container className="FlexbidCreate">
        <PageLoader isLoading={loading} />
        <YieldMenu />
        <Button
          content="Save"
          floated="right"
          icon="save"
          labelPosition="left"
          primary
          onClick={saveFlexbid(config)}
        />
        <Divider />
        <PageHeader content="Add Flexbid Config" />
        <Grid columns="equal" stackable>
          <Grid.Column>
            <FlexbidCreateForm
              flexbid={config}
              setFlexbid={setFlexbid(config)}
              parseFlexbidTSV={parseFlexbidTSV}
            />
          </Grid.Column>
          <Grid.Column><JsonPreview toStringify={config} /></Grid.Column>
        </Grid>

      </Container>
    );
  }
}

FlexbidCreate.propTypes = {
  loading: PropTypes.bool.isRequired,
  config: PropTypes.shape(PropTypes.object).isRequired,
  addFlexbid: PropTypes.func.isRequired,
  fetchFlexbid: PropTypes.func.isRequired,
  saveFlexbid: PropTypes.func.isRequired,
  setFlexbid: PropTypes.func.isRequired,
  parseFlexbidTSV: PropTypes.func.isRequired,
  flexbidId: PropTypes.string,
};

FlexbidCreate.defaultProps = { flexbidId: null };

const mapStateToProps = (state, ownProps) => {
  const { match: { params: { id: flexbidId } } } = ownProps;
  return {
    flexbidId,
    loading: selectIsLoadingFlexbids(state),
    config: selectFlexbidsConfig(state),
  };
};

const mapDispatchToProps = dispatch => ({
  addFlexbid: () => dispatch(addFlexbid()),
  fetchFlexbid: flexbidId => dispatch(fetchFlexbid(flexbidId)),
  saveFlexbid: newFlexbid => () => dispatch(saveFlexbid(newFlexbid)),
  setFlexbid: () => (event, { name, value }) =>
    dispatch(setFlexbid(name, value)),
  parseFlexbidTSV: tsv => dispatch(parseFlexbidTSV(tsv)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlexbidCreate);
