import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';

class Name extends React.Component {
  constructor(props) {
    super(props);
    const { bidder: { name = '' } } = props;
    this.state = { name };
  }

  componentWillReceiveProps(nextProps) {
    const { bidder: { name } } = this.props;
    const { bidder: { name: nextName } } = nextProps;
    if (name !== nextName) {
      this.setState({ name: nextName });
    }
  }

  debouncedSetBidder = debounce(this.props.setBidder, 500);

  render() {
    const { bidder: { id }, errors } = this.props;

    const onChange = (event, ownProps) => {
      const { target: { validationMessage = '' } = {} } = event;
      const { value: name } = ownProps;
      this.setState({ name });
      this.debouncedSetBidder(id, { name }, { name: validationMessage });
    };

    return (
      <Form.Field>
        <label htmlFor="bidder-name">Bidder Name</label>
        <Input
          autoComplete="organization"
          error={!isEmpty(errors.name)}
          id="bidder-name"
          inputMode="latin"
          label={{ tag: true, content: 'Required' }}
          labelPosition="right"
          onChange={onChange}
          placeholder="Acme Bidding Company"
          required
          type="text"
          value={this.state.name}
        />
        {errors.name ? <Label basic color="red" pointing>{errors.name}</Label> : null}
      </Form.Field>
    );
  }
}

export default Name;

Name.defaultProps = {
  bidder: {},
  errors: {},
  setBidder: () => {},
};

Name.propTypes = {
  bidder: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  setBidder: PropTypes.func,
};
