import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// views
import { PageHeader, CustomMessage } from '../../../../../src/shared/components';
import IntegrationsLayout from '../../shared/views/IntegrationsLayout';
import DemandAggregatorHeader from './DemandAggregatorHeader';
import DemandAggregatorTable from './DemandAggregatorTable';
import DemandAggregatorNoData from './DemandAggregatorNoData';

// modules
import DemandAggregator from '../';
/* TODO:
        - error displaying for on save / load somehow...
*/

class DemandAggregatorOverview extends Component {
  componentDidMount() {
    this.props.dispatch(DemandAggregator.Actions.fetchData());
  }

  render() {
    return (
      <IntegrationsLayout>
        <CustomMessage
          visible={this.props.showMessage}
          message={this.props.message}
          isError={this.props.isError}
          handleDismiss={() => this.props.dispatch(DemandAggregator.Actions.hideMessage())}
        />
        <PageHeader>DA Dashboard</PageHeader>
        <DemandAggregatorHeader filters={this.props.filters} dispatch={this.props.dispatch} />
        {!this.props.loading && Object.keys(this.props.data).length === 0 ? (
          <DemandAggregatorNoData />
        ) : (
          <DemandAggregatorTable
            data={this.props.data}
            loading={this.props.loading}
            dispatch={this.props.dispatch}
          />
        )}
      </IntegrationsLayout>
    );
  }
}

DemandAggregatorOverview.propTypes = {
  dispatch: PropTypes.func,
  data: PropTypes.object,
  message: PropTypes.string,
  isError: PropTypes.bool,
  showMessage: PropTypes.bool
};

DemandAggregatorOverview.defaultProps = {
  dispatch: null,
  data: {},
  message: null,
  isError: false,
  showMessage: false
};

const mapStateToProps = state => {
  const data = DemandAggregator.Store.getData(state);
  const filters = DemandAggregator.Store.getFilters(state);
  const message = DemandAggregator.Store.getMessage(state);
  const isError = DemandAggregator.Store.getIsError(state);
  const showMessage = DemandAggregator.Store.getShowMessage(state);
  return {
    data,
    filters,
    loading: DemandAggregator.Store.getLoading(state),
    message,
    isError,
    showMessage
  };
};

export default connect(
  mapStateToProps,
  null
)(DemandAggregatorOverview);
