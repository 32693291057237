import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'semantic-ui-react';

const TableRow = (props) => {
  const { row: { key: rowKey, ...data } } = props;
  return (
    <Table.Row key={rowKey}>
      {Object.keys(data).map(key => (
        <Table.Cell key={`${rowKey}-${key}`} {...data[key].cellAttribute}>{data[key].value}</Table.Cell>
      ))}
    </Table.Row>
  );
};

TableRow.defaultProps = {};

TableRow.propTypes = { row: PropTypes.shape({}).isRequired };

export default TableRow;
