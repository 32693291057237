import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Container,
  Dropdown,
  Icon,
  Loader,
  Modal,
  Table,
} from 'semantic-ui-react';

import PageHeader from '../../shared/components/PageHeader';
import * as phasesActions from '../store/phases/actions';

const USER_GROUP_OPTIONS = [
  { text: 'None', value: 'none' },
  { text: 'Alpha', value: 'alpha' },
  { text: 'Alpha + Beta', value: 'beta' },
  { text: 'Alpha + Beta + Gamma', value: 'gamma' },
  { text: 'All', value: 'all' },
];

const checkPhasesToRelease = (phase) => {
  switch (phase.name) {
    case 'Phase 1': return true;
    case 'Phase 3': return true;
    case 'Phase 4.0': return true;
    case 'app-ads.txt': return true;
    case 'user-access-management': return true;
    default: return false;
  }
};

const USER_GROUP_MAP = USER_GROUP_OPTIONS.reduce((obj, item) => ({
  ...obj,
  [item.value]: item.text,
}), {});

const transformPhases = phases => phases.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
  .map((phase) => {
    const { group, _id } = phase;
    return ({
      ...phase,
      id: _id,
      group: group === null ? 'none' : group,
      readyToRelease: checkPhasesToRelease(phase),
    });
  });

class Phases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: {},
      editRowIndex: null,
      isEditMode: false,
      isFetched: false,
      isModalOpen: false,
      phases: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.handleClickConfirm = this.handleClickConfirm.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.loadPhases = this.loadPhases.bind(this);
    this.setModalOpen = this.setModalOpen.bind(this);
  }

  componentDidMount() {
    this.loadPhases();
  }

  setModalOpen(open) {
    this.setState(prevState => ({
      ...prevState,
      isModalOpen: open,
    }));
  }

  async loadPhases() {
    await this.props.getPhases();
    this.setState(prevState => ({
      ...prevState,
      isFetched: true,
      phases: transformPhases(this.props.phases),
    }));
  }

  handleClickEdit(phase, group) {
    const { phases } = this.state;
    this.setState(prevState => ({
      ...prevState,
      isEditMode: true,
      editRowIndex: phases.findIndex(p => p.name === phase),
      editData: { phase, group },
    }));
  }

  handleClickCancel() {
    this.setState(prevState => ({
      ...prevState,
      isEditMode: false,
      isModalOpen: false,
      editRowIndex: null,
      editData: {},
    }));
  }

  handleClickSave() {
    this.setState(prevState => ({
      ...prevState,
      isModalOpen: true,
    }));
  }

  handleChange(e, { value }) {
    this.setState(prevState => ({
      ...prevState,
      editData: {
        ...prevState.editData,
        group: value,
      },
    }));
  }

  handleClickConfirm() {
    const { editRowIndex, editData, phases } = this.state;
    const updatedPhase = phases.filter((phase, index) => index === editRowIndex).map(a => ({
      ...a,
      group: editData.group,
    })).reduce((p, phaseObject) => phaseObject, {});

    this.props.updatePhases([updatedPhase]);

    this.setState(prevState => ({
      ...prevState,
      phases: phases.map((phase) => {
        if (phase.id === updatedPhase.id) {
          return {
            ...phase,
            ...updatedPhase,
          };
        }
        return phase;
      }),
      isEditMode: false,
      isModalOpen: false,
      editData: {},
      editRowIndex: null,
    }));
  }

  render() {
    const { loading } = this.props;
    const { phases, isEditMode, isModalOpen, editData, editRowIndex } = this.state;

    return (
      <Container className="phases">
        <PageHeader>Rollout Phases</PageHeader>
        {loading ?
          <Loader active /> :
          <Container>
            <Table celled striped selectable unstackable collapsing={false} columns={3}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Dashboard Phases</Table.HeaderCell>
                  <Table.HeaderCell>User Groups</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {phases.map((phase, rowIndex) => (
                  <Table.Row key={phase.name}>
                    <Table.Cell width={2}>{phase.name}</Table.Cell>
                    <Table.Cell width={8}>
                      {rowIndex === editRowIndex ? (
                        <Dropdown
                          onChange={this.handleChange}
                          options={USER_GROUP_OPTIONS}
                          selection
                          value={editData.group}
                        />
                      ) : (
                        USER_GROUP_MAP[phase.group]
                      )}
                    </Table.Cell>
                    {rowIndex === editRowIndex ? (
                      <Table.Cell width={4}>
                        <Button onClick={this.handleClickCancel} color="red">
                          <Icon name="cancel" /> Cancel
                        </Button>
                        <Button onClick={this.handleClickSave} color="green">
                          <Icon name="save" /> Save
                        </Button>
                      </Table.Cell>
                    ) : (
                      <Table.Cell width={4}>
                        <Button
                          disabled={isEditMode || !phase.readyToRelease}
                          onClick={() => this.handleClickEdit(phase.name, phase.group)}
                        >
                          <Icon name="pencil" />
                        </Button>
                      </Table.Cell>
                    )}
                  </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Container>
        }
        <Modal
          dimmer="blurring"
          open={isModalOpen}
          size="tiny"
        >
          <Modal.Header>{editData.phase} Rollout for {USER_GROUP_MAP[editData.group]} Users</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>
                Are you sure you want to release {editData.phase} to {USER_GROUP_MAP[editData.group]} users?
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color="grey" onClick={this.handleClickCancel}>
              Cancel
            </Button>
            <Button
              content="Confirm"
              icon="checkmark"
              labelPosition="right"
              onClick={this.handleClickConfirm}
              positive
            />
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

Phases.defaultProps = { phases: [] };

Phases.propTypes = {
  getPhases: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  phases: PropTypes.arrayOf(PropTypes.shape({})),
  updatePhases: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loading: state.phases.loading,
  phases: state.phases.phases,
});

const mapDispatchToProps = dispatch => ({
  getPhases: () => dispatch(phasesActions.fetchPhases()),
  updatePhases: body => dispatch(phasesActions.updatePhases(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Phases);
