import React from 'react';
import { Form } from 'semantic-ui-react';

const GlobalPriceFormula = ({ globals, setGlobals }) => (
  <Form.Group grouped>
    <label>Winning Price Formula</label>
    <Form.Checkbox
      checked={globals.price_formula.formula === 'dollars'}
      label="Cents"
      name="price_formula.formula"
      onChange={setGlobals}
      radio
      value="dollars"
    />
    <Form.Checkbox
      checked={globals.price_formula.formula === 'percent'}
      label="Percent"
      name="price_formula.formula"
      onChange={setGlobals}
      radio
      value="percent"
    />
  </Form.Group>
);

export default GlobalPriceFormula;
