import PLATFORM from './platforms';

export default class Apps {
  constructor(
    id,
    name,
    nickname,
    platform,
    dateCreated,
    blockedAdvertiserBundles,
    blockedAdomains,
  ) {
    this.id = id;
    this.name = name;
    this.nickname = nickname;
    this.platform = platform;
    this.date_created = dateCreated;
    this.blocked_advertiser_bundles = blockedAdvertiserBundles;
    this.blocked_advertiser_bundles_length = blockedAdvertiserBundles.length;
    this.blocked_adomains = blockedAdomains;
    this.blocked_adomains_length = blockedAdomains.length;
  }

  getPlatformName() {
    const platformId = this.getPlatformId();
    return PLATFORM[platformId];
  }

  getPlatformId() {
    return Object.keys(PLATFORM).find(id => (
      PLATFORM[id].toLowerCase() === this.platform.toLowerCase()
    ));
  }
}
