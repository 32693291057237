/* eslint-disable no-param-reassign, no-console */

import { shouldCache } from './shared/helpers';

const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

const onRegister = (registration) => {
  registration.onupdatefound = () => {
    const installingWorker = registration.installing;
    installingWorker.onstatechange = () => {
      if (installingWorker.state === 'installed') {
        if (navigator.serviceWorker.controller) {
          console.log('New content is available; please refresh.');
        } else {
          console.log('Content is cached for offline use.');
        }
      }
    };
  };
};

const onError = (error) => { console.error('Error during service worker registration:', error); };

const register = () => {
  navigator.serviceWorker.register(swUrl).then(onRegister, onError);
};

const unregister = () => {
  navigator.serviceWorker.getRegistrations()
    .then(registrations => registrations.forEach(registration => registration.unregister()));
};

export default function () {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', shouldCache ? register : unregister);
  }
}
