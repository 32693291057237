import PropTypes from 'prop-types';
import React from 'react';
import CreativeCard from './CreativeCard';
import CreativesList from './CreativesList';

const ExchangeCreative = ({
  creativeTemplate,
  creativeTemplateLoaded,
  creativeTemplateName,
  creativeTemplateS3,
  exchangeCreative,
  getCreativeTemplate,
}) => {
  if (exchangeCreative.length === 0) {
    return (
      <p>No creatives found</p>
    );
  }

  if (exchangeCreative.length === 1) {
    return (
      <CreativeCard
        creative={exchangeCreative[0]}
        creativeTemplate={creativeTemplate}
        creativeTemplateLoaded={creativeTemplateLoaded}
        creativeTemplateName={creativeTemplateName}
        creativeTemplateS3={creativeTemplateS3}
        getCreativeTemplate={getCreativeTemplate}
      />
    );
  }

  return (
    <CreativesList
      creativeTemplate={creativeTemplate}
      creativeTemplateLoaded={creativeTemplateLoaded}
      creativeTemplateName={creativeTemplateName}
      creativeTemplateS3={creativeTemplateS3}
      exchangeCreative={exchangeCreative}
      getCreativeTemplate={getCreativeTemplate}
    />
  );
};


ExchangeCreative.propTypes = {
  creativeTemplate: PropTypes.string.isRequired,
  creativeTemplateLoaded: PropTypes.bool.isRequired,
  creativeTemplateName: PropTypes.string.isRequired,
  creativeTemplateS3: PropTypes.string.isRequired,
  exchangeCreative: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getCreativeTemplate: PropTypes.func.isRequired,
};

export default ExchangeCreative;
