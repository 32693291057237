import * as fromBidders from '../bidders/actions';
import * as fromGlobals from '../globals/actions';
import * as fromPublishers from '../publishers/actions';

const isSyncing = (state = false, action = {}) => {
  switch (action.type) {
    case fromBidders.CREATE_BIDDER:
    case fromBidders.SYNC_BIDDER:
    case fromGlobals.SAVE_GLOBALS:
    case fromPublishers.SAVE_PUBLISHER:
      return true;

    case fromBidders.CREATE_BIDDER_FAILURE:
    case fromBidders.CREATE_BIDDER_SUCCESS:
    case fromBidders.SYNC_BIDDER_FAILURE:
    case fromBidders.SYNC_BIDDER_SUCCESS:
    case fromGlobals.SAVE_GLOBALS_FAILURE:
    case fromGlobals.SAVE_GLOBALS_SUCCESS:
    case fromPublishers.SAVE_PUBLISHER_FAILURE:
    case fromPublishers.SAVE_PUBLISHER_SUCCESS:
      return false;

    default:
      return state;
  }
};

export default isSyncing;

export const getIsSyncing = state => state;
