import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import '../styles/WhitelistField.css';
import { isObjectId } from '../../../shared/helpers';

class WhitelistField extends Component {
  constructor() {
    super();
    this.state = {
      error: false
    };
    this.parseCsv = this.parseCsv.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleDownloadCsvFile = this.handleDownloadCsvFile.bind(this);
  }
  parseCsv(csvFile) {
    const { validateObjectIds } = this.props;
    const rows = csvFile.split('\n');
    const items = new Set();

    rows.forEach((row) => {
      const value = row.trim();
      if (!validateObjectIds) {
        items.add(value);
      } else if (isObjectId(value)) {
        items.add(value);
      } else {
        this.setState({ error: true });
      }
    });
    return Array.from(items);
  }

  handleFile(element) {
    const { onChange } = this.props;
    this.setState({ error: false });
    const fileReader = new FileReader();
    const file = element.target.files[0];
    fileReader.onloadend = event => onChange(this.parseCsv(event.target.result));
    fileReader.readAsText(file);
  }

  handleDownloadCsvFile() {
    const { title, whitelistedItems } = this.props;
    const element = document.createElement('a');
    const file = new Blob(
      [whitelistedItems.join('\n')],
      { type: 'text/csv', endings: 'native' },
    );
    element.href = URL.createObjectURL(file);
    element.download = `${title.toLowerCase()}-whitelist.csv`;
    document.body.appendChild(element); // required by FireFox
    element.click();
  }

  render() {
    const { title, whitelistedItems } = this.props;
    return (
      <Form.Field>
        <Form.Input
          label={`Publisher ${title} Allowlist`}
          name="file"
          type="file"
          icon="upload"
          accept=".csv"
          onChange={this.handleFile}
          className="mb-1"
        />
        <Form.Field className="mb-1 default-field">
          {this.state.error &&
            (<p className="warningFusionFlagMessage">The CSV contains unexpected characters, extra columns or incorrect IDs. The value was overridden with only valid IDs.</p>)
          }
        </Form.Field>
        <Form.Field className="mb-1 default-field">
          <b>Current list ({whitelistedItems ? whitelistedItems.length : 0})</b>
        </Form.Field>
        <Form.Button
          disabled={!whitelistedItems || whitelistedItems.length === 0}
          onClick={this.handleDownloadCsvFile}
        >
          Download current list
        </Form.Button>
      </Form.Field>
    );
  }
}

WhitelistField.defaultProps = { validateObjectIds: true };

WhitelistField.propTypes = {
  title: PropTypes.string.isRequired,
  whitelistedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  validateObjectIds: PropTypes.bool,
};

export default WhitelistField;
