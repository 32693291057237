import debounce from 'lodash/debounce';
import getter from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import setter from 'lodash/set';
import { Form, Input } from 'semantic-ui-react';
import { splitIds } from '../../../../shared/helpers';

class BlockedCreatives extends React.Component {
  constructor(props) {
    super(props);
    const { bidder, path } = this.props;
    const blockedCreatives = getter(bidder, path, null);
    this.state = { blocked_creatives: blockedCreatives };
  }

  debouncedSetBidder = debounce(this.props.setBidder, 500);

  render() {
    const { bidder, path, label } = this.props;
    const onChange = (event, ownProps) => {
      const nextBidder = Object.assign({}, bidder);
      const creativeArray = splitIds(event.target.value);
      const nextProps = setter(nextBidder, path, creativeArray);
      this.setState({ blocked_creatives: event.target.value });
      this.debouncedSetBidder(bidder.id, nextProps);
    };

    return (
      <Form.Field>
        <label htmlFor="bidder-block-creatives">Blocked crid list</label>
        <Input
          autoComplete="blocked_creatives"
          id="bidder-block-creatives"
          onChange={onChange}
          placeholder="Comma-separated list of crid's"
          value={this.state.blocked_creatives}
        />
      </Form.Field>
    );
  }
}

export default BlockedCreatives;

BlockedCreatives.defaultProps = {
  bidder: {},
  path: '',
  setBidder: () => {},
};

BlockedCreatives.propTypes = {
  bidder: PropTypes.shape({}),
  path: PropTypes.string,
  setBidder: PropTypes.func,
};
