import PropTypes from 'prop-types';
import React from 'react';
import { Loader } from 'semantic-ui-react';

const PageLoader = ({ isLoading }) => {
  if (isLoading) { return <Loader active />; }
  return null;
};

PageLoader.defaultProps = { isLoading: false };

PageLoader.propTypes = { isLoading: PropTypes.bool };

export default PageLoader;
