import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Item, Segment } from 'semantic-ui-react';

import { EXECUTE_DEPLOY_PUB_CONFIG_FAILURE } from '../actions';

const DeployPubConfig = (props) => {
  const {
    bidder: {
      id: bidderId,
      test_env: testEnv = {},
    },
    executeDeployPubConfig,
    isDeployingPubConfigs,
    serverMessages,
  } = props;

  const dateCreatedPretty = testEnv.date_created
    ? `First deployed ${moment(testEnv.date_created * 1000).format('LL')}`
    : '';

  const dateModifiedPretty = testEnv.date_modified
    ? `Last deployed ${moment(testEnv.date_modified * 1000).fromNow()}`
    : '';

  const onClick = () => {
    executeDeployPubConfig(bidderId);
  };

  /**
   * Get the best JSX based on the current state of the test environment
   * @returns {JSX} The JSX representing the state of the test environment
   */
  const getDeployStateJsx = () => {
    if (isDeployingPubConfigs) {
      return (<div>Deploying Publisher configuration...</div>);
    }

    if (serverMessages[EXECUTE_DEPLOY_PUB_CONFIG_FAILURE]) {
      return (<div>Sorry, there was a problem deploying the Publisher configuration.</div>);
    }

    if (isEmpty(testEnv)) {
      return (<div>Publisher configuration has never been deployed.</div>);
    }

    return (
      <Item.Group>
        <Item>
          <Item.Content>
            <Item.Header>
              DSP Code:{' '}{testEnv.code_name}
            </Item.Header>
            <Item.Description>
              <span title={moment(testEnv.date_created * 1000).format('LLLL')}>
                {dateCreatedPretty}
              </span>
            </Item.Description>
            <Item.Description>
              <span title={moment(testEnv.date_created * 1000).format('LLLL')}>
                {dateModifiedPretty}
              </span>
            </Item.Description>
          </Item.Content>
        </Item>
      </Item.Group>
    );
  };

  return (
    <div>
      <Button
        content="Deploy Publisher configuration"
        loading={isDeployingPubConfigs}
        onClick={onClick}
        type="button"
      />
      <Segment>
        {getDeployStateJsx()}
      </Segment>
    </div>
  );
};

export default DeployPubConfig;

DeployPubConfig.defaultProps = {
  bidder: {},
  executeDeployPubConfig: () => {},
  isDeployingPubConfigs: false,
  serverMessages: {},
};

DeployPubConfig.propTypes = {
  bidder: PropTypes.shape({}),
  executeDeployPubConfig: PropTypes.func,
  isDeployingPubConfigs: PropTypes.bool,
  serverMessages: PropTypes.shape({}),
};
