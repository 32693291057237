import * as bidderActions from '../../bidders/actions';
import * as globalActions from '../../globals/actions';
import * as publisherActions from '../../publishers/actions';

export const ACTION_TITLES = {
  [bidderActions.CREATE_BIDDER_FAILURE]: 'Problem creating bidder',
  [bidderActions.FETCH_DEVICE_LISTS_FAILURE]: 'Problem getting device lists',
  [bidderActions.FETCH_BIDDER_FAILURE]: 'Problem loading bidder',
  [bidderActions.FETCH_BIDDERS_FAILURE]: 'Problem loading bidders',
  [bidderActions.SEARCH_BUNDLE_ID_FAILURE]: 'Problem finding app',
  [bidderActions.SEARCH_APP_NAME_FAILURE]: 'Problem finding app',
  [bidderActions.SYNC_BIDDER_FAILURE]: 'Problem saving bidder',
  [globalActions.FETCH_GLOBALS_FAILURE]: 'Problem fetching global settings',
  [globalActions.SAVE_GLOBALS_FAILURE]: 'Problem saving global settings',
  [publisherActions.FETCH_PUBLISHER_FAILURE]: 'Problem fetching publisher settings',
  [publisherActions.SAVE_PUBLISHER_FAILURE]: 'Problem saving publisher settings',
};

export default ACTION_TITLES;
