import differenceWith from 'lodash/differenceWith';
import getter from 'lodash/get';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Dropdown, Form } from 'semantic-ui-react';

import { COUNTRY_FLAG_OPTIONS, DEPRECRATED_COUNTRIES } from '../../shared/constants';
import { objectComparator } from '../../shared/helpers';

const AddCountry = (props) => {
  const { addListItems, bidder, path } = props;
  const selectedCountries = getter(bidder, path, []);

  const sortedOptions = differenceWith(
    COUNTRY_FLAG_OPTIONS,
    selectedCountries.concat(DEPRECRATED_COUNTRIES),
    ({ key: countryCode }, otherCountryCode) => countryCode === otherCountryCode,
  )
    .sort(objectComparator('text'));

  const onClick = (event, ownProps) => {
    addListItems(bidder, path, ownProps.value);
  };

  const addAllCountries = () => {
    addListItems(bidder, path, map(sortedOptions, country => country.key));
  };

  return (
    <div>
      <Form.Field>
        <h3>Add a specific country</h3>
        <Dropdown
          fluid
          onChange={onClick}
          options={sortedOptions}
          placeholder="Add a specific country"
          search
          selection
        />
      </Form.Field>
      <Form.Field>
        <Button onClick={addAllCountries} type="button">Add all countries</Button>
      </Form.Field>
    </div>
  );
};

export default AddCountry;

AddCountry.defaultProps = {
  addListItems: () => {},
  bidder: {},
  path: '',
};

AddCountry.propTypes = {
  addListItems: PropTypes.func,
  bidder: PropTypes.shape({}),
  path: PropTypes.string,
};
