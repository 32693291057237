import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import WarningTooltip from './WarningTooltip';

const AppsSelectedList = ({
  appsSelected,
  appsThatExceededTheLimit,
  maxRows,
  action,
  validate,
}) => {
  const getPlatformIconName = platform => (
    platform === 'ios'
      ? 'apple'
      : platform
  );

  return (
    <List divided relaxed>
      {appsSelected.map(app => (
        <List.Item key={app.id}>
          <List.Icon name={getPlatformIconName(app.platform)} size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>
              {app.nickname}
              {(appsThatExceededTheLimit.includes(app) && validate) && (
                <WarningTooltip message={`exceeds the limit of ${maxRows} ${action}`} />
              )}
            </List.Header>
            <List.Description>{app.id}</List.Description>
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};

AppsSelectedList.propTypes = {
  appsSelected: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  appsThatExceededTheLimit: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  maxRows: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
  validate: PropTypes.bool.isRequired,
};

export default AppsSelectedList;
