import debounce from 'lodash/debounce';
import differenceWith from 'lodash/differenceWith';
import getter from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown, Form } from 'semantic-ui-react';

import { getPlatform } from '../../shared/helpers';

class BlockApps extends React.Component {

  debouncedSearchApp = debounce(this.props.searchApp, 1000);

  render() {
    const { addListItems, appResultOptions, apps, bidder, isSearchingAppName } = this.props;
    const { android = [], ios = [] } = getter(bidder, 'request_validation.blocked_apps', {});

    const filteredOptions = differenceWith(
      appResultOptions,
      android.concat(ios),
      ({ value: bundleId }, otherBundleId) => bundleId === otherBundleId,
    );

    const onSearchChange = (event, ownProps) => {
      this.debouncedSearchApp(bidder, ownProps);
    };

    const onResultSelect = (event, ownProps) => {
      const { value: bundleId } = ownProps;
      const platformKey = getPlatform(apps[bundleId].platform).key;
      addListItems(bidder, `request_validation.blocked_apps.${platformKey}`, bundleId);
    };

    return (
      <Form.Field>
        <label>Block apps</label>
        <Dropdown
          fluid
          icon="search"
          id="block-apps"
          loading={isSearchingAppName}
          onChange={onResultSelect}
          onSearchChange={onSearchChange}
          options={filteredOptions}
          placeholder="Search apps to block"
          search
          selection
        />
      </Form.Field>
    );
  }
}

export default BlockApps;

BlockApps.defaultProps = {
  addListItems: () => {},
  appResultOptions: [],
  apps: {},
  bidder: {},
  isSearchingAppName: false,
  searchApp: () => {},
};

BlockApps.propTypes = {
  addListItems: PropTypes.func,
  appResultOptions: PropTypes.arrayOf(PropTypes.object),
  apps: PropTypes.shape({}),
  bidder: PropTypes.shape({}),
  isSearchingAppName: PropTypes.bool,
  searchApp: PropTypes.func,
};

