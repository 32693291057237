import { xhr } from '../../shared/helpers';
import { DASH_API } from '../../shared/api';

export const FETCH_BIDDERS = 'BIDDERS.FETCH';
export const FETCH_BIDDERS_SUCCESS = 'BIDDERS.FETCH.SUCCESS';
export const FETCH_BIDDERS_FAILURE = 'BIDDERS.FETCH.FAILURE';

export const fetchBidders = () => async (dispatch) => {
  dispatch({ type: FETCH_BIDDERS });
  try {
    const { response } = await xhr(`${DASH_API}/exchange/bidders`);
    dispatch({ type: FETCH_BIDDERS_SUCCESS, response });
  } catch ({ message }) {
    dispatch({
      type: FETCH_BIDDERS_FAILURE,
      error: message,
      message: ['fetch-failure', 'bidders'],
    });
  }
};
