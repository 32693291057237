export const success = type => `${type}.SUCCESS`;
export const failure = type => `${type}.FAILURE`;
export const started = type => type;

const zipArgs = (args, argNames = []) => argNames.reduce((accumulator, argName, index) =>
  ({ ...accumulator, [argName]: args[index] }), {});

export const createAction = (type, ...argNames) => (...args) =>
  ({ type, payload: zipArgs(args, argNames) });

export const createAsyncAction = (type, argNames, fn, meta) => (...args) => async (dispatch) => {
  const argPayload = zipArgs(args, argNames);
  dispatch({ type, ...argPayload });
  try {
    const response = await fn(...args);
    const payload = { ...argPayload, response };
    dispatch({ type: success(type), payload, meta });
  } catch (error) {
    const metaToSend = meta;
    metaToSend.messages.failure[1] = JSON.stringify(error.errors, null, 2);
    dispatch({ type: failure(type), payload: error.message, error: true, meta: metaToSend });
  }
};

export const createMessages = (verb, noun) => ({
  success: [`${verb}-success`, noun],
  failure: [`${verb}-failure`, noun],
});
