/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Divider, Loader } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import AddPMPDeal from '../components/PrivateMarketPlaceAddButton';
import PrivateMarketplaceContainer from '../components/PrivateMarketplaceContainer';
import PrivateMarketplaceMenu from '../components/PrivateMarketplaceMenu';
import { retrieveDeals, saveDeal } from '../store/pmpDeals/actions';
import '../components/styles/PrivateMarketplaceOverview.css';

const MaxRow = 7;

class PrivateMarketplaceOverview extends Component {
  constructor(props) {
    super(props);
    this.ascending = 'asc';
    this.descending = 'desc';
    this.state = {
      activePage: 1,
      sort_by: '',
      sort_dir: this.ascending,
      query: '',
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.fetchDeals = this.fetchDeals.bind(this);
    this.updateQueryState = this.updateQueryState.bind(this);
    this.showNextPage = this.showNextPage.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  componentDidMount() {
    this.fetchDeals();
  }

  updateQueryState(query) {
    this.setState({ query });
  }

  showNextPage(data) {
    this.setState({ activePage: data.activePage }, this.fetchDeals);
  }

  handleSearch() {
    this.setState({ activePage: 1 }, this.fetchDeals);
  }

  handleSort(clickedColumn) {
    const { sort_by: column } = this.state;
    let { sort_dir: direction } = this.state;

    if (column !== clickedColumn || direction !== this.ascending) {
      direction = this.ascending;
    } else {
      direction = this.descending;
    }
    this.setState({
      sort_by: clickedColumn,
      sort_dir: direction,
      activePage: 1,
    }, this.fetchDeals);
  }

  fetchDeals() {
    const { retrieveDeals } = this.props;
    const { activePage, sort_by: column, sort_dir: direction, query } = this.state;
    const opt = {
      limit: MaxRow,
      page: activePage,
    };

    if (!isEmpty(column)) {
      opt.sort_by = column === 'id' ? '_id' : column;
      opt.sort_dir = direction;
    }

    if (!isEmpty(query)) {
      opt.query = query;
    }

    retrieveDeals(opt);
  }


  render() {
    const {
      deals,
      loading,
      total,
      location,
    } = this.props;

    const { activePage, sort_by: sortedBy, sort_dir: direction, query } = this.state;
    const totalPages = Math.ceil(total / MaxRow);
    const isAdminView = !location.pathname.startsWith('/public');

    return (
      <Container className="PrivateMarketplaceList">
        <AddPMPDeal path={isAdminView ? '/pmp/new' : '/public/pmp/new'} />
        <PrivateMarketplaceMenu />
        <Divider />
        {loading ?
          <Loader active /> :
          <PrivateMarketplaceContainer
            deals={deals}
            showNextPage={this.showNextPage}
            totalPages={totalPages}
            activePage={activePage}
            onSortChange={this.handleSort}
            sortedBy={sortedBy}
            direction={direction === this.ascending ? 'ascending' : 'descending'}
            onSearch={this.handleSearch}
            query={query}
            updateQueryState={this.updateQueryState}
            isAdminView={isAdminView}
          />}
      </Container>
    );
  }
}

PrivateMarketplaceOverview.defaultProps = { deals: [], location: {} };

PrivateMarketplaceOverview.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.shape({})),
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  apiValidation: PropTypes.shape({}).isRequired,
  retrieveDeals: PropTypes.func.isRequired,
  location: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  deals: state.pmpDeals.deals,
  total: state.pmpDeals.total,
  loading: state.pmpDeals.loading,
  apiValidation: state.pmpDeals.apiValidation,
});

const mapDispatchToProps = dispatch => ({
  retrieveDeals: opt => dispatch(retrieveDeals(opt)),
  saveDeal: (id, deal) => dispatch(saveDeal(id, deal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrivateMarketplaceOverview));
