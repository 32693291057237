import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Card, Icon, List } from 'semantic-ui-react';
import Clipboard from 'clipboard';

import './styles/CampaignsList.css';

const CampaignsList = ({ campaigns }) => {
  const initClipboard = () => new Clipboard('#campaigns-copy');

  if (campaigns.length === 0) {
    return (
      <p>No campaigns attached in this creative</p>
    );
  }

  initClipboard();

  return (
    <Fragment>
      <List className="campaignsList" divided relaxed>
        {campaigns.map((campaign) => {
          const campaignStatus = campaign.active ? 'active' : 'paused';
          return (
            <List.Item key={campaign.campaign_id}>
              <List.Content>
                <List.Header>
                  <b>Status: </b>
                  <span className={`status--${campaignStatus}`}>
                    {campaignStatus}
                  </span>
                </List.Header>
                <List.Description>
                  <b>Name: </b> {campaign.name}
                </List.Description>
                <List.Description>
                  <b>Id: </b> {campaign.campaign_id}
                </List.Description>
              </List.Content>
            </List.Item>
          );
        })}
      </List>
      {campaigns.length > 1 && (
        <Fragment>
          <div className="campaignsIDsHeader">
            <h3>Campaigns IDs</h3>
            <Icon name="copy" id="campaigns-copy" data-clipboard-target="#campaignIDs" />
          </div>
          <Card id="campaignIDs">
            {campaigns.map(campaign => (
              <span className="campaignID" key={campaign.campaign_id}>
                {campaign.campaign_id}
              </span>
            ))}
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
};

CampaignsList.propTypes = { campaigns: PropTypes.arrayOf(PropTypes.shape({})).isRequired };

export default CampaignsList;
