import getter from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import setter from 'lodash/set';
import toPath from 'lodash/toPath';
import { Dropdown, Form } from 'semantic-ui-react';

import { BLOCK_APPS_BEHAVIORS } from '../constants';

const BlockAppsBehavior = (props) => {
  const { bidder, setBidder } = props;
  const path = 'request_processing.bapp_behavior';

  const onChange = (event, ownProps) => {
    const { value } = ownProps;
    const nextBappBehavior = pick(bidder, head(toPath(path)));
    setter(nextBappBehavior, path, value);
    setBidder(bidder.id, nextBappBehavior);
  };

  const bappBehavior = getter(bidder, path, 'all');

  return (
    <Form.Field>
      <label htmlFor="bidder-bapp-behavior">Block Publisher apps behavior</label>
      <Dropdown
        fluid
        id="bidder-bapp-behavior"
        onChange={onChange}
        options={map(BLOCK_APPS_BEHAVIORS, behavior => behavior)}
        placeholder="Select Block Publisher apps behavior"
        selection
        value={bappBehavior}
      />
    </Form.Field>
  );
};

export default BlockAppsBehavior;

BlockAppsBehavior.defaultProps = {
  bidder: {},
  setBidder: () => {},
};

BlockAppsBehavior.propTypes = {
  bidder: PropTypes.shape({}),
  setBidder: PropTypes.func,
};

