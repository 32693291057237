import React from 'react';
import { Segment, Header, Icon } from 'semantic-ui-react';

const CBExchangePlaceholder = () => (
  <Segment className="place-holder" textAlign="center" padded="very">
    <Header icon>
      <Icon name="search" />
      Search apps by app id or publisher id <br />
      To Block Bundles please pick apps from the same platform
    </Header>
  </Segment>
);

export default CBExchangePlaceholder;
