import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Menu } from 'semantic-ui-react';

const CBExchangeMenu = ({ handleCancel }) => (
  <div>
    <Menu inverted secondary stackable compact>
      <Menu.Item onClick={handleCancel} active>Apps</Menu.Item>
    </Menu>
    <Divider />
  </div>
);
CBExchangeMenu.defaultProps = { handleCancel: () => {} };

CBExchangeMenu.propTypes = { handleCancel: PropTypes.func };

export default CBExchangeMenu;
