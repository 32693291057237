import * as fromBidders from '../actions';

const isFetchingDeviceLists = (state = false, action = {}) => {
  switch (action.type) {
    case fromBidders.FETCH_DEVICE_LISTS:
      return true;

    case fromBidders.FETCH_DEVICE_LISTS_FAILURE:
    case fromBidders.FETCH_DEVICE_LISTS_SUCCESS:
      return false;

    default:
      return state;
  }
};

export default isFetchingDeviceLists;
