import differenceWith from 'lodash/differenceWith';
import getter from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown, Form } from 'semantic-ui-react';

import { CREATIVE_ATTRIBUTE_OPTIONS } from '../../shared/constants';
import { objectComparator } from '../../shared/helpers';

const BlockCreativeAttributes = (props) => {
  const { addListItems, bidder, label, path } = props;
  const blockedCreativeTypes = getter(bidder, path, []);

  const sortedOptions = differenceWith(
    CREATIVE_ATTRIBUTE_OPTIONS,
    blockedCreativeTypes,
    (attribute, blockedAttribute) => attribute.value === blockedAttribute,
  )
    .sort(objectComparator('text'));

  const onClick = (event, ownProps) => {
    addListItems(bidder, path, ownProps.value);
  };

  return (
    <Form.Field>
      <label>{label}</label>
      <Dropdown
        fluid
        multiple
        onChange={onClick}
        options={sortedOptions}
        placeholder="Select creative types"
        search
        selection
      />
    </Form.Field>
  );
};

export default BlockCreativeAttributes;

BlockCreativeAttributes.defaultProps = {
  addListItems: () => {},
  bidder: {},
  path: '',
};

BlockCreativeAttributes.propTypes = {
  addListItems: PropTypes.func,
  bidder: PropTypes.shape({}),
  path: PropTypes.string,
};

