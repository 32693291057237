import Clipboard from 'clipboard';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Header, Label, Segment } from 'semantic-ui-react';

class JsonPreview extends React.Component {
  constructor(props) {
    super(props);
    this.clipboard = { destroy: () => {} };
    this.state = { showCopied: false };
  }

  componentDidMount() {
    this.clipboard = new Clipboard('.copy.button');
    this.clipboard.on('success', (e) => {
      e.clearSelection();
      this.setState({ showCopied: true });
      setTimeout(() => { this.setState({ showCopied: false }); }, 1000);
    });
  }

  componentWillUnmount() {
    this.clipboard.destroy();
  }

  render() {
    const { toStringify, title, shouldShowButton } = this.props;
    const prettyJson = JSON.stringify(toStringify, null, 2);

    const renderButton = (shouldRender) => {
      if (!shouldRender) return <span />;
      return (
        <Button
          basic
          className="copy button"
          content="Copy JSON"
          data-clipboard-target="#json-preview"
          icon="copy"
          type="button"
        />
      );
    };

    return (
      <div>
        <Header as="h3" content={title} icon="code" inverted />
        <Segment inverted><pre id="json-preview">{prettyJson}</pre></Segment>
        {renderButton(shouldShowButton)}
        {this.state.showCopied && <Label basic color="green" pointing="left">Copied!</Label>}
      </div>
    );
  }
}

export default JsonPreview;

JsonPreview.defaultProps = {
  toStringify: {},
  title: 'JSON Preview',
  shouldShowButton: true
};

JsonPreview.propTypes = {
  toStringify: PropTypes.shape({}),
  title: PropTypes.string,
  shouldShowButton: PropTypes.bool
};
