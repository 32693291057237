import { ANALYTICS_API, DASH_API } from '../../shared/api';
import { LOADED, LOADING } from '../../shared/constants/readyStates';
import { xhr, axios, CancelToken } from '../../shared/helpers';

export const LOADING_API_REQUEST = 'PUBLISHING_APPS.LOADING_API_REQUEST';
export const LOADED_API_REQUEST = 'PUBLISHING_APPS.LOADED_API_REQUEST';
export const RETRIEVED_APPS = 'PUBLISHING_APPS.RETRIEVED_APPS';
export const RETRIEVED_APPS_ANALYTICS = 'PUBLISHING_APPS.RETRIEVED_APPS_ANALYTICS';
export const APPROVED_APP = 'PUBLISHING_APPS.APPROVED_APP';
export const REJECTED_APP = 'PUBLISHING_APPS.REJECTED_APP';
export const REREVIEW_APP = 'PUBLISHING_APPS.REREVIEW_APP';
export const ERROR_RETIRIEVING_APPS = 'PUBLISHING_APPS.ERROR_RETRIEVING_APPS';
export const ERROR_APPROVING_APP = 'PUBLISHING_APPS.ERROR_APPROVING_APP';
export const ERROR_REJECTING_APP = 'PUBLISHING_APPS.ERROR_REJECTING_APP';
export const ERROR_REREVIEWING_APP = 'PUBLISHING_APPS.ERROR_REREVIEWING_APP';

let cancel;

const retrieveAppsAnalytics = async (appIds, dispatch) => {
  try {
    const today = new Date();
    const weekAgoDay = new Date();
    weekAgoDay.setDate(today.getDate() - 6);
    const response = await axios.get(
      `${ANALYTICS_API}/app`,
      {
        params: {
          appId: appIds,
          timeRange: 'lastweek',
          dateMinWord: 'lastweek',
          groupBy: 'app',
          platform: 'iOS,Google Play,Amazon',
          aggregate: 'daily',
          dateMin: weekAgoDay.toISOString().slice(0, 10),
          dateMax: today.toISOString().slice(0, 10),
        },
      },
    );
    dispatch({ type: RETRIEVED_APPS_ANALYTICS, metrics: response.data });
  } catch (error) {
    // avoid blocking apps loading in case analytics are not available
    console.error('[Analytics]', error);
  }
};

export const retrieveAppsWithMetrics = (status, withMetrics = false) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    if (typeof cancel === 'function') cancel();
    const { data } = await axios.get(`${DASH_API}/apps/publishing/${status}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    });
    dispatch({ type: RETRIEVED_APPS, apps: data });

    if (withMetrics) {
      await retrieveAppsAnalytics(data.map(({ app }) => app._id), dispatch);
    }

    dispatch({ type: LOADED });
  } catch (error) {
    dispatch({ type: ERROR_RETIRIEVING_APPS, error });
  }
};

export const searchApps = (query, appStatus) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    if (typeof cancel === 'function') cancel();
    const response = await axios.get(`${DASH_API}/apps/publishing/search?query=${query}&status=${appStatus}`);
    dispatch({ type: RETRIEVED_APPS, apps: response.data });
    dispatch({ type: LOADED });
  } catch (error) {
    dispatch({ type: ERROR_RETIRIEVING_APPS, error });
  }
};

export const approveApp = (appId, notes) => async (dispatch) => {
  dispatch({ type: LOADING_API_REQUEST, appId });
  try {
    const publishingApp = await xhr(`${DASH_API}/apps/publishing/${appId}/approve`, { reviewer_notes: notes }, { method: 'POST' });
    dispatch({
      type: APPROVED_APP,
      app: publishingApp.app,
      review_user: publishingApp.review_user,
    });
    dispatch({ type: LOADED_API_REQUEST, appId });
  } catch (error) {
    dispatch({ type: ERROR_APPROVING_APP, error });
  }
};

export const rejectApp = (appId, notes, rejectReason, rejectDetail) => async (dispatch) => {
  dispatch({ type: LOADING_API_REQUEST, appId });
  try {
    const publishingApp = await xhr(`${DASH_API}/apps/publishing/${appId}/reject`, { reviewer_notes: notes, reject_reason: rejectReason, reject_detail: rejectDetail }, { method: 'POST' });
    dispatch({
      type: REJECTED_APP,
      app: publishingApp.app,
      review_user: publishingApp.review_user,
    });
    dispatch({ type: LOADED_API_REQUEST, appId });
  } catch (error) {
    dispatch({ type: ERROR_REJECTING_APP, error });
  }
};

export const reReviewApp = (appId, notes) => async (dispatch) => {
  dispatch({ type: LOADING_API_REQUEST, appId });
  try {
    const publishingApp = await xhr(`${DASH_API}/apps/publishing/${appId}/rereview`, { reviewer_notes: notes }, { method: 'POST' });
    dispatch({
      type: REREVIEW_APP,
      app: publishingApp.app,
      review_user: publishingApp.review_user,
    });
    dispatch({ type: LOADED_API_REQUEST, appId });
  } catch (error) {
    dispatch({ type: ERROR_REREVIEWING_APP, error });
  }
};
