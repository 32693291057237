import filter from 'lodash/filter';
import xor from 'lodash/xor';
import { isNotAmazon } from './helpers';

import {
  FETCH_CAMPAIGNS_FAILURE,
  FETCH_PUBLISHER_SUCCESS,
  FETCH_CAMPAIGNS_SUCCESS,
  SET_PUBLISHER,
  SAVE_PUBLISHER_SUCCESS,
  TOGGLE_BIDDER,
} from './actions';

const defaultState = {
  campaigns: [],
  publisher: {
    badv: [],
    bapp: [],
    bcat: [],
    dsp_blocklist: [],
    last_exchanged_modified: '',
    mode: 0,
    platform: '',
  },
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case FETCH_CAMPAIGNS_SUCCESS:
      return { ...state, campaigns: filter(action.campaigns, isNotAmazon) };

    case FETCH_CAMPAIGNS_FAILURE:
      return { ...state, campaigns: [] };

    case FETCH_PUBLISHER_SUCCESS:
    case SAVE_PUBLISHER_SUCCESS:
      return { ...state, publisher: action.publisher };

    case SET_PUBLISHER:
      return { ...state, publisher: { ...state.publisher, ...action.attrs } };

    case TOGGLE_BIDDER:
      return {
        ...state,
        publisher: {
          ...state.publisher,
          dsp_blocklist: xor(state.publisher.dsp_blocklist, [action.id]),
        },
      };

    default:
      return state;
  }
}
