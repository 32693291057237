import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input } from 'semantic-ui-react';

const PrivateMarketplaceSearch = (props) => {
  const { query, updateQueryState, searchDeals } = props;

  return (
    <div style={{ textAlign: 'right' }}>
      <Form onSubmit={searchDeals}>
        <Form.Field inline>
          <Input
            className="prompt"
            type="text"
            icon="search"
            placeholder="Search..."
            value={query}
            onChange={e => updateQueryState(e.target.value)}
          />
        </Form.Field>
      </Form>
    </div>
  );
};

PrivateMarketplaceSearch.propTypes = {
  searchDeals: PropTypes.func.isRequired,
  updateQueryState: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
};

export default PrivateMarketplaceSearch;
