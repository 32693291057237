/**
 * @namespace core/api
 */

import 'whatwg-fetch';
import querystring from 'querystring';
import { getAuthHeadersByUrl } from '../shared/helpers';


/**
 * @function parseResponse
 * @param string res - xmlHttpResponse from fetch()
 * @description converts response text to json if content-type is not text/html
 * @returns object - json response from api
 * @memberof core/api
 */

const parseResponse = (res) => {
  if (res.ok) {
    switch (res.headers.get('Content-Type')) {
      case 'text/html': return res.text();
      default: return res.json();
    }
  }
  const err = new Error(res.statusText);
  err.response = res;
  throw err;
};

/**
 * @function xhr
 * @description performs xhr requests
 * @memberof core/api
 */

const xhr = (url, data, options = {}) => {
  const { credentials = 'include', encode, method, multipart, token } = options;
  let request = { credentials, method: method || (data ? 'POST' : 'GET') };
  let headers = { 'Accept-Encoding': 'gzip' };

  if (token) {
    const authHeaders = getAuthHeadersByUrl(url);
    headers = { ...headers, ...authHeaders };
  }

  if (data) {
    const body = encode ? querystring.stringify(data) : JSON.stringify(data);
    const type = encode ? 'application/x-www-form-urlencoded' : 'application/json';
    headers = { ...headers, Accept: 'application/json', 'Content-Type': type };
    request = { ...request, body, headers };
  } else {
    request = { ...request, headers };
  }
  if (multipart) {
    request.body = data;
    delete request.headers['Content-Type'];
  }
  return fetch(url, request).then(parseResponse);
};

/**
 * @function get
 * @description performs a get requests
 * @memberof core/api
 */

const get = xhr;

/**
 * @function post
 * @description performs a post request
 * @memberof core/api
 */

const post = xhr;

/**
 * @function patch
 * @description performs a patch requests
 * @memberof core/api
 */

const patch = (url, data, options) => xhr(url, data, { ...options, method: 'PATCH' });

/**
 * @function del
 * @description performs a del requests
 * @memberof core/api
 */

const del = (url, data, options) => xhr(url, data, { ...options, method: 'DELETE' });


const Api = {
  ANALYTICS_API: process.env.REACT_APP_ANALYTICS_API,
  DASH_API: process.env.REACT_APP_DASH_API,
  DSP_API: `${process.env.REACT_APP_API_GATEWAY}/dsp_service`,
  SEGMENTS_API: process.env.REACT_APP_SEGMENTS_API,
  INTEGRATIONS_API: `${process.env.REACT_APP_API_GATEWAY}/ssp_integrations_service`,
  SSP_REPORTING_API: process.env.REACT_APP_SSP_REPORTING_API,
  parseResponse,
  patch,
  xhr,
  post,
  get,
  del,
};

export default Api;
