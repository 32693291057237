import originalAxios from 'axios';
import 'whatwg-fetch';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import querystring from 'querystring';
import transform from 'lodash/transform';
import moment from 'moment';

import { parseValue } from './reducers';
import { getAuthToken, getTokenFromAuth0 } from '../store/localStorage';

import { DASH_API } from './api';
import { SSP_SERVICE_BASE_URLS, API_GATEWAY_SSP_SERVER_HEADER_NAME } from './constants';

const flexbidUrl = `${DASH_API}/flexbids`;
const pmpDealUrl = `${DASH_API}/pmp/deals`;


export const getAuthHeadersByUrl = url => {
  const headerValue = `Bearer ${getTokenFromAuth0()}`;
  const headers = { Authorization: headerValue };

  if (SSP_SERVICE_BASE_URLS.some(sspUrl => url.startsWith(sspUrl))) {
    headers[API_GATEWAY_SSP_SERVER_HEADER_NAME] = `Bearer ${getAuthToken()}`;
  }

  return headers;
};

export const parseResponse = (res) => {
  if (res.ok) {
    switch (res.headers.get('Content-Type')) {
      case 'text/html': return res.text();
      default:

        if (res.statusText.toUpperCase() === 'NO CONTENT') {
          return {};
        }

        if (res.status === 204) {
          return {};
        }

        return res.json();
    }
  }
  if (res.url === flexbidUrl) {
    return res.json().then((data) => {
      throw data;
    });
  }
  if (res.url === pmpDealUrl) {
    return res.json().then((data) => {
      throw data;
    });
  }
  if (res.url.indexOf('pricing_config') > -1) {
    return res.json().then(data => ({ patchError: data, patchErrorCode: res.status }));
  }
  const err = new Error(res.statusText);

  err.response = res;
  throw err;
};

/**
 * Send an XMLHttpRequest
 *
 * @param  {String}  url     - request endpoint
 * @param  {Object}  data    - JSON payload to send
 * @param  {Object}  options - other options
 * @param  {Boolean} options.encode - should the data be urlEncoded?
 * @param  {String}  options.method - HTTP method, e.g. 'PUT'
 * @return {Promise}
 */
export const xhr = (url, data, options = {}) => {
  const { credentials = 'include', encode, method, multipart, token = true } = options;
  let request = { credentials, method };
  let headers = {};

  if (token) {
    const authHeaders = getAuthHeadersByUrl(url);
    headers = { ...headers, ...authHeaders };
  }

  if (data) {
    const body = encode ? querystring.stringify(data) : JSON.stringify(data);
    const type = encode ? 'application/x-www-form-urlencoded' : 'application/json';
    headers = { ...headers, Accept: 'application/json', 'Content-Type': type };
    request = { ...request, body, headers };
  } else {
    request = { ...request, headers };
  }
  if (multipart) {
    request.body = data;
    delete request.headers['Content-Type'];
  }
  return fetch(url, request).then(parseResponse);
};

export const get = (url, data, options) => xhr(url, data, { ...options, method: 'GET' });
export const post = (url, data, options) => xhr(url, data, { ...options, method: 'POST' });
export const patch = (url, data, options) => xhr(url, data, { ...options, method: 'PATCH' });
export const del = (url, data, options) => xhr(url, data, { ...options, method: 'DELETE' });
export const put = (url, data, options) => xhr(url, data, { ...options, method: 'PUT' });

export const axios = originalAxios.create({
  withCredentials: true,
  headers: { Authorization: `Bearer ${getTokenFromAuth0()}` }
});
export const CancelToken = originalAxios.CancelToken;

/**
 * Split a string of comma or whitespace-separated ObjectIds into an array
 *
 * @param  {String} idString - comma or whitespace-separated ObjectIds
 * @return {[ObjectId]} array of ObjectIds
 */
export const splitIds = idString => idString.split(/[\s,]+/).filter(Boolean);

/**
 * Very simple throttle function, so we don't have to import Lodash
 *
 * @param  {Function} callback - function to be throttled
 * @param  {Number}   limit    - waiting period in ms
 * @return {Function}          - new function won't execute more often than `limit`
 */
export const throttle = (callback, limit = 1000) => {
  let wait = false;
  return () => {
    if (!wait) {
      callback.call();
      wait = true;
      setTimeout(() => { wait = false; }, limit);
    }
  };
};

/**
 * Tests if a given string is a valid Mongo ObjectId
 *
 * @param  {String} id - string to evaluate
 * @return {Boolean}   - is string a valid Mongo ObjectId?
 */
export const isObjectId = id => /^[0-9a-fA-F]{24}$/.test(id);

const toShortISO = date => date.toISOString().split('T')[0];

export const getISODate = (keyword) => {
  const today = new Date();
  switch (keyword) {
    case 'yesterday':
      return toShortISO(new Date(today.setDate(today.getDate() - 1)));

    case 'monthStart':
      return moment().utc().startOf('month').format('YYYY-MM-DD');

    case 'monthEnd':
      return moment().utc().endOf('month').format('YYYY-MM-DD');

    case 'today':
    default:
      return toShortISO(today);
  }
};

export const shouldCache = process.env.REACT_APP_CACHING === 'true';

export const getRouteParams = ownProps => ownProps.match.params;

export const handleSemanticEvent = (callback, ...args) => (event, data) =>
  callback(...args, data.name, parseValue(data.value || data.checked));

export function diff(object, base) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = isObject(value) && isObject(base[key]) ? diff(value, base[key]) : value; // eslint-disable-line no-param-reassign, max-len
    }
  });
}

export const advancedSearch = searchTerm => (entity) => {
  if (searchTerm === '') { return true; }
  return searchTerm.split(' ').every((clause) => {
    const [key, value] = clause.split(':');
    if (!value && entity.name) { return entity.name.toLowerCase().includes(key.toLowerCase()); }
    if (key === 'is') { return entity[value] === true; }
    return entity[key] == value; // eslint-disable-line eqeqeq
  });
};


export const getRandomId = () => Math.random().toString(36).substring(2, 15);

/**
* Base64 encodes a string
* @param {String} str - String to be encoded
* @returns {String} A base64 encoded string
*/
export const b64EncodeUnicode = str => (
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  btoa(encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    (match, p1) => String.fromCharCode(`0x${p1}`),
  ))
);


const isMessageObject = (element) => element.some((value) => typeof value === 'object');

export const getDashApiErrorMessage = (message) => {
  if (isMessageObject(message)) {
    const lastMessage = [];
    message.forEach((dimension) => {
      Object.keys(dimension).forEach(key => {
        lastMessage.push(getDashApiErrorMessage(dimension[key]).toString());
      });
    });
    return lastMessage;
  }
  return message;
};
