import { LOADED, LOADING, UNLOADED } from '../../../shared/constants/readyStates';
import {
  getApps,
  postAppBlockedAdvertiserBundles,
  postAppblockedAdomains,
} from '../../../services/apps';

import { getAppsSelected } from './reducer';
import AppIdList, { MaxApps } from '../../../domain/AppIdList';
import AdomainList, { MaxAdomains } from '../../../domain/AdomainList';

export const UPDATE_STATUS = 'APPS.UPDATE_STATUS';
export const SET_QUERY = 'APPS.SET_QUERY';
export const FETCH_APPS = 'APPS.FETCH';
export const RESET_APPS = 'APPS.RESET';
export const ERROR_FETCH_APPS = 'APPS.ERROR_FETCH';
export const SORT_APPS = 'APPS.SORT';
export const SELECTED_APPS = 'APPS.SELECTED';
export const SELECT_PLATFORM = 'APPS.SELECT_PLATFORM';
export const SAVE_ADOMAINS = 'APPS.SAVE_ADOMAINS';
export const SAVE_ADOMAINS_ERROR = 'APPS.SAVE_ADOMAINS_ERROR';
export const SAVE_BUNDLES = 'APPS.SAVE_BUNDLES';
export const SAVE_BUNDLES_ERROR = 'APPS.SAVE_BUNDLES_ERROR';
export const IDS_ADDED_OR_REMOVED = 'APPS.IDS_ADDED_OR_REMOVED';

export function appsStatus(status) {
  return { type: UPDATE_STATUS, status };
}

export const setPlatform = platform => async (dispatch) => {
  dispatch({ type: SELECT_PLATFORM, platform });
};

export const setQuery = query => async (dispatch) => {
  dispatch({ type: SET_QUERY, query });
};

export const resetApps = () => async (dispatch) => {
  dispatch({ type: SET_QUERY, query: '' });
  dispatch({ type: RESET_APPS });
  dispatch(appsStatus(UNLOADED));
};

export const fetchApps = (queryObject = {}) => async (dispatch) => {
  dispatch(appsStatus(LOADING));
  try {
    const apps = await getApps({ query: queryObject.query });
    dispatch({ type: FETCH_APPS, apps, platform: queryObject.platform });
    dispatch(appsStatus(LOADED));
    return apps;
  } catch ({ message }) {
    dispatch({ type: ERROR_FETCH_APPS, error: message, message: ['fetch-failure', 'Apps'] });
    dispatch(appsStatus(LOADED));
    return [];
  }
};

export const sortApps = sortBy => async (dispatch) => {
  dispatch({ type: SORT_APPS, sortBy });
};

export const selectApps = appsSelected => async (dispatch) => {
  dispatch({ type: SELECTED_APPS, appsSelected });
};

export const successfuldIdsAddedOrRemoved = idsAddedOrRemoved => async (dispatch) => {
  dispatch({ type: IDS_ADDED_OR_REMOVED, idsAddedOrRemoved });
};


export const saveBundles = (idsToAdd, add) => async (dispatch, getState) => {
  try {
    const appsSaved = [];
    const appsSelected = getAppsSelected(getState());
    const promises = [];

    appsSelected.forEach((app) => {
      let newListOfIds = [];
      if (add) {
        newListOfIds = AppIdList.addAppIdsToList(idsToAdd, app.getPlatformId(), app.blocked_advertiser_bundles);
      } else {
        newListOfIds = app.blocked_advertiser_bundles.filter(id => (
          !idsToAdd.includes(id)
        ));
      }
      const body = { blocked_advertiser_bundles: newListOfIds };

      if (newListOfIds.length <= MaxApps) {
        promises.push(postAppBlockedAdvertiserBundles(app, body)
          .then(response => response)
          .catch(() => ({ id: app.id, error: true })));
      }
    });

    await Promise.all(promises)
      .then((response) => {
        response.forEach((result) => {
          if (!result.error) {
            appsSaved.push(result.id);
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE_BUNDLES_ERROR,
          message: ['delete-failure', 'Bundles'],
        });
      });

    if (appsSaved.length === 0) {
      throw new Error();
    } else {
      dispatch(selectApps(appsSaved));
      dispatch(successfuldIdsAddedOrRemoved(idsToAdd));
      dispatch({
        type: SAVE_BUNDLES,
        message: ['save-success', 'Bundles'],
      });
      return true;
    }
  } catch (error) {
    dispatch({ type: SAVE_BUNDLES_ERROR, error: error.message, message: ['save-failure', 'Bundles'] });
    throw error;
  }
};

export const saveAdomains = (idsToAdd, add) => async (dispatch, getState) => {
  try {
    const appsSaved = [];
    const appsSelected = getAppsSelected(getState());
    const promises = [];
    appsSelected.forEach((app) => {
      let newListOfIds = [];
      if (add) {
        newListOfIds = AdomainList.addAdomainToList(idsToAdd, app.blocked_adomains);
      } else {
        newListOfIds = app.blocked_adomains.filter(id => (
          !idsToAdd.includes(id)
        ));
      }
      const body = { blocked_adomains: newListOfIds };
      if (newListOfIds.length <= MaxAdomains) {
        promises.push(postAppblockedAdomains(app, body)
          .then(response => (response))
          .catch(() => ({ id: app.id, error: true })));
      }
    });

    await Promise.all(promises)
      .then((responses) => {
        responses.forEach((response) => {
          if (!response.error) {
            appsSaved.push(response.id);
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE_ADOMAINS_ERROR,
          message: ['delete-failure', 'Adomains'],
        });
      });

    if (appsSaved.length === 0) {
      throw new Error();
    } else {
      dispatch(selectApps(appsSaved));
      dispatch(successfuldIdsAddedOrRemoved(idsToAdd));
      dispatch({
        type: SAVE_ADOMAINS,
        message: ['save-success', 'Adomains'],
      });
    }
  } catch (error) {
    dispatch({ type: SAVE_ADOMAINS_ERROR, error: error.message, message: ['save-failure', 'Adomains'] });
    throw error;
  }
};
