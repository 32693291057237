import { LOADED, LOADING } from '../../../shared/constants/readyStates';
import { getDeals, getDeal, postDeals, deleteDeal } from '../../../services/pmpDeal';
import getDSPs from '../../../services/dsp';
import getCountries from '../../../services/countries';
import getSegments from '../../../services/segments';
import { getDashApiErrorMessage } from '../../../shared/helpers';

export const LOADING_API_REQUEST = 'PMP.LOADING_API_REQUEST';
export const LOADED_API_REQUEST = 'PMP.LOADED_API_REQUEST';
export const RETRIEVED_DEALS = 'PMP.RETRIEVED_DEALS';
export const SAVE_DEAL = 'PMP.SAVE_DEAL';
export const FETCH_DEAL = 'PMP.FETCH_DEAL';
export const UPDATE_DEAL = 'PMP.UPDATE_DEAL';
export const DELETE_DEAL = 'PMP.DELETE_DEAL';
export const ERROR_RETRIEVING_DEALS = 'PMP.ERROR_RETRIEVING_DEALS';
export const ERROR_DELETING_DEAL = 'PMP.ERROR_DELETING_DEAL';
export const ERROR_RETRIEVING_DSPS = 'PMP.ERROR_RETRIEVING_DSPS';
export const FETCH_SEGMENTS_FAILURE = 'PMP.FETCH_SEGMENTS_FAILURE';
export const FETCH_COUNTRIES_FAILURE = 'PMP.FETCH_COUNTRIES_FAILURE';
export const FETCH_DSPS = 'PMP.FETCH_DSPS';
export const FETCH_SEGMENTS = 'PMP.FETCH_SEGMENTS';
export const FETCH_COUNTRIES = 'PMP.FETCH_COUNTRIES';

export const retrieveDeals = (queryObject = {}) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await getDeals(queryObject);
    dispatch({
      type: RETRIEVED_DEALS,
      data,
    });
    dispatch({ type: LOADED });
  } catch (error) {
    dispatch({ type: ERROR_RETRIEVING_DEALS, error });
    dispatch({ type: LOADED });
  }
};

export const retrieveDSPs = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const dsps = await getDSPs();
    dispatch({
      type: FETCH_DSPS,
      dsps,
    });
    dispatch({ type: LOADED });
  } catch (error) {
    dispatch({ type: ERROR_RETRIEVING_DSPS, error });
    dispatch({ type: LOADED });
  }
};

export const saveDeal = (id, bodyDeal) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const iabCategories = String(bodyDeal.dimensions.iab_categories_v2);
    let parsedIabCategories;

    if (iabCategories.trim() === '') {
      parsedIabCategories = [];
    } else {
      parsedIabCategories = iabCategories
        .split(',')
        .map(item => item.trim())
        .filter(Boolean);
    }

    const parsedDeal = {
      ...bodyDeal,
      dimensions: {
        ...bodyDeal.dimensions,
        iab_categories_v2: parsedIabCategories,
      },
    };
    const deal = await postDeals(parsedDeal);
    dispatch({
      type: id === 'new' ? SAVE_DEAL : UPDATE_DEAL,
      deal,
      message: ['save-success', 'PMP Deal'],
    });
    dispatch({ type: LOADED });
  } catch (error) {
    const { errors } = error;
    if (errors && errors.dimensions) {
      errors.dimensions.forEach((dimension) => {
        Object.keys(dimension).forEach(key => {
          const message = getDashApiErrorMessage(dimension[key]);
          dispatch({
            type: ERROR_RETRIEVING_DEALS,
            error: message,
            message: ['save-failure', `PMP Deal > error on Field ${key}: ${message}`]
          });
        });
      });
    } else {
      Object.entries(errors).forEach(([field, messages]) => {
        messages.forEach((message) => {
          dispatch({ type: ERROR_RETRIEVING_DEALS, error: errors, message: ['save-failure', `PMP Deal > '${field}' ${message}`] });
        });
      });
    }
    dispatch({ type: LOADED });
    throw new Error();
  }
};

export const removeDeal = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await deleteDeal(id);
    dispatch({
      type: DELETE_DEAL,
      id,
      message: ['delete-success', `> deal id: ${id}`],
    });
    dispatch({ type: LOADED });
  } catch ({ message }) {
    dispatch({ type: ERROR_DELETING_DEAL, error: message, message: ['delete-failure', 'PMP Deal'] });
    dispatch({ type: LOADED });
  }
};

export const fetchDeal = dealId => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const deal = await getDeal(dealId);
    dispatch({ type: FETCH_DEAL, deal });
    dispatch({ type: LOADED });
  } catch (error) {
    dispatch({ type: ERROR_RETRIEVING_DEALS, error });
    dispatch({ type: LOADED });
  }
};

export const fetchSegments = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const segments = await getSegments();
    dispatch({ type: FETCH_SEGMENTS, segments });
    dispatch({ type: LOADED });
  } catch (error) {
    dispatch({ type: FETCH_SEGMENTS_FAILURE, error });
  }
};

export const fetchCountries = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const countries = await getCountries();

    dispatch({
      type: FETCH_COUNTRIES,
      countries,
    });
    dispatch({ type: LOADED });
  } catch (error) {
    dispatch({ type: FETCH_COUNTRIES_FAILURE, error });
    dispatch({ type: LOADED });
  }
};
