/* eslint-disable react/no-array-index-key */

import partition from 'lodash/partition';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown, Table } from 'semantic-ui-react';

import { lazyRender } from '.';

class CBTable extends Component {
  constructor(props) {
    super(props);
    const { columns, width } = props;
    const [required, optional] = partition(columns, 'required');
    this.state = {
      indexed: columns.reduce((indexed, column) => ({ ...indexed, [column.value]: column }), {}),
      options: columns.filter(col => !col.required).map(({ text, value }) => ({ text, value })),
      selected: [...required, ...optional.slice(0, width - required.length)]
        .map(column => column.value),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { columns, width } = nextProps;
    const [required, optional] = partition(columns, 'required');
    this.setState({
      indexed: columns.reduce((indexed, column) => ({ ...indexed, [column.value]: column }), {}),
      options: columns.filter(col => !col.required).map(({ text, value }) => ({ text, value })),
      selected: [...required, ...optional.slice(0, width - required.length)]
        .map(column => column.value),
    });
  }

  selectColumn(columnIndex, columnValue) {
    const selected = [...this.state.selected];
    selected[columnIndex] = columnValue;
    this.setState({ selected });
  }

  render() {
    const { items } = this.props;
    const { indexed, options, selected } = this.state;

    return (
      <Table size="small" singleLine striped>
        <Table.Header>
          <Table.Row>
            {selected.map((value, index) => {
              const column = indexed[value];
              // Uses the onClick set for the column, if it doesnt exist it becomes undefined
              const clickFunction = column.onClick ? column.onClick : undefined;
              const styleClasses = column.styleClasses ? column.styleClasses : '';
              return (
                <Table.HeaderCell key={index} onClick={clickFunction} className={styleClasses} >
                  {column.required
                    ? column.text
                    : (
                      <Dropdown
                        defaultValue={column.value}
                        onChange={(event, target) => this.selectColumn(index, target.value)}
                        options={options}
                        selection
                      />
                    )
                  }
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map(item => (
            <Table.Row key={item.id}>
              {selected.map((value, index) => {
                const column = indexed[value];
                return (
                  <Table.Cell
                    key={index}
                    textAlign={column.align || 'left'}
                    {...(column.format ? { [column.format(item)]: true } : {})}
                  >
                    {column.render ? column.render(item) : item[column.value] }
                  </Table.Cell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

CBTable.defaultProps = {
  columns: [],
  items: [],
  width: 8,
};

CBTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    required: PropTypes.bool,
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    value: PropTypes.string,
  })),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  width: PropTypes.number,
};

export default lazyRender(CBTable, 'items', 10000);
