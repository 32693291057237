import isEmpty from 'lodash/isEmpty';
import url from 'url';

/**
 * Takes a URL and combines it into a string
 * @param {Object} endpoint - A url broken down into its parts: { protocol, hostname, port, path }
 * @returns {String} The constructed url
 */
export const formatEndpoint = (endpoint = {}) => {
  const { hostname, path, protocol, port } = endpoint;
  const getPort = port || 80;
  const preparedEndpoint = {
    protocol,
    hostname,
    port: protocol === 'https:' ? 443 : getPort,
    pathname: path,
  };
  const formattedEndpoint = decodeURIComponent(url.format(preparedEndpoint));
  return formattedEndpoint.endsWith(protocol) ? `${formattedEndpoint}//` : formattedEndpoint;
};

/**
 * Checks if the bidderId is valid. 24 characters base 16.
 * @param {String} bidderId - Alphanumber bidder id
 * @return {Boolean} true if valid. Otherwise false.
 */
export const isBidderId = (bidderId = '') => /^[a-f0-9]{24}$/.test(bidderId);

/**
 * Returns true if the bidder is new
 * @param {Object} bidder - Bidder attributes
 * @returns True if the bidder is new. False otherwise.
 */
export const isNewBidder = (bidder = {}) => (
  isEmpty(bidder) || (bidder.id || 'new').includes('new')
);

const abbreviateNumber = (num, fixed) => {
  if (num === null) { return null; }
  if (num === 0) { return '0'; }
  let g = fixed;
  g = (!fixed || fixed < 0) ? 0 : fixed;
  const b = (num).toPrecision(2).split('e');
  const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3);
  const c = k < 1 ? num.toFixed(0 + g) : (num / (10 ** (k * 3))).toFixed(1 + g);
  const d = c < 0 ? c : Math.abs(c);
  const e = d + ['', 'K', 'M', 'B', 'T'][k];
  return e;
};

export const formatCreditOrAmount = (creditLimit) => {
  let formattedCreditLimit;
  if (!creditLimit) {
    formattedCreditLimit = '--';
    return formattedCreditLimit;
  }
  return abbreviateNumber(creditLimit);
};

export const formatPriceType = (priceType) => {
  switch (priceType) {
    case 'first-price':
      return '1st';
    case 'second-price':
      return '2nd';
    default:
      return 'err';
  }
};
