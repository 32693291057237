import React from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const AddPMPDeal = (props) => {
  const { path } = props;
  return (
    <Button
      as={Link}
      content="Create a PMP Deal"
      floated="right"
      icon="plus"
      labelPosition="left"
      primary
      to={path}
    />
  );
};

AddPMPDeal.propTypes = { path: PropTypes.string.isRequired };

export default AddPMPDeal;
