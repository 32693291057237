import { b64EncodeUnicode } from '../../../shared/helpers';

/**
* Determines if a given plaintext string is a valid VAST ad
* @param {String} str - Ad string to validate
* @returns {Boolean} Whether or not the string is valid VAST
*/
export const isVastType = (str) => {
  const doc = (new DOMParser()).parseFromString(str, 'text/xml');
  return doc.firstChild.nodeName.toUpperCase() === 'VAST';
};

/**
* Combines the template and the adm to make a playable
* @param {String} templateHtml - A serialized html template
* @param {String} adm - A serialized game to add to the template
* @returns {String} A complete serialized html game
*/
export const createPlayableHtml = (templateHtml, adFormat, adm = '') => {
  const base64Adm = b64EncodeUnicode(adm);
  const base64Template = templateHtml.replace('{% encoding %}', 'base64');
  const playableHtml = base64Template.replace('{% adm %}', base64Adm);
  return playableHtml ? `${playableHtml}<ins datetime="${new Date().toString()}" />` : '';
};

export default createPlayableHtml;
