export const APP_ONLINE = 'APP.ONLINE';
export const APP_RESIZE = 'APP.RESIZE';

export const TOGGLE_NAVBAR = 'APP.NAVBAR';
export const REGISTER_BUNDLE = 'APP.REGISTER_BUNDLE';

export const appOnline = status => ({ type: APP_ONLINE, status });
export const appResize = () => ({
  type: APP_RESIZE,
  dimensions: [window.innerHeight, window.innerWidth]
});
export const registerBundle = name => ({ type: REGISTER_BUNDLE, name });
export const toggleNavbar = visible => ({ type: TOGGLE_NAVBAR, visible });
