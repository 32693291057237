import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Form, Table } from 'semantic-ui-react';
import DemandAggregatorSspRows from './DemandAggregatorSspRows';
import DemandAggregator from '../';

const DemandAggregatorTable = ({ data, loading, dispatch }) => {
  const updateField = (a, b) => {
    let [sspName, field] = b.name.split('.');
    const value = b.hasOwnProperty('checked') ? b.checked : b.value;
    dispatch(DemandAggregator.Actions.updateField(sspName, null, field, value));
  };

  const addNewDspEntry = (a, b) => {
    dispatch(DemandAggregator.Actions.addNewDsp(b.name));
  };

  return (
    <Segment loading={loading}>
      {Object.keys(data).map((ssp, index) => {
        const sspInfo = data[ssp];
        return (
          <Segment key={`segment-${ssp}-${index}`}>
            <Form>
              <h3> {ssp} </h3>
              <Form.Group inline widths="equal">
                <Form.Input
                  fluid
                  label="New DSP Name"
                  name={`${ssp}.new_dsp_name`}
                  value={sspInfo.new_dsp_name}
                  onChange={updateField}
                />
                <Form.Button
                  content={'Add new DSP'}
                  icon="plus"
                  name={ssp}
                  onClick={addNewDspEntry}
                  color="green"
                  style={{ marginTop: '1.4rem' }}
                />
                <Form.Checkbox
                  checked={sspInfo.debug_logging}
                  label="Debug Logging"
                  name={`${ssp}.debug_logging`}
                  onClick={updateField}
                  toggle
                  style={{ marginTop: '1.4rem' }}
                />
                <Form.Checkbox
                  checked={sspInfo.auction_sampling}
                  label="Auction Sampling"
                  name={`${ssp}.auction_sampling`}
                  onClick={updateField}
                  toggle
                  style={{ marginTop: '1.4rem' }}
                />
              </Form.Group>
            </Form>

            {/* Now i do the dsp stuff here. */}

            {Object.keys(sspInfo['eligibleBidders']).length > 0 && (
              <Table size="small" striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Banner</Table.HeaderCell>
                    <Table.HeaderCell>Vast</Table.HeaderCell>
                    <Table.HeaderCell>Mraid</Table.HeaderCell>
                    <Table.HeaderCell>Comments</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Object.keys(sspInfo['eligibleBidders']).map((dsp, index2) => (
                    <DemandAggregatorSspRows
                      key={dsp + index2}
                      sspName={ssp}
                      dspName={dsp}
                      index={index2}
                      data={sspInfo['eligibleBidders'][dsp]}
                      dispatch={dispatch}
                    />
                  ))}
                </Table.Body>
              </Table>
            )}
          </Segment>
        );
      })}
    </Segment>
  );
};

DemandAggregatorTable.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  dispatch: PropTypes.func
};

DemandAggregatorTable.defaultProps = {
  data: {},
  loading: true,
  dispatch: null
};

export default DemandAggregatorTable;
