import dashapi from './dashapi';

export const getFeatures = async (query) => {
  try {
    const { response: features } = await dashapi.get(`features?${query}`);
    return features;
  } catch (error) {
    return error;
  }
};

export const deleteFeature = async (id) => {
  try {
    return await dashapi.delete(`features/${id}`);
  } catch (error) {
    throw error;
  }
};

export const getFlags = async () => {
  try {
    const { response } = await dashapi.get('features/names');
    return response;
  } catch (error) {
    return error;
  }
};

export const addFeature = async (body) => {
  try {
    const { response: feature } = await dashapi.post('features', body);
    return feature;
  } catch (error) {
    throw error;
  }
};
