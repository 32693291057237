import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon, Label } from 'semantic-ui-react';

class WarningTooltip extends Component {
  constructor(props) {
    super(props);

    this.state = { tooltipVisible: false };
  }

  handleToltipVisibility(value) {
    this.setState({ tooltipVisible: value });
  }

  render() {
    const { message } = this.props;
    const { tooltipVisible } = this.state;

    return (
      <div className="item-warning">
        <Icon
          name="warning sign"
          onMouseOver={() => this.handleToltipVisibility(true)}
          onMouseLeave={() => this.handleToltipVisibility(false)}
          onFocus={() => this.handleToltipVisibility(false)}
        />
        {
          tooltipVisible
          && (
          <Label
            basic
            color="red"
            pointing
            className={tooltipVisible ? 'label--active' : ''}
          >
            {message}
          </Label>
        )}
      </div>
    );
  }
}

WarningTooltip.propTypes = { message: PropTypes.string.isRequired };


export default WarningTooltip;
