export default [
  {
    name: 'overview',
    icon: 'pie chart',
    features: [],
    label: 'Overview',
    path: '/',
    roles: ['admin', 'advertiser', 'publisher']
  },
  {
    name: 'search',
    icon: 'search',
    features: [],
    label: 'Search',
    path: '/search',
    roles: ['admin', 'advertiser', 'publisher']
  },
  {
    name: 'exchange',
    icon: 'exchange',
    features: [],
    label: 'Exchange',
    path: '/exchange',
    roles: ['admin', 'bidder']
  },
  {
    name: 'playables_validation',
    icon: 'check',
    features: [],
    label: 'Playables Validation',
    path: `${process.env.REACT_APP_CREATIVES_DASHBOARD_URL}/playables-validation`,
    roles: ['admin', 'studio'],
    externalLink: true
  },
  {
    name: 'yield',
    icon: 'chart line',
    features: [],
    label: 'Yield',
    path: '/yield',
    roles: ['admin']
  },
  {
    name: 'templates',
    icon: 'file alternate outline',
    features: [],
    label: 'Templates',
    path: `${process.env.REACT_APP_CREATIVES_DASHBOARD_URL}/templates`,
    roles: ['admin'],
    externalLink: true
  },
  {
    name: 'publishing_apps',
    icon: 'american sign language interpreting',
    features: [],
    label: 'Publishing Apps',
    path: '/publishing_apps',
    roles: ['admin']
  },
  {
    name: 'integrations',
    icon: 'internet explorer',
    features: [],
    label: 'Integrations',
    path: '/integrations',
    roles: ['admin']
  },
  {
    name: 'pmp',
    icon: 'gavel',
    features: [],
    label: 'PMP',
    path: '/pmp',
    roles: ['admin']
  },
  {
    name: 'users',
    icon: 'users',
    features: [],
    label: 'Users',
    path: '/users',
    roles: ['admin']
  },
  {
    name: 'creatives',
    icon: 'lightbulb outline',
    features: [],
    label: 'Creatives',
    path: '/creatives',
    roles: ['admin']
  },
  {
    name: 'CBExchangeOverview',
    icon: 'exchange',
    features: [],
    label: 'CBExchange',
    path: '/cb-exchange',
    roles: ['admin']
  },
  {
    name: 's2sTracking',
    icon: 'flag checkered',
    features: [],
    label: 'S2S Tracking',
    path: '/s2stracking',
    roles: ['admin']
  },
  {
    name: 'phases',
    icon: 'gem',
    features: [],
    label: 'Rollout Phases',
    path: '/phases',
    roles: ['admin']
  }
];
