import React from 'react';
import { Table } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

const DiscrepancyTableRow = ({ row }) => (
  <Table.Row>
    <Table.Cell>{row.partner}</Table.Cell>
    <Table.Cell>{row.timezone || 'n/a'}</Table.Cell>
    <Table.Cell>{row.dt}</Table.Cell>
    <Table.Cell>
      <NumberFormat
        value={row.impressions.cb}
        displayType={'text'}
        decimalScale={0}
        thousandSeparator={true}
      />
    </Table.Cell>
    <Table.Cell>
      <NumberFormat
        value={row.impressions.partner}
        displayType={'text'}
        decimalScale={0}
        thousandSeparator={true}
      />
    </Table.Cell>
    <Table.Cell>
      <NumberFormat
        value={row.impressions.cb - row.impressions.partner}
        displayType={'text'}
        decimalScale={0}
        thousandSeparator={true}
      />
    </Table.Cell>
    <Table.Cell>
      <NumberFormat
        value={row.impressions.discrepancy}
        displayType={'text'}
        suffix={'%'}
        decimalScale={2}
      />
    </Table.Cell>
    <Table.Cell>
      <NumberFormat
        value={row.money.cb}
        displayType={'text'}
        decimalScale={2}
        thousandSeparator={true}
      />
    </Table.Cell>
    <Table.Cell>
      <NumberFormat
        value={row.money.partner}
        displayType={'text'}
        decimalScale={2}
        thousandSeparator={true}
      />
    </Table.Cell>
    <Table.Cell>
      <NumberFormat
        value={row.money.cb - row.money.partner}
        displayType={'text'}
        decimalScale={2}
        thousandSeparator={true}
      />
    </Table.Cell>
    <Table.Cell>
      <NumberFormat
        value={row.money.discrepancy}
        displayType={'text'}
        suffix={'%'}
        decimalScale={2}
      />
    </Table.Cell>
  </Table.Row>
);

DiscrepancyTableRow.defaultProps = {
  row: {}
};

export default DiscrepancyTableRow;
