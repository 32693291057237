import Api from '../../../../src/core/api';
import DemandAggregatorHelpers from './helpers';

const getData = () =>
  Api.get(`${Api.INTEGRATIONS_API}/ssp`, null, {
    token: true
  });

const saveData = (data, originalData) =>
  Api.post(
    `${Api.INTEGRATIONS_API}/ssp`,
    DemandAggregatorHelpers.formatPayload(data, originalData),
    {
      token: true
    }
  );

const DemandAggregatorApi = {
  getData,
  saveData
};

export default DemandAggregatorApi;
