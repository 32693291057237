import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import PublishingAppSearch from './PublishingAppSearch';

export default class PublishingAppMenu extends React.Component {
  constructor() {
    super();
    this.state = { activeItem: 'pending' };
    this.handleItemClick = this.handleItemClick.bind(this);
    this.retrieveApps = this.retrieveApps.bind(this);
  }

  handleItemClick(e, { name }) {
    this.setState({ activeItem: name });
    this.retrieveApps(name);
  }

  retrieveApps(status) {
    const { retrieveApps } = this.props;
    retrieveApps(status);
  }

  render() {
    const { activeItem } = this.state;
    return (<Menu>
      <Menu.Item
        name="pending"
        active={activeItem === 'pending'}
        onClick={this.handleItemClick}
      >
        Pending review
      </Menu.Item>
      <Menu.Item
        name="rejected"
        active={activeItem === 'rejected'}
        onClick={this.handleItemClick}
      >
        Rejected
      </Menu.Item>
      <Menu.Item
        name="approved"
        active={activeItem === 'approved'}
        onClick={this.handleItemClick}
      >
        Last Approved
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item>
          <PublishingAppSearch searchApps={this.props.searchApps} status={activeItem} />
        </Menu.Item>
      </Menu.Menu>
    </Menu>);
  }
}

PublishingAppMenu.defaultProps = {};

PublishingAppMenu.propTypes = {
  retrieveApps: PropTypes.func.isRequired,
  searchApps: PropTypes.func.isRequired,
};
