import getter from 'lodash/get';
import map from 'lodash/map';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Icon, List } from 'semantic-ui-react';

import { CREATIVE_ATTRIBUTES_BY_ID } from '../../shared/constants';
import { objectComparator } from '../../shared/helpers';

const BlockCreativeAttributesList = (props) => {
  const {
    bidder,
    path,
    removeListItems,
  } = props;

  const onClick = (event, ownProps) => {
    removeListItems(bidder, path, ownProps.value);
  };

  const creativeAttributes = getter(bidder, path, []);
  const pickedAttrs = pick(CREATIVE_ATTRIBUTES_BY_ID, creativeAttributes);
  const sortedAttrs = map(pickedAttrs, creativeAttr => creativeAttr)
    .sort(objectComparator('text'));

  const listItems = sortedAttrs.map(creativeAttr => (
    <List.Item key={`creative-attr-${creativeAttr.value}`}>
      <Button
        basic
        floated="left"
        icon
        onClick={onClick}
        type="button"
        value={creativeAttr.value}
      >
        <Icon name="minus" />
      </Button>
      <List.Content floated="left">
        {creativeAttr.text}
      </List.Content>
    </List.Item>
  ));

  return (
    <List size="small" verticalAlign="middle">
      {listItems}
    </List>
  );
};

export default BlockCreativeAttributesList;

BlockCreativeAttributesList.defaultProps = {
  bidder: {},
  path: '',
  removeListItems: () => {},
};

BlockCreativeAttributesList.propTypes = {
  bidder: PropTypes.shape({}),
  path: PropTypes.string,
  removeListItems: PropTypes.func,
};
