import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import PublishingAppCardAppStatusPending from './PublishingAppCardAppStatusPending';
import PublishingAppCardAppStatusReviewed from './PublishingAppCardAppStatusReviewed';
import PublishingAppCardNotes from './PublishingAppCardNotes';
import PublishingAppCardManagers from './PublishingAppCardManagers';

class PublishingAppCardAppStatus extends React.Component {
  constructor(props) {
    super(props);
    const { publishingApplication } = props;
    this.state = { reviewerNotes: publishingApplication.reviewer_notes };
    this.handleChangeNotes = this.handleChangeNotes.bind(this);
  }

  handleChangeNotes(e) {
    this.setState({ reviewerNotes: e.target.value });
  }

  render() {
    const {
      publishingApplication,
      apiValidation,
      reviewUser,
      appId,
      approveApp,
      rejectApp,
      reReviewApp,
    } = this.props;
    const { reviewerNotes } = this.state;

    const approveAppOnClick = () => {
      approveApp(appId, reviewerNotes);
    };
    const rejectAppOnChange = (e, data) => {
      const dropdownValue = data.value;
      if (dropdownValue !== 'none') {
        const reasons = dropdownValue.split('_');
        rejectApp(appId, reviewerNotes, reasons[0], reasons[1]);
      }
    };
    const reReviewAppOnClick = () => {
      reReviewApp(appId, reviewerNotes);
    };

    const buttonGroup = publishingApplication.status === 'Pending' ?
      (
        <PublishingAppCardAppStatusPending
          appId={appId}
          apiValidation={apiValidation}
          approveApp={approveAppOnClick}
          rejectApp={rejectAppOnChange}
        />
      ) : (
        <PublishingAppCardAppStatusReviewed
          publishing_application={publishingApplication}
          reviewUser={reviewUser}
          reReviewApp={reReviewAppOnClick}
        />
      );
    return (
      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column>
            <PublishingAppCardNotes
              reviewerNotes={reviewerNotes}
              handleChangeNotes={this.handleChangeNotes}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <PublishingAppCardManagers salesforce={this.props.salesforce} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {buttonGroup}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

PublishingAppCardAppStatus.defaultProps = {
  reviewUser: '',
  salesforce: {},
};

PublishingAppCardAppStatus.propTypes = {
  publishingApplication: PropTypes.shape({}).isRequired,
  apiValidation: PropTypes.shape({}).isRequired,
  reviewUser: PropTypes.string,
  appId: PropTypes.string.isRequired,
  approveApp: PropTypes.func.isRequired,
  rejectApp: PropTypes.func.isRequired,
  reReviewApp: PropTypes.func.isRequired,
  salesforce: PropTypes.shape({}),
};

export default PublishingAppCardAppStatus;
