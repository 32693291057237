import { createSelector } from 'reselect';

import validateBidder from './bidders/validator';
import validateGlobals from './globals/validator';
import validatePublisher from './publishers/validator';
import { AD_FORMATS, TEMPLATE_NAMES } from './creatives/constants';
import { by } from './shared/helpers';
import { isVastType } from './creatives/helpers';


const exchangeState = state => state.exchange;

// Loading

export const selectLoading = createSelector(
  exchangeState,
  exchange => exchange.isFetching,
);

// Bidders

export const selectBidders = createSelector(
  exchangeState,
  exchange => Object.values(exchange.bidders.bidders).sort(by('name')),
);

export const selectBidder = bidderId => createSelector(
  selectBidders,
  bidders => bidders.find(bidder => bidder.id === bidderId) || {},
);

export const selectBidderErrors = bidderId => createSelector(
  selectBidder(bidderId),
  bidder => validateBidder(bidder),
);

export const selectBidderHasErrors = bidderId => createSelector(
  selectBidderErrors(bidderId),
  errors => !!Object.keys(errors).length,
);

export const selectCreateBidderErrors = createSelector(
  exchangeState,
  exchange => exchange.bidders.errors,
);

// Globals

export const selectGlobals = createSelector(
  exchangeState,
  exchange => exchange.globals.globals,
);

export const selectGlobalsErrors = createSelector(
  selectGlobals,
  globals => validateGlobals(globals),
);

export const selectGlobalsHasErrors = createSelector(
  selectGlobalsErrors,
  errors => !!Object.keys(errors).length,
);

// Publishers

export const selectPublishers = createSelector(
  exchangeState,
  exchange => exchange.publishers,
);

export const selectPublisher = createSelector(
  selectPublishers,
  publishers => publishers.publisher,
);

export const selectCampaigns = createSelector(
  selectPublishers,
  publishers => publishers.campaigns,
);

export const selectHasCampaigns = createSelector(
  selectCampaigns,
  campaigns => !!campaigns.length,
);

export const selectPublisherErrors = createSelector(
  selectPublisher,
  publisher => validatePublisher(publisher),
);

export const selectPublisherHasErrors = createSelector(
  selectPublisherErrors,
  errors => !!Object.keys(errors).length,
);

// Creative Validation selectors

export const selectCreatives = createSelector(
  exchangeState,
  exchange => exchange.creatives,
);

export const selectRecentCreatives = createSelector(
  selectCreatives,
  creatives => creatives.recentCreatives,
);

export const selectCreative = createSelector(
  selectCreatives,
  creatives => creatives.creative,
);

export const selectTemplates = createSelector(
  selectCreatives,
  creatives => creatives.templates,
);

export const selectCreativeFilters = createSelector(
  selectCreatives,
  creatives => creatives.creativeFilters,
);

export const selectHasCreative = createSelector(
  selectCreatives,
  creatives => ('previewCreative' in creatives) && ('id' in creatives.previewCreative),
);

export const selectCreativeIdFilter = createSelector(
  selectCreativeFilters,
  creativeFilters => creativeFilters.creativeId,
);

export const selectCreativeStatusFilter = createSelector(
  selectCreativeFilters,
  creativeFilters => creativeFilters.status,
);

export const selectCreativeBidderIdFilter = createSelector(
  selectCreativeFilters,
  creativeFilters => creativeFilters.bidderId || 'all',
);

export const selectPreviewCreative = createSelector(
  selectCreatives,
  selectRecentCreatives,
  (creatives, recentCreatives) => {
    const creativeId = creatives.previewCreative.id;
    return recentCreatives.find(({ id }) => creativeId === id);
  },
);

export const selectAdFormat = createSelector(
  selectPreviewCreative,
  (previewCreative) => {
    const adm = (previewCreative || {}).adm || '';
    return isVastType(adm) ? AD_FORMATS.VAST : AD_FORMATS.MRAID;
  },
);

export const selectCurrentTemplate = createSelector(
  selectTemplates,
  selectAdFormat,
  (templates, adFormat) => {
    const templateName = (adFormat === AD_FORMATS.VAST) ?
      TEMPLATE_NAMES.VAST :
      TEMPLATE_NAMES.MRAID;
    return { ...templates[templateName], adFormat };
  },
);
