import {
  FETCH_RECENT_CREATIVES_FAILURE,
  FETCH_RECENT_CREATIVES_SUCCESS,
  UPDATE_CREATIVE_FAILURE,
  UPDATE_CREATIVE_SUCCESS,
} from '../actions';


const recentCreatives = (state = [], action = {}) => {
  switch (action.type) {
    case FETCH_RECENT_CREATIVES_FAILURE: {
      return [];
    }

    case FETCH_RECENT_CREATIVES_SUCCESS: {
      return action.creatives;
    }

    case UPDATE_CREATIVE_FAILURE: {
      return state;
    }

    case UPDATE_CREATIVE_SUCCESS: {
      const { id } = action.data;
      const nextState = state.map((recentCreative) => {
        if (recentCreative.id === id) return action.data;
        return recentCreative;
      });
      return nextState;
    }

    default:
      return state;
  }
};

export default recentCreatives;
