import { FUSION_API } from '../shared/api';
import { del, get } from '../shared/helpers';

export default class FusionAPI {
  static delete(id) {
    return del(`${FUSION_API}/pmp-deals/${id}`, null, { token: true });
  }

  static get(path) {
    return get(`${FUSION_API}/${path}`, null, { token: true });
  }
}
