import React from 'react';
import { Checkbox, Header, Form, Label, Select, Segment } from 'semantic-ui-react';

import { PublisherBlocklistDropdown } from '.';
import { SectionHeader } from '../../../../shared/components';

const PublisherEditForm = ({
  bidders = [],
  errors,
  modes,
  publisher: { badv, bapp, bcat, dsp_blocklist = [], mode } = {},
  setPublisher,
  toggleBidder,
}) => (
  <Form>

    <SectionHeader content="Exchange Mode" icon="list layout" />
    <Select
      name="mode"
      onChange={setPublisher}
      options={modes}
      value={mode !== null ? mode : -1}
    />

    <SectionHeader content="Block Demand Partners" icon="dont" />
    <Segment>
      <Form.Group grouped>
        {bidders.map(({ name, user: id }) => (
          <Form.Field key={`bidders:${id}`}>
            <Checkbox
              checked={dsp_blocklist.includes(id)}
              label={name}
              onChange={toggleBidder(id)}
            />
          </Form.Field>
      ))}
      </Form.Group>
    </Segment>

    <SectionHeader content="Block Lists" icon="dont" />
    <Segment>
      <Header as="h4">Block Domains</Header>
      <Label basic color="red" pointing="below">Blocking adomains on campaigns is deprecated and will not work. <a href="https://www.w3schools.com" className="labelLink">File a support ticket instead!</a> </Label>
      <PublisherBlocklistDropdown
        disabled={true}
        errors={errors}
        name="badv"
        onChange={setPublisher}
        placeholder="abc.xyz.com"
        value={badv}
      />

      <Header as="h4">Block Demand IAB Categories</Header>
      <PublisherBlocklistDropdown
        errors={errors}
        name="bcat"
        onChange={setPublisher}
        placeholder="IAB20-19"
        value={bcat}
      />

      <Header as="h4">Block Demand Bundles</Header>
      <PublisherBlocklistDropdown
        errors={errors}
        name="bapp"
        onChange={setPublisher}
        placeholder="abc.xyz.com"
        value={bapp}
      />
    </Segment>

  </Form>
);

export default PublisherEditForm;
