import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Dropdown, Form, Label } from 'semantic-ui-react';

const PublisherBlocklistDropdown = ({ errors = {}, name, onChange, placeholder, value = [], disabled}) => (
  <Form.Field>
    <Dropdown
      disabled={disabled}
      allowAdditions
      error={!isEmpty(errors[name])}
      fluid
      multiple
      name={name}
      noResultsMessage={null}
      onChange={onChange}
      options={value.map(v => ({ text: String(v), value: v }))}
      placeholder={placeholder}
      search
      selection
      value={value}
    />
    {errors[name] ? <Label basic color="red" pointing>{errors[name]}</Label> : null}
  </Form.Field>
);

export default PublisherBlocklistDropdown;
