import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'semantic-ui-react';

const PublishingAppCardAppStatusPendingRejectDropdown = (props) => {
  const { rejectApp } = props;
  const options = [
    {
      key: 'none',
      value: 'none',
      text: 'Reject',
      type: 'item',
      selected: true,
    },
    {
      key: 'sdk-integration',
      value: 'sdk-integration',
      text: 'Issue with SDK integration',
      type: 'header',
      disabled: true,
    },
    {
      key: 'sdk-integration_no-sdk',
      value: 'sdk-integration_no-sdk',
      text: 'No Chartboost SDK found',
      type: 'item',
    },
    {
      key: 'sdk-integration_wrong-app-id',
      value: 'sdk-integration_wrong-app-id',
      text: 'Wrong App ID',
      type: 'item',
    },
    {
      key: 'sdk-integration_not-minimum-sdk',
      value: 'sdk-integration_not-minimum-sdk',
      text: 'Minimum SDK version not met',
      type: 'item',
    },
    {
      key: 'sdk-integration_sdk-not-detected-in-mediation',
      value: 'sdk-integration_sdk-not-detected-in-mediation',
      text: 'Chartboost Adapter cannot be detected in your mediation',
      type: 'item',
    },
    { key: 'sdk-integration_other', value: 'sdk-integration_other', text: 'Other', type: 'item' },
    {
      key: 'app-not-available',
      value: 'app-not-available',
      text: 'App is not available for download',
      type: 'header',
      disabled: true,
    },
    {
      key: 'app-not-available_incorrect-store-url',
      value: 'app-not-available_incorrect-store-url',
      text: 'Incorrect Store URL',
      type: 'item',
    },
    {
      key: 'app-not-available_incorrect-bundle-id',
      value: 'app-not-available_incorrect-bundle-id',
      text: 'Incorrect Bundle ID',
      type: 'item',
    },
    {
      key: 'app-not-available_not-available-nl',
      value: 'app-not-available_not-available-nl',
      text: 'Not available in the Netherlands store',
      type: 'item',
    },
    {
      key: 'app-not-available_error-downloading',
      value: 'app-not-available_error-downloading',
      text: 'Error while downloading',
      type: 'item',
    },
    {
      key: 'app-not-available_other',
      value: 'app-not-available_other',
      text: 'Other',
      type: 'item',
    },
    {
      key: 'quality',
      value: 'quality',
      text: 'Issue with publisher quality standards',
      type: 'header',
      disabled: true,
    },
    {
      key: 'quality_app-crashing',
      value: 'quality_app-crashing',
      text: 'App is crashing',
      type: 'item',
    },
    {
      key: 'quality_app-cannot-open',
      value: 'quality_app-cannot-open',
      text: 'App cannot be open on a device',
      type: 'item',
    },
    {
      key: 'quality_too-frequent-ads',
      value: 'quality_too-frequent-ads',
      text: 'Too frequent ads',
      type: 'item',
    },
    { key: 'quality_clickbait', value: 'quality_clickbait', text: 'Clickbait app', type: 'item' },
    { key: 'quality_other', value: 'quality_other', text: 'Other', type: 'item' },
    {
      key: 'app-not-complaiant',
      value: 'app-not-complaiant',
      text: 'App is against our Terms & Conditions',
      type: 'header',
      disabled: true,
    },
    {
      key: 'app-not-complaiant_incentivized-traffic',
      value: 'app-not-complaiant_incentivized-traffic',
      text: 'Incentivized traffic',
      type: 'item',
    },
    {
      key: 'app-not-complaiant_invalid-content',
      value: 'app-not-complaiant_invalid-content',
      text: 'App promotes inappropriate content',
      type: 'item',
    },
    {
      key: 'app-not-complaiant_suspected-fraud',
      value: 'app-not-complaiant_suspected-fraud',
      text: 'Suspected fraudulent activity',
      type: 'item',
    },
    {
      key: 'app-not-complaiant_other',
      value: 'app-not-complaiant_other',
      text: 'Other',
      type: 'item',
    },
    { key: 'other', value: 'other', text: 'Other', type: 'header', disabled: true },
    { key: 'other_other', value: 'other_other', text: 'Other reasons', type: 'item' },
    { key: 'other_chinese-publisher', value: 'other_chinese-publisher', text: 'Chinese publisher', type: 'item' },
    {
      key: 'dau',
      value: 'dau',
      text: 'Issue with Daily Active Users',
      type: 'header',
      disabled: true,
    },
    { key: 'dau_low-dau', value: 'dau_low-dau', text: 'Minimum Daily Active Users of 250 not met', type: 'item' },
  ];
  const dropdownStyle = {
    border: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  };
  return (<Dropdown
    placeholder="Reject"
    className="selection red center aligned"
    options={options}
    onChange={rejectApp}
    basic
    button
    style={dropdownStyle}
  />);
};

PublishingAppCardAppStatusPendingRejectDropdown.defaultProps = {};
PublishingAppCardAppStatusPendingRejectDropdown.propTypes = { rejectApp: PropTypes.func.isRequired };

export default PublishingAppCardAppStatusPendingRejectDropdown;
