import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const YieldMenu = () => (
  <Menu inverted secondary stackable compact>
    <Menu.Item as={NavLink} to="/yield/flexbids">Flexbids</Menu.Item>
  </Menu>
);

export default YieldMenu;
