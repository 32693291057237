import PropTypes from 'prop-types';
import React from 'react';
import { Form, Segment, Header } from 'semantic-ui-react';

import { SectionHeader } from '../../../../shared/components';

const FlexbidCreateForm = ({ flexbid, setFlexbid, parseFlexbidTSV }) => {
  const handleFile = (element) => {
    const fileReader = new FileReader();
    const file = element.target.files[0];
    fileReader.onloadend = event => parseFlexbidTSV({ tsv: event.target.result });
    fileReader.readAsText(file);
  };
  const editDimensions = (event, { name, value }) => {
    const jsonValue = JSON.parse(value);
    setFlexbid(event, { name, value: jsonValue });
  };
  return (
    <Form>
      <SectionHeader content="Flexbid Config" icon="flag" />
      <Segment>
        <Header>Switch</Header>
        <Form.Checkbox
          checked={flexbid.active}
          label="Active ?"
          name="active"
          onClick={setFlexbid}
          value={!flexbid.active}
        />
        <Form.Checkbox
          checked={flexbid.automate}
          label="Automate ?"
          name="automate"
          onClick={setFlexbid}
          value={!flexbid.automate}
        />
        <Form.Checkbox
          checked={flexbid.deleted}
          label="Delete ?"
          name="deleted"
          onClick={setFlexbid}
          value={!flexbid.deleted}
        />
        <Header>Misc</Header>
        <Form.Group>
          <Form.Input
            width={8}
            icon="dollar"
            label="Min Bid"
            name="dimensions.min_bid"
            type="number"
            onChange={setFlexbid}
            value={flexbid.dimensions.min_bid}
          />
          <Form.Input
            width={8}
            icon="dollar"
            label="Max Bid"
            name="dimensions.max_bid"
            type="number"
            onChange={setFlexbid}
            value={flexbid.dimensions.max_bid}
          />
        </Form.Group>
        <Form.Group>
          <Form.TextArea
            width={16}
            autoHeight
            label="Dimensions"
            name="dimensions"
            onChange={editDimensions}
            value={JSON.stringify(flexbid.dimensions, null, 2)}
          />
        </Form.Group>
        <Header>Target</Header>
        <Form.Input
          label="Campaign ID"
          name="campaign_id"
          onChange={setFlexbid}
          value={flexbid.campaign_id}
        />
        <Form.Input
          label="Target ID"
          name="target_id"
          onChange={setFlexbid}
          value={flexbid.target_id}
        />
        <Header>TSV Upload</Header>
        <Form.Input
          label="Flexbid TSV"
          name="file"
          type="file"
          icon="upload"
          onChange={handleFile}
        />
      </Segment>
    </Form>
  );
};

FlexbidCreateForm.propTypes = {
  flexbid: PropTypes.shape(PropTypes.object).isRequired,
  setFlexbid: PropTypes.func.isRequired,
  parseFlexbidTSV: PropTypes.func.isRequired,
};

export default FlexbidCreateForm;
