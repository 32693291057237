/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Container,
  Checkbox,
  Grid,
  List,
  Input,
  Icon,
  Tab,
} from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as Status from '../../shared/constants/readyStates';
import OverviewTable from '../components/OverviewTable';
import CBExchangeMenu from '../components/CBExchange/CBExchangeMenu';
import CBExchangePlaceholder from '../components/CBExchange/CBExchangePlaceholder';
import * as appsActions from '../store/apps/actions';
import '../styles/CBExchange.css';

const formatDate = date => new Date(date * 1000).toLocaleString();
const validId = (bidderId = '') => /^[0-9a-fA-F]{24}$/.test(bidderId);

class CBExchangeOverview extends Component {
  static getDownloadFileUrl(rows) {
    return `data:text/csv;charset=utf-8,${encodeURIComponent(rows.join('\r\n'))}`;
  }

  constructor(props) {
    super(props);
    const { appsSelected, query } = this.props;
    this.panes = [
      { menuItem: 'All' },
      { menuItem: 'iOS' },
      { menuItem: 'Android' },
      { menuItem: 'Amazon' },
    ];

    this.state = {
      query,
      appsSelected,
      showAllBundles: [],
      showAllAdomains: [],
      search: false,
    };
  }

  getColumnsForTable() {
    const {
      apps,
      sortApps,
      sortBy,
      sortDir: direction,
    } = this.props;
    const { appsSelected } = this.state;
    const allAppsSelected = isEmpty(apps) ? false : appsSelected.length === apps.length;

    return [
      {
        value: (
          <Checkbox
            checked={allAppsSelected}
            onChange={() => this.handleAllAppsSelection()}
            value={appsSelected.length > 0 ? 1 : 0}
          />
        ),
        attr: { width: 1 },
      },
      {
        value: 'Name',
        attr: {
          width: 4,
          sorted: sortBy === 'nickname' ? direction : null,
          onClick: () => sortApps('nickname'),
        },
      },
      { value: 'ID', attr: { width: 4 } },
      {
        value: 'Platform',
        attr: {
          width: 2,
          sorted: sortBy === 'platform' ? direction : null,
          onClick: () => sortApps('platform'),
        },
      },
      {
        value: 'Creation Date',
        attr: {
          width: 3,
          sorted: sortBy === 'date_created' ? direction : null,
          onClick: () => sortApps('date_created'),
        },
      },
      {
        value: 'Blocked Bundles',
        attr: {
          width: 4,
          sorted: sortBy === 'blocked_advertiser_bundles_length' ? direction : null,
          onClick: () => sortApps('blocked_advertiser_bundles_length'),
          textAlign: 'center',
        },
      },
      {
        value: 'Blocked adomains',
        attr: {
          width: 4,
          sorted: (sortBy === 'blocked_adomains_length') ? direction : null,
          onClick: () => sortApps('blocked_adomains_length'),
          textAlign: 'center',
        },
      },
    ];
  }

  getAppBundlesAdomainList(appId, stateName, options, fileName) {
    const showAll = this.state[stateName];
    const to = showAll.includes(appId) ? options.length : 5;

    return (
      <List>
        {options.slice(0, to).map(option => (
          <List.Item className="item-id" key={`${option}-${appId}`}>{option}</List.Item>
        ))}
        {this.getAppBundlesAdomainOptions(appId, stateName, options, fileName)}
      </List>
    );
  }

  getExpandCompressOption(appId, stateName, option) {
    const showAll = this.state[stateName];

    if (option.length < 5) {
      return undefined;
    }

    return showAll.includes(appId)
      ? (<Icon name="compress" onClick={() => this.handleStateSelection(stateName, appId)} />)
      : (<Icon name="expand" onClick={() => this.handleStateSelection(stateName, appId)} />);
  }

  getAppBundlesAdomainOptions(appId, stateName, option, fileName) {
    if (!option.length) {
      return undefined;
    }

    return (
      <div>
        {this.getExpandCompressOption(appId, stateName, option)}
        <CopyToClipboard text={option.join('\r\n')}>
          <Icon name="copy outline" />
        </CopyToClipboard>
        <a
          className="icon-link"
          href={CBExchangeOverview.getDownloadFileUrl(option)}
          download={`${appId}_${fileName}`}
        >
          <Icon name="download" />
        </a>
      </div>
    );
  }

  getAppsForTable() {
    const { apps } = this.props;

    return apps.map(app => ({
      key: app.id,
      select: {
        cellAttribute: { collapsing: true },
        value: (
          <Checkbox
            checked={this.isAppSelected(app.id)}
            name="select"
            value={this.isAppSelected(app.id) ? 1 : 0}
            onChange={() => this.handleStateSelection('appsSelected', app.id)}
          />
        ),
      },
      name: { value: app.nickname },
      id: { value: app.id },
      platform: { value: app.getPlatformName() },
      date_created: { value: formatDate(app.date_created) },
      blocked_advertiser_bundles: {
        value: this.getAppBundlesAdomainList(app.id, 'showAllBundles', app.blocked_advertiser_bundles, 'bundles'),
        cellAttribute: {
          singleLine: true,
          textAlign: 'center',
        },
      },
      blocked_adomains: {
        value: this.getAppBundlesAdomainList(app.id, 'showAllAdomains', app.blocked_adomains, 'adomains'),
        cellAttribute: {
          singleLine: true,
          textAlign: 'center',
        },
      },
    }));
  }

  handleStateChange(name, value) {
    this.setState({ [name]: value });
  }

  handleTabChange(activeIndex) {
    const { setPlatform, status, query } = this.props;
    if (validId(query) && status !== Status.UNLOADED) {
      this.searchApp(activeIndex, query);
    } else {
      setPlatform(activeIndex);
    }
  }

  handleKeyPress(key) {
    const { query } = this.state;
    if (key === 'Enter' && validId(query)) {
      this.updateQuery();
    }
  }

  handleStateSelection(name, value) {
    const stateArray = this.state[name];
    const index = stateArray.indexOf(value);

    if (index > -1) {
      stateArray.splice(index, 1);
    } else {
      stateArray.push(value);
    }
    this.setState({ [name]: stateArray });
  }

  handleAllAppsSelection() {
    const { apps } = this.props;
    let { appsSelected } = this.state;

    if (appsSelected.length === apps.length) {
      appsSelected = [];
    } else {
      appsSelected = apps.map(app => app.id);
    }
    this.setState({ appsSelected });
  }

  selectOption() {
    const { selectApps } = this.props;
    const { appsSelected } = this.state;

    selectApps(appsSelected);
  }

  updateQuery() {
    const { setQuery, platform } = this.props;
    const { query } = this.state;
    setQuery(query);
    this.searchApp(platform, query);
  }

  async searchApp(platform, query) {
    const { getApps, platform: currentPlatform } = this.props;
    const opt = {
      query,
      platform: platform === undefined ? currentPlatform : platform,
    };

    this.setState({
      appsSelected: [],
      showAllBundles: [],
      showAllAdomains: [],
    });

    const apps = await getApps(opt);

    if (apps.length === 1) {
      this.setState({ appsSelected: [apps[0].id] });
    }
  }

  isAppSelected(appId) {
    const { appsSelected } = this.state;
    return appsSelected.includes(appId);
  }

  validToBlockBundles() {
    const { apps } = this.props;
    const { appsSelected } = this.state;
    let platform;

    if (isEmpty(apps)) {
      return false;
    }

    const appsSamePlatform = appsSelected.filter((id) => {
      const app = apps.find(app => (app.id === id));
      if (isEmpty(platform)) {
        platform = app.platform;
        return true;
      }
      if (platform === app.platform) return true;
      return false;
    });

    return appsSelected.length > 0 && appsSamePlatform.length === appsSelected.length;
  }

  validToBlockAdomains() {
    const { apps } = this.props;
    const { appsSelected } = this.state;
    return !isEmpty(appsSelected) && !isEmpty(apps);
  }

  render() {
    const { status, platform } = this.props;
    const { query } = this.state;

    return (
      <Container id="CBExchangeOverview">
        <CBExchangeMenu />
        {<Tab
          panes={this.panes}
          activeIndex={platform}
          onTabChange={(e, { activeIndex }) => this.handleTabChange(activeIndex)}
        />}
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <Input
                iconPosition="left"
                icon="search"
                action={{
                  onClick: () => this.updateQuery(),
                  content: 'Search',
                  color: 'green',
                  disabled: !validId(query),
                  id: 'search-app',
                }}
                placeholder="App ID or Publisher ID"
                onChange={({ target: { value } }) => this.handleStateChange('query', value)}
                onKeyPress={({ key }) => this.handleKeyPress(key)}
                id="input-app"
                value={query}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="right">
              <Button.Group>
                <Button
                  as={NavLink}
                  to="/cb-exchange/bundle"
                  disabled={!this.validToBlockBundles()}
                  color={this.validToBlockBundles() ? 'green' : 'grey'}
                  onClick={() => this.selectOption()}
                >Block Bundles
                </Button>
                <Button.Or />
                <Button
                  as={NavLink}
                  to="/cb-exchange/adomain"
                  disabled={!this.validToBlockAdomains()}
                  color={this.validToBlockAdomains() ? 'green' : 'grey'}
                  onClick={() => this.selectOption()}
                >
                  Block Adomains
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {status === Status.UNLOADED ? (
          <CBExchangePlaceholder />
        ) : (
          <OverviewTable
            columns={this.getColumnsForTable()}
            rows={this.getAppsForTable()}
            noResultMessage="No Apps found"
            loading={status === Status.LOADING || status === Status.UNLOADED}
            attributes={{ celled: true, fixed: true, sortable: true }}
          />
        )}
      </Container>
    );
  }
}

CBExchangeOverview.defaultProps = {
  apps: [],
  status: Status.UNLOADED,
  query: '',
  sortBy: null,
  sortDir: null,
  appsSelected: [],
};

CBExchangeOverview.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({})),
  status: PropTypes.oneOf([Status.LOADED, Status.LOADING, Status.UNLOADED]),
  query: PropTypes.string,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  platform: PropTypes.number.isRequired,
  getApps: PropTypes.func.isRequired,
  sortApps: PropTypes.func.isRequired,
  selectApps: PropTypes.func.isRequired,
  setPlatform: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
  appsSelected: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  apps: state.apps.apps,
  status: state.apps.status,
  sortBy: state.apps.sortBy,
  sortDir: state.apps.sortDir,
  platform: state.apps.platform,
  query: state.apps.query,
  appsSelected: state.apps.appsSelected,
});

const mapDispatchToProps = dispatch => ({
  getApps: opt => dispatch(appsActions.fetchApps(opt)),
  sortApps: opt => dispatch(appsActions.sortApps(opt)),
  selectApps: appsIds => dispatch(appsActions.selectApps(appsIds)),
  setPlatform: platform => dispatch(appsActions.setPlatform(platform)),
  setQuery: query => dispatch(appsActions.setQuery(query)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CBExchangeOverview);
