import PropTypes from 'prop-types';
import React from 'react';
import { Pagination } from 'semantic-ui-react';

const PrivateMarketplacePagination = (props) => {
  const { activePage, onPageChange, totalPages } = props;

  return (
    <Pagination
      boundaryRange={0}
      activePage={activePage}
      ellipsisItem={null}
      firstItem={null}
      lastItem={null}
      siblingRange={1}
      prevItem={activePage >= 4 ? undefined : null}
      nextItem={totalPages > 4 && activePage < totalPages - 2 ? undefined : null}
      totalPages={totalPages}
      onPageChange={(e, data) => onPageChange(data)}
    />
  );
};

PrivateMarketplacePagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default PrivateMarketplacePagination;
