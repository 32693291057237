import PropTypes from 'prop-types';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Tab, List, Button, Icon } from 'semantic-ui-react';
import Clipboard from 'clipboard';
import {
  filterCreativeAssets,
  filterCreativeVideoAssets,
  ASSETS_URL,
} from '../helpers';

import './styles/CreativeAssets.css';

const CreativeAssets = ({ creative }) => {
  const filteredAssets = filterCreativeAssets(creative);
  const filteredVideoAssets = filterCreativeVideoAssets(creative);
  const initClipboard = () => new Clipboard('#asset-copy');

  if (isEmpty(filteredAssets) && isEmpty(filteredVideoAssets)) {
    return (
      <p>The creative does not have any valid asset.</p>
    );
  }

  initClipboard();

  const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

  const generatePane = (assets, isVideo = false) => (
    Object.entries(assets).map(([orientation, aspectRatios]) => ({
      menuItem: capitalize(orientation),
      render: () => (
        <Tab.Pane attached={false}>
          <List divided verticalAlign="middle" celled relaxed>
            {Object.entries(aspectRatios).map(([aspectRatio, sizes]) => (
              <List.Item key={aspectRatio}>
                <span className="orientation_title">Aspect ratio: {aspectRatio}</span>
                <List.List>
                  {Object.entries(sizes).map(([size, asset]) => {
                    const aspectRatioId = aspectRatio.replace(':', '-');
                    const assetUrl = (!isVideo) ? `${ASSETS_URL}${asset.url}` : asset.url;
                    return (
                      <List.Item key={size} className="asset_item">
                        <List.Content floated="right">
                          <Icon name="copy outline" id="asset-copy" className="pointer" data-clipboard-target={`#ar-${aspectRatioId}-${size}-asset-url`} />
                          <Button
                            onClick={() => window.open(assetUrl, '_blank')}
                          >Open
                          </Button>
                        </List.Content>
                        <List.Content>
                          <List.Header className="asset_size">{size}</List.Header>
                          <List.Description id={`ar-${aspectRatioId}-${size}-asset-url`} className="asset_url">
                            {assetUrl}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    );
                })}
                </List.List>
              </List.Item>
            ))}
          </List>
        </Tab.Pane>
      ),
    }))
  );

  const assetsPanes = [
    { menuItem: 'Images', render: () => <Tab menu={{ pointing: true }} panes={generatePane(filteredAssets)} /> },
  ];

  if (!isEmpty(filteredVideoAssets)) {
    assetsPanes.push({ menuItem: 'Videos', render: () => <Tab menu={{ pointing: true }} panes={generatePane(filteredVideoAssets, true)} /> });
  }

  return (
    <Tab
      grid={{ paneWidth: 14, tabWidth: 2 }}
      menu={{ fluid: true, vertical: true, tabular: true, pointing: true }}
      panes={assetsPanes}
    />
  );
};

CreativeAssets.propTypes = { creative: PropTypes.shape({}).isRequired };

export default CreativeAssets;
