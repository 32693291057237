import dashapi from './dashapi';

export default async function getDSPs() {
  try {
    const { response: dsps } = await dashapi.get('exchange/bidders');
    return dsps;
  } catch (error) {
    throw error;
  }
}
