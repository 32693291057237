import dashapi from './dashapi';

export async function getPhases() {
  try {
    const { response: phases } = await dashapi.get('fusion/phases');
    return phases;
  } catch (error) {
    throw error;
  }
}

export async function putPhases(body) {
  try {
    const { response: phases } = await dashapi.put('fusion/phases', body);
    return phases;
  } catch (error) {
    throw error;
  }
}
