import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import {
  CREATE_BIDDER_SUCCESS,
  FETCH_BIDDER_SUCCESS,
  FETCH_BIDDERS_SUCCESS,
  SYNC_BIDDER_SUCCESS,
} from '../actions';

const savedBidders = (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_BIDDER_SUCCESS:
      return cloneDeep(Object.assign({}, state, { [action.response.id]: { ...action.response } }));

    case FETCH_BIDDERS_SUCCESS:
      return cloneDeep(Object.assign({}, state, keyBy(action.response, 'id')));

    case CREATE_BIDDER_SUCCESS:
    case SYNC_BIDDER_SUCCESS: {
      const { response, response: { id: bidderId = 'no_id' } } = action;
      const newState = { [bidderId]: { ...response } };
      return cloneDeep(Object.assign({}, state, newState));
    }

    default:
      return state;
  }
};

export default savedBidders;
