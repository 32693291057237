import { combineReducers } from 'redux';

import creative from './creative';
import creativeFilters from './creativeFilters';
import previewCreative from './previewCreative';
import recentCreatives from './recentCreatives';
import templates from './templates';

const reducer = combineReducers({
  creative,
  creativeFilters,
  previewCreative,
  recentCreatives,
  templates,
});

export default reducer;
