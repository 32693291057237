import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import {
  Button,
  Container,
  Grid,
  Header,
  Segment,
  Modal,
} from 'semantic-ui-react';
import CBExchangeEdit from '../components/CBExchange/CBExchangeEdit';
import CBExchangeMenu from '../components/CBExchange/CBExchangeMenu';
import MessageValidation from '../components/CBExchange/MessageValidation';
import AppsSelectedList from '../components/CBExchange/AppsSelectedList';
import AppIdList, { MaxApps } from '../../domain/AppIdList';
import AdomainList, { MaxAdomains } from '../../domain/AdomainList';
import * as appsActions from '../store/apps/actions';
import { getAppsSelected } from '../store/apps/reducer';

import '../styles/CBExchange.css';

class CBExchangeBlockView extends Component {
  constructor(props) {
    super(props);
    const { appsSelected, action } = props;
    this.platform = 1;
    this.maxRows = MaxAdomains;
    this.listName = 'blocked_adomains';

    if (appsSelected.length > 0) {
      this.platform = appsSelected[0].getPlatformId();
    }

    if (action === 'bundle') {
      this.maxRows = MaxApps;
      this.listName = 'blocked_advertiser_bundles';
    }

    this.state = {
      editionOption: null,
      idsToAdd: [],
      modalOpen: false,
      loading: false,
      save: false,
      goBack: false,
    };
  }

  appsThatExceededTheLimit() {
    const { appsSelected } = this.props;
    const { idsToAdd } = this.state;

    const test = appsSelected.filter(app => (
      (app[this.listName].length + idsToAdd.length) > this.maxRows
    ));
    return test;
  }

  appsLimitExceeded() {
    const { appsSelected } = this.props;
    return this.appsThatExceededTheLimit().length === appsSelected.length;
  }

  validate() {
    const { editionOption } = this.state;
    return editionOption === 'block';
  }

  isListValid(skipMaxList = false) {
    const { action } = this.props;
    const { idsToAdd } = this.state;

    if (!this.validate() && idsToAdd.length > 0) return true;

    if (action === 'bundle') {
      return AppIdList.isAppIdListValid(idsToAdd, this.platform, skipMaxList);
    }
    return AdomainList.isAdomainListValid(idsToAdd, skipMaxList);
  }

  handleEditionOptionClick(editionOption) {
    this.setState({ editionOption });
  }

  handleClearInvalids() {
    const { action } = this.props;
    const { idsToAdd } = this.state;
    let newListOfIdsToAdd = idsToAdd;

    if (action === 'bundle') {
      newListOfIdsToAdd = AppIdList.addAppIdsToList(idsToAdd, this.platform, [], true, true);
    } else {
      newListOfIdsToAdd = AdomainList.addAdomainToList(idsToAdd, [], true, true);
    }

    this.setState({ idsToAdd: newListOfIdsToAdd });
  }

  handleGoBack() {
    this.setState({ goBack: true });
  }

  removeItemToAdd(id) {
    const { idsToAdd } = this.state;
    idsToAdd.splice(idsToAdd.indexOf(id), 1);
    this.setState({ idsToAdd });
  }

  addIds(ids, validate = true) {
    const { action } = this.props;
    const { idsToAdd } = this.state;
    let newListOfIdsToAdd = idsToAdd;

    if (action === 'bundle') {
      newListOfIdsToAdd = AppIdList.addAppIdsToList(ids, this.platform, idsToAdd, validate);
    } else {
      newListOfIdsToAdd = AdomainList.addAdomainToList(ids, idsToAdd, validate);
    }

    this.setState({ idsToAdd: newListOfIdsToAdd });
  }

  handleCancel() {
    const { selectApps, resetApps } = this.props;
    resetApps();
    selectApps([]);
  }

  handleModal(value) {
    this.setState({ modalOpen: value });
  }

  async saveChanges() {
    const { saveBundles, saveAdomains, action } = this.props;
    const { idsToAdd, editionOption } = this.state;
    this.handleModal(false);
    this.setState({ loading: true });
    try {
      if (action === 'bundle') {
        await saveBundles(idsToAdd, editionOption === 'block');
      } else {
        await saveAdomains(idsToAdd, editionOption === 'block');
      }
      this.setState({ save: true, loading: false });
    } catch (error) {
      // error saving data
      this.setState({ idsToAdd: [] });
      this.setState({ loading: false });
    }
  }

  render() {
    const { action, appsSelected } = this.props;
    const { editionOption, idsToAdd, modalOpen, loading, save, goBack } = this.state;
    const appsThatExceededTheLimit = this.appsThatExceededTheLimit();
    const summayViewLink = `/cb-exchange/${action}/${editionOption}/summary`;
    const isValidEdition = !isEmpty(editionOption) && this.validate();
    const isAppsLimitExceeded = !isEmpty(appsSelected.filter(selectedApp => appsThatExceededTheLimit.includes(selectedApp))) && appsSelected.length === appsThatExceededTheLimit.length && isValidEdition;

    if (appsSelected.length === 0 || goBack) { return <Redirect to="/cb-exchange" />; }

    if (save) { return <Redirect to={summayViewLink} />; }

    const header = `${isEmpty(editionOption) ? 'Block' : editionOption} ${action.charAt(0).toUpperCase() + action.slice(1)}s`;

    const blockRightPanel = editionOption === null
      ? (
        <Grid.Column textAlign="center" className="editionOptions">
          <Button.Group className="option-buttons">
            <Button color="blue" onClick={() => this.handleEditionOptionClick('block')}>Block</Button>
            <Button.Or />
            <Button color="grey" onClick={() => this.handleEditionOptionClick('unblock')}>Unblock</Button>
          </Button.Group>
        </Grid.Column>
      )
      : (
        <Grid.Column className="edit-panel">
          <CBExchangeEdit
            action={action}
            allAppsExceedTheLimit={isAppsLimitExceeded}
            editionOption={editionOption}
            platform={this.platform}
            removeItemToAdd={value => this.removeItemToAdd(value)}
            handleClearInvalids={() => this.handleClearInvalids()}
            addIds={(value, validate) => this.addIds(value, validate)}
            isListValid={skipMaxList => this.isListValid(skipMaxList)}
            handleCancel={() => this.handleCancel()}
            maxRows={this.maxRows}
            idsToAdd={idsToAdd}
            handleSave={() => this.handleModal(true)}
            loading={loading}
          />
        </Grid.Column>
      );

    return (
      <Container id="CBExchangeBlockView">
        <CBExchangeMenu handleCancel={() => this.handleCancel()} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={2}>
              <Button
                content="Back"
                icon="left arrow"
                labelPosition="left"
                onClick={() => this.handleGoBack()}
              />
            </Grid.Column>
            <Grid.Column textAlign="center" width={12}>
              <Header className="title" as="h3">{header}</Header>
            </Grid.Column>
          </Grid.Row>
          <MessageValidation
            appsLimitExceeded={appsThatExceededTheLimit}
            maxRows={this.maxRows}
            validate={isValidEdition}
            action={action}
          />
        </Grid>
        <Segment padded>
          <Grid columns={2} stackable divided>
            <Grid.Row>
              <Grid.Column className="apps-selected-list">
                <AppsSelectedList
                  appsSelected={appsSelected}
                  appsThatExceededTheLimit={appsThatExceededTheLimit}
                  maxRows={this.maxRows}
                  action={action}
                  validate={isValidEdition}
                />
              </Grid.Column>
              {blockRightPanel}
            </Grid.Row>
          </Grid>
        </Segment>
        <Modal
          open={modalOpen}
        >
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <Header>You are about to {editionOption} {action}s</Header>
              <p>
                Are you sure you want to continue?
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color="black" onClick={() => this.handleModal(false)}>
              Cancel
            </Button>
            <Button
              content="Confirm"
              labelPosition="right"
              icon="checkmark"
              positive
              onClick={() => this.saveChanges()}
            />
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match: { params: { action } } } = ownProps;
  return {
    action,
    appsSelected: getAppsSelected(state),
  };
};

const mapDispatchToProps = dispatch => ({
  selectApps: appsIds => dispatch(appsActions.selectApps(appsIds)),
  resetApps: () => dispatch(appsActions.resetApps()),
  saveAdomains: (idsToAdd, add) => dispatch(appsActions.saveAdomains(idsToAdd, add)),
  saveBundles: (idsToAdd, add) => dispatch(appsActions.saveBundles(idsToAdd, add)),
});

CBExchangeBlockView.propTypes = {
  action: PropTypes.string.isRequired,
  appsSelected: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectApps: PropTypes.func.isRequired,
  resetApps: PropTypes.func.isRequired,
  saveAdomains: PropTypes.func.isRequired,
  saveBundles: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CBExchangeBlockView);
