/**
 * @namespace core/utils
 * All common utils should go here to reduce code duplication
 * this example only has a few functions implemented for demonstration
 */

import moment from 'moment';

/**
 * @function exists
 * @param any val
 * @description checks if val is undefined
 * @returns {boolean} true if val is not undefined
 * @memberof core/framework/utils
 */

const exists = val => typeof val !== 'undefined';

/**
 * @function isObject
 * @param any val
 * @description checks if val is strictly a json object
 * that is not null or array
 * @returns {boolean} true if val is Object {}
 * @memberof core/framework/utils
 */

const isObject = val => typeof val === 'object' && val !== null && Array.isArray(val) === false;

/**
 * @function hasKey
 * @param any val
 * @param string key
 * @description checks if val is Object {} and has the specified key
 * @returns {boolean} true if val is Object {} and has key
 * @memberof core/framework/utils
 */

const hasKey = (val, key) => exists(val) && isObject(val) && exists(val[key]);

/**
 * @function get
 * @param any val
 * @param string key
 * @param any def - false
 * @description if val is Object {} and has the specified key
 * it returns the value in the specified key , otherwise it returns
 * the default value def
 * @returns {any} value of key if found , otherwise def
 * @memberof core/framework/utils
 */

const get = (val, key, def = false) => (hasKey(val, key) ? val[key] : def);

/**
 * @function getArray
 * @param any val
 * @param string key
 * @param any def - false
 * @description if val is Object {} and has the specified key
 * and the value in key is an Array , the array is returned
 * otherwise the default value def is returned
 * @returns {Array} value of key if found , otherwise def
 * @memberof core/framework/utils
 */

const getArray = (val, key, def = false) => (
  get(val, key, false) && Array.isArray(val[key]) ? val[key] : def
);


/**
 * @function handleFormEvent
 * @param function callback
 * @param any args
 * @description replaces handleSemanticEvent , returns a function that applies
 * callback to a form field's name and value
 * @returns {object}
 * @memberof core/utils
 */

const handleFormEvent = (callback, ...args) => (event, { name, value }) =>
  callback(...args, name, value);


/**
 * @function toPayload
 * @param array values - array of values (any)
 * @param string[] keys - array of strings
 * @description merges the keys array and values array into a JSON dictionary of
 * key/value pairs, used mainly to create action payloads for reducers
 * @returns {object}
 * @memberof core/utils
 */

const toPayload = (values, keys = []) => keys.reduce((previous, key, i) =>
  ({ ...previous, [key]: values[i] }), {});


/**
 * @function toDictionary
 * @param array[] list - array of values (any)
 * @param string key - optional string key to use for finding the keys
 * @description takes an array of objects and converts it to a dictionary
 * @returns {object}
 * @memberof core/utils
 */

const toDictionary = (list, key) => list.reduce((acc, cur) => ({
  ...acc,
  [key ? cur[key] : cur]: cur,
}), {});


/**
 * @function getISODate
 * @param string keyword
 * @description takes an string keyword date and returns ISO date
 * @returns {object}
 * @memberof core/utils
 */

const toShortISO = date => date.toISOString().split('T')[0];

export const getISODate = (keyword) => {
  const today = new Date();
  switch (keyword) {
    case 'yesterday':
      return toShortISO(new Date(today.setDate(today.getDate() - 1)));

    case 'monthStart':
      return moment().utc().startOf('month').format('YYYY-MM-DD');

    case 'monthEnd':
      return moment().utc().endOf('month').format('YYYY-MM-DD');

    case 'today':
    default:
      return toShortISO(today);
  }
};


const Utils = {
  exists,
  isObject,
  hasKey,
  get,
  getArray,
  handleFormEvent,
  toPayload,
  toDictionary,
  getISODate,
};

export default Utils;
