import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'semantic-ui-react';

const PlayableValidationPendingRejectDropdown = (props) => {
  const { onChange, options, loading } = props;

  const dropdownStyle = {
    border: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  };
  return (<Dropdown
    placeholder="Reject"
    className="selection red center aligned"
    options={options}
    onChange={(e, { value }) => {
      if (value !== 'none') {
        onChange(value);
      }
    }}
    selection
    search
    allowAdditions
    basic
    button
    style={dropdownStyle}
    loading={loading}
  />);
};

PlayableValidationPendingRejectDropdown.defaultProps = {};
PlayableValidationPendingRejectDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PlayableValidationPendingRejectDropdown;
