import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

const divStyle = { color: 'white' };
const h1Style = { fontSize: '4rem' };
const h2Style = { fontSize: '2rem', margin: '1rem' };
const buttonStyle = { marginTop: '3rem', marginLeft: '1rem' };

const NotFoundView = () => (
  <div style={divStyle}>
    <h1 style={h1Style}>Not found</h1>
    <h2 style={h2Style}>{document.location.pathname} is not found.</h2>
    <Button
      as={Link}
      to="/"
      primary
      size="medium"
      style={buttonStyle}
      icon="home"
      content="Overview"
    />
  </div>
);

export default NotFoundView;
