import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';


const TableNoResults = ({ message }) => (
  <Table.Row>
    <Table.Cell colSpan={11} textAlign="center">
      {message}
    </Table.Cell>
  </Table.Row>
);

TableNoResults.defaultProps = { message: 'No results found' };

TableNoResults.propTypes = { message: PropTypes.string };

export default TableNoResults;
