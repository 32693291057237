import moment from 'moment';

import { PUBLISHER_MODE_MAP } from '../shared/constants';

/*
 * Return corresponding mode based on the mode map
 * @params {Integer} Mode Integer (0,1,2)
 * @returns {String} String (off, on, on_force)
 */
export const getMode = mode => PUBLISHER_MODE_MAP[mode !== null ? mode : -1].text;

/*
 * Convert epoch timestamp to a human readable date
 * @params {Integer} Epoch timestamp e.g. 1394104654
 * @returns {String} Date
*/
export const convertTimestamp = epoch => (epoch ? moment(epoch * 1000).toString() : 'N/A');

/*
 * Filter amazon campaigns from the response
 * @params {Object} Campaign Object
 * @returns {Object} Filtered campaigns
*/
export const isNotAmazon = (campaign) => {
  if (campaign) { return campaign.platform !== 3; }
  return false;
};
