export const getCreativeTypeById = (mediaType) => {
  switch (mediaType) {
    case 1: return 'Image';
    case 2: return 'Video';
    case 3: return 'Promotion';
    case 5: return 'GIF';
    case 6: return 'Playable';
    default: return 'Image';
  }
};

export const formatDate = date => new Date(date * 1000).toLocaleString();
