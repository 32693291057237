import map from 'lodash/map';
import memoize from 'lodash/memoize';
import reduce from 'lodash/reduce';
import { combineReducers } from 'redux';

import activeIndex from './activeIndex';
import appResults from './appResults';
import apps from './apps';
import bidders from './bidders';
import deviceLists from './deviceLists';
import errors from './errors';
import isDeployingPubConfigs from './isDeployingPubConfigs';
import isFetchingDeviceLists from './isFetchingDeviceLists';
import isSearchingAppName from './isSearchingAppName';
import savedBidders from './savedBidders';
import searchingBundleIds from './searchingBundleIds';

import { getPlatform, getPlatformFromAttrs } from '../../shared/helpers';
import { PLATFORMS_BY_KEY } from '../../shared/constants';

const reducer = combineReducers({
  activeIndex,
  appResults,
  apps,
  bidders,
  deviceLists,
  errors,
  isDeployingPubConfigs,
  isFetchingDeviceLists,
  isSearchingAppName,
  savedBidders,
  searchingBundleIds,
});

export default reducer;

export const getAppResultOptions = memoize((state = {}) => map(state.appResults, app => ({
  key: app.id,
  description: app.bundle_id,
  icon: getPlatform(app.platform).icon,
  image: app.icon,
  text: app.name,
  value: app.bundle_id,
})));

export const getApps = (state = {}) => state.apps || {};

export const getBidder = (state = {}, bidderId = '') => state.bidders[bidderId] || {};

export const getBidderIds = (state = {}) => Object.keys(state.bidders) || {};

export const getBundleIds = memoize((state = {}, bidderId = '') => {
  const { [bidderId]: bidder = {} } = (state.bidders || {});
  const { request_validation: { blocked_apps: blockedApps = { android: [], ios: [] } } = {} } = bidder;

  // android[] and ios[] is combined into an object of bundleIds with bundleId:{platform} pair
  return reduce(blockedApps, (platforms, platformBundleIds, platformKey) => (
    Object.assign({}, platforms, platformBundleIds.reduce((ids, id) => (
      Object.assign({}, ids, { [id]: PLATFORMS_BY_KEY[platformKey] })
    ), {}))
  ), {});
});

export const getBidders = (state = {}) => state.bidders || {};

export const getDeviceListOptions = memoize((state = {}) => map(state.deviceLists, list => ({
  icon: getPlatformFromAttrs({ key: list.platform }).icon,
  text: list.name,
  value: list.id,
  description: `${list.values_count} devices`,
})));

export const getDeviceLists = (state = {}) => state.deviceLists;

export const getErrors = (state = {}) => state.errors || {};

export const getIsDeployingPubConfigs = (state = {}) => state.isDeployingPubConfigs;

export const getIsFetchingDeviceLists = (state = {}) => state.isFetchingDeviceLists;

export const getIsSearchingAppName = (state = {}) => state.isSearchingAppName;

export const getSavedBidder = (state = {}, bidderId = '') => {
  if (state.savedBidders && state.savedBidders[bidderId]) { return state.savedBidders[bidderId]; }
  return {};
};

export const getSearchingBundleIds = (state = []) => state.searchingBundleIds || [];

export const getActiveIndex = (state = {}) => state.activeIndex;
