import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'semantic-ui-react';

import { FlexbidRow } from '../components';

const FlexbidRows = ({ flexbids }) => (
  <Table fixed size="small" singleLine striped>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={1}>Active</Table.HeaderCell>
        <Table.HeaderCell width={6}>Name</Table.HeaderCell>
        <Table.HeaderCell width={3}>Campaign ID</Table.HeaderCell>
        <Table.HeaderCell width={2}>Target</Table.HeaderCell>
        <Table.HeaderCell width={3}>Company</Table.HeaderCell>
        <Table.HeaderCell width={1}>Edit</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    {flexbids.map((flexbid) => {
      const { _id: id } = flexbid;
      return (
        <Table.Body key={id}>
          <FlexbidRow flexbid={flexbid} />
        </Table.Body>
      );
    })}
  </Table>
);

FlexbidRows.propTypes = { flexbids: PropTypes.arrayOf(PropTypes.object).isRequired };

export default FlexbidRows;
