import dashapi from './dashapi';
import Apps from '../domain/apps';

export async function getApps(queryObject) {
  const query = Object.keys(queryObject).map(key => `${key}=${queryObject[key]}`).join('&');
  const { response } = await dashapi.get(`apps/admin/search?${query}`);

  const apps = response.filter(app => !app.deleted).map((rawApp) => {
    const {
      blocked_advertiser_bundles: blockedAdvertiserBundles = [],
      exchange_settings: { badv: blockedAdomains = [] },
      ...app
    } = rawApp;
    return new Apps(
      app._id,
      app.name,
      app.nickname,
      app.platform,
      app.date_created,
      blockedAdvertiserBundles,
      blockedAdomains,
    );
  });

  return apps;
}

export async function postAppBlockedAdvertiserBundles(appToUpdate, body) {
  try {
    const app = appToUpdate;
    const { response: { blocked_advertiser_bundles: blockedAdvertiserBundles } } = await dashapi.post(`apps/${appToUpdate.id}/admin/blocked_advertiser_bundles`, body);
    app.blocked_advertiser_bundles_length = blockedAdvertiserBundles.length;
    app.blocked_advertiser_bundles = blockedAdvertiserBundles;
    return app;
  } catch (error) {
    throw error;
  }
}

export async function postAppblockedAdomains(appToUpdate, body) {
  try {
    const app = appToUpdate;
    const { response } = await dashapi.post(`apps/${appToUpdate.id}/admin/blocked_adomains`, body);
    const { blockedAdomains = [] } = response;
    app.blocked_adomains_length = blockedAdomains.lenght;
    app.blocked_adomains = blockedAdomains;
    return app;
  } catch (error) {
    throw error;
  }
}

export async function getUserApps() {
  const { response: { apps: userApps } } = await dashapi.get('apps');
  return Object.values(userApps).filter(app => !app.deleted);
}
