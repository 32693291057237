import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { capitalize, isEmpty } from 'lodash';

import { Icon } from 'semantic-ui-react';

import { selectBundles } from '../../user/selectors';

const CurrentModuleIcon = ({ bundlePath }) => {
  const bundles = useSelector(selectBundles);

  if (isEmpty(bundles)) return null;

  const bundle = bundles.find(b => b.path === bundlePath);
  const title = `Current module: ${capitalize(bundle.name)}`;

  return <Icon title={title} size="large" inverted name={bundle.icon} />;
};

CurrentModuleIcon.defaultProps = { bundlePath: '/' };

CurrentModuleIcon.propTypes = { bundlePath: PropTypes.string };

export default CurrentModuleIcon;
