import React, { Component } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';


class CreativeRow extends Component {

  handlePreviewClicked = () => {
    this.props.onPreviewClicked(this.props.creative.id);
  }

  render() {
    const { bidder_id, bidder_name, count, creative_id } = this.props.creative;
    return (
      <Table.Row className="CreativeValidation__TableRow">
        <Table.Cell className="CreativeValidation__IdCell">
          {creative_id}
        </Table.Cell>
        <Table.Cell>{count}</Table.Cell>
        <Table.Cell>{bidder_id}</Table.Cell>
        <Table.Cell>{bidder_name}</Table.Cell>
        <Table.Cell className="CreativeValidation__ReviewCell" textAlign="right">
          <Button icon labelPosition="right" onClick={this.handlePreviewClicked}>
            view 
            <Icon name="external square" />
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }
}

const CreativeList = ({ creatives, onPreviewClicked }) => (
  <Table celled selectable striped structured>
    <Table.Header className="CreativeValidation__TableHead">
      <Table.Row>
        <Table.HeaderCell>Creative Id</Table.HeaderCell>
        <Table.HeaderCell>Impressions</Table.HeaderCell>
        <Table.HeaderCell>Bidder ID</Table.HeaderCell>
        <Table.HeaderCell>Bidder</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>{creatives.map(creative => <CreativeRow key={creative.id} creative={creative} onPreviewClicked={onPreviewClicked} />)}</Table.Body>
  </Table>
);

export default CreativeList;
