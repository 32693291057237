import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import getter from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';

import * as BidderUi from '.';

class CountriesSection extends React.Component {
  constructor(props) {
    super(props);

    const {
      allow: { path: allowPath },
      block: { path: blockPath },
    } = this.COUNTRY_LIST_TYPES;

    const { bidder } = props;

    const allowedList = getter(bidder, allowPath, []);
    const blockedList = getter(bidder, blockPath, []);

    this.state = { ...this.getListType(allowedList, blockedList) };
  }

  componentWillReceiveProps(nextProps) {
    const {
      allow: { path: allowPath },
      block: { path: blockPath },
    } = this.COUNTRY_LIST_TYPES;

    const { bidder } = this.props;
    const { bidder: nextBidder } = nextProps;

    const allowedList = getter(bidder, allowPath, []);
    const blockedList = getter(bidder, blockPath, []);

    const nextAllowedList = getter(nextBidder, allowPath, []);
    const nextBlockedList = getter(nextBidder, blockPath, []);

    if (!isEqual(allowedList, nextAllowedList) || !isEqual(blockedList, nextBlockedList)) {
      this.setState({ ...this.getListType(nextAllowedList, nextBlockedList) });
    }
  }

  /**
   * Determine which list to use depending on contents of each list
   * @param {String[]} allowedList - A list of the allowed countries
   * @param {String[]} blockedList - A list of the blocked countries
   * @returns {Object} An object of properties describing the list to use
   */
  getListType(allowedList, blockedList) {
    const { allow, block } = this.COUNTRY_LIST_TYPES;

    if (!isEmpty(allowedList)) {
      return allow;
    } else if (!isEmpty(blockedList)) {
      return block;
    }

    return allow;
  }

  COUNTRY_LIST_TYPES = {
    allow: {
      path: 'request_validation.allowed_countries',
      text: 'Whitelist - Only these countries receive traffic',
    },
    block: {
      path: 'request_validation.blocked_countries',
      text: 'Blacklist - These countries receive no traffic',
    },
  };

  render() {
    const { allow: allowList, block: blockList } = this.COUNTRY_LIST_TYPES;

    const { bidder, moveList } = this.props;
    const { path } = this.state;

    const onChange = nextPath => () => {
      moveList(bidder, path, nextPath);
      this.setState({ path: nextPath });
    };

    return (
      <div>
        <Form.Field>
          <BidderUi.AddCountry
            {...this.props}
            path={path}
          />
        </Form.Field>

        <h3>Choose behavior</h3>
        <Form.Group>
          <Form.Field>
            <Checkbox
              checked={path === allowList.path}
              label={allowList.text}
              name="countryListBehavior"
              onChange={onChange(allowList.path)}
              radio
              value={allowList.path}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <Checkbox
              checked={path === blockList.path}
              label={blockList.text}
              name="countryListBehavior"
              onChange={onChange(blockList.path)}
              radio
              value={blockList.path}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <BidderUi.CountriesList
            {...this.props}
            path={path}
          />
        </Form.Field>
        <Form.Field>
          <BidderUi.PremadeCountryLists
            {...this.props}
            path={path}
          />
        </Form.Field>
      </div>
    );
  }
}

export default CountriesSection;

CountriesSection.defaultProps = {
  bidder: {},
  moveList: () => {},
};

CountriesSection.propTypes = {
  bidder: PropTypes.shape({}),
  moveList: PropTypes.func,
};
