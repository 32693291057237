import React, { Component } from 'react';
import { Visibility } from 'semantic-ui-react';

const lazyRender = (LazyComponent, items, itemsPerPage = 24, startPage = 1) =>
  class LazyRender extends Component {
    constructor(props) {
      super(props);
      this.state = { allItems: props[items], page: startPage };
      this.lazyRender = this.lazyRender.bind(this);
    }

    componentWillReceiveProps(nextProps) { this.setState({ allItems: nextProps[items] }); }

    lazyRender() { this.setState({ page: this.state.page + 1 }); }

    render() {
      const { allItems, page } = this.state;
      const props = { ...this.props, [items]: allItems.slice(0, page * itemsPerPage) };
      return (
        <Visibility onBottomVisible={this.lazyRender} once={false}>
          <LazyComponent {...props} />
        </Visibility>
      );
    }
  };

export default lazyRender;
