import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { STATUS_COLORS, STATUS_NAMES } from '../constants';


const STATUS_OPTIONS = STATUS_NAMES
  .map(statusName => ({
    key: statusName,
    value: statusName,
    text: statusName,
    label: {
      color: STATUS_COLORS[statusName],
      empty: true,
      circular: true,
    },
  }));

const StatusDropdown = ({ status, onChange }) => (
  <Dropdown
    button
    className="CreativeValidation__Dropdown CreativeValidation__StatusDropdown icon"
    icon={{ name: 'circle', color: STATUS_COLORS[status] }}
    labeled
    onChange={onChange}
    options={STATUS_OPTIONS}
    selection
    value={status}
  />
);

StatusDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  status: PropTypes.oneOf(STATUS_NAMES).isRequired,
};

export default StatusDropdown;
