import keyBy from 'lodash/keyBy';

export const PUBLISHER_MODE_OPTIONS = [
  { text: 'Default', value: -1 },
  { text: 'Block', value: 0 },
  { text: 'Compete', value: 1 },
  { text: 'Force', value: 2 },
  { text: 'Backfill', value: 3 },
];

export const PUBLISHER_MODE_MAP = keyBy(PUBLISHER_MODE_OPTIONS, 'value');
