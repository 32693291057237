/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Loader } from 'semantic-ui-react';
import PublishingAppContainer from './components/PublishingAppContainer';
import PublishingAppMenu from './components/PublishingAppMenu';
import { retrieveAppsWithMetrics, searchApps, approveApp, rejectApp, reReviewApp } from './actions';

export class PublishingAppsListContainer extends Component {
  componentDidMount() {
    const { retrieveApps } = this.props;
    retrieveApps('pending');
  }

  render() {
    const {
      apps,
      loading,
      retrieveApps,
      searchApps,
      approveApp,
      rejectApp,
      reReviewApp,
      apiValidation,
    } = this.props;

    return (
      <Container className="PublishingAppsList">
        <PublishingAppMenu retrieveApps={retrieveApps} searchApps={searchApps} />
        { loading ?
          <Loader active /> :
          <PublishingAppContainer
            apps={apps}
            retrieveApps={retrieveApps}
            approveApp={approveApp}
            rejectApp={rejectApp}
            reReviewApp={reReviewApp}
            apiValidation={apiValidation}
          />}
      </Container>
    );
  }
}

PublishingAppsListContainer.defaultProps = {};

PublishingAppsListContainer.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  apiValidation: PropTypes.shape({}).isRequired,
  retrieveApps: PropTypes.func.isRequired,
  searchApps: PropTypes.func.isRequired,
  approveApp: PropTypes.func.isRequired,
  rejectApp: PropTypes.func.isRequired,
  reReviewApp: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  apps: state.publishingApps.apps,
  loading: state.publishingApps.loading,
  apiValidation: state.publishingApps.apiValidation,
});

const mapDispatchToProps = dispatch => ({
  retrieveApps: status => dispatch(retrieveAppsWithMetrics(status, true)),
  searchApps: (query, appStatus) => dispatch(searchApps(query, appStatus)),
  approveApp: (appId, notes) => dispatch(approveApp(appId, notes)),
  rejectApp: (appId, notes, reason, detail) => dispatch(rejectApp(appId, notes, reason, detail)),
  reReviewApp: (appId, notes) => dispatch(reReviewApp(appId, notes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishingAppsListContainer);
