import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import PublishingAppCardAppStatusPendingRejectDropdown from './PublishingAppCardAppStatusPendingRejectDropdown';

const PublishingAppCardAppStatusPending = (props) => {
  const { appId, apiValidation, approveApp, rejectApp } = props;
  if (apiValidation.isLoading && apiValidation.appId === appId) {
    return (
      <div className="ui two buttons">
        <Button basic loading disabled color="grey">Loading</Button>
      </div>
    );
  }
  return (<div className="ui two buttons">
    <Button basic color="green" onClick={approveApp}>
      Approve
    </Button>
    <PublishingAppCardAppStatusPendingRejectDropdown rejectApp={rejectApp} />
  </div>);
};

PublishingAppCardAppStatusPending.defaultProps = {};
PublishingAppCardAppStatusPending.propTypes = {
  appId: PropTypes.string.isRequired,
  apiValidation: PropTypes.shape({}).isRequired,
  approveApp: PropTypes.func.isRequired,
  rejectApp: PropTypes.func.isRequired,
};

export default PublishingAppCardAppStatusPending;
