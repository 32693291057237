import React from 'react';
import PropTypes from 'prop-types';

import { Card, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const CustomLink = ({ isExternalLink, link, children }) => {
  if (isExternalLink) {
    return (
      <a href={link}>
        {children}
      </a>
    );
  }

  return (
    <Link to={link}>
      {children}
    </Link>
  );
};

const OverviewLinkCard = ({ link, icon, header, isExternalLink }) => (
  <Card.Group style={{ marginTop: '0.5rem' }}>
    <CustomLink key={link} link={link} isExternalLink={isExternalLink}>
      <Card>
        <div style={{ width: '100%', height: '200px', 'text-align': 'center' }}>
          <Icon name={icon} size="massive" style={{ 'line-height': '200px', 'vertical-align': 'center', color: '#4A5664' }} />
        </div>
        <Card.Content>
          <Card.Header style={{ textAlign: 'center' }}>{header}</Card.Header>
        </Card.Content>
      </Card>
    </CustomLink>
  </Card.Group>
);

OverviewLinkCard.defaultProps = { isExternalLink: false };

CustomLink.defaultProps = { isExternalLink: false };

OverviewLinkCard.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  isExternalLink: PropTypes.bool,
};

CustomLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  link: PropTypes.string.isRequired,
  isExternalLink: PropTypes.bool,
};

export default OverviewLinkCard;
