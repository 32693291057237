import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Form, Dropdown } from 'semantic-ui-react';

const DiscrepancyMenu = ({ onPartnerChange, partners }) => (
  <Segment>
    <Form>
      <Form.Field>
        <label htmlFor="partner">Partner</label>
        <Dropdown
          id="partner"
          name="partner"
          placeholder="Partner name..."
          onChange={onPartnerChange}
          selection
          options={partners || []}
        />
      </Form.Field>
    </Form>
  </Segment>
);

DiscrepancyMenu.propTypes = {
  onPartnerChange: PropTypes.func
};

DiscrepancyMenu.defaultProps = {
  onPartnerChange: null,
  partners: []
};

export default DiscrepancyMenu;
