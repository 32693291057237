import { createElement } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Raven from 'raven-js';

import { App } from './app';
import configureStore from './store';
import setupServiceWorker from './sw';

const loadRavenForSentry = () => Raven.config(process.env.REACT_APP_SENTRY_DSN_URL).install();

setupServiceWorker();
if (process.env.REACT_APP_SENTRY_DSN_URL) {
  loadRavenForSentry();
}

// eslint-disable-next-line import/prefer-default-export
export const store = configureStore();

render(createElement(Router, {}, createElement(App, { store })), document.getElementById('root'));
