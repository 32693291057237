import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { Message } from 'semantic-ui-react';

const ServerMessages = (props) => {
  const { serverMessages } = props;
  const messages = map(serverMessages, (error, key) => (
    <Message key={key} negative>
      <Message.Header>{error.title}</Message.Header>
      <p>{error.message}</p>
    </Message>
  ));

  return (
    <div>
      {messages}
    </div>
  );
};

export default ServerMessages;

ServerMessages.defaultProps = {
  serverMessages: {},
};

ServerMessages.propTypes = {
  serverMessages: PropTypes.shape({}),
};
