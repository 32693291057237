import PropTypes from 'prop-types';
import React from 'react';
import { Button, Checkbox, Icon, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DEFAULT_END_TIMESTAMP } from '../../domain/pmpDeals/constants';

import DEAL_TYPE from '../../domain/pmpDeals/dealType';

const formatDate = date => new Date(date).toLocaleString();
const PrivateMarketplaceRow = (props) => {
  const { deal, isAdminView } = props;
  const isActive = deal.is_active ? 1 : 0;
  if (deal.end_timestamp === DEFAULT_END_TIMESTAMP) {
    deal.end_timestamp = '';
  }

  const dealLink = isAdminView ? `/pmp/${deal.id}` : `/public/pmp/${deal.id}`;

  return (
    <Table.Row>
      <Table.Cell collapsing>
        <Checkbox
          checked={isActive === 1}
          label="Enabled"
          name="is_active"
          toggle
        />
      </Table.Cell>
      <Table.Cell>{deal.id}</Table.Cell>
      <Table.Cell><Link to={dealLink}>{deal.name}</Link></Table.Cell>
      <Table.Cell collapsing>{deal.deal_price}</Table.Cell>
      <Table.Cell>{DEAL_TYPE[deal.deal_type]}</Table.Cell>
      <Table.Cell>{formatDate(deal.date_created)}</Table.Cell>
      <Table.Cell>{deal.start_timestamp ? formatDate(deal.start_timestamp) : ''}</Table.Cell>
      <Table.Cell>{deal.end_timestamp ? formatDate(deal.end_timestamp) : ''}</Table.Cell>
      <Table.Cell collapsing>
        <Button icon as={Link} to={dealLink} >
          <Icon name="pencil" />
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

PrivateMarketplaceRow.defaultProps = { isAdminView: false };

PrivateMarketplaceRow.propTypes = {
  deal: PropTypes.shape({}).isRequired,
  isAdminView: PropTypes.bool,
};

export default PrivateMarketplaceRow;
