import React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';

const GlobalPriceInput = ({ error, label, max, name, placeholder, setGlobals, value }) => {
  const onChange = (event, { name, value }) => { setGlobals(event, { name, value: value / 100 }); };
  return (
    <Form.Field>
      <Input
        autoComplete="on"
        error={error}
        inputMode="numeric"
        label={label}
        labelPosition="right"
        max={max || undefined}
        min="0"
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type="number"
        value={value * 100}
      />
      {error && <Label basic color="red" pointing>{error}</Label>}
    </Form.Field>
  );
};

export default GlobalPriceInput;
