import React from 'react';

import { Form } from 'semantic-ui-react';
import { CREATIVE_MODE_OPTIONS } from '../../shared/constants';

const GlobalCreativeModes = ({ globals, setGlobals }) => (
  <Form.Group grouped>
    <Form.Dropdown
      label="Static"
      name="creative_types.static"
      options={CREATIVE_MODE_OPTIONS}
      onChange={setGlobals}
      placeholder="Static"
      selection
      value={globals.creative_types.static}
    />
    <Form.Dropdown
      label="Video"
      name="creative_types.video"
      options={CREATIVE_MODE_OPTIONS}
      onChange={setGlobals}
      placeholder="Video"
      selection
      value={globals.creative_types.video}
    />
    <Form.Dropdown
      label="MRAID"
      name="creative_types.mraid"
      options={CREATIVE_MODE_OPTIONS}
      onChange={setGlobals}
      placeholder="Mraid"
      selection
      value={globals.creative_types.mraid}
    />
  </Form.Group>
);

export default GlobalCreativeModes;
