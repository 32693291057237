import 'whatwg-fetch';
import compact from 'lodash/compact';
import find from 'lodash/find';
import getter from 'lodash/get';
import isMatch from 'lodash/isMatch';

import { ACTION_TITLES, ERROR_CODES, PLATFORMS_BY_ID } from './constants';

/**
 * Convert a comma separated string into an array. Delimits by comma, space, or new line
 * @params {String} comma separated string
 * @returns {Array} array of ids
 */
export const commaStringToArray = (query = '') => {
  const delimiter = find([',', ' ', '\n'], char => query.includes(char)) || null;
  return compact(query.split(delimiter).map((id = '') => id.trim()));
};

/**
 * Converts a decimal number to an int multiplied by a 100
 * @param {float} decimal - Floating number
 * @returns {int} Returns an integer or 0 if unparseable
 */
export const decimalToInt = decimal => parseInt(Math.round(decimal * 100), 10) || 0;

/**
 * Get platform attributes based on id
 * @params {Integer} Platform Integer (1,2,3)
 * @returns {Object} Platform attributes
 */
export const getPlatform = platformId => (
  PLATFORMS_BY_ID[platformId] || { icon: 'question', name: ' ', key: 'unknown' }
);

/**
 * Get the platform attributes based on known attributes
 * @param {Object} attrs - Platform attributes to match
 * @returns {Object} All the platform attributes
 */
export const getPlatformFromAttrs = attrs => (
  find(PLATFORMS_BY_ID, platform => isMatch(platform, attrs))
  || { icon: 'question', name: ' ', key: 'unknown' }
);

/**
 * Get the title of the action
 * @param {String} action - The action
 * @returns {String} - A title for the action
 */
export const getActionTitle = action => ACTION_TITLES[action] || 'What was that?';

/**
 * Get a human readable message from the error code
 * @param {String} errorCode - An error code from the server
 * @returns {String} - A human readable message
 */
export const getErrorMessage = errorCode => ERROR_CODES[errorCode]
  || 'Something so weird happened, I can\'t explain it';

/**
 * Given a string of app ids, parse the ids into an array.
 * App Ids are 24 alphanumeric base 16 chars.
 * @param {String} ids - A string of ids delimited by comma, space, or new line
 * @return {String[]} - An array of ids that were parsed. Drops bad ids.
 */
export const parseIds = (ids = '') => (
  commaStringToArray(ids).filter(id => /^[a-f0-9]{24}$/i.test(id))
);

/**
 * A sort comparator for objects. Useful when sorting Object[].
 * Can sort on strings or numbers.
 * @param {String} path - A path to the object attribute to compare
 * @returns {Number} 1 if a > b, 0 if a === b, -1 if a < b.
 */
export const objectComparator = path => (objectA, objectB) => {
  const a = getter(objectA, path, '');
  const b = getter(objectB, path, '');
  return +(a > b) || +(a === b) - 1;
};

export const by = key => (a, b) => { if (a[key] < b[key]) { return -1; } return 1; };
