import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';

import {
  ADD_BIDDER,
  CREATE_BIDDER_SUCCESS,
  EXECUTE_DEPLOY_PUB_CONFIG_SUCCESS,
  FETCH_BIDDER_SUCCESS,
  FETCH_BIDDERS_SUCCESS,
  FETCH_BIDDERS_BUDGET_SUCCESS,
  REMOVE_BIDDER,
  SET_BIDDER,
  SYNC_BIDDER_SUCCESS,
} from '../actions';

import { BIDDER_DEFAULTS } from '../constants';

const bidders = (state = {}, action = {}) => {
  switch (action.type) {
    case ADD_BIDDER:
      return Object.assign(
        {}, state,
        { [action.bidderId]: { id: action.bidderId, ...cloneDeep(BIDDER_DEFAULTS) } },
      );

    case EXECUTE_DEPLOY_PUB_CONFIG_SUCCESS: {
      const {
        attrs = {},
        bidderId,
      } = action;
      const { [bidderId]: bidder = {} } = state;

      return Object.assign({}, state, { [bidderId]: { ...bidder, test_env: attrs } });
    }

    case FETCH_BIDDER_SUCCESS: {
      const { response = {} } = action;
      const nextState = { [response.id]: Object.assign({}, BIDDER_DEFAULTS, state[response.id], response) };
      return Object.assign({}, state, nextState);
    }

    case FETCH_BIDDERS_SUCCESS: {
      const mergedState = mapValues(keyBy(action.response, 'id'), (nextBidder, bidderId) => {
        const bidder = state[bidderId] || { date_modified: 0 };
        return Object.assign(
          {}, BIDDER_DEFAULTS,
          bidder.date_modified > nextBidder.date_modified ? bidder : nextBidder,
        );
      });
      return mergedState;
    }

    case FETCH_BIDDERS_BUDGET_SUCCESS: {
      const mergedState = { ...state };
      const { payload: { response: { response } } } = action;
      response.map((account) => {
        Object.keys(mergedState).map((key) => {
          const { bidder_id: bidderId, account: { credit_limit: creditLimit, amount } } = account;
          if (key === bidderId) {
            mergedState[key].credit_limit = creditLimit;
            mergedState[key].amount = amount;
          }
          return mergedState;
        });
        return mergedState;
      });
      return mergedState;
    }

    case REMOVE_BIDDER:
      return omit(state, action.bidderId);

    case SET_BIDDER: {
      const { bidderId = 'no_id', attrs = {} } = action;
      const nextState = { [bidderId]: Object.assign({}, BIDDER_DEFAULTS, state[bidderId], attrs) };
      return Object.assign({}, state, nextState);
    }

    case CREATE_BIDDER_SUCCESS:
    case SYNC_BIDDER_SUCCESS: {
      const { response, response: { id: bidderId = 'no_id' } } = action;
      const nextState = { [bidderId]: Object.assign({}, BIDDER_DEFAULTS, state[bidderId], response) };
      return Object.assign({}, state, nextState);
    }

    default:
      return state;
  }
};

export default bidders;
