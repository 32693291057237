import getter from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import setter from 'lodash/set';
import { Form } from 'semantic-ui-react';

const Toggle = (props) => {
  const { bidder, path, label, setBidder } = props;
  const checked = getter(bidder, path, false);

  const onChange = (event, ownProps) => {
    const nextBidder = Object.assign({}, bidder);
    const nextProps = setter(nextBidder, path, ownProps.checked);
    setBidder(bidder.id, nextProps);
  };

  return <Form.Checkbox checked={checked} label={label} onChange={onChange} toggle />;
};

export default Toggle;

Toggle.defaultProps = {
  bidder: {},
  label: '',
  path: '',
  setBidder: () => {},
};

Toggle.propTypes = {
  bidder: PropTypes.shape({}),
  label: PropTypes.string,
  path: PropTypes.string,
  setBidder: PropTypes.func,
};
