import { isEmpty } from 'lodash';
import { DASH_API } from '../../shared/api';
import { LOADED, LOADING } from '../../shared/constants/readyStates';
import { axios } from '../../shared/helpers';

export const RETRIEVED_PLAYABLES = 'PLAYABLES_VALIDATION.RETRIEVED_PLAYABLES';
export const PLAYABLES_PAGES = 'PLAYABLES_VALIDATION.PLAYABLES_PAGES';
export const SELECT_PLAYABLE = 'PLAYABLES_VALIDATION.SELECT_PLAYABLE';
export const UNSELECT_PLAYABLE = 'PLAYABLES_VALIDATION.UNSELECT_PLAYABLE';
export const ERROR_RETRIEVING_PLAYABLES = 'PLAYABLES_VALIDATION.ERROR_RETRIEVING_PLAYABLES';
export const ERROR_SAVING_PLAYABLE_VALIDATION = 'PLAYABLES_VALIDATION.ERROR_SAVING_PLAYABLE_VALIDATION';
export const SAVING_PLAYABLE_VALIDATION = 'PLAYABLES_VALIDATION.SAVING_PLAYABLE_VALIDATION';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const TOTAL_PLAYABLES = 'TOTAL_PLAYABLES';

export const selectPlayable = playable => ({ type: SELECT_PLAYABLE, playable });
export const unselectPlayable = () => ({ type: UNSELECT_PLAYABLE });
export const setPageNumber = () => ({ type: SET_PAGE_NUMBER });

export const loadPage =
  (page, playablesPerPage, query = '', forceRequest = false) => async (dispatch, getState) => {
    dispatch({ type: LOADING });

    if (
      forceRequest ||
      !getState().playablesList.playablesPages[page] ||
      getState().playablesList.playablesPages[page].length === 0
    ) {
      try {
        const queryParams = query !== '' ? `?query=${query}` : '';
        const response = await axios.get(`${DASH_API}/playables/${page}/${playablesPerPage}${queryParams}`);

        dispatch({
          type: PLAYABLES_PAGES,
          page,
          playables: response.data.playables,
        });

        dispatch({
          type: TOTAL_PLAYABLES,
          totalPlayables: response.data.total,
        });
      } catch (error) {
        console.error('Failed to retrieve playables', error);
        dispatch({ type: ERROR_RETRIEVING_PLAYABLES, error });
      }
    }

    const filteredPlayables = getState().playablesList.playablesPages[page];

    dispatch({
      type: RETRIEVED_PLAYABLES,
      playables: filteredPlayables,
    });

    dispatch({ type: LOADED });
  };

export const savePlayableValidation = (
  creativeId,
  reason,
  validationDate,
  validationNotes,
  page,
  playablesPerPage,
  query,
) => async (dispatch) => {
  dispatch({ type: SAVING_PLAYABLE_VALIDATION });
  try {
    const playableValidation = {
      validation_status: isEmpty(reason) ? 'APPROVED' : 'REJECTED',
      validation_result: {
        validated_at: validationDate,
        notes: validationNotes,
      },
    };
    if (!isEmpty(reason)) {
      playableValidation.validation_result.reject_reason = reason;
    }

    await axios.patch(`${DASH_API}/playables/${creativeId}`, playableValidation);

    dispatch(unselectPlayable());
    dispatch(loadPage(page, playablesPerPage, query, true));
  } catch (error) {
    console.error('Failed to save playable validation: ', error);
    dispatch({ type: ERROR_SAVING_PLAYABLE_VALIDATION }, error);
  }
};

export const getRejectReasons = async () => {
  try {
    const { response: rejectReasons } = await axios.get(`${DASH_API}/playables/reject_reasons`);
    return rejectReasons;
  } catch (error) {
    console.error('Failed to get rejectReasons: ', error);
    return [];
  }
};
