import React from 'react';
import { Form } from 'semantic-ui-react';

import FormulaInput from './FormulaInput';
import FormulaOption from './FormulaOption';

const FormulaSet = props => (
  <Form.Group grouped>
    <Form.Group inline>
      <Form.Field><FormulaOption {...props} formulaKey="percent" /></Form.Field>
      <Form.Field><FormulaInput
        {...props}
        formulaKey="percent"
        label="%"
        max={100}
        placeholder="Percentage"
      /></Form.Field>
    </Form.Group>
    <Form.Group inline>
      <Form.Field><FormulaOption {...props} formulaKey="dollars" /></Form.Field>
      <Form.Field><FormulaInput
        {...props}
        formulaKey="dollars"
        label="¢"
        placeholder="Cents"
      /></Form.Field>
    </Form.Group>
  </Form.Group>
);

export default FormulaSet;
