export const AD_FORMATS = {
  MRAID: 'MRAID',
  VAST: 'VAST',
};

export const TEMPLATE_NAMES = {
  MRAID: 'mraid-iframe-open',
  VAST: 'vast-moat-v2',
};

export const STATUS_COLORS = {
  open: 'yellow',
  approved: 'green',
  rejected: 'red',
};

export const STATUS_NAMES = ['open', 'approved', 'rejected'];
