import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Card, Divider, Icon, List, Popup } from 'semantic-ui-react';
import truncate from 'lodash/truncate';
import isEmpty from 'lodash/isEmpty';
import Clipboard from 'clipboard';

import { formatDate } from '../../utils';
import {
  MRAID_CREATIVE_MEDIA_TYPE,
  VAST_CREATIVE_MEDIA_TYPE,
} from '../../helpers';
import CreativeVisualization from '../CreativeVisualization';

import '../styles/CreativeCard.css';

const CreativeCard = ({
  creative,
  creativeTemplate,
  creativeTemplateLoaded,
  creativeTemplateName,
  creativeTemplateS3,
  getCreativeTemplate,
}) => {
  const initClipboard = () => new Clipboard('#adm-copy');
  const creativeMediaType = creative.adm.includes('</VAST>')
    ? VAST_CREATIVE_MEDIA_TYPE
    : MRAID_CREATIVE_MEDIA_TYPE;

  useEffect(() => {
    initClipboard();
    if (isEmpty(creativeTemplate) && !creativeTemplateLoaded) {
      getCreativeTemplate(creativeMediaType);
    }
  }, []);

  return (
    <Card fluid id="creativeCard">
      <Card.Content>
        <Card.Content>
          <h2>Creative details</h2>
          <List divided relaxed>
            <List.Item id="creative_id">
              <List.Icon name="puzzle piece" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>Creative ID</List.Header>
                <List.Description>{creative.creative_id}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item id="creative-status">
              <List.Icon name="wifi" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>Creative status</List.Header>
                <List.Description>{creative.status}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item id="creative_date">
              <List.Icon name="calendar outline" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>Creation date</List.Header>
                <List.Description><span className="date">{formatDate(creative.date_created)}</span></List.Description>
              </List.Content>
            </List.Item>
            <List.Item className="adm">
              <List.Icon name="code" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>Creative adm</List.Header>
                <List.Description>
                  <span>{truncate(creative.adm, { length: 100 })}</span>
                  <Popup
                    content="adm copied!"
                    on="click"
                    position="top center"
                    trigger={<Icon name="copy" id="adm-copy" data-clipboard-text={creative.adm} />}
                  />
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Card.Content>
        <Divider />
        <Card.Content>
          <h2>Bidder details</h2>
          <List divided relaxed>
            <List.Item id="bidder-id">
              <List.Icon name="puzzle piece" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>Bidder ID</List.Header>
                <List.Description>{creative.bidder_id}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item id="bidder-name">
              <List.Icon name="newspaper outline" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>Bidder name</List.Header>
                <List.Description>{creative.bidder_name}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item id="impressions">
              <List.Icon name="eye" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>Impressions</List.Header>
                <List.Description>{creative.count}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Card.Content>
        <Divider />
        <Card.Content>
          <h2>Visual representation</h2>
          <CreativeVisualization
            creative={{
              ...creative,
              media_type: creativeMediaType,
            }}
            template={creativeTemplate}
            templateName={creativeTemplateName}
            templateS3={creativeTemplateS3}
          />
        </Card.Content>
      </Card.Content>
    </Card>
  );
};

CreativeCard.propTypes = {
  creative: PropTypes.shape({}).isRequired,
  creativeTemplate: PropTypes.string.isRequired,
  creativeTemplateLoaded: PropTypes.bool.isRequired,
  creativeTemplateName: PropTypes.string.isRequired,
  creativeTemplateS3: PropTypes.string.isRequired,
  getCreativeTemplate: PropTypes.func.isRequired,
};

export default CreativeCard;
