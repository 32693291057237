import React from 'react';
import { Form } from 'semantic-ui-react';

import BlockConnectionOption from './ConnectionOption';
import { CELLULAR_CONNECTION, WIFI_CONNECTION } from '../../shared/constants/connectionType';

const BlockConnectionsSet = props => (
  <Form.Group grouped>
    <label>Block connection types</label>
    <Form.Field>
      <BlockConnectionOption {...props} connection={CELLULAR_CONNECTION} />
    </Form.Field>
    <Form.Field>
      <BlockConnectionOption {...props} connection={WIFI_CONNECTION} />
    </Form.Field>
  </Form.Group>
);

export default BlockConnectionsSet;
