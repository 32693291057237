import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

import CampaignOption from './CampaignOption';
import { ANDROID_PLATFORM, IOS_PLATFORM } from '../../shared/constants';

const CampaignSet = (props) => {
  const { campaignType, label } = props;

  return (
    <Form.Group grouped>
      <label>{label}</label>
      <CampaignOption
        {...props}
        path={`campaigns.${campaignType}.android`}
        platform={ANDROID_PLATFORM}
      />
      <CampaignOption
        {...props}
        path={`campaigns.${campaignType}.ios`}
        platform={IOS_PLATFORM}
      />
    </Form.Group>
  );
};

export default CampaignSet;

CampaignSet.defaultProps = {
  campaignType: '',
  label: '',
};

CampaignSet.propTypes = {
  campaignType: PropTypes.string,
  label: PropTypes.string,
};
