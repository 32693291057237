import React from 'react';
import { Icon, Table } from 'semantic-ui-react';

import { BidderRow } from '.';

const BidderListTable = ({ bidders = [] }) => (
  <Table celled selectable striped structured>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell rowSpan={2}>Bidder Name</Table.HeaderCell>
        <Table.HeaderCell rowSpan={2}>Company Name</Table.HeaderCell>
        <Table.HeaderCell rowSpan={2}>User ID</Table.HeaderCell>
        <Table.HeaderCell rowSpan={2}>Credit</Table.HeaderCell>
        <Table.HeaderCell rowSpan={2}>Balance</Table.HeaderCell>
        <Table.HeaderCell rowSpan={2}>Credit Left</Table.HeaderCell>
        <Table.HeaderCell colSpan={4} textAlign="center">Throttling</Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell textAlign="center"><Icon name="android" /> Static</Table.HeaderCell>
        <Table.HeaderCell textAlign="center"><Icon name="apple" /> Static</Table.HeaderCell>
        <Table.HeaderCell textAlign="center"><Icon name="android" /> Video</Table.HeaderCell>
        <Table.HeaderCell textAlign="center"><Icon name="apple" /> Video</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>{bidders.map(bidder => <BidderRow key={bidder.id} bidder={bidder} />)}</Table.Body>
  </Table>
);

export default BidderListTable;
