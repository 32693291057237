import getter from 'lodash/get';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'semantic-ui-react';

import ConnectionLabel from './ConnectionLabel';

const ConnectionOption = (props) => {
  const {
    bidder,
    connection,
    setListItem,
  } = props;

  const path = 'request_validation.blocked_connection_types';
  const checked = includes(getter(bidder, path, []), connection.id);

  const onChange = (event, ownProps) => {
    setListItem(bidder, path, Number(ownProps.value));
  };

  return (
    <Checkbox
      checked={checked}
      label={<ConnectionLabel {...props} path={path} />}
      onChange={onChange}
      value={String(connection.id)}
    />
  );
};

export default ConnectionOption;

ConnectionOption.defaultProps = {
  bidder: {},
  connection: {},
  setListItem: () => {},
};

ConnectionOption.propTypes = {
  bidder: PropTypes.shape({}),
  connection: PropTypes.shape({}),
  setListItem: PropTypes.func,
};
