import isEmpty from 'lodash/isEmpty';
import { DEFAULT_END_TIMESTAMP } from './constants';

export default class PMPDeal {
  constructor(
    id = 'new',
    description = '',
    name = '',
    isActive = false,
    dateCreated = '',
    dateModified = '',
    startTimestamp = '',
    endTimestamp = '',
    eligibleBidders = [],
    dealType = 3,
    dealPrice = '',
    currency = 'USD',
    demandSubType = '',
    priorityLevel = 0,
    dimensions = {
      country: [],
      state: [],
      placement_type: ['interstitial', 'rewarded', 'banner'],
      platform: [1, 2],
      ad_type: [0, 1],
      skippable: [],
      segments: [],
      publisher_companies: [],
      publisher_apps: [],
      ad_location: [],
      iab_categories: [],
      iab_categories_v2: '',
    },
  ) {
    this.id = id;
    this.description = description;
    this.name = name;
    this.is_active = isActive;
    this.date_created = dateCreated;
    this.date_modified = dateModified;
    this.start_timestamp = startTimestamp;
    this.end_timestamp = endTimestamp;
    this.eligible_bidders = eligibleBidders;
    this.deal_type = dealType;
    this.deal_price = dealPrice;
    this.currency = currency;
    this.demand_sub_type = demandSubType;
    this.priority_level = priorityLevel;
    this.dimensions = dimensions;
  }

  getBodyJsonRequest() {
    const { dimensions: { skippable } } = this;

    const deal = {
      _id: this.id,
      name: this.name,
      description: this.description,
      is_active: this.is_active,
      date_created: this.date_created,
      date_modified: new Date().getTime(),
      start_timestamp: this.start_timestamp,
      end_timestamp: this.end_timestamp,
      eligible_bidders: this.eligible_bidders,
      deal_type: this.deal_type,
      deal_price: parseFloat(this.deal_price),
      currency: this.currency,
      demand_sub_type: this.demand_sub_type,
      priority_level: this.priority_level,
      dimensions: this.dimensions,
    };

    if (this.id === 'new') {
      delete deal._id;
      deal.date_created = new Date().getTime();
    }

    if (deal.end_timestamp === '') {
      deal.end_timestamp = DEFAULT_END_TIMESTAMP;
    }

    if (isEmpty(skippable)) {
      delete deal.dimensions.skippable;
    }

    return deal;
  }

  static isSegment(appSegment, value) { return appSegment.id === value; }

  getDeviceSegments(deviceSegmentsList) {
    return this.dimensions.segments.filter(segment => (
      deviceSegmentsList.some(element => PMPDeal.isSegment(element, segment))
    ));
  }

  getAppSegment(appSegmentsList) {
    return this.dimensions.segments.filter(segment => (
      appSegmentsList.some(element => PMPDeal.isSegment(element, segment))
    ));
  }

  setSegments(appSegments, deviceSegments) {
    this.dimensions.segments = [...new Set([...appSegments, ...deviceSegments])];
  }

  invalidToSave() {
    const {
      dimensions: {
        country,
        platform,
        placement_type: placementType,
        ad_type: adType,
      },
    } = this;
    return (isEmpty(this.name)
      || isEmpty(country)
      || isEmpty(platform)
      || isEmpty(placementType)
      || isEmpty(adType)
      || this.deal_type < 1
      || parseFloat(this.deal_price) < 0
      || isEmpty(this.deal_price.toString())
      || isEmpty(this.eligible_bidders)
      || isEmpty(this.demand_sub_type)
      || this.priority_level === 0);
  }

  copy() {
    return new PMPDeal(
      'new',
      this.description,
      `Copy of ${this.name}`,
      false,
      '',
      '',
      this.start_timestamp,
      this.end_timestamp,
      this.eligible_bidders,
      this.deal_type,
      this.deal_price,
      this.currency,
      this.demand_sub_type,
      this.priority_level,
      this.dimensions,
    );
  }
}
