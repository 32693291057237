import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { Redirect, Route } from 'react-router-dom';

import { selectBundles } from '../../user/selectors';

export const ProtectedRouteContainer = ({ bundles, name, ...rest }) => {
  if (!bundles.length) { return <Loader active />; }
  return bundles.map(bundle => bundle.name).includes(name)
    ? <Route {...rest} />
    : <Redirect to="/" />;
};

ProtectedRouteContainer.defaultProps = {
  bundles: [],
  name: '',
};

ProtectedRouteContainer.propTypes = {
  bundles: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
};

const mapStateToProps = state => ({ bundles: selectBundles(state) });

export default connect(mapStateToProps)(ProtectedRouteContainer);
