import { applyMiddleware, compose, createStore } from 'redux';

import { createReducer, injectReducer } from './reducer';
import { messaging, thunk } from './middleware';
import { dehydrate } from './localStorage';

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable no-underscore-dangle */

export default function configureStore(initialState = {}) {
  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(applyMiddleware(messaging, thunk)),
  );
  store.subscribe(dehydrate(store));
  store.reducers = {};
  store.injectReducer = injectReducer(store);
  return store;
}
