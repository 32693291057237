import { APP_ONLINE, APP_RESIZE, TOGGLE_NAVBAR } from './actions';

const initState = {
  dimensions: [window.innerHeight, window.innerWidth],
  messages: [],
  navbarVisible: false,
  online: navigator.onLine
};

const reducer = (state = initState, action) => {
  const { dimensions, status, type, visible } = action;
  switch (type) {
    case APP_ONLINE:
      return { ...state, online: status };

    case APP_RESIZE:
      return { ...state, dimensions };

    case TOGGLE_NAVBAR:
      return { ...state, navbarVisible: visible !== undefined ? visible : !state.navbarVisible };

    default:
      return state;
  }
};

export default reducer;
