import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';

const validator = (attrs = {}) => (
  pickBy(mapValues(attrs, (attr, key) => {
    switch (key) {
      case 'creative_types':
        if (attr.static === null) { return 'Static Creative Type cannot be null'; }
        if (attr.video === null) { return 'Video Creative Type cannot be null'; }
        return null;

      case 'price_formula':
        if (attr.percent > 1) { return { percent: 'Should not exceed 100%' }; }
        return null;

      default:
        return null;
    }
  }))
);

export default validator;
