import { LOADED, LOADING } from '../../../shared/constants/readyStates';
import { FETCH_STATUS } from './actions';
import { rehydrate } from '../../../store/localStorage';

const initState = {
  loading: false,
  trackingResponse: {},
};

const reducer = (state = rehydrate('s2sTracking', initState), action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };

    case LOADED:
      return { ...state, loading: false };

    case FETCH_STATUS:
      return {
        ...state,
        trackingResponse: action.trackingResponse,
      };

    default:
      return state;
  }
};

export default reducer;
