import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Icon } from 'semantic-ui-react';

const FlexbidRow = ({ flexbid }) => {
  const {
    name,
    active,
    deleted,
    _id: id,
    campaign_id: campaignId,
    target_id: targetId,
    user_id: userId,
  } = flexbid;
  return (
    <Table.Row negative={deleted}>
      <Table.Cell><Icon name="circle" color={active ? 'green' : 'red'} /></Table.Cell>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>
        <Link to={`flexbids/${id}`}>{campaignId}</Link>
      </Table.Cell>
      <Table.Cell>{targetId}</Table.Cell>
      <Table.Cell>{userId}</Table.Cell>
      <Table.Cell>
        <Link to={`flexbids/${id}`}><Icon name="edit" /></Link>
      </Table.Cell>
    </Table.Row>
  );
};

FlexbidRow.propTypes = { flexbid: PropTypes.shape(PropTypes.object).isRequired };

export default FlexbidRow;
