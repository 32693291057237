/* eslint-disable */
import { failure, started, success } from '../../../shared/actions';
import { LOADING, UNLOADED, LOADED } from '../../../shared/constants/readyStates';
import setter from 'lodash/set';
import {
  FETCH_FLEXBID,
  FETCH_FLEXBIDS,
  ADD_FLEXBIDS,
  SET_FLEXBIDS,
  RESET_FLEXBIDS,
  SAVE_FLEXBIDS,
  PARSE_FLEXBID_TSV,
  TOGGLE_SHOW_DELETED,
} from './actions';

const initState = {
  readyState: UNLOADED,
  showDeleted: false,
  new: null,
  data: [],
};

export const flexbidDefaults = {
  active: true,
  automate: true,
  deleted: false,
  campaign_id: '',
  target_id: '',
  dimensions: {
    max_bid: '15',
  }
};

const reducer = (state = initState, { payload, type }) => {
  switch (type) {

    case started(FETCH_FLEXBIDS):
      return { ...state, readyState: LOADING };

    case success(FETCH_FLEXBIDS):
      return { ...state, readyState: LOADED, data: payload.response.response };

    case failure(FETCH_FLEXBIDS):
      return { ...state, readyState: LOADED };

    case started(FETCH_FLEXBID):
      return { ...state, readyState: LOADING };

    case success(FETCH_FLEXBID):
      return { ...state, readyState: LOADED, new: payload.response.response };

    case failure(FETCH_FLEXBID):
      return { ...state, readyState: LOADED, new: null};

    case ADD_FLEXBIDS:
      return { ...state, new: state.new ? state.new : { ...flexbidDefaults } };

    case SET_FLEXBIDS:
      setter(state.new, payload.name, payload.value);
      return {
        ...state,
        new: { ...state.new }
      };

    case RESET_FLEXBIDS:
      return { ...state, new: null };

    case SAVE_FLEXBIDS:
      return { ...state };

    case PARSE_FLEXBID_TSV:
      let dimensions = { ...state.new.dimensions };
      const rows = payload.files.tsv.split('\n');
      rows.forEach(row => {
        const [ dimension, key, value ] = row.split('\t');
        if (!dimensions[dimension]) dimensions[dimension] = {};
        dimensions[dimension][key.toLowerCase()] = parseFloat(value);
      });
      return { ...state, new: { ...state.new, dimensions } };

    case TOGGLE_SHOW_DELETED:
      return { ...initState, showDeleted: !state.showDeleted};

    default:
      return { ...state };

  }
};

export default reducer;
