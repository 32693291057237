import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';


const TableNoResults = props => (
  <Table.Row>
    <Table.Cell colSpan={props.colSpan} textAlign="center">
      { props.emptyState }
    </Table.Cell>
  </Table.Row>
);


TableNoResults.defaultProps = { emptyState: 'No Results found' };

TableNoResults.propTypes = {
  colSpan: PropTypes.number.isRequired,
  emptyState: PropTypes.string,
};

export default TableNoResults;
