import * as fromBidders from '../actions';

const activeIndex = (state = {}, action = {}) => {
  switch (action.type) {
    case fromBidders.ACTIVE_INDEX:
      return action.activeIndex;
    default:
      return -1;
  }
};

export default activeIndex;
