import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Modal } from 'semantic-ui-react';
import { createPlayableHtml } from '../helpers';
import { CreativeEmbed, CreativeInfo } from '.';


class CreativePreviewSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playableHtml: '',
    };
  }

  componentWillMount() {
    const {
      creative: { adm },
      template: { html: templateHtml, adFormat },
    } = this.props;
    this.setState({ playableHtml: createPlayableHtml(templateHtml, adFormat, adm) });
  }

  render() {
    const { creative, isOpen, onClose, onSave } = this.props;
    const { playableHtml } = this.state;

    return (
      <Modal open={isOpen} dimmer={'blurring'} className="CreativePreview__Modal">
        <Modal.Header className="CreativePreview__Header">
          <span>
            <div className="CreativePreview__HeaderTitle">Creative ID:</div>
            <p>{creative.creative_id}</p>
          </span>
          <button onClick={onClose} className="CreativePreview__Close">
            <Icon name="close" color="grey" size="large" />
          </button>
        </Modal.Header>
        <Modal.Content>
          <div className="CreativePreview">
            <div className="CreativePreview__LeftPanel">
              {playableHtml &&
                <CreativeEmbed
                  isPortrait
                  playableHtml={playableHtml}
                />
              }
            </div>
            <div className="CreativePreview__RightPanel">
              <CreativeInfo
                {...creative}
                onSave={onSave}
              />
              {playableHtml &&
                <CreativeEmbed
                  isPortrait={false}
                  playableHtml={playableHtml}
                />
              }
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

CreativePreviewSection.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

CreativePreviewSection.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func.isRequired,
};

export default CreativePreviewSection;
