import keyBy from 'lodash/keyBy';

import { SEARCH_APP_NAME_SUCCESS } from '../actions';

const appResults = (state = {}, action = {}) => {
  switch (action.type) {
    case SEARCH_APP_NAME_SUCCESS:
      return keyBy(action.response, 'id');

    default:
      return state;
  }
};

export default appResults;
