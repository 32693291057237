import map from 'lodash/map';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';

import { BIDDER_MODES } from '../constants';

class Mode extends React.Component {
  constructor(props) {
    super(props);
    const { bidder: { mode = '' } } = props;
    this.state = { mode };
  }

  componentWillReceiveProps(nextProps) {
    const { bidder: { mode } } = this.props;
    const { bidder: { mode: nextMode } } = nextProps;
    if (mode !== nextMode) {
      this.setState({ mode: nextMode });
    }
  }

  render() {
    const { bidder, bidder: { id }, setBidder } = this.props;

    const onChange = (event, ownProps) => {
      const { value: mode } = ownProps;
      const bidderMode = BIDDER_MODES[mode] || BIDDER_MODES.default;
      const endpoint = bidder[bidderMode.endpoint];
      const testMode = mode === 'test';
      const requestProcessing = pick(bidder, 'request_processing', {}).request_processing;
      requestProcessing.test_mode = testMode;
      this.setState({ mode });
      setBidder(id, { endpoint, mode, request_processing: requestProcessing });
    };

    const modes = map(omit(BIDDER_MODES, 'default'), (mode, key) => (
      <Form.Field key={`mode-${key}`}>
        <Checkbox
          checked={this.state.mode === key}
          label={mode.text}
          name="mode"
          onChange={onChange}
          radio
          value={key}
        />
      </Form.Field>
    ));

    return (
      <Form.Group inline>
        <label>Endpoint Mode</label>
        {modes}
      </Form.Group>
    );
  }
}

export default Mode;

Mode.defaultProps = {
  bidder: {},
  setBidder: () => {},
};

Mode.propTypes = {
  bidder: PropTypes.shape({}),
  setBidder: PropTypes.func,
};
