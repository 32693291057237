import map from 'lodash/map';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';

import { PRICE_TYPES } from '../constants';

class PriceType extends React.Component {
  constructor(props) {
    super(props);
    const { bidder: { price_type: priceType = PRICE_TYPES.default } } = props;
    this.state = { priceType };
  }

  componentWillReceiveProps(nextProps) {
    const { bidder: { price_type: priceType } } = this.props;
    const { bidder: { price_type: nextPriceType } } = nextProps;
    if (priceType !== nextPriceType) {
      this.setState({ priceType: nextPriceType });
    }
  }

  onChange = (event, ownProps) => {
    const { bidder: { id }, setBidder } = this.props;
    const { value: priceType } = ownProps;
    const nextPriceType = PRICE_TYPES[priceType] || PRICE_TYPES.default;
    this.setState({ priceType: nextPriceType });
    setBidder(id, { price_type: nextPriceType.value });
  };

  render() {
    const priceTypes = map(omit(PRICE_TYPES, 'default'), (priceType, key) => (
      <Form.Field key={`price-type--${key}`}>
        <Checkbox
          checked={this.state.priceType === key}
          label={priceType.text}
          name="price-type"
          onChange={this.onChange}
          radio
          value={key}
        />
      </Form.Field>
    ));

    return (
      <Form.Group inline>
        <label>Price Type</label>
        {priceTypes}
      </Form.Group>
    );
  }
}

export default PriceType;

PriceType.defaultProps = {
  bidder: {},
  setBidder: () => {},
};

PriceType.propTypes = {
  bidder: PropTypes.shape({}),
  setBidder: PropTypes.func,
};
