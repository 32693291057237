import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

const BidderId = (props) => {
  const { id } = props;
  return (
    <Form.Input
      label="Bidder Id"
      placeholder="Auto generated"
      value={id}
      readOnly
      transparent
    />
  );
};

export default BidderId;

BidderId.defaultProps = {
  id: '',
};

BidderId.propTypes = {
  id: PropTypes.string,
};
