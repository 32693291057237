import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';

const isDomain = dom => /(\w+\.)?\w+\.\w+/.test(dom);
const isIABCategory = cat => /IAB\d(-\d+)?/.test(cat);
const isBundle = bun => /(\w+\.)?\w+\.\w+/.test(bun);

const validator = (attrs = {}) => (
  pickBy(mapValues(attrs, (attr, key) => {
    switch (key) {
      case 'badv':
        if (!attr.every(isDomain)) { return 'Must be domains.'; }
        return null;

      case 'bapp':
        if (!attr.every(isBundle)) { return 'Must be app bundles.'; }
        return null;

      case 'bcat':
        if (!attr.every(isIABCategory)) { return 'Must be IAB categories.'; }
        return null;

      default:
        return null;
    }
  }))
);

export default validator;
