import React from 'react';
import { Form, Segment } from 'semantic-ui-react';

import { GlobalCreativeModes, GlobalPriceFormula, GlobalPriceValues } from '.';
import { SectionHeader } from '../../../../shared/components';

const GlobalEditForm = ({ errors, globals, setGlobals }) => (
  <Form>

    <SectionHeader content="Creative Types" icon="block layout" />
    <Segment>
      <GlobalCreativeModes globals={globals} setGlobals={setGlobals} />
    </Segment>

    <SectionHeader content="Pricing Formula" icon="money" />
    <Segment>
      <GlobalPriceFormula globals={globals} setGlobals={setGlobals} />
      <GlobalPriceValues errors={errors} globals={globals} setGlobals={setGlobals} />
    </Segment>
  </Form>
  );

export default GlobalEditForm;
