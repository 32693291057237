/**
 *  @namespace Discrepancy
 *
 *  this module contains the discrepancy tool for Integrations
 */

import Actions from './actions';
import Api from './api';
import Store from './store';
import Reducer from './reducer';
import Helpers from './helpers';

const Overview = {
  Actions,
  Api,
  Store,
  Reducer,
  Helpers
};

export default Overview;
