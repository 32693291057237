import keyBy from 'lodash/keyBy';
import map from 'lodash/map';

export const CHARTBOOST_COUNTRIES = {
  A1: { name: 'Anonymous Proxy' },
  A2: { name: 'Satellite Provider' },
  AA: { name: 'User defined code' },
  AD: { name: 'Andorra' },
  AE: { name: 'United Arab Emirates' },
  AF: { name: 'Afghanistan' },
  AG: { name: 'Antigua and Barbuda' },
  AI: { name: 'Anguilla' },
  AL: { name: 'Albania' },
  AM: { name: 'Armenia' },
  AN: { name: 'Netherlands Antilles' },
  AP: { name: 'Asia/Pacific Region' },
  AO: { name: 'Angola' },
  AR: { name: 'Argentina' },
  AS: { name: 'American Samoa' },
  AT: { name: 'Austria' },
  AU: { name: 'Australia' },
  AW: { name: 'Aruba' },
  AX: { name: 'Åland Islands' },
  AZ: { name: 'Azerbaijan' },
  BA: { name: 'Bosnia and Herzegovina' },
  BB: { name: 'Barbados' },
  BD: { name: 'Bangladesh' },
  BE: { name: 'Belgium' },
  BF: { name: 'Burkina Faso' },
  BG: { name: 'Bulgaria' },
  BH: { name: 'Bahrain' },
  BI: { name: 'Burundi' },
  BJ: { name: 'Benin' },
  BL: { name: 'Saint Barthélemy' },
  BM: { name: 'Bermuda' },
  BN: { name: 'Brunei Darussalam' },
  BO: { name: 'Bolivia' },
  BQ: { name: 'Caribbean Netherlands' },
  BR: { name: 'Brazil' },
  BS: { name: 'Bahamas' },
  BT: { name: 'Bhutan' },
  BW: { name: 'Botswana' },
  BY: { name: 'Belarus' },
  BZ: { name: 'Belize' },
  CA: { name: 'Canada' },
  CC: { name: 'Cocos (Keeling) Islands' },
  CD: { name: 'Congo, the Democratic Republic of the' },
  CF: { name: 'Central African Republic' },
  CG: { name: 'Congo' },
  CH: { name: 'Switzerland' },
  CI: { name: 'Cote D\'Ivoire' },
  CK: { name: 'Cook Islands' },
  CL: { name: 'Chile' },
  CM: { name: 'Cameroon' },
  CN: { name: 'China' },
  CO: { name: 'Colombia' },
  CR: { name: 'Costa Rica' },
  CU: { name: 'Cuba' },
  CV: { name: 'Cape Verde' },
  CW: { name: 'Curaçao' },
  CX: { name: 'Christmas Island' },
  CY: { name: 'Cyprus' },
  CZ: { name: 'Czech Republic' },
  DE: { name: 'Germany' },
  DJ: { name: 'Djibouti' },
  DK: { name: 'Denmark' },
  DM: { name: 'Dominica' },
  DO: { name: 'Dominican Republic' },
  DZ: { name: 'Algeria' },
  EC: { name: 'Ecuador' },
  EE: { name: 'Estonia' },
  EG: { name: 'Egypt' },
  EH: { name: 'Western Sahara' },
  ER: { name: 'Eritrea' },
  ES: { name: 'Spain' },
  ET: { name: 'Ethiopia' },
  EU: { name: 'European Union' },
  FI: { name: 'Finland' },
  FJ: { name: 'Fiji' },
  FK: { name: 'Falkland Islands (Malvinas)' },
  FM: { name: 'Micronesia, Federated States of' },
  FO: { name: 'Faroe Islands' },
  FR: { name: 'France' },
  GA: { name: 'Gabon' },
  GB: { name: 'United Kingdom' },
  GD: { name: 'Grenada' },
  GE: { name: 'Georgia' },
  GF: { name: 'French Guiana' },
  GG: { name: 'Guernsey' },
  GH: { name: 'Ghana' },
  GI: { name: 'Gibraltar' },
  GL: { name: 'Greenland' },
  GM: { name: 'Gambia' },
  GN: { name: 'Guinea' },
  GP: { name: 'Guadeloupe' },
  GQ: { name: 'Equatorial Guinea' },
  GR: { name: 'Greece' },
  GT: { name: 'Guatemala' },
  GU: { name: 'Guam' },
  GW: { name: 'Guinea-Bissau' },
  GY: { name: 'Guyana' },
  HK: { name: 'Hong Kong' },
  HN: { name: 'Honduras' },
  HR: { name: 'Croatia' },
  HT: { name: 'Haiti' },
  HU: { name: 'Hungary' },
  ID: { name: 'Indonesia' },
  IE: { name: 'Ireland' },
  IL: { name: 'Israel' },
  IM: { name: 'Isle of Man' },
  IN: { name: 'India' },
  IO: { name: 'British Indian Ocean Territory' },
  IQ: { name: 'Iraq' },
  IR: { name: 'Iran, Islamic Republic of' },
  IS: { name: 'Iceland' },
  IT: { name: 'Italy' },
  JE: { name: 'Jersey' },
  JM: { name: 'Jamaica' },
  JO: { name: 'Jordan' },
  JP: { name: 'Japan' },
  KE: { name: 'Kenya' },
  KG: { name: 'Kyrgyzstan' },
  KH: { name: 'Cambodia' },
  KI: { name: 'Kiribati' },
  KM: { name: 'Comoros' },
  KN: { name: 'Saint Kitts and Nevis' },
  KP: { name: 'Korea, Democratic People\'s Republic of' },
  KR: { name: 'Korea, Republic of' },
  KW: { name: 'Kuwait' },
  KY: { name: 'Cayman Islands' },
  KZ: { name: 'Kazakhstan' },
  LA: { name: 'Lao People\'s Democratic Republic' },
  LB: { name: 'Lebanon' },
  LC: { name: 'Saint Lucia' },
  LI: { name: 'Liechtenstein' },
  LK: { name: 'Sri Lanka' },
  LR: { name: 'Liberia' },
  LS: { name: 'Lesotho' },
  LT: { name: 'Lithuania' },
  LU: { name: 'Luxembourg' },
  LV: { name: 'Latvia' },
  LY: { name: 'Libyan Arab Jamahiriya' },
  MA: { name: 'Morocco' },
  MC: { name: 'Monaco' },
  MD: { name: 'Moldova, Republic of' },
  ME: { name: 'Montenegro' },
  MF: { name: 'Saint Martin (French part)' },
  MG: { name: 'Madagascar' },
  MH: { name: 'Marshall Islands' },
  MK: { name: 'Macedonia, the Former Yugoslav Republic of' },
  ML: { name: 'Mali' },
  MM: { name: 'Myanmar' },
  MN: { name: 'Mongolia' },
  MO: { name: 'Macao' },
  MP: { name: 'Northern Mariana Islands' },
  MQ: { name: 'Martinique' },
  MR: { name: 'Mauritania' },
  MS: { name: 'Montserrat' },
  MT: { name: 'Malta' },
  MU: { name: 'Mauritius' },
  MV: { name: 'Maldives' },
  MW: { name: 'Malawi' },
  MX: { name: 'Mexico' },
  MY: { name: 'Malaysia' },
  MZ: { name: 'Mozambique' },
  NA: { name: 'Namibia' },
  NC: { name: 'New Caledonia' },
  NE: { name: 'Niger' },
  NF: { name: 'Norfolk Island' },
  NG: { name: 'Nigeria' },
  NI: { name: 'Nicaragua' },
  NL: { name: 'Netherlands' },
  NO: { name: 'Norway' },
  NP: { name: 'Nepal' },
  NR: { name: 'Nauru' },
  NU: { name: 'Niue' },
  NZ: { name: 'New Zealand' },
  OM: { name: 'Oman' },
  PA: { name: 'Panama' },
  PE: { name: 'Peru' },
  PF: { name: 'French Polynesia' },
  PG: { name: 'Papua New Guinea' },
  PH: { name: 'Philippines' },
  PK: { name: 'Pakistan' },
  PL: { name: 'Poland' },
  PM: { name: 'Saint Pierre and Miquelon' },
  PN: { name: 'Pitcairn' },
  PR: { name: 'Puerto Rico' },
  PS: { name: 'Palestinian Territory, Occupied' },
  PT: { name: 'Portugal' },
  PW: { name: 'Palau' },
  PY: { name: 'Paraguay' },
  QA: { name: 'Qatar' },
  QM: { name: 'User defined code' },
  QN: { name: 'User defined code' },
  QO: { name: 'User defined code' },
  QP: { name: 'User defined code' },
  QQ: { name: 'User defined code' },
  QR: { name: 'User defined code' },
  QS: { name: 'User defined code' },
  QT: { name: 'User defined code' },
  QU: { name: 'User defined code' },
  QV: { name: 'User defined code' },
  QW: { name: 'User defined code' },
  QX: { name: 'User defined code' },
  QY: { name: 'User defined code' },
  QZ: { name: 'User defined code' },
  RE: { name: 'Reunion' },
  RO: { name: 'Romania' },
  RS: { name: 'Serbia' },
  RU: { name: 'Russian Federation' },
  RW: { name: 'Rwanda' },
  SA: { name: 'Saudi Arabia' },
  SB: { name: 'Solomon Islands' },
  SC: { name: 'Seychelles' },
  SD: { name: 'Sudan' },
  SE: { name: 'Sweden' },
  SG: { name: 'Singapore' },
  SH: { name: 'Saint Helena' },
  SI: { name: 'Slovenia' },
  SJ: { name: 'Svalbard and Jan Mayen' },
  SK: { name: 'Slovakia' },
  SL: { name: 'Sierra Leone' },
  SM: { name: 'San Marino' },
  SN: { name: 'Senegal' },
  SO: { name: 'Somalia' },
  SR: { name: 'Suriname' },
  SS: { name: 'South Sudan' },
  ST: { name: 'Sao Tome and Principe' },
  SV: { name: 'El Salvador' },
  SX: { name: 'Sint Maarten' },
  SY: { name: 'Syrian Arab Republic' },
  SZ: { name: 'Swaziland' },
  TC: { name: 'Turks and Caicos Islands' },
  TD: { name: 'Chad' },
  TG: { name: 'Togo' },
  TH: { name: 'Thailand' },
  TJ: { name: 'Tajikistan' },
  TK: { name: 'Tokelau' },
  TL: { name: 'Timor-Leste' },
  TM: { name: 'Turkmenistan' },
  TN: { name: 'Tunisia' },
  TO: { name: 'Tonga' },
  TR: { name: 'Turkey' },
  TT: { name: 'Trinidad and Tobago' },
  TV: { name: 'Tuvalu' },
  TW: { name: 'Taiwan' },
  TZ: { name: 'Tanzania, United Republic of' },
  UA: { name: 'Ukraine' },
  UG: { name: 'Uganda' },
  UM: { name: 'United States Minor Outlying Islands' },
  US: { name: 'United States' },
  UY: { name: 'Uruguay' },
  UZ: { name: 'Uzbekistan' },
  VA: { name: 'Holy See (Vatican City State)' },
  VC: { name: 'Saint Vincent and the Grenadines' },
  VE: { name: 'Venezuela' },
  VG: { name: 'Virgin Islands, British' },
  VI: { name: 'Virgin Islands, USA' },
  VN: { name: 'Vietnam' },
  VU: { name: 'Vanuatu' },
  WF: { name: 'Wallis and Futuna' },
  WS: { name: 'Samoa' },
  YE: { name: 'Yemen' },
  YT: { name: 'Mayotte' },
  XA: { name: 'User defined code' },
  XB: { name: 'User defined code' },
  XC: { name: 'User defined code' },
  XD: { name: 'User defined code' },
  XE: { name: 'User defined code' },
  XF: { name: 'User defined code' },
  XG: { name: 'User defined code' },
  XH: { name: 'User defined code' },
  XI: { name: 'User defined code' },
  XJ: { name: 'User defined code' },
  XK: { name: 'Kosovo' },
  XL: { name: 'User defined code' },
  XM: { name: 'User defined code' },
  XN: { name: 'User defined code' },
  XO: { name: 'User defined code' },
  XP: { name: 'User defined code' },
  XQ: { name: 'User defined code' },
  XR: { name: 'User defined code' },
  XS: { name: 'User defined code' },
  XT: { name: 'User defined code' },
  XU: { name: 'User defined code' },
  XV: { name: 'User defined code' },
  XW: { name: 'User defined code' },
  XX: { name: 'User defined code' },
  XY: { name: 'User defined code' },
  XZ: { name: 'User defined code' },
  ZA: { name: 'South Africa' },
  ZM: { name: 'Zambia' },
  ZW: { name: 'Zimbabwe' },
  ZZ: { name: 'User defined code' },
};

const SEMANTIC_FLAGS = [
  { name: 'Afghanistan', countryCode: 'af' },
  { name: 'Aland Islands', countryCode: 'ax' },
  { name: 'Albania', countryCode: 'al' },
  { name: 'Algeria', countryCode: 'dz' },
  { name: 'American Samoa', countryCode: 'as' },
  { name: 'Andorra', countryCode: 'ad' },
  { name: 'Angola', countryCode: 'ao' },
  { name: 'Anguilla', countryCode: 'ai' },
  { name: 'Antigua', countryCode: 'ag' },
  { name: 'Argentina', countryCode: 'ar' },
  { name: 'Armenia', countryCode: 'am' },
  { name: 'Aruba', countryCode: 'aw' },
  { name: 'Australia', countryCode: 'au' },
  { name: 'Austria', countryCode: 'at' },
  { name: 'Azerbaijan', countryCode: 'az' },
  { name: 'Bahamas', countryCode: 'bs' },
  { name: 'Bahrain', countryCode: 'bh' },
  { name: 'Bangladesh', countryCode: 'bd' },
  { name: 'Barbados', countryCode: 'bb' },
  { name: 'Belarus', countryCode: 'by' },
  { name: 'Belgium', countryCode: 'be' },
  { name: 'Belize', countryCode: 'bz' },
  { name: 'Benin', countryCode: 'bj' },
  { name: 'Bermuda', countryCode: 'bm' },
  { name: 'Bhutan', countryCode: 'bt' },
  { name: 'Bolivia', countryCode: 'bo' },
  { name: 'Bosnia', countryCode: 'ba' },
  { name: 'Botswana', countryCode: 'bw' },
  { name: 'Bouvet Island', countryCode: 'bv' },
  { name: 'Brazil', countryCode: 'br' },
  { name: 'British Virgin Islands', countryCode: 'vg' },
  { name: 'Brunei', countryCode: 'bn' },
  { name: 'Bulgaria', countryCode: 'bg' },
  { name: 'Burkina Faso', countryCode: 'bf' },
  { name: 'Burma', countryCode: 'mm', alias: 'Myanmar' },
  { name: 'Burundi', countryCode: 'bi' },
  { name: 'Caicos Islands', countryCode: 'tc' },
  { name: 'Cambodia', countryCode: 'kh' },
  { name: 'Cameroon', countryCode: 'cm' },
  { name: 'Canada', countryCode: 'ca' },
  { name: 'Cape Verde', countryCode: 'cv' },
  { name: 'Cayman Islands', countryCode: 'ky' },
  { name: 'Central African Republic', countryCode: 'cf' },
  { name: 'Chad', countryCode: 'td' },
  { name: 'Chile', countryCode: 'cl' },
  { name: 'China', countryCode: 'cn' },
  { name: 'Christmas Island', countryCode: 'cx' },
  { name: 'Cocos Islands', countryCode: 'cc' },
  { name: 'Colombia', countryCode: 'co' },
  { name: 'Comoros', countryCode: 'km' },
  { name: 'Congo', countryCode: 'cd' },
  { name: 'Congo Brazzaville', countryCode: 'cg' },
  { name: 'Cook Islands', countryCode: 'ck' },
  { name: 'Costa Rica', countryCode: 'cr' },
  { name: 'Cote Divoire', countryCode: 'ci' },
  { name: 'Croatia', countryCode: 'hr' },
  { name: 'Cuba', countryCode: 'cu' },
  { name: 'Cyprus', countryCode: 'cy' },
  { name: 'Czech Republic', countryCode: 'cz' },
  { name: 'Denmark', countryCode: 'dk' },
  { name: 'Djibouti', countryCode: 'dj' },
  { name: 'Dominica', countryCode: 'dm' },
  { name: 'Dominican Republic', countryCode: 'do' },
  { name: 'Ecuador', countryCode: 'ec' },
  { name: 'Egypt', countryCode: 'eg' },
  { name: 'El Salvador', countryCode: 'sv' },
  { name: 'Equatorial Guinea', countryCode: 'gq' },
  { name: 'Eritrea', countryCode: 'er' },
  { name: 'Estonia', countryCode: 'ee' },
  { name: 'Ethiopia', countryCode: 'et' },
  { name: 'Europeanunion', countryCode: 'eu' },
  { name: 'Falkland Islands', countryCode: 'fk' },
  { name: 'Faroe Islands', countryCode: 'fo' },
  { name: 'Fiji', countryCode: 'fj' },
  { name: 'Finland', countryCode: 'fi' },
  { name: 'France', countryCode: 'fr' },
  { name: 'French Guiana', countryCode: 'gf' },
  { name: 'French Polynesia', countryCode: 'pf' },
  { name: 'French Territories', countryCode: 'tf' },
  { name: 'Gabon', countryCode: 'ga' },
  { name: 'Gambia', countryCode: 'gm' },
  { name: 'Georgia', countryCode: 'ge' },
  { name: 'Germany', countryCode: 'de' },
  { name: 'Ghana', countryCode: 'gh' },
  { name: 'Gibraltar', countryCode: 'gi' },
  { name: 'Greece', countryCode: 'gr' },
  { name: 'Greenland', countryCode: 'gl' },
  { name: 'Grenada', countryCode: 'gd' },
  { name: 'Guadeloupe', countryCode: 'gp' },
  { name: 'Guam', countryCode: 'gu' },
  { name: 'Guatemala', countryCode: 'gt' },
  { name: 'Guinea', countryCode: 'gn' },
  { name: 'Guinea-Bissau', countryCode: 'gw' },
  { name: 'Guyana', countryCode: 'gy' },
  { name: 'Haiti', countryCode: 'ht' },
  { name: 'Heard Island', countryCode: 'hm' },
  { name: 'Honduras', countryCode: 'hn' },
  { name: 'Hong Kong', countryCode: 'hk' },
  { name: 'Hungary', countryCode: 'hu' },
  { name: 'Iceland', countryCode: 'is' },
  { name: 'India', countryCode: 'in' },
  { name: 'Indian Ocean Territory', countryCode: 'io' },
  { name: 'Indonesia', countryCode: 'id' },
  { name: 'Iran', countryCode: 'ir' },
  { name: 'Iraq', countryCode: 'iq' },
  { name: 'Ireland', countryCode: 'ie' },
  { name: 'Israel', countryCode: 'il' },
  { name: 'Italy', countryCode: 'it' },
  { name: 'Jamaica', countryCode: 'jm' },
  { name: 'Jan Mayen', countryCode: 'sj', alias: 'Svalbard' },
  { name: 'Japan', countryCode: 'jp' },
  { name: 'Jordan', countryCode: 'jo' },
  { name: 'Kazakhstan', countryCode: 'kz' },
  { name: 'Kenya', countryCode: 'ke' },
  { name: 'Kiribati', countryCode: 'ki' },
  { name: 'Kuwait', countryCode: 'kw' },
  { name: 'Kyrgyzstan', countryCode: 'kg' },
  { name: 'Laos', countryCode: 'la' },
  { name: 'Latvia', countryCode: 'lv' },
  { name: 'Lebanon', countryCode: 'lb' },
  { name: 'Lesotho', countryCode: 'ls' },
  { name: 'Liberia', countryCode: 'lr' },
  { name: 'Libya', countryCode: 'ly' },
  { name: 'Liechtenstein', countryCode: 'li' },
  { name: 'Lithuania', countryCode: 'lt' },
  { name: 'Luxembourg', countryCode: 'lu' },
  { name: 'Macau', countryCode: 'mo' },
  { name: 'Macedonia', countryCode: 'mk' },
  { name: 'Madagascar', countryCode: 'mg' },
  { name: 'Malawi', countryCode: 'mw' },
  { name: 'Malaysia', countryCode: 'my' },
  { name: 'Maldives', countryCode: 'mv' },
  { name: 'Mali', countryCode: 'ml' },
  { name: 'Malta', countryCode: 'mt' },
  { name: 'Marshall Islands', countryCode: 'mh' },
  { name: 'Martinique', countryCode: 'mq' },
  { name: 'Mauritania', countryCode: 'mr' },
  { name: 'Mauritius', countryCode: 'mu' },
  { name: 'Mayotte', countryCode: 'yt' },
  { name: 'Mexico', countryCode: 'mx' },
  { name: 'Micronesia', countryCode: 'fm' },
  { name: 'Moldova', countryCode: 'md' },
  { name: 'Monaco', countryCode: 'mc' },
  { name: 'Mongolia', countryCode: 'mn' },
  { name: 'Montenegro', countryCode: 'me' },
  { name: 'Montserrat', countryCode: 'ms' },
  { name: 'Morocco', countryCode: 'ma' },
  { name: 'Mozambique', countryCode: 'mz' },
  { name: 'Namibia', countryCode: 'na' },
  { name: 'Nauru', countryCode: 'nr' },
  { name: 'Nepal', countryCode: 'np' },
  { name: 'Netherlands', countryCode: 'nl' },
  { name: 'Netherlandsantilles', countryCode: 'an' },
  { name: 'New Caledonia', countryCode: 'nc' },
  { name: 'New Guinea', countryCode: 'pg' },
  { name: 'New Zealand', countryCode: 'nz' },
  { name: 'Nicaragua', countryCode: 'ni' },
  { name: 'Niger', countryCode: 'ne' },
  { name: 'Nigeria', countryCode: 'ng' },
  { name: 'Niue', countryCode: 'nu' },
  { name: 'Norfolk Island', countryCode: 'nf' },
  { name: 'North Korea', countryCode: 'kp' },
  { name: 'Northern Mariana Islands', countryCode: 'mp' },
  { name: 'Norway', countryCode: 'no' },
  { name: 'Oman', countryCode: 'om' },
  { name: 'Pakistan', countryCode: 'pk' },
  { name: 'Palau', countryCode: 'pw' },
  { name: 'Palestine', countryCode: 'ps' },
  { name: 'Panama', countryCode: 'pa' },
  { name: 'Paraguay', countryCode: 'py' },
  { name: 'Peru', countryCode: 'pe' },
  { name: 'Philippines', countryCode: 'ph' },
  { name: 'Pitcairn Islands', countryCode: 'pn' },
  { name: 'Poland', countryCode: 'pl' },
  { name: 'Portugal', countryCode: 'pt' },
  { name: 'Puerto Rico', countryCode: 'pr' },
  { name: 'Qatar', countryCode: 'qa' },
  { name: 'Reunion', countryCode: 're' },
  { name: 'Romania', countryCode: 'ro' },
  { name: 'Russia', countryCode: 'ru' },
  { name: 'Rwanda', countryCode: 'rw' },
  { name: 'Saint Helena', countryCode: 'sh' },
  { name: 'Saint Kitts and Nevis', countryCode: 'kn' },
  { name: 'Saint Lucia', countryCode: 'lc' },
  { name: 'Saint Pierre', countryCode: 'pm' },
  { name: 'Saint Vincent', countryCode: 'vc' },
  { name: 'Samoa', countryCode: 'ws' },
  { name: 'San Marino', countryCode: 'sm' },
  { name: 'Sandwich Islands', countryCode: 'gs' },
  { name: 'Sao Tome', countryCode: 'st' },
  { name: 'Saudi Arabia', countryCode: 'sa' },
  { name: 'Scotland', countryCode: 'gb sct' },
  { name: 'Senegal', countryCode: 'sn' },
  { name: 'Serbia', countryCode: 'cs' },
  { name: 'Serbia', countryCode: 'rs' },
  { name: 'Seychelles', countryCode: 'sc' },
  { name: 'Sierra Leone', countryCode: 'sl' },
  { name: 'Singapore', countryCode: 'sg' },
  { name: 'Slovakia', countryCode: 'sk' },
  { name: 'Slovenia', countryCode: 'si' },
  { name: 'Solomon Islands', countryCode: 'sb' },
  { name: 'Somalia', countryCode: 'so' },
  { name: 'South Africa', countryCode: 'za' },
  { name: 'South Korea', countryCode: 'kr' },
  { name: 'Spain', countryCode: 'es' },
  { name: 'Sri Lanka', countryCode: 'lk' },
  { name: 'Sudan', countryCode: 'sd' },
  { name: 'Suriname', countryCode: 'sr' },
  { name: 'Swaziland', countryCode: 'sz' },
  { name: 'Sweden', countryCode: 'se' },
  { name: 'Switzerland', countryCode: 'ch' },
  { name: 'Syria', countryCode: 'sy' },
  { name: 'Taiwan', countryCode: 'tw' },
  { name: 'Tajikistan', countryCode: 'tj' },
  { name: 'Tanzania', countryCode: 'tz' },
  { name: 'Thailand', countryCode: 'th' },
  { name: 'Timorleste', countryCode: 'tl' },
  { name: 'Togo', countryCode: 'tg' },
  { name: 'Tokelau', countryCode: 'tk' },
  { name: 'Tonga', countryCode: 'to' },
  { name: 'Trinidad', countryCode: 'tt' },
  { name: 'Tunisia', countryCode: 'tn' },
  { name: 'Turkey', countryCode: 'tr' },
  { name: 'Turkmenistan', countryCode: 'tm' },
  { name: 'Tuvalu', countryCode: 'tv' },
  { name: 'U.A.E.', countryCode: 'ae', alias: 'United Arab Emirates' },
  { name: 'Uganda', countryCode: 'ug' },
  { name: 'Ukraine', countryCode: 'ua' },
  { name: 'United Kingdom', countryCode: 'gb' },
  { name: 'United States', countryCode: 'us', alias: 'America' },
  { name: 'Uruguay', countryCode: 'uy' },
  { name: 'US Minor Islands', countryCode: 'um' },
  { name: 'US Virgin Islands', countryCode: 'vi' },
  { name: 'Uzbekistan', countryCode: 'uz' },
  { name: 'Vanuatu', countryCode: 'vu' },
  { name: 'Vatican City', countryCode: 'va' },
  { name: 'Venezuela', countryCode: 've' },
  { name: 'Vietnam', countryCode: 'vn' },
  { name: 'Wales', countryCode: 'gb wls' },
  { name: 'Wallis and Futuna', countryCode: 'wf' },
  { name: 'Western Sahara', countryCode: 'eh' },
  { name: 'Yemen', countryCode: 'ye' },
  { name: 'Zambia', countryCode: 'zm' },
  { name: 'Zimbabwe', countryCode: 'zw' },
];

export const SEMANTIC_FLAGS_BY_ID = keyBy(SEMANTIC_FLAGS, 'countryCode');

export const COUNTRY_FLAG_OPTIONS = map(CHARTBOOST_COUNTRIES, (country, code) => {
  const semanticCode = code.toLowerCase();
  const flag = SEMANTIC_FLAGS_BY_ID[semanticCode] ? { flag: semanticCode } : {};
  return {
    key: code,
    text: `[${code}] ${country.name}`,
    value: code,
    ...flag,
  };
});

export const EMEA_LIST = [
  'AF', 'AX', 'AL', 'DZ', 'AD', 'AO', 'AM', 'AT', 'AZ', 'BH', 'BY', 'BE',
  'BJ', 'BA', 'BW', 'BV', 'BG', 'BF', 'BI', 'CM', 'CV', 'CF', 'TD', 'KM', 'CD', 'CG', 'CI', 'HR',
  'CY', 'CZ', 'DK', 'DJ', 'EG', 'GQ', 'ER', 'EE', 'ET', 'FK', 'FO', 'FI', 'FR', 'GA', 'GM', 'GE',
  'DE', 'GH', 'GI', 'GR', 'GL', 'GG', 'GN', 'GW', 'VA', 'HU', 'IS', 'IR', 'IQ', 'IE', 'IM', 'IL',
  'IT', 'JE', 'JO', 'KZ', 'KE', 'KV', 'KW', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU',
  'MK', 'MG', 'MW', 'ML', 'MT', 'MR', 'MU', 'YT', 'MD', 'MC', 'ME', 'MA', 'MZ', 'NA', 'NL', 'NE',
  'NG', 'NO', 'OM', 'PS', 'PL', 'PT', 'QA', 'RE', 'RO', 'RU', 'RW', 'SH', 'SM', 'ST', 'SA', 'SN',
  'RS', 'SC', 'SL', 'SK', 'SI', 'SO', 'ZA', 'GS', 'ES', 'SD', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TZ',
  'TG', 'TN', 'TR', 'UG', 'UA', 'AE', 'GB', 'EH', 'YE', 'ZM', 'ZW',
];

export const APAC_LIST = [
  'AS', 'AQ', 'AU', 'BD', 'BT', 'IO', 'BN', 'KH', 'CN', 'CX', 'CC', 'CK', 'FJ', 'PF', 'TF', 'GU',
  'HM', 'HK', 'IN', 'ID', 'JP', 'KI', 'KR', 'KG', 'KP', 'LA', 'MO', 'MY', 'MV', 'MH', 'FM', 'MN', 'MM', 'NR',
  'NP', 'NC', 'NZ', 'NU', 'NF', 'MP', 'PK', 'PW', 'PG', 'PH', 'PN', 'WS', 'SG', 'SB', 'SS', 'LK',
  'TW', 'TJ', 'TH', 'TL', 'TK', 'TO', 'TM', 'TV', 'UM', 'UZ', 'VU', 'VN', 'WF',
];

export const AMER_LIST = [
  'AI', 'AG', 'AR', 'AW', 'BS', 'BB', 'BZ', 'BM', 'BO', 'BQ', 'BR', 'CA', 'KY', 'CL', 'CO', 'CR',
  'CU', 'CW', 'DM', 'DO', 'EC', 'SV', 'GF', 'GD', 'GP', 'GT', 'GY', 'HT', 'HN', 'JM', 'MQ', 'MX',
  'MS', 'NI', 'PA', 'PY', 'PE', 'PR', 'BL', 'KN', 'LC', 'MF', 'PM', 'VC', 'SX', 'SR', 'TT', 'TC',
  'US', 'UY', 'VE', 'VG', 'VI',
];

export const COUNTRY_LISTS = {
  amer: {
    countries: AMER_LIST,
    description: 'The Americas',
    name: 'AMER',
  },
  apac: {
    countries: APAC_LIST,
    description: 'Asia Pacific',
    name: 'APAC',
  },
  emea: {
    countries: EMEA_LIST,
    description: 'Europe, the Middle East, and Africa',
    name: 'EMEA',
  },
};

export const COUNTRY_LIST_OPTIONS = map(COUNTRY_LISTS, (list, id) => ({
  text: list.name,
  key: id,
  value: id,
}));

export const DEPRECRATED_COUNTRIES = ['A1', 'A2', 'AP', 'EU'];
