import PropTypes from 'prop-types';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button } from 'semantic-ui-react';

import CurrentModuleIcon from './CurrentModuleIcon';

import logo from '../media/logo.svg';
import './Header.css';
import { removeTokenFromAuth0 } from '../../store/localStorage';

/**
 * Get a bundle path based on the current location.
 * If no location is provided, returns the root path '/'.
 *
 * @param {object} location - Location object provided by react-router.
 * @returns {string} The bundle path extracted from the URL path or '/' if no location is provided.
 */
const getBundlePath = location => {
  if (!location) return '/';
  const pathParts = location.pathname.split('/');
  return `/${pathParts[1]}`;
};

const HeaderButton = ({ children = null, ...props }) => (
  <Button {...props} size="tiny" basic inverted>
    {children}
  </Button>
);

const Header = ({ toggleNavbar }) => {
  const location = useLocation();
  const bundlePath = getBundlePath(location);
  const { logout: auth0Logout } = useAuth0();

  const onClickLogout = async () => {
    try {
      await auth0Logout({ logoutParams: { returnTo: window.location.origin } });
      removeTokenFromAuth0();
    } catch (error) {
      toast.error('auth0 logout error');
    }
  };

  return (
    <div className="Header">
      <div className="Header-left">
        <HeaderButton aria-label="Menu" icon="bars" onClick={toggleNavbar} />
        <CurrentModuleIcon bundlePath={bundlePath} />
      </div>
      <Link to="/">
        <img src={logo} className="Header-logo" alt="logo" />
      </Link>
      <div className="Header-right">
        <HeaderButton aria-label="Log Out" icon="log out" onClick={onClickLogout} />
      </div>
    </div>
  );
};

Header.defaultProps = { toggleNavbar: () => {} };

Header.propTypes = { toggleNavbar: PropTypes.func };

export default Header;
