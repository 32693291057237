import debounce from 'lodash/debounce';
import getter from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import setter from 'lodash/set';
import toPath from 'lodash/toPath';
import { Form, Input, Label } from 'semantic-ui-react';

import { decimalToInt } from '../../shared/helpers';

class PercentageInput extends React.Component {
  constructor(props) {
    super(props);
    const { bidder, path } = props;
    const percentage = getter(bidder, path, 0);
    this.state = { percentage };
  }

  componentWillReceiveProps(nextProps) {
    const { bidder, path } = this.props;
    const { bidder: nextBidder } = nextProps;

    const percentage = getter(bidder, path, 0);
    const nextPercentage = getter(nextBidder, path, 0);

    if (percentage !== nextPercentage) {
      this.setState({ percentage: nextPercentage });
    }
  }

  debouncedSetBidder = debounce(this.props.setBidder, 500);

  render() {
    const {
      bidder,
      errors,
      label,
      path,
    } = this.props;

    const onChange = (event, ownProps) => {
      const { value: percentage } = ownProps;
      const { target: { validationMessage = '' } = {} } = event;

      const percentageFloat = parseFloat(percentage) / 100.0 || 0.0;
      const nextProps = setter(pick(bidder, head(toPath(path))), path, percentageFloat);
      const percentageErrors = isEmpty(validationMessage)
        ? {}
        : setter({}, path, validationMessage);
      this.setState({ percentage: percentageFloat });
      this.debouncedSetBidder(bidder.id, nextProps, percentageErrors);
    };

    const percentageError = getter(errors, path, '');

    return (
      <Form.Field>
        <label htmlFor="percentage">{label}</label>
        <Input
          autoComplete="on"
          className="number"
          error={!isEmpty(percentageError)}
          inputMode="numeric"
          label={{ content: '%' }}
          labelPosition="right"
          max={100}
          min={0}
          onChange={onChange}
          placeholder="0"
          type="number"
          value={decimalToInt(this.state.percentage)}
        />
        {percentageError ? <Label basic color="red" pointing>{percentageError}</Label> : null}
      </Form.Field>
    );
  }
}

export default PercentageInput;

PercentageInput.defaultProps = {
  bidder: {},
  errors: {},
  label: '',
  path: '',
  setBidder: () => {},
};

PercentageInput.propTypes = {
  bidder: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  label: PropTypes.string,
  path: PropTypes.string,
  setBidder: PropTypes.func,
};
