import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

class PublishingAppSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { query: '' };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleInputChange(e) {
    this.setState({ query: e.target.value });
  }

  handleFormSubmit() {
    this.props.searchApps(this.state.query, this.props.status);
  }

  render() {
    return (
      <Form onSubmit={this.handleFormSubmit}>
        <Form.Input
          className="prompt"
          type="text"
          icon="search"
          placeholder="Search..."
          value={this.state.query}
          onChange={this.handleInputChange}
        />
      </Form>
    );
  }
}

PublishingAppSearch.defaultProps = {};

PublishingAppSearch.propTypes = {
  searchApps: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default PublishingAppSearch;
