import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { toast } from 'react-toastify';

import { Button, Input } from 'semantic-ui-react';

import './InlineEdit.css';

class StatefulInlineEdit extends Component {
  constructor() {
    super();
    this.state = { active: false };
    this.activate = this.activate.bind(this);
    this.cancel = this.cancel.bind(this);
    this.confirm = this.confirm.bind(this);
    this.handleRef = this.handleRef.bind(this);
  }

  activate() {
    this.setState({ active: true });
  }

  cancel() {
    this.setState({ active: false });
  }

  isValid() {
    if (!this.props.validate) return true;

    const value = this.ref.inputRef.value;
    const isValid = this.props.validate(value);
    if (!isValid) {
      toast.error(`Invalid value for ${this.props.name}`);
    }

    return isValid;
  }

  confirm() {
    if (!this.isValid()) return;
    this.props.edit(null, this.ref.inputRef);
    this.setState({ active: false });
  }

  handleRef(c) {
    this.ref = c;
  }

  render() {
    const { cancelable, editable, name, type, value } = this.props;

    return this.state.active ? (
      <Input action fluid defaultValue={value} name={name} ref={this.handleRef}>
        <input type={type} />
        <Button icon="check" onClick={this.confirm} />
        {cancelable && <Button icon="cancel" onClick={this.cancel} />}
      </Input>
    ) : (
      <div>
        <div className="InlineEdit__value">{value}</div>
        {editable && <Button basic floated="right" icon="compose" onClick={this.activate} />}
      </div>
    );
  }
}

StatefulInlineEdit.defaultProps = {
  cancelable: false,
  edit: () => {},
  editable: true,
  name: '',
  type: 'text',
  value: null,
  validate: () => true
};

StatefulInlineEdit.propTypes = {
  cancelable: PropTypes.bool,
  edit: PropTypes.func,
  editable: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.number, PropTypes.string]),
  validate: PropTypes.func
};

export default StatefulInlineEdit;
