export const ERROR_CODES = {
  bidder_internal_error: 'It\'s not you. It\'s us. Something went wrong on our end.',
  bidder_invalid_dollar: 'Invalid dollar amount',
  bidder_invalid_email: 'Invalid email',
  bidder_invalid_formula: 'Invalid formula.',
  bidder_invalid_mode: 'Invalid mode.',
  bidder_invalid_name: 'Invalid name.',
  bidder_invalid_object_id: 'Invalid object id.',
  bidder_invalid_percentage: 'Invalid percentage.',
  bidder_invalid_protocol: 'That\'s a strange new Internet you\'re using.',
  bidder_invalid_request: 'Problem with the request.',
  bidder_invalid_banner_resolution: 'Invalid banner size resolution',
  bidder_invalid_throttling: 'No way we can throttle at that rate.',
  bidder_missing_name: 'Name is required.',
  bidder_name_too_long: 'Name is too long.',
  bidder_name_too_short: 'Name is too short',
  bidder_not_exist: 'Bidder does not exist',
  bidder_not_supported_connection_type: 'Connection type not supported.',
  bidder_update_not_acknowledged: 'Failed to update.',
  bidders_not_found: 'Having trouble finding the bidders.',
  exchange_global_settings_not_exist: 'Global settings do not exist',
  exchange_global_settings_invalid_mraid_mode: 'Invalid mraid mode',
  exchange_global_settings_invalid_static_mode: 'Invalid static mode',
  exchange_global_settings_invalid_video_mode: 'Invalid video mode',
  exchange_global_settings_invalid_formula: 'Invalid formula',
  exchange_global_settings_invalid_dollars: 'Cents has to be greater or equal to zero',
  exchange_global_settings_invalid_percent: 'Percentage has to be between 0 and 100',
  exchange_global_settings_update_failed: 'Update global settings failed',
  internal_error: 'It\'s not you, it\'s me',
  invalid_exchange_settings: 'Invalid Blocklist format',
  no_permission: 'Forbidden. Are you logged in as an Admin on Dashboard?',
  request_invalid_schema: 'The payload format is incorrect',
  validation_error: 'Not so fast cowboy. Check your data and try again.',
  validation_globals_error: 'Check your data and try again',
  unknown_error: 'Unknown error',
  '`mode` must be a valid integer.': 'Mode cannot be empty',
};

export default ERROR_CODES;
