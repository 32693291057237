import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Input, Label, Segment } from 'semantic-ui-react';

class BannerResize extends React.Component {
  constructor(props) {
    super(props);
    const { size } = this.props;
    this.state = { size };
  }

  componentWillReceiveProps(nextProps) {
    const { size } = this.props;
    const { size: nextSize } = nextProps;

    if (!isEqual(size, nextSize)) {
      this.setState({ size: nextSize });
    }
  }

  onChange = axis => (event, ownProps) => {
    const { bannerKey, setBannerSize } = this.props;
    const { target: { validationMessage = '' } = {} } = event;
    const { value } = ownProps;
    const sizeErrors = ['', ''];
    sizeErrors[axis] = validationMessage;
    const validationErrors = validationMessage
      ? {
        request_processing: {
          change_banner_size: { [bannerKey]: sizeErrors },
        },
      }
      : {};
    setBannerSize(bannerKey, axis, value, validationErrors);
  };

  /**
   * Convenience function to pull errors out of the errors prop
   * @param {number} axis - Index of `0` (x-axis) or `1` (y-axis)
   * @returns {String} Error message, if any. Otherwise, empty string.
   */
  getError = (axis) => {
    const { bannerKey, errors } = this.props;
    const {
      request_processing: {
        change_banner_size: changeBannerSize = {},
      } = {},
    } = errors;

    return (changeBannerSize[bannerKey] || ['', ''])[axis] || '';
  };

  render() {
    const WIDTH = 0;
    const HEIGHT = 1;
    const { bannerKey, linked } = this.props;
    const { size: [width, height] } = this.state;
    const [widthKey, heightKey] = bannerKey.split('x');

    const widthError = this.getError(WIDTH);
    const heightError = this.getError(HEIGHT);

    return (
      <Segment basic compact disabled={linked} textAlign="center">
        <div>{widthKey}<Icon name="x" />{heightKey}</div>
        <div><Icon name="arrow down" size="large" /></div>
        <div>
          <Input
            autoComplete="on"
            className="banner-resize"
            disabled={linked}
            inputMode="numeric"
            min="0"
            onChange={this.onChange(WIDTH)}
            placeholder="Width"
            type="number"
            value={width}
          />
          {widthError ? <Label basic color="red">{widthError}</Label> : null}
          <Icon name="x" size="large" />
          <Input
            autoComplete="on"
            className="banner-resize"
            disabled={linked}
            inputMode="numeric"
            min="0"
            onChange={this.onChange(HEIGHT)}
            placeholder="Height"
            type="number"
            value={height}
          />
          {heightError ? <Label basic color="red">{heightError}</Label> : null}
        </div>
      </Segment>
    );
  }
}

export default BannerResize;

BannerResize.defaultProps = {
  bannerKey: '0x0',
  errors: {},
  linked: false,
  setBannerSize: () => {},
  size: [0, 0],
};

BannerResize.propTypes = {
  bannerKey: PropTypes.string,
  errors: PropTypes.shape({}),
  linked: PropTypes.bool,
  setBannerSize: PropTypes.func,
  size: PropTypes.arrayOf(PropTypes.number),
};
