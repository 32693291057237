const allowedDomains = ['caffeine.io', 'chartboost.com'];
const reservedServiceName = ['next', 'local'];

export const getDefaultLocation = (URL) => {
  const defaultLocation = document.createElement('a');
  defaultLocation.href = URL;
  return defaultLocation;
};

export const getAPI = (defaultURL, currentLocation = window.location) => {
  const { host: currentHost } = currentLocation;
  const { host: defaultHost, pathname: defaultPath } = getDefaultLocation(defaultURL);

  if (currentHost.includes('bluecaffeine.io')) { return defaultURL; }

  if (!allowedDomains.some(domain => currentHost.includes(domain))) { return defaultURL; }

  const currentSubdomain = currentHost.split('.')[0];
  const currentService = currentSubdomain.includes('-')
    ? currentSubdomain.split('-')[1]
    : currentSubdomain;
  const defaultService = defaultHost.split('.')[0];

  if (reservedServiceName.indexOf(currentService) >= 0) { return defaultURL; }
  return `https://${currentHost.replace(currentService, defaultService)}${defaultPath}`;
};

export const ANALYTICS_API = process.env.REACT_APP_ANALYTICS_API;
export const API_GATEWAY = process.env.REACT_APP_API_GATEWAY;
export const DSP_API = `${API_GATEWAY}/dsp_service`;
export const INTEGRATIONS_API = `${API_GATEWAY}/ssp_integrations_service`;
export const SEGMENTS_API = process.env.REACT_APP_SEGMENTS_API;
export const SSP_REPORTING_API = process.env.REACT_APP_SSP_REPORTING_API;
export const FUSION_API = process.env.REACT_APP_FUSION_API;
export const DASH_API = `${FUSION_API}/dashapi`;
