import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

import {
  FETCH_GLOBALS_SUCCESS,
  RESET_GLOBALS,
  SAVE_GLOBALS_SUCCESS,
  SET_GLOBALS,
} from './actions';

const initState = {
  globals: {
    creative_types: { mraid: '', static: '', video: '' },
    price_formula: { formula: 'dollars' },
  },
  saved: {},
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_GLOBALS_SUCCESS:
    case SAVE_GLOBALS_SUCCESS:
      return { ...state, globals: action.response, saved: cloneDeep(action.response) };

    case RESET_GLOBALS:
      return { ...state, globals: cloneDeep(state.saved) };

    case SET_GLOBALS:
      return { ...state, globals: set({ ...state.globals }, action.name, action.value) };

    default:
      return state;
  }
};

export default reducer;
