import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Divider } from 'semantic-ui-react';

import { PageLoader } from '../../../../shared/components';
import IntegrationsMenu from './IntegrationsMenu';

import '../css/IntegrationsLayout.css';

class IntegrationsLayout extends Component {
  componentDidMount() {}

  render() {
    const { loading } = this.props;
    return (
      <Container className="IntegrationsLayout">
        <PageLoader isLoading={loading} />
        <IntegrationsMenu />
        <Divider />
        {this.props.children}
      </Container>
    );
  }
}

IntegrationsLayout.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.object)
};

IntegrationsLayout.defaultProps = {
  loading: false,
  children: []
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationsLayout);
