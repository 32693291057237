import React from 'react';
import { connect } from 'react-redux';
import { Container, Divider, Search } from 'semantic-ui-react';

import { fetchCampaigns } from '../actions';
import { ExchangeMenu } from '../../shared/components';
import { PageHeader, PageLoader } from '../../../../shared/components';
import { PublisherTable } from '../components';
import { selectCampaigns, selectHasCampaigns, selectLoading } from '../../selectors';
import { PLATFORMS_BY_ID } from '../../shared/constants';

import './PublisherList.css';

const PublisherList = ({ campaigns, fetchCampaigns, hasCampaigns, loading, platforms }) => (
  <Container className="PublisherList">
    <PageLoader isLoading={loading} />

    <ExchangeMenu />
    <Search
      className="PublisherList__Search"
      loading={loading}
      onSearchChange={fetchCampaigns}
      placeholder="Search by company ID or campaign ID"
      showNoResults={false}
    />
    <Divider />

    <PageHeader content="Publishers" />
    {hasCampaigns &&
      <PublisherTable
        campaigns={campaigns}
        loading={loading}
        platforms={platforms}
      />
    }

  </Container>
);

const mapStateToProps = state => ({
  campaigns: selectCampaigns(state),
  hasCampaigns: selectHasCampaigns(state),
  loading: selectLoading(state),
  platforms: PLATFORMS_BY_ID,
});

const mapDispatchToProps = dispatch => ({
  fetchCampaigns: (e, input) => dispatch(fetchCampaigns(input.value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublisherList);
