import { LOADING, UNLOADED } from '../../../shared/constants/readyStates';
import { flexbidDefaults } from '../flexbids/reducers';

// Selector Functions
export const yieldState = state => state.yield;
export const isUnloaded = readyState => readyState === UNLOADED;
export const isLoading = readyState => readyState === LOADING;

export const selectConfig = (flexbids) => {
  if (flexbids.new) return flexbids.new;
  return flexbidDefaults;
};
