import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Container, Segment, Header, Icon, List } from 'semantic-ui-react';
import { connect } from 'react-redux';

import CBExchangeMenu from '../components/CBExchange/CBExchangeMenu';
import { getAppsSelected } from '../store/apps/reducer';
import * as appsActions from '../store/apps/actions';

import '../styles/CBExchange.css';

class CBExchangeSummaryView extends Component {
  getMessageToCopy() {
    const { action, appsSelected, editionOption, idsAddedOrRemoved } = this.props;
    const appsSelectedToString = appsSelected.map(app => `${app.nickname} - ${app.id}`);
    const message = `The following ${action} IDs have been successfully ${editionOption}ed:
      ${idsAddedOrRemoved.join('\r\n')}\nTo the following apps: \n${appsSelectedToString.join('\r\n')}`;

    return message;
  }

  handleCancel() {
    const { selectApps, resetApps } = this.props;
    resetApps();
    selectApps([]);
  }

  render() {
    const { action, appsSelected, editionOption, idsAddedOrRemoved } = this.props;
    const header = `${editionOption.charAt(0).toUpperCase() + editionOption.slice(1)}ed ${action.charAt(0).toUpperCase() + action.slice(1)}s`;

    if (appsSelected.length === 0) { return <Redirect to="/cb-exchange" />; }

    return (
      <Container id="CBExchangeSummaryView">
        <CBExchangeMenu />
        <Segment padded="very" textAlign="center">
          <Header as="h3">{header}</Header>
          <Icon className="check-icon" name="check circle" size="huge" />
          <Header as="h4">The following {action} IDs have been successfully {editionOption}ed:</Header>
          <List verticalAlign="middle">
            {idsAddedOrRemoved.map(id => (
              <List.Item key={id} className="list-ids">
                <List.Content className="item-id">{id}</List.Content>
              </List.Item>
            ))}
          </List>
          <Header as="h4">To the following {appsSelected.length} apps:</Header>
          <List verticalAlign="middle">
            {appsSelected.map(app => (
              <List.Item key={app.id}>
                <List.Content>{app.nickname} - {app.id}</List.Content>
              </List.Item>
            ))}
          </List>
          <Header as="h4" className="copy-message">
            <CopyToClipboard text={this.getMessageToCopy()}>
              <Icon name="copy outline" />
            </CopyToClipboard>
          </Header>
          <Button onClick={() => this.handleCancel()}>Go back to the Apps page</Button>
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match: { params: { action, editionOption } } } = ownProps;
  return {
    action,
    editionOption,
    appsSelected: getAppsSelected(state),
    idsAddedOrRemoved: state.apps.idsAddedOrRemoved,
  };
};

const mapDispatchToProps = dispatch => ({
  selectApps: appsIds => dispatch(appsActions.selectApps(appsIds)),
  resetApps: () => dispatch(appsActions.resetApps()),
});

CBExchangeSummaryView.propTypes = {
  action: PropTypes.string.isRequired,
  editionOption: PropTypes.string.isRequired,
  appsSelected: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  idsAddedOrRemoved: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectApps: PropTypes.func.isRequired,
  resetApps: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CBExchangeSummaryView);
