import React from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const AddBidder = () => (
  <Button
    as={Link}
    content="Add Bidder"
    floated="right"
    icon="plus"
    labelPosition="left"
    primary
    to="/exchange/bidders/new"
  />
);

export default AddBidder;
