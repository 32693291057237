import PropTypes from 'prop-types';
import React from 'react';
import { Icon, List } from 'semantic-ui-react';
import './PlatformChecks.css';

const PlatformChecks = ({ platformId, platformName }) => {
  if (platformId !== 0) {
    const checkList = [
      {
        title: 'Does the playable open?',
        content: '',
      },
      {
        title: 'Does it respond to user interactions (taps)?',
        content: '',
      },
      {
        title: 'Is the game play mechanics working correctly?',
        content: '',
      },
      {
        title: 'Does the CTA button work?',
        content: '',
      },
      {
        title: 'Does the X button work?',
        content: '',
      },
      {
        title: 'Does the playable work in both horizontal and vertical dimensions?',
        content: '',
      },
      {
        title: 'Can you "break" the playable?',
        content: 'Via taps, orientation changes, muting/unmuting or closing/reopening app',
      },
    ];

    let platformAdvice = '';

    if (platformId === 1) {
      platformAdvice = 'Please bear in mind check both Webkit and Webview';
    }

    const items = checkList.map((check, index) => ({
      icon: 'check',
      key: index,
      header: check.title,
      description: check.content,
    }));

    return (
      <div className="platform_checks">
        <h4>Platform checks for <span className="platform_name">{platformName}</span></h4>
        {platformAdvice && <span className="platform_advice"><Icon name="exclamation" size="small" color="orange" />{platformAdvice}</span> }
        <List items={items} />
      </div>
    );
  }
  return null;
};

PlatformChecks.defaultProps = {
  platformId: 0,
  platformName: '',
};

PlatformChecks.propTypes = {
  platformId: PropTypes.number,
  platformName: PropTypes.string,
};

export default PlatformChecks;
