export const PLATFORM = {
  apple: 'iOS',
  amazon: 'Amazon',
  android: 'Android',
};

export const PLATFORM_STORE = {
  apple: 'app store',
  amazon: 'amazon',
  android: 'google play',
};

export const REJECT_REASONS = {
  'sdk-integration_no-sdk': 'No Chartboost SDK found',
  'sdk-integration_wrong-app-id': 'Wrong App ID',
  'sdk-integration_not-minimum-sdk': 'Minimum SDK version not met',
  'sdk-integration_sdk-not-detected-in-mediation': 'Chartboost Adapter cannot be detected in your mediation',
  'sdk-integration_other': 'Other',
  'app-not-available_incorrect-store-url': 'Incorrect Store URL',
  'app-not-available_incorrect-bundle-id': 'Incorrect Bundle ID',
  'app-not-available_not-available-nl': 'Not avaialble in the Netherlands store',
  'app-not-available_error-downloading': 'Error while downloading',
  'app-not-available_other': 'Other',
  'quality_app-crashing': 'App is crashing',
  'quality_app-cannot-open': 'App cannot be open on a device',
  'quality_too-frequent-ads': 'Too frequent ads',
  quality_clickbait: 'Clickbait app',
  quality_other: 'Other',
  'app-not-complaiant_incentivized-traffic': 'Incentivized traffic',
  'app-not-complaiant_invalid-content': 'App promotes inappropriate content (nudity, drugs, weapons, etc.)',
  'app-not-complaiant_suspected-fraud': 'Suspected fraudulent activity',
  'app-not-complaiant_other': 'Other',
  'dau_low-dau': 'Issue with Daily Active Users',
  other_other: 'Other',
};
