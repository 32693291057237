import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Container,
  Grid,
  Header,
  Label,
  Loader,
} from 'semantic-ui-react';
import S2STrackingInput from '../components/S2STrackingInput';
import PageHeader from '../../shared/components/PageHeader';
import * as s2sActions from '../store/s2sTracking/actions';
import '../styles/S2STracking.css';

const INIT_TRACKING_RESPONSE = {
  blocked_advertiser_bundles: [],
  date_created: 0,
  date_modified: 0,
  deleted: false,
  exchange_settings: {},
  name: '',
  nickname: '',
  platform: '',
  s2s_tracking_status: null,
  _id: '',
};

const ENABLED_STATUSES = ['on', 'pending', 'approved'];

class S2STracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appID: '',
      currentAppID: '',
      isFetched: false,
      trackingResponse: null,
    };

    this.getS2STracking = this.getS2STracking.bind(this);
    this.handleRequestTracking = this.handleRequestTracking.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  onKeyDown(event) {
    const { key } = event;
    if (key === 'Enter' && this.state.appID !== '') {
      this.getS2STracking(this.state.appID);
    }
  }

  async getS2STracking(appID) {
    const trackingResponse = await this.props.getTrackingStatus(appID);
    this.setState(state => ({
      ...state,
      trackingResponse,
      currentAppID: appID,
      isFetched: true,
    }));
  }

  async handleRequestTracking() {
    try {
      await this.props.requestTracking(this.state.currentAppID);
    } finally {
      await this.getS2STracking(this.state.currentAppID);
    }
  }

  handleInputChange(e, data) {
    this.setState({ appID: data.value });
  }

  render() {
    const { appID, currentAppID, isFetched, trackingResponse } = this.state;
    const { loading } = this.props;
    let labelColor = 'blue';
    let shouldDisableTracking = true;
    let statusLabel = 'not requested';
    if (trackingResponse != null) {
      shouldDisableTracking = ENABLED_STATUSES.some(status => status === trackingResponse.s2s_tracking_status);
      labelColor = shouldDisableTracking ? 'blue' : 'yellow';
      statusLabel = String(trackingResponse.s2s_tracking_status) === 'null' ? statusLabel : String(trackingResponse.s2s_tracking_status);
    }

    return (
      <Container className="s2sTrackingContainer">
        <PageHeader>S2S Tracking</PageHeader>
        {loading ?
          <Loader active /> :
          <Container fluid>
            <Grid centered id="s2sTrackingInput">
              <Grid.Row columns={2} >
                <Grid.Column>
                  <S2STrackingInput
                    appID={appID}
                    handleInputChange={((e, data) => this.handleInputChange(e, data))}
                    handleKeyDown={e => this.onKeyDown(e)}
                  />
                </Grid.Column>
                <Grid.Column >
                  <Button
                    color="green"
                    content="Search"
                    onClick={() => {
                      if (appID !== '') {
                        this.getS2STracking(this.state.appID);
                      }
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {isFetched && trackingResponse != null && (
              <Container className="trackingStatusResponse">
                <Card fluid className="trackingAppCard">
                  <Card.Content>
                    <Card.Header>
                      {trackingResponse.nickname.length ? trackingResponse.nickname : trackingResponse.name}
                    </Card.Header>
                    <Card.Meta>
                      {currentAppID} - {trackingResponse.platform}
                    </Card.Meta>
                    <Card.Description>
                      <div>
                        <Header as="h5">S2S Tracking Status</Header>
                      </div>
                      <div>
                        <Label color={labelColor}>
                          {statusLabel}
                        </Label>
                      </div>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      color="green"
                      disabled={shouldDisableTracking}
                      onClick={this.handleRequestTracking}
                    >
                      Request S2S Tracking
                    </Button>
                  </Card.Content>
                </Card>
              </Container>
            )}
          </Container>
        }
      </Container>
    );
  }
}

S2STracking.defaultProps = { trackingResponse: INIT_TRACKING_RESPONSE };

S2STracking.propTypes = {
  getTrackingStatus: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  requestTracking: PropTypes.func.isRequired,
  trackingResponse: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  loading: state.s2sTracking.loading,
  trackingResponse: state.s2sTracking.trackingResponse,
});

const mapDispatchToProps = dispatch => ({
  getTrackingStatus: appID => dispatch(s2sActions.fetchStatus(appID)),
  requestTracking: appID => dispatch(s2sActions.requestTracking(appID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S2STracking);
