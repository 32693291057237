import getter from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Input } from 'semantic-ui-react';

import { isNewBidder } from '../helpers';

const CampaignId = (props) => {
  const { bidder, path, platform } = props;
  const { campaign_id: campaignId, enabled } = getter(bidder, path, {});
  const newBidder = isNewBidder(bidder);

  return (
    <Input
      placeholder="Campaign generated after saving"
      readOnly
      transparent={!newBidder}
      value={campaignId}
    >
      <Icon name={enabled ? 'toggle on' : 'toggle off'} size="big" link />
      <Icon name={platform.icon} size="big" link />
      <input />
    </Input>
  );
};

export default CampaignId;

CampaignId.defaultProps = {
  bidder: {},
  path: '',
  platform: {},
};

CampaignId.propTypes = {
  bidder: PropTypes.shape({}),
  path: PropTypes.string,
  platform: PropTypes.shape({}),
};
