import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Button, Confirm, Container, Divider, Segment } from 'semantic-ui-react';
import { getRouteParams } from '../../shared/helpers';
import { PageHeader } from '../../shared/components';
import PrivateMarketplaceMenu from '../components/PrivateMarketplaceMenu';
import DealBasicSettings from '../components/DealBasicSettings';
import DealDetailsSettings from '../components/DealDetailsSettings';
import {
  fetchDeal,
  retrieveDSPs,
  saveDeal,
  removeDeal,
  fetchSegments,
  fetchCountries,
} from '../store/pmpDeals/actions';
import { getPMPDeal } from '../store/pmpDeals/reducer';
import { DEFAULT_END_TIMESTAMP } from '../../domain/pmpDeals/constants';
import PMPDeal from '../../domain/pmpDeals/pmpDeal';
import '../styles/PrivateMarketplace.css';
import { getUserApps } from '../../services/apps';


class PrivateMarketplaceEdit extends Component {
  constructor(props) {
    super(props);
    let { deal } = props;
    // We need to clone it with lodash cloneDeep because the deal is being reassigned
    // instead of being set as a new state on DealDetailsSettings
    // therefore the original deal is being modified on every change
    // further explanation: https://dev.to/boywithsilverwings/reassignment-vs-mutability-21ob
    const clonedDeal = isEmpty(deal) ? null : cloneDeep(deal.copy());
    if (isEmpty(deal)) {
      deal = new PMPDeal();
    }

    this.colum1 = 3;
    this.colum2 = 12;
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.closeConfirmDelete = this.closeConfirmDelete.bind(this);
    this.handleDeleteConfirmed = this.handleDeleteConfirmed.bind(this);

    if (deal.dimensions && isEmpty(deal.dimensions.skippable)) {
      deal.dimensions.skippable = [];
    }

    this.state = {
      id: props.id,
      deal,
      loading: false,
      saved: false,
      apps: [],
      clonedDeal,
      confirmDeleteOpen: false,
    };
  }

  componentDidMount() {
    const {
      deal,
      DSPs,
      id,
      countries,
      getDeal,
      getDSPs,
      appSegments,
      getSegments,
      getCountries,
    } = this.props;

    if (id !== 'new' && isEmpty(deal)) {
      getDeal(id);
    }
    if (isEmpty(DSPs)) {
      getDSPs();
    }

    if (isEmpty(appSegments)) {
      getSegments();
    }
    if (isEmpty(countries)) {
      getCountries();
    }
    getUserApps().then((apps) => {
      this.setState({ apps });
    });
  }

  componentWillReceiveProps(newProps) {
    const { id, deal: oldDeal } = this.props;
    const { deal } = newProps;
    const { clonedDeal } = this.state;

    if (!isEqual(deal, oldDeal)) {
      if (deal.end_timestamp === DEFAULT_END_TIMESTAMP) {
        deal.end_timestamp = '';
      }
      if (deal.dimensions && isEmpty(deal.dimensions.skippable)) {
        deal.dimensions.skippable = [];
      }
      this.setState({ deal });
      if (id !== 'new' && isEmpty(clonedDeal)) {
        this.setState({ clonedDeal: cloneDeep(deal.copy()) });
      }
    }
  }

  handleStateChange(data) {
    const { appSegments: appSegmentsList, deviceSegments: deviceSegmentsList } = this.props;
    let { name, value } = data;
    if (['deviceSegments', 'appSegments'].includes(name)) {
      const { deal } = this.state;
      const segments = {
        deviceSegments: deal.getDeviceSegments(deviceSegmentsList),
        appSegments: deal.getAppSegment(appSegmentsList),
      };
      segments[name] = value;
      deal.setSegments(segments.appSegments, segments.deviceSegments);
      name = 'deal';
      value = deal;
    }
    this.setState({ name: value });
  }

  async handleSave() {
    const { id } = this.props;
    const { deal } = this.state;
    this.setState({ loading: true });
    try {
      await this.props.saveDeal(id, deal.getBodyJsonRequest());
      this.setState({ saved: true });
    } catch (error) {
      this.setState({ saved: false });
      this.setState({ loading: false });
    }
  }

  async handleCopy() {
    const { clonedDeal } = this.state;

    this.setState({ loading: true });
    try {
      await this.props.saveDeal('new', clonedDeal.getBodyJsonRequest());
      this.setState({ saved: true });
    } catch (error) {
      this.setState({ saved: false });
    }
  }

  closeConfirmDelete() {
    this.setState({ confirmDeleteOpen: false });
  }

  handleDeleteClick() {
    this.setState({ confirmDeleteOpen: true });
  }

  async handleDeleteConfirmed() {
    const { id } = this.props;
    this.setState({ loading: true });

    try {
      await this.props.removeDeal(id);
      this.setState({ confirmDeleteOpen: false });
      this.setState({ saved: true });
    } catch (error) {
      this.setState({ saved: false });
    }
  }

  render() {
    const { clonedDeal, deal, id, loading, saved } = this.state;

    const {
      DSPs,
      countries,
      appSegments: appSegmentsList,
      deviceSegments: deviceSegmentsList,
      location,
    } = this.props;
    const isAdminView = !location.pathname.startsWith('/public');
    const redirectUrl = isAdminView ? '/pmp' : '/public/pmp';

    if (saved) {
      return <Redirect to={redirectUrl} />;
    }

    const deviceSegments = deal.getDeviceSegments(deviceSegmentsList);

    const appSegments = deal.getDeviceSegments(appSegmentsList);

    const invalidToSave = deal.invalidToSave();
    const title = id === 'new' ? 'Create a new PMP deal' : 'Edit Deal';

    return (
      <Container className="PrivateMarketplaceEdit">
        {isAdminView && (
          <div>
            <PrivateMarketplaceMenu />
            <Button.Group floated="right" >
              <Button as={Link} secondary to={redirectUrl}>
                Cancel
              </Button>
              <Button primary disabled={invalidToSave} onClick={this.handleSave} loading={loading}>
                Save
              </Button>
              {id !== 'new' && (
                <React.Fragment>
                  <Button color="teal" onClick={this.handleCopy} loading={loading} disabled={isEmpty(clonedDeal)}>
                    Copy Deal
                  </Button>
                  <Button color="red" onClick={this.handleDeleteClick} loading={loading}>
                    Delete Deal
                  </Button>
                  <Confirm
                    open={this.state.confirmDeleteOpen}
                    onCancel={this.closeConfirmDelete}
                    onConfirm={this.handleDeleteConfirmed}
                    content="Are you sure you want to delete this deal?"
                    confirmButton={{ color: 'red', content: 'Delete', primary: false }}
                  />
                </React.Fragment>
              )}
            </Button.Group>
            <Divider />
            <PageHeader content={title} />
          </div>
        )}
        <Segment>
          <DealBasicSettings
            deal={deal}
            onChange={this.handleStateChange}
            colum1={this.colum1}
            colum2={this.colum2}
          />
          <DealDetailsSettings
            id={id}
            deal={deal}
            deviceSegments={deviceSegments}
            appSegments={appSegments}
            colum1={this.colum1}
            colum2={this.colum2}
            onChange={this.handleStateChange}
            invalidToSave={invalidToSave}
            save={this.handleSave}
            DSPs={DSPs}
            countries={countries}
            appSegmentsList={appSegmentsList}
            deviceSegmentsList={deviceSegmentsList}
            isAdminView={isAdminView}
            userApps={this.state.apps}
            duplicate={this.handleCopy}
            remove={this.handleDeleteConfirmed}
            loading={loading}
          />
        </Segment>
      </Container>
    );
  }
}

PrivateMarketplaceEdit.defaultProps = {
  deal: {},
  DSPs: [],
  deviceSegments: [],
  appSegments: [],
  countries: [],
  location: {},
};

PrivateMarketplaceEdit.propTypes = {
  id: PropTypes.string.isRequired,
  deal: PropTypes.shape({}),
  DSPs: PropTypes.arrayOf(PropTypes.shape({})),
  deviceSegments: PropTypes.arrayOf(PropTypes.shape({})),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  appSegments: PropTypes.arrayOf(PropTypes.shape({})),
  saveDeal: PropTypes.func.isRequired,
  getDeal: PropTypes.func.isRequired,
  removeDeal: PropTypes.func.isRequired,
  getDSPs: PropTypes.func.isRequired,
  getSegments: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  location: PropTypes.shape({}),
};

const mapStateToProps = (state, props) => {
  const { id } = getRouteParams(props);

  return {
    id,
    deal: getPMPDeal(state.pmpDeals.deals, id),
    DSPs: state.pmpDeals.DSPs,
    deviceSegments: state.pmpDeals.deviceSegments,
    appSegments: state.pmpDeals.appSegments,
    loading: state.pmpDeals.loading,
    apiValidation: state.pmpDeals.apiValidation,
    countries: state.pmpDeals.countries,
  };
};

const mapDispatchToProps = dispatch => ({
  saveDeal: (id, deal) => dispatch(saveDeal(id, deal)),
  getDeal: id => dispatch(fetchDeal(id)),
  removeDeal: id => dispatch(removeDeal(id)),
  getDSPs: () => dispatch(retrieveDSPs()),
  getSegments: () => dispatch(fetchSegments()),
  getCountries: () => dispatch(fetchCountries()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PrivateMarketplaceEdit));
