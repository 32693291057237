import React from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { convertTimestamp, getMode } from '../helpers';

const PublisherCampaignTable = ({ campaigns = [], platforms }) => (
  <Table celled>

    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Campaign ID</Table.HeaderCell>
        <Table.HeaderCell>Campaign Name</Table.HeaderCell>
        <Table.HeaderCell>Company ID</Table.HeaderCell>
        <Table.HeaderCell>Platform</Table.HeaderCell>
        <Table.HeaderCell>Exchange Mode</Table.HeaderCell>
        <Table.HeaderCell>Last Modified</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {campaigns.map(({
      campaign_id,
      campaign_name,
      company_id,
      last_exchange_modified,
      mode,
      platform,
    }) => (
      <Table.Row key={campaign_id}>
        <Table.Cell>
          <Link to={`/exchange/publishers/${campaign_id}`}>{campaign_id}</Link>
        </Table.Cell>
        <Table.Cell>{campaign_name}</Table.Cell>
        <Table.Cell>{company_id}</Table.Cell>
        <Table.Cell><Icon name={platforms[platform].icon} /></Table.Cell>
        <Table.Cell>{getMode(mode)}</Table.Cell>
        <Table.Cell>{convertTimestamp(last_exchange_modified)}</Table.Cell>
      </Table.Row>
    ))}
    </Table.Body>
  </Table>
);

export default PublisherCampaignTable;
