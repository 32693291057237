import { get, del, post, put } from '../shared/helpers';
import { DASH_API } from '../shared/api';


class Dashapi {
  static get(path) {
    return get(`${DASH_API}/${path}`, null, { token: true });
  }

  static delete(path) {
    return del(`${DASH_API}/${path}`, null, { token: true });
  }

  static post(path, data) {
    return post(`${DASH_API}/${path}`, data, { token: true });
  }

  static put(path, data) {
    return put(`${DASH_API}/${path}`, data, { token: true });
  }
}

export default Dashapi;
