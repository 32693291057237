import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'semantic-ui-react';
import { StatusDropdown } from '.';
import { STATUS_NAMES } from '../constants';


class CreativeInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentStatus: props.status,
    };
  }

  handleDropdownChange = (proxy, target) => {
    const nextStatus = target.value;
    this.setState({ currentStatus: nextStatus });
  };

  handleOnSave = () => {
    const { creative_id: creativeId, id } = this.props;
    const status = this.state.currentStatus;
    this.props.onSave({ creativeId, status, id });
  };

  render() {
    const { count, bidder_id: bidderId, bidder_name: bidderName, status } = this.props;
    const { currentStatus } = this.state;
    const formIsDirty = currentStatus !== status;
    return (
      <div className="CreativePreview__Info">
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <div className="CreativePreview__InfoTitle">Impression Count:</div>
                <p>{count}</p>
              </Table.Cell>
              <Table.Cell>
                <div className="CreativePreview__InfoTitle">Bidder ID:</div>
                <p>{bidderId}</p>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <div className="CreativePreview__InfoTitle">Bidder Name:</div>
                <p>{bidderName}</p>
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
          </Table.Body>
        </Table>
        <StatusDropdown
          onChange={this.handleDropdownChange}
          defaultValue={STATUS_NAMES[0]}
          status={currentStatus}
        />
        <Button
          disabled={!formIsDirty}
          color="green"
          className="CreativePreview__SaveButton"
          onClick={this.handleOnSave}
        >
          Save
        </Button>
      </div>
    );
  }
}

CreativeInfo.defaultProps = {
  bidder_id: '',
  bidder_name: '',
  count: 0,
  creative_id: '',
  id: '',
  onSave: () => {},
  status: STATUS_NAMES[0],
};

CreativeInfo.propTypes = {
  bidder_id: PropTypes.string,
  bidder_name: PropTypes.string,
  count: PropTypes.number,
  creative_id: PropTypes.string,
  id: PropTypes.string,
  onSave: PropTypes.func,
  status: PropTypes.oneOf(STATUS_NAMES),
};

export default CreativeInfo;
