import { LOADED, LOADING } from '../../../shared/constants/readyStates';
import {
  FETCH_PHASES,
  UPDATE_PHASES_SUCCESS,
} from './actions';
import { rehydrate } from '../../../store/localStorage';

const initState = {
  loading: true,
  phases: [],
};

const reducer = (state = rehydrate('phases', initState), action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };

    case LOADED:
      return { ...state, loading: false };

    case FETCH_PHASES:
      return {
        ...state,
        phases: [...action.phases],
      };

    case UPDATE_PHASES_SUCCESS:
      return {
        ...state,
        phases: [...action.phases],
      };

    default:
      return state;
  }
};

export default reducer;
