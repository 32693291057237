import pick from 'lodash/pick';

import { DASH_API } from '../../../shared/api';
import { isObjectId, xhr } from '../../../shared/helpers';

// Action Types

export const FETCH_CAMPAIGNS = 'PUBLISHER.CAMPAIGNS.FETCH';
export const FETCH_CAMPAIGNS_SUCCESS = 'PUBLISHER.CAMPAIGNS.FETCH.SUCCESS';
export const FETCH_CAMPAIGNS_FAILURE = 'PUBLISHER.CAMPAIGNS.FETCH.FAILURE';

export const FETCH_PUBLISHER = 'PUBLISHER.FETCH';
export const FETCH_PUBLISHER_SUCCESS = 'PUBLISHER.FETCH.SUCCESS';
export const FETCH_PUBLISHER_FAILURE = 'PUBLISHER.FETCH.FAILURE';

export const SET_PUBLISHER = 'PUBLISHER.SET';
export const TOGGLE_BIDDER = 'PUBLISHER.TOGGLE_BIDDER';

export const SAVE_PUBLISHER = 'PUBLISHER.SAVE';
export const SAVE_PUBLISHER_SUCCESS = 'PUBLISHER.SAVE.SUCCESS';
export const SAVE_PUBLISHER_FAILURE = 'PUBLISHER.SAVE.FAILURE';

// Action Creators

export const fetchCampaigns = id => async (dispatch) => {
  if (!isObjectId(id)) { return; }

  dispatch({ type: FETCH_CAMPAIGNS });
  try {
    const { response: { campaigns } } = await xhr(`${DASH_API}/campaigns/exchange/search/${id}`);
    dispatch({ type: FETCH_CAMPAIGNS_SUCCESS, campaigns });
  } catch ({ message }) {
    dispatch({ type: FETCH_CAMPAIGNS_FAILURE, error: message });
  }
};

export const fetchPublisher = campaignId => async (dispatch) => {
  dispatch({ type: FETCH_PUBLISHER });
  try {
    const { response: publisher } = await xhr(`${DASH_API}/campaigns/exchange/${campaignId}`);
    dispatch({ type: FETCH_PUBLISHER_SUCCESS, publisher });
  } catch ({ message }) {
    dispatch({
      type: FETCH_PUBLISHER_FAILURE,
      error: message,
      message: ['fetch-failure', 'publisher'],
    });
  }
};

export const setPublisher = ({ name, value }) => ({
  type: SET_PUBLISHER,
  attrs: { [name]: value },
});

export const toggleBidder = id => ({ type: TOGGLE_BIDDER, id });

export const savePublisher = (attrs, id) => async (dispatch) => {
  dispatch({ type: SAVE_PUBLISHER });
  try {
    const data = pick(attrs, ['badv', 'bcat', 'bapp', 'mode', 'dsp_blocklist']);
    data.mode = (attrs.mode == null) ? -1 : attrs.mode;
    const { response: publisher } = await xhr(`${DASH_API}/campaigns/exchange/${id}`, data, { method: 'PUT' });
    dispatch({ type: SAVE_PUBLISHER_SUCCESS, publisher, message: ['save-success', 'publisher'] });
  } catch ({ message }) {
    dispatch({
      type: SAVE_PUBLISHER_FAILURE,
      error: message,
      message: ['save-failure', 'publisher'],
    });
  }
};
