import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Container, Divider, Grid, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as BidderUi from '../components';
import { ExchangeMenu, ServerMessages } from '../../shared/components';
import { JsonPreview, PageHeader } from '../../../../shared/components';

import { isBidderId, isNewBidder } from '../helpers';
import { removeMessages } from '../../shared/actions';
import {
  addBidder,
  addListItems,
  createBidder,
  executeDeployPubConfig,
  fetchBidder,
  fetchDeviceLists,
  moveList,
  removeBidder,
  removeErrors,
  removeListItems,
  saveBidder,
  searchApp,
  searchBundleId,
  setBidder,
  setEndpoint,
  setListItem,
  setActiveIndex,
} from '../actions';
import {
  getAppResultOptions,
  getApps,
  getBundleIds,
  getDeviceListOptions,
  getDeviceLists,
  getIsDeployingPubConfigs,
  getIsFetching,
  getIsFetchingDeviceLists,
  getIsSearchingAppName,
  getIsSyncing,
  getSavedBidder,
  getSearchingBundleIds,
  getServerMessages,
  getActiveIndex,
} from '../../reducers';

import { selectBidder, selectBidderErrors, selectBidderHasErrors } from '../../selectors';

class BidderEdit extends Component {
  componentDidMount() {
    const {
      addBidder,
      fetchDeviceLists,
      match: { params: { id: bidderId } },
      removeErrors,
      removeMessages,
    } = this.props;

    if (isBidderId(bidderId)) {
      this.fetchData();
      fetchDeviceLists();
    } else if (bidderId === 'new') {
      addBidder();
      fetchDeviceLists();
    }

    removeErrors();
    removeMessages();
  }

  componentWillUnmount() {
    const { savedBidder, setBidder } = this.props;
    if (!isNewBidder(savedBidder)) { setBidder(savedBidder.id, savedBidder); }
    this.props.removeBidder('new');
  }

  fetchData() {
    const { bidder, fetchBidder, match: { params: { id: bidderId } } } = this.props;
    if (isEmpty(bidder)) { fetchBidder(bidderId); }
  }

  render() {
    const { bidder, createBidder, hasErrors, isFetching, saveBidder } = this.props;
    const { date_created: dateCreated, date_modified: dateModified } = bidder;

    const headerText = isFetching
      ? 'Loading...'
      : (isNewBidder(bidder) && 'Add Bidder') || 'Edit Bidder';

    const dateCreatedPretty = dateCreated ? moment(dateCreated * 1000).format('LL') : '';
    const dateModifiedPretty = dateModified ? moment(dateModified * 1000).fromNow() : '';

    return (
      <Container className="BidderEdit">

        <ExchangeMenu />
        <Button.Group floated="right">
          <Button as={Link} secondary to="/exchange/bidders">Cancel</Button>
          {
            isNewBidder(bidder)
              ? <Button as={Link} primary disabled={hasErrors} onClick={() => createBidder(bidder)} to="/exchange/bidders">Save</Button>
              : <Button as={Link} primary disabled={hasErrors} onClick={() => saveBidder(bidder)} to="/exchange/bidders">Save</Button>
          }
        </Button.Group>
        <ServerMessages {...this.props} />

        <Divider />

        <PageHeader content={headerText} />
        <Grid columns="equal" stackable>
          <Grid.Column><BidderUi.BidderEditForm {...this.props} /></Grid.Column>
          <Grid.Column><JsonPreview toStringify={bidder} /></Grid.Column>
        </Grid>

        <Divider />

        <Segment basic>
          <Button.Group floated="right">
            <Button as={Link} secondary to="/exchange/bidders">Cancel</Button>
            {
              isNewBidder(bidder)
                ? <Button as={Link} primary onClick={() => createBidder(bidder)} to="/exchange/bidders">Save</Button>
                : <Button as={Link} primary onClick={() => saveBidder(bidder)} to="/exchange/bidders">Save</Button>
            }
          </Button.Group>
          {dateCreatedPretty && <div>{`Created ${dateCreatedPretty}`}</div>}
          {dateModifiedPretty && <div>{`Last modified ${dateModifiedPretty}`}</div>}
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match: { params: { id: bidderId } } } = ownProps;
  return {
    bidder: selectBidder(bidderId)(state),
    errors: selectBidderErrors(bidderId)(state),
    hasErrors: selectBidderHasErrors(bidderId)(state),

    activeIndex: getActiveIndex(state),
    appResultOptions: getAppResultOptions(state),
    apps: getApps(state),
    bundleIds: getBundleIds(state, bidderId),
    deviceListOptions: getDeviceListOptions(state),
    deviceLists: getDeviceLists(state),
    isDeployingPubConfigs: getIsDeployingPubConfigs(state),
    isFetching: getIsFetching(state),
    isFetchingDeviceLists: getIsFetchingDeviceLists(state),
    isSearchingAppName: getIsSearchingAppName(state),
    isSyncing: getIsSyncing(state),
    savedBidder: getSavedBidder(state, bidderId),
    searchingBundleIds: getSearchingBundleIds(state),
    serverMessages: getServerMessages(state),
  };
};

const mapDispatchToProps = {
  addBidder,
  addListItems,
  createBidder,
  executeDeployPubConfig,
  fetchBidder,
  fetchDeviceLists,
  moveList,
  removeBidder,
  removeErrors,
  removeListItems,
  removeMessages,
  saveBidder,
  searchApp,
  searchBundleId,
  setBidder,
  setEndpoint,
  setListItem,
  setActiveIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(BidderEdit);
