import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Icon, Image, List, Loader } from 'semantic-ui-react';

class BlockAppsList extends React.Component {
  componentDidMount() {
    this.fetchApps();
  }

  componentDidUpdate(prevProps) {
    const { bundleIds: prevBundleIds } = prevProps;
    const { bundleIds } = this.props;

    if (!isEqual(bundleIds, prevBundleIds)) {
      this.fetchApps();
    }
  }

  fetchApps() {
    const { bundleIds, apps, searchBundleId, searchingBundleIds } = this.props;
    forEach(bundleIds, (platform, bundleId) => {
      if (!apps[bundleId] && !searchingBundleIds.includes(bundleId)) {
        searchBundleId(bundleId, platform.id);
      }
    });
  }

  render() {
    const {
      apps,
      bidder,
      bundleIds,
      removeListItems,
      searchingBundleIds,
    } = this.props;

    const listItems = map(bundleIds, (platform, bundleId) => {
      const app = apps[bundleId] || { name: '', bundle_id: bundleId };

      const onClick = (event, ownProps) => {
        const { value: removedBundleId } = ownProps;
        const platformKey = bundleIds[removedBundleId].key;
        removeListItems(bidder, `request_validation.blocked_apps.${platformKey}`, ownProps.value);
      };

      const defaultImage = searchingBundleIds.includes(bundleId)
        ? <Loader active inline />
        : <Icon name="image" size="big" />;

      const appImage = app.icon
        ? <Image size="mini" src={app.icon} verticalAlign="middle" />
        : defaultImage;

      return (
        <List.Item key={`app-${bundleId}`}>
          <Button
            basic
            floated="left"
            icon
            onClick={onClick}
            type="button"
            value={bundleId}
          >
            <Icon name="minus" />
          </Button>
          <List.Content floated="left">
            {appImage}
          </List.Content>
          <List.Content floated="left">
            <Icon name={platform.icon} size="large" />
          </List.Content>
          <List.Content floated="left">
            <List.Header>{app.name}</List.Header>
            <List.Description>{app.bundle_id}</List.Description>
          </List.Content>
        </List.Item>
      );
    });

    return (
      <List size="small" verticalAlign="middle">
        {listItems}
      </List>
    );
  }
}

export default BlockAppsList;

BlockAppsList.defaultProps = {
  apps: {},
  bidder: {},
  bundleIds: {},
  removeListItems: () => {},
  searchBundleId: () => {},
  searchingBundleIds: [],
};

BlockAppsList.propTypes = {
  apps: PropTypes.shape({}),
  bidder: PropTypes.shape({}),
  bundleIds: PropTypes.shape({}),
  removeListItems: PropTypes.func,
  searchBundleId: PropTypes.func,
  searchingBundleIds: PropTypes.arrayOf(PropTypes.string),
};
