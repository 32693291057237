import isEmpty from 'lodash/isEmpty';

export default class CSVFile {
  static splitFile(lines) {
    return lines.split(/\r\n|\r|\n/g);
  }

  static validateCsvIsOneColumn(fileContents) {
    const lines = CSVFile.splitFile(fileContents);
    const lineTokens = lines.map(line => line.split(','));
    return !lineTokens.some(tokens => tokens.length > 1);
  }

  static readCSVFile(file, callback) {
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      const formattedCorrectly = CSVFile.validateCsvIsOneColumn(text);
      if (!formattedCorrectly) {
        callback();
        return;
      }
      if (isEmpty(text)) {
        callback([]);
        return;
      }
      const lines = CSVFile.splitFile(text);
      callback(lines);
    };
    reader.readAsText(file);
  }
}
