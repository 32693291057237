import React from 'react';
import PropTypes from 'prop-types';

const CreativeEmbed = ({ playableHtml, isPortrait = false }) => (
  <div className={`CreativePreview__EmbedWrapper ${(isPortrait ? ' portrait' : ' landscape')}`}>
    <iframe scrolling="no" srcDoc={playableHtml} className="CreativePreview__Embed" title={`preview-${Math.random()}`} />
  </div>
);

CreativeEmbed.propTypes = {
  isPortrait: PropTypes.bool.isRequired,
  playableHtml: PropTypes.string.isRequired,
};

export default CreativeEmbed;
