import {
  FETCH_CREATIVE_FAILURE,
  FETCH_CREATIVE_SUCCESS,
} from '../actions';

const creative = (state = {}, action = {}) => {
  const creativeFields = action.creativeData;
  switch (action.type) {
    case FETCH_CREATIVE_FAILURE:
      return { ...state, errors: 'Unable to fetch creative' };

    case FETCH_CREATIVE_SUCCESS:
      return {
        ...state,
        adm: creativeFields.adm,
        bidderId: creativeFields.bidderId,
        bidderName: creativeFields.bidderName,
        id: creativeFields.id,
        name: creativeFields.name,
      };

    default:
      return state;
  }
};

export default creative;
