import differenceWith from 'lodash/differenceWith';
import getter from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';


const BlockDeviceLists = (props) => {
  const { addListItems, bidder, deviceListOptions, isFetchingDeviceLists } = props;
  const blockedDeviceLists = getter(bidder, 'request_validation.blocked_device_lists', {});

  const filteredOptions = differenceWith(
    deviceListOptions,
    blockedDeviceLists,
    ({ value: listId }, otherListId) => listId === otherListId,
  );

  const onClick = (event, ownProps) => {
    addListItems(bidder, 'request_validation.blocked_device_lists', ownProps.value);
  };

  return (
    <Form.Dropdown
      disabled={isFetchingDeviceLists}
      fluid
      label="Blocked device lists"
      loading={isFetchingDeviceLists}
      onChange={onClick}
      options={filteredOptions}
      placeholder="Block device lists"
      search
      selection
    />
  );
};

export default BlockDeviceLists;

BlockDeviceLists.defaultProps = {
  addListItems: () => {},
  bidder: {},
  deviceListOptions: [],
  isFetchingDeviceLists: false,
};

BlockDeviceLists.propTypes = {
  addListItems: PropTypes.func,
  bidder: PropTypes.shape({}),
  deviceListOptions: PropTypes.arrayOf(PropTypes.object),
  isFetchingDeviceLists: PropTypes.bool,
};
