import * as status from '../../../shared/constants/readyStates';
import {
  UPDATE_STATUS,
  FETCH_APPS,
  SORT_APPS,
  SELECTED_APPS,
  SELECT_PLATFORM,
  RESET_APPS,
  SET_QUERY,
  IDS_ADDED_OR_REMOVED,
} from './actions';
import PLATFORM from '../../../domain/platforms';

import { rehydrate } from '../../../store/localStorage';

export const initState = {
  apps: [],
  status: status.UNLOADED,
  sortBy: null,
  sortDir: null,
  platform: 0,
  appsSelected: [],
  idsAddedOrRemoved: [],
  query: '',
};

const sortApps = (apps, sortBy) => {
  apps.sort((a, b) => {
    if (
      !Object.prototype.hasOwnProperty.call(a, sortBy)
      || !Object.prototype.hasOwnProperty.call(b, sortBy)
    ) {
      return 0;
    }

    if (typeof a[sortBy] === 'number') {
      return a[sortBy] - b[sortBy];
    }

    const valueA = (typeof a[sortBy] === 'string') ? a[sortBy].toUpperCase() : a[sortBy];
    const valueB = (typeof a[sortBy] === 'string') ? b[sortBy].toUpperCase() : b[sortBy];

    if (valueA > valueB) return 1;
    if (valueA < valueB) return -1;
    return 0;
  });

  return apps;
};

export const getAppsSelected = state => (
  state.apps.apps.filter(app => state.apps.appsSelected.includes(app.id))
);

const reducer = (state = rehydrate('apps', initState), action) => {
  switch (action.type) {
    case UPDATE_STATUS:
      return { ...state, status: action.status };

    case FETCH_APPS: {
      const { platform } = action;
      const sortBy = 'nickname';
      const apps = platform !== 0
        ? [...action.apps.filter(app => (
          app.platform.toLowerCase() === PLATFORM[platform].toLowerCase()
        ))]
        : [...action.apps];

      return {
        ...state,
        apps: sortApps(apps, sortBy),
        platform,
        sortBy,
        sortDir: 'ascending',
      };
    }

    case SORT_APPS: {
      const { apps, sortDir } = state;
      const { sortBy } = action;

      if (state.sortBy === sortBy) {
        return {
          ...state,
          apps: apps.slice().reverse(),
          sortDir: sortDir === 'ascending' ? 'descending' : 'ascending',
        };
      }

      apps.sort((a, b) => {
        if (
          !Object.prototype.hasOwnProperty.call(a, sortBy)
          || !Object.prototype.hasOwnProperty.call(b, sortBy)
        ) {
          return 0;
        }

        if (typeof a[sortBy] === 'number') {
          return a[sortBy] - b[sortBy];
        }

        const valueA = (typeof a[sortBy] === 'string') ? a[sortBy].toUpperCase() : a[sortBy];
        const valueB = (typeof a[sortBy] === 'string') ? b[sortBy].toUpperCase() : b[sortBy];
        if (typeof a[sortBy] === 'string') {
          return valueA.localeCompare(valueB);
        }

        if (valueA > valueB) return 1;
        if (valueA < valueB) return -1;
        return 0;
      });

      return {
        ...state,
        apps,
        sortBy,
        sortDir: 'ascending',
      };
    }

    case SELECTED_APPS:
      return { ...state, appsSelected: action.appsSelected };

    case IDS_ADDED_OR_REMOVED:
      return { ...state, idsAddedOrRemoved: action.idsAddedOrRemoved };

    case SELECT_PLATFORM:
      return { ...state, platform: action.platform };

    case SET_QUERY:
      return { ...state, query: action.query };

    case RESET_APPS:
      return { ...initState };
    default:
      return state;
  }
};

export default reducer;
