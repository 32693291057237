import React, { Component } from 'react';
import { Button, Container, Divider, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ExchangeMenu } from '../../shared/components';
import { fetchBidders } from '../../actions';
import { fetchPublisher, savePublisher, setPublisher, toggleBidder } from '../actions';
import { JsonPreview, PageHeader, PageLoader } from '../../../../shared/components';
import { PUBLISHER_MODE_OPTIONS } from '../../shared/constants';
import { PublisherEditForm } from '../components';
import {
  selectBidders,
  selectLoading,
  selectPublisher,
  selectPublisherErrors,
  selectPublisherHasErrors,
} from '../../selectors';
import { selectOnline } from '../../../../app/selectors';

class PublisherEdit extends Component {
  componentDidMount() {
    const { fetchBidders, fetchPublisher, loading, online } = this.props;
    if (online && !loading) {
      fetchBidders();
      fetchPublisher();
    }
  }

  render() {
    const {
      bidders,
      errors,
      hasErrors,
      loading,
      modes,
      publisher,
      savePublisher,
      setPublisher,
      toggleBidder,
    } = this.props;
    return (
      <Container className="PublisherEdit">
        <PageLoader isLoading={loading} />

        <ExchangeMenu />
        <Button.Group floated="right">
          <Button as={Link} secondary to="/exchange/publishers">Cancel</Button>
          <Button
            as={Link}
            disabled={hasErrors}
            primary
            onClick={savePublisher(publisher)}
            to="/exchange/publishers"
          >
            Save
          </Button>
        </Button.Group>

        <Divider />

        <PageHeader content="Publisher Settings" />
        <Grid columns="equal" stackable>
          <Grid.Column>
            <PublisherEditForm
              bidders={bidders}
              errors={errors}
              modes={modes}
              publisher={publisher}
              setPublisher={setPublisher}
              toggleBidder={toggleBidder}
            />
          </Grid.Column>
          <Grid.Column><JsonPreview toStringify={publisher} /></Grid.Column>
        </Grid>

      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match: { params: { id } } } = ownProps;
  return {
    bidders: selectBidders(state),
    campaignId: id,
    errors: selectPublisherErrors(state),
    hasErrors: selectPublisherHasErrors(state),
    loading: selectLoading(state),
    modes: PUBLISHER_MODE_OPTIONS,
    online: selectOnline(state),
    publisher: selectPublisher(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match: { params: { id } } } = ownProps;
  return {
    savePublisher: publisher => () => dispatch(savePublisher(publisher, id)),
    fetchPublisher: () => dispatch(fetchPublisher(id)),
    fetchBidders: () => dispatch(fetchBidders()),
    setPublisher: (e, input) => dispatch(setPublisher(input)),
    toggleBidder: id => () => dispatch(toggleBidder(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublisherEdit);
