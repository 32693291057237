import { combineReducers } from 'redux';

import bidders, * as fromBidders from '../bidders/reducers';
import creatives from '../creatives/reducers';
import globals from '../globals/reducer';
import publishers from '../publishers/reducer';
import isFetching, * as fromIsFetching from './isFetching';
import isSyncing, * as fromIsSyncing from './isSyncing';
import serverMessages, * as fromServerMessages from './serverMessages';

const exchangeApp = combineReducers({
  bidders,
  creatives,
  globals,
  isFetching,
  isSyncing,
  publishers,
  serverMessages,
});

export default exchangeApp;

export const getAppResultOptions = (state = {}) => fromBidders.getAppResultOptions(state.bidders);

export const getApps = (state = {}) => fromBidders.getApps(state.bidders) || {};

export const getBidder = (state = {}, bidderId = '') => (
  fromBidders.getBidder(state.bidders, bidderId) || {}
);

export const getBidders = (state = {}) => fromBidders.getBidders(state.bidders);

export const getBundleIds = (state = {}, bidderId = '') => (
  fromBidders.getBundleIds(state.bidders, bidderId)
);

export const getDeviceListOptions = state => fromBidders.getDeviceListOptions(state.bidders);

export const getDeviceLists = state => fromBidders.getDeviceLists(state.bidders);

export const getIsFetching = state => fromIsFetching.getIsFetching(state.isFetching);

export const getIsDeployingPubConfigs = state => (
  fromBidders.getIsDeployingPubConfigs(state.bidders)
);

export const getIsFetchingDeviceLists = state => (
  fromBidders.getIsFetchingDeviceLists(state.bidders)
);

export const getIsSearchingAppName = state => fromBidders.getIsSearchingAppName(state.bidders);

export const getIsSyncing = state => fromIsSyncing.getIsSyncing(state.isSyncing);

export const getSavedBidder = (state, bidderId) => (
  fromBidders.getSavedBidder(state.bidders, bidderId)
);

export const getSearchingBundleIds = state => fromBidders.getSearchingBundleIds(state.bidders);

export const getServerMessages = state => (
  fromServerMessages.getServerMessages(state.serverMessages)
);

export const getActiveIndex = state => fromBidders.getActiveIndex(state.exchange.bidders);
