/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Grid,
  Input,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import './styles/CreativeFinder.css';

const CREATIVE_ID_PARAMETER = 'creative_id';
const EXCHANGE_CREATIVE_PARAMETER = 'exchange_creative';

class CreativeFinder extends Component {
  constructor(props) {
    super(props);

    this.state = { creativeId: '' };

    this.setAndGetCreative = this.setAndGetCreative.bind(this);
    this.handleQueryParametersOnMount = this.handleQueryParametersOnMount.bind(this);
  }

  componentDidMount() {
    const { initCreativesPage } = this.props;
    // Clean store
    initCreativesPage();
    this.handleQueryParametersOnMount();
  }

  onKeyDown(event) {
    const { key } = event;
    if (key === 'Enter') {
      this.setAndGetCreative(this.state.creativeId);
    }
  }


  setAndGetCreative(creativeID, isExchangeCreative = false, onComponentMount = false) {
    if (creativeID) {
      const {
        getCreativeById,
        getExchangeCreativeById,
        history,
        location,
      } = this.props;
      const { creativeId } = this.state;
      const isAnExchangeCreative = this.props.isExchangeCreative || isExchangeCreative;

      if (!onComponentMount) {
        history.push(`${location.pathname}?${CREATIVE_ID_PARAMETER}=${creativeID}` +
          `${isAnExchangeCreative ? `&${EXCHANGE_CREATIVE_PARAMETER}` : ''}`);
      }

      if (creativeID !== creativeId) this.setState({ creativeId: creativeID });

      if (isAnExchangeCreative) {
        getExchangeCreativeById(creativeID);
      } else {
        getCreativeById(creativeID);
      }
    }
  }

  handleQueryParametersOnMount() {
    const { location, toggleExchangeCreative } = this.props;
    if (location.search) {
      const queryParameters = new URLSearchParams(location.search);
      const isExchangeCreative = queryParameters.has(EXCHANGE_CREATIVE_PARAMETER);
      if (isExchangeCreative) toggleExchangeCreative();
      this.setAndGetCreative(queryParameters.get(CREATIVE_ID_PARAMETER), isExchangeCreative, true);
    }
  }

  render() {
    const { creativeId } = this.state;
    const { isExchangeCreative, toggleExchangeCreative } = this.props;

    return (
      <Grid centered id="CreativeFinder">
        <Grid.Row columns={2} >
          <Grid.Column>
            <Input
              fluid
              id="creative_id"
              name="creativeId"
              placeholder="Creative ID..."
              onChange={(e, data) => {
                this.setState({ creativeId: data.value });
              }}
              onKeyDown={this.onKeyDown}
              value={creativeId}
            />
            <Checkbox
              checked={isExchangeCreative}
              label="Exchange creative"
              onChange={() => toggleExchangeCreative()}
              toggle
            />
          </Grid.Column>
          <Grid.Column >
            <Button
              color="blue"
              content="Go"
              onClick={() => this.setAndGetCreative(this.state.creativeId)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

CreativeFinder.defaultProps = {
  location: { search: '' },
  history: { push: () => {} },
};

CreativeFinder.propTypes = {
  getCreativeById: PropTypes.func.isRequired,
  getExchangeCreativeById: PropTypes.func.isRequired,
  initCreativesPage: PropTypes.func.isRequired,
  isExchangeCreative: PropTypes.bool.isRequired,
  location: PropTypes.shape({}),
  history: PropTypes.shape({ push: PropTypes.func }),
  toggleExchangeCreative: PropTypes.func.isRequired,
};

export default withRouter(CreativeFinder);
