import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

const CBModal = ({ open, title, message, handleClickConfirm, handleClickCancel }) => (
  <Modal open={open}>
    <Modal.Header>{title}</Modal.Header>
    <Modal.Content>
      {message}
    </Modal.Content>
    <Modal.Actions>
      <Button
        content="Confirm"
        icon="checkmark"
        labelPosition="right"
        onClick={handleClickConfirm}
        positive
      />
      <Button color="grey" onClick={handleClickCancel}>
        Cancel
      </Button>
    </Modal.Actions>
  </Modal>
);

CBModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  handleClickConfirm: PropTypes.func.isRequired,
  handleClickCancel: PropTypes.func.isRequired,
};

export default CBModal;
