import * as fromBidders from '../bidders/actions';
import * as fromCreatives from '../creatives/actions';
import * as fromGlobals from '../globals/actions';
import * as fromPublishers from '../publishers/actions';


const isFetching = (state = false, action = {}) => {
  switch (action.type) {
    case fromBidders.FETCH_BIDDER:
    case fromBidders.FETCH_BIDDERS:
    case fromCreatives.FETCH_CREATIVE:
    case fromCreatives.FETCH_RECENT_CREATIVES:
    case fromCreatives.FETCH_TEMPLATE:
    case fromGlobals.FETCH_GLOBALS:
    case fromPublishers.FETCH_CAMPAIGNS:
      return true;

    case fromBidders.FETCH_BIDDER_FAILURE:
    case fromBidders.FETCH_BIDDER_SUCCESS:
    case fromBidders.FETCH_BIDDERS_FAILURE:
    case fromBidders.FETCH_BIDDERS_SUCCESS:
    case fromCreatives.FETCH_CREATIVE_FAILURE:
    case fromCreatives.FETCH_CREATIVE_SUCCESS:
    case fromCreatives.FETCH_RECENT_CREATIVES_FAILURE:
    case fromCreatives.FETCH_RECENT_CREATIVES_SUCCESS:
    case fromCreatives.FETCH_TEMPLATE_FAILURE:
    case fromCreatives.FETCH_TEMPLATE_SUCCESS:
    case fromGlobals.FETCH_GLOBALS_FAILURE:
    case fromGlobals.FETCH_GLOBALS_SUCCESS:
    case fromPublishers.FETCH_CAMPAIGNS_FAILURE:
    case fromPublishers.FETCH_CAMPAIGNS_SUCCESS:
      return false;

    default:
      return state;
  }
};

export default isFetching;

export const getIsFetching = state => state;
