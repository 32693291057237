import dashapi from './dashapi';

export async function getTrackingStatus(appID) {
  try {
    const { response: trackingResponse } = await dashapi.get(`apps/admin/search?query=${appID}`);
    return trackingResponse;
  } catch (error) {
    throw error;
  }
}

export async function requestS2STracking(appID) {
  try {
    const { response: trackingResponse } = await dashapi.post(`tracking/request/${appID}`);
    return trackingResponse;
  } catch (error) {
    throw error;
  }
}
