import { LOADED, LOADING } from '../../shared/constants/readyStates';
import {
  INIT_CREATIVES_PAGE,
  RETRIEVED_CREATIVE,
  ERROR_RETRIEVING_CREATIVE,
  INVALID_CREATIVE_ID,
  NOT_VIDEO_CREATIVE,
  RETRIEVED_CREATIVE_CAMPAIGNS,
  ERROR_FETCHING_CREATIVE_CAMPAIGNS,
  EMPTY_CREATIVE_CAMPAIGNS,
  ERROR_RETRIEVING_TEMPLATE,
  EMPTY_CREATIVE_TEMPLATE,
  RETRIEVED_CREATIVE_TEMPLATE,
  TOGGLE_EXCHANGE_CREATIVE,
} from './actions';

const initState = {
  creative: null,
  creative_campaigns: [],
  campaigns_loaded: false,
  creative_template: '',
  creative_template_name: '',
  creative_template_s3: '',
  is_exchange_creative: false,
  loading: true,
  template_loaded: false,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case INIT_CREATIVES_PAGE: return initState;

    case LOADING:
      return {
        ...state,
        loading: true,
        campaigns_loaded: false,
        creative_campaigns: [],
        creative_template: '',
        template_loaded: false,
      };

    case LOADED:
      return { ...state, loading: false };

    case RETRIEVED_CREATIVE:
      return {
        ...state,
        creative: action.creative,
      };

    case INVALID_CREATIVE_ID:
      return {
        ...state,
        creative: null,
      };

    case ERROR_RETRIEVING_CREATIVE:
      return {
        ...state,
        creative: null,
      };

    case NOT_VIDEO_CREATIVE:
      return {
        ...state,
        creative: action.creative,
      };

    case RETRIEVED_CREATIVE_CAMPAIGNS:
      return {
        ...state,
        creative_campaigns: action.creative_campaigns,
        campaigns_loaded: true,
      };

    case ERROR_FETCHING_CREATIVE_CAMPAIGNS:
    case EMPTY_CREATIVE_CAMPAIGNS:
      return {
        ...state,
        creative_campaigns: [],
        campaigns_loaded: true,
      };

    case RETRIEVED_CREATIVE_TEMPLATE:
      return {
        ...state,
        creative_template: action.creative_template,
        creative_template_name: action.creative_template_name,
        creative_template_s3: action.creative_template_s3,
        template_loaded: true,
      };

    case ERROR_RETRIEVING_TEMPLATE:
    case EMPTY_CREATIVE_TEMPLATE:
      return {
        ...state,
        creative_template: '',
        template_loaded: true,
      };

    case TOGGLE_EXCHANGE_CREATIVE:
      return {
        ...state,
        creative: null,
        is_exchange_creative: !state.is_exchange_creative,
      };

    default:
      return state;
  }
};

export default reducer;
