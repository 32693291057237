import PropTypes from 'prop-types';
import React from 'react';
import setter from 'lodash/set';
import { Checkbox } from 'semantic-ui-react';

const FormulaOption = (props) => {
  const {
    bidder,
    bidder: {
      id,
      price_formula: { formula } = {},
    },
    errors,
    formulaKey = '',
    setBidder,
  } = props;

  const onChange = (event, ownProps) => {
    const nextProps = Object.assign({}, bidder);
    const { name: path, value } = ownProps;
    setter(nextProps, path, value);
    setBidder(id, nextProps);
  };

  return (
    <Checkbox
      checked={formula === formulaKey}
      disabled={!!errors.price_formula}
      name="price_formula.formula"
      onChange={onChange}
      radio
      value={formulaKey}
    />
  );
};

export default FormulaOption;

FormulaOption.defaultProps = {
  bidder: {},
  errors: {},
  formulaKey: '',
  setBidder: () => {},
};

FormulaOption.propTypes = {
  bidder: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  formulaKey: PropTypes.string,
  setBidder: PropTypes.func,
};
