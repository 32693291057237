export const MaxAdomains = 1000;
export const MaxAdomainsLength = 255;

export default class AdomainList {
  static isAdomainValid(adomain) {
    return adomain.match(/^[^.]+.*(?:\.(.){1,})+[^.]$/) && adomain.length <= MaxAdomainsLength;
  }

  static isAdomainValidToAdd(adomainList, adomain, skipMaxList) {
    if (!AdomainList.isAdomainValid(adomain)) {
      throw new Error('Wrong format of adomain');
    }

    if (adomainList.includes(adomain)) {
      throw new Error('Adomain already blocked');
    }

    if (adomainList.length >= MaxAdomains && !skipMaxList) {
      throw new Error(`You can only block ${MaxAdomains} adomains!`);
    }
    return true;
  }

  static isAdomainListValid(adomainList, skipMaxList) {
    const validAppIds = AdomainList.addAdomainToList(adomainList, [], true, skipMaxList);
    return validAppIds.length === adomainList.length && validAppIds.length > 0;
  }


  static addAdomainToList(adomainsToAdd, currentList = [], validate = true, skipMaxList) {
    const adomainList = [...currentList];
    adomainsToAdd.forEach((adomain) => {
      if ((!validate
        && !adomainList.includes(adomain))
      ) {
        adomainList.push(adomain);
      } else {
        try {
          const isValid = AdomainList.isAdomainValidToAdd(adomainList, adomain, skipMaxList);
          if (isValid) {
            adomainList.push(adomain);
          }
        } catch (error) {
          // is not valid
        }
      }
    });
    return adomainList;
  }
}
