import getter from 'lodash/get';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Input } from 'semantic-ui-react';

const ConnectionLabel = (props) => {
  const { bidder, connection, path } = props;
  const checked = includes(getter(bidder, path, []), connection.id);

  return (
    <Input
      readOnly
      transparent
      value={connection.text}
    >
      <Icon name={checked ? 'checkmark' : 'square outline'} size="big" link />
      <Icon name={connection.icon} size="big" link />
      <input />
    </Input>
  );
};

export default ConnectionLabel;

ConnectionLabel.defaultProps = {
  bidder: {},
  connection: {},
  path: '',
};

ConnectionLabel.propTypes = {
  bidder: PropTypes.shape({}),
  connection: PropTypes.shape({}),
  path: PropTypes.string,
};
