import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';

import { formatEndpoint } from '../helpers';

class Endpoint extends React.Component {
  constructor(props) {
    super(props);
    const { bidder: { [props.endpointKey]: endpoint = {} } } = props;
    this.state = { endpoint: formatEndpoint(endpoint) };
  }

  componentWillReceiveProps(nextProps) {
    const { bidder: { [this.props.endpointKey]: endpoint } } = this.props;
    const { bidder: { [nextProps.endpointKey]: nextEndpoint = {} } } = nextProps;
    if (endpoint !== nextEndpoint) {
      // {hostname: "chartboost.aarki.net", path: "/rtb/bid", port: 80, protocol: "http:"}
      this.setState({ endpoint: formatEndpoint(nextEndpoint) });
    }
  }

  debouncedSetEndpoint = debounce((endpoint, errors) => {
    const { bidder, endpointKey, setEndpoint } = this.props;
    setEndpoint(bidder, endpointKey, endpoint, errors);
  }, 1000);

  render() {
    const { bidder, label, endpointKey, errors } = this.props;

    const onChange = (event, ownProps) => {
      const { value: endpointValue } = ownProps;
      const { target: { validationMessage = '' } = {} } = event;
      this.setState({ endpoint: endpointValue });
      this.debouncedSetEndpoint(endpointValue, { [endpointKey]: validationMessage });
    };

    const labels = endpointKey.includes(bidder.mode)
      ? { label: { tag: true, content: 'Required' }, labelPosition: 'right', required: true }
      : {};

    return (
      <Form.Field>
        <label htmlFor={`endpoint-${endpointKey}`}>{label}</label>
        <Input
          {...labels}
          autoComplete="on"
          id={`endpoint-${endpointKey}`}
          placeholder="http://subdomain.bidder.com/path/to/endpoint?filter=lasers"
          onChange={onChange}
          type="url"
          value={this.state.endpoint}
        />
        {errors[endpointKey] ? <Label basic color="red" pointing>{errors[endpointKey]}</Label> : null}
      </Form.Field>
    );
  }
}

export default Endpoint;

Endpoint.defaultProps = {
  bidder: {},
  endpointKey: '',
  errors: {},
  label: 'Endpoint',
  setEndpoint: () => {},
};

Endpoint.propTypes = {
  bidder: PropTypes.shape({}),
  endpointKey: PropTypes.string,
  errors: PropTypes.shape({}),
  label: PropTypes.string,
  setEndpoint: PropTypes.func,
};
