import React from 'react';
import { Progress } from 'semantic-ui-react';

const ProgressBar = ({amount, credit_limit}) => {
  const evaluateProgress = (amount, creditLimit) => {
    if (!creditLimit) {
      return 'N/A';
    }
    const creditLeftPercent = 100 - (Math.abs(amount) / creditLimit * 100);
    if (creditLeftPercent < 10) {
      return ['urgent', creditLeftPercent];
    }
    if (creditLeftPercent < 25) {
      return ['warning', creditLeftPercent];
    }
    return ['normal', creditLeftPercent];
  };
  const progress = evaluateProgress(amount, credit_limit);
  const progressLabel = Number(progress[1]).toFixed(1) + "% left!";
  switch (progress[0]) {
    case 'urgent':
      return <Progress percent={progress[1]} size='small' color='red' label={progressLabel}></Progress>;
    case 'warning':
      return <Progress percent={progress[1]} size='tiny' color='orange'></Progress>;
    default:
      return <Progress percent={progress[1]} size='tiny' color='green'></Progress>;
  }
};

export default ProgressBar;
