import { DASH_API } from '../../../shared/api';
import { xhr } from '../../../shared/helpers';

export const FETCH_GLOBALS = 'GLOBALS.FETCH';
export const FETCH_GLOBALS_SUCCESS = 'GLOBALS.FETCH.SUCCESS';
export const FETCH_GLOBALS_FAILURE = 'GLOBALS.FETCH.FAILURE';

export const RESET_GLOBALS = 'GLOBALS.RESET';

export const SAVE_GLOBALS = 'GLOBALS.SAVE';
export const SAVE_GLOBALS_SUCCESS = 'GLOBALS.SAVE.SUCCESS';
export const SAVE_GLOBALS_FAILURE = 'GLOBALS.SAVE.FAILURE';

export const SET_GLOBALS = 'GLOBALS.SET';

export const fetchGlobals = () => async (dispatch) => {
  dispatch({ type: FETCH_GLOBALS });
  try {
    const { response } = await xhr(`${DASH_API}/exchange/global_settings`);
    dispatch({ type: FETCH_GLOBALS_SUCCESS, response });
  } catch ({ message }) {
    dispatch({
      type: FETCH_GLOBALS_FAILURE,
      error: message,
      message: ['fetch-failure', 'global settings'],
    });
  }
};

export const resetGlobals = () => ({ type: RESET_GLOBALS });

export const saveGlobals = globals => async (dispatch) => {
  try {
    const { response } = await xhr(`${DASH_API}/exchange/global_settings`, globals, { method: 'PUT' });
    dispatch({ type: SAVE_GLOBALS_SUCCESS, response, message: ['save-success', 'global settings'] });
  } catch ({ message }) {
    dispatch({
      type: SAVE_GLOBALS_FAILURE,
      error: message,
      message: ['save-failure', 'global settings'],
    });
  }
};

export const setGlobals = (event, { name, value }) => ({ type: SET_GLOBALS, name, value });
