import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const ExchangeMenu = () => (
  <Menu inverted secondary stackable compact>
    <Menu.Item as={NavLink} to="/exchange/bidders">Bidders</Menu.Item>
    <Menu.Item as={NavLink} to="/exchange/publishers">Publishers</Menu.Item>
    <Menu.Item as={NavLink} to="/exchange/global">Global</Menu.Item>
    <Menu.Item as={NavLink} to="/exchange/creatives">Creative Validation</Menu.Item>
  </Menu>
);

export default ExchangeMenu;
