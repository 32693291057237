import PropTypes from 'prop-types';
import React from 'react';
import { List, Grid, Icon } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PLATFORM_STORE } from '../constants';
import { getAppType } from '../utils';

const PublishingAppCardStoreDetailsLinks = (props) => {
  const { app } = props;
  const appType = getAppType(app);
  const storeUrl = app.market_url || app.itunes_url;

  return (
    <Grid.Column width={8}>
      <h2>Store details</h2>
      <List divided relaxed>
        <List.Item>
          <List.Icon name={PLATFORM_STORE[appType]} size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>
              Store URL
              {
                !isEmpty(storeUrl) && (
                  <CopyToClipboard text={storeUrl}>
                    <Icon name="copy outline" className="pointer" />
                  </CopyToClipboard>
                )
              }
            </List.Header>
            <List.Description>{storeUrl || '-'}</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="id badge outline" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>App store App ID</List.Header>
            <List.Description>{app.appstore_app_id || '-'}</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="send" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>Bundle ID</List.Header>
            <List.Description>{app.appstore_bundleId || '-'}</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="itunes" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>Itunes</List.Header>
            <List.Description>{app.itunes || '-'}</List.Description>
          </List.Content>
        </List.Item>
      </List>
    </Grid.Column>
  );
};

PublishingAppCardStoreDetailsLinks.defaultProps = {};

PublishingAppCardStoreDetailsLinks.propTypes = { app: PropTypes.shape({}).isRequired };

export default PublishingAppCardStoreDetailsLinks;
