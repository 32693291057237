import keyBy from 'lodash/keyBy';

import { FETCH_DEVICE_LISTS_SUCCESS } from '../actions';

const deviceLists = (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_DEVICE_LISTS_SUCCESS: {
      return Object.assign(
        {}, state,
        keyBy(action.response, 'id'),
      );
    }

    default:
      return state;
  }
};

export default deviceLists;
