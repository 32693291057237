import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import setter from 'lodash/set';
import { Input, Label } from 'semantic-ui-react';

import { decimalToInt } from '../../shared/helpers';

class FormulaInput extends React.Component {
  constructor(props) {
    super(props);
    const { bidder: { price_formula: priceFormula = {} } } = props;
    this.state = { priceFormula };
  }

  componentWillReceiveProps(nextProps) {
    const { bidder: { price_formula: priceFormula } } = this.props;
    const { bidder: { price_formula: nextPriceFormula } } = nextProps;
    if (!isEqual(priceFormula, nextPriceFormula)) {
      this.setState({ priceFormula: nextPriceFormula });
    }
  }

  debouncedSetBidder = debounce(this.props.setBidder, 500);

  render() {
    const {
      bidder,
      errors,
      formulaKey = '',
      label,
      max,
      placeholder,
    } = this.props;
    const { price_formula: { [formulaKey]: formulaError } = {} } = errors;
    const { priceFormula: { [formulaKey]: amount } } = this.state;
    const maxProp = max ? { max } : {};

    const onChange = (event, ownProps) => {
      const { name: path, value } = ownProps;
      const { target: { validationMessage = '' } = {} } = event;
      const amountFloat = parseFloat(value) || 0.0;
      const nextPriceFormula = setter(pick(bidder, 'price_formula'), path, amountFloat / 100.0);
      const formulaErrors = setter({}, path, validationMessage);

      this.setState({ priceFormula: nextPriceFormula.price_formula });
      this.debouncedSetBidder(bidder.id, nextPriceFormula, formulaErrors);
    };

    return (
      <div>
        <Input
          {...maxProp}
          autoComplete="on"
          className="number"
          error={!isEmpty(formulaError)}
          inputMode="numeric"
          labelPosition="right"
          min="0"
          name={`price_formula.${formulaKey}`}
          onChange={onChange}
          placeholder={placeholder}
          type="number"
          value={decimalToInt(amount)}
        >
          <input />
          <Label>{label}</Label>
        </Input>
        {formulaError ? <Label basic color="red" pointing>{formulaError}</Label> : null}
      </div>
    );
  }
}

export default FormulaInput;

FormulaInput.defaultProps = {
  bidder: {},
  errors: {},
  formulaKey: '',
  label: '',
  max: 0,
  placeholder: '',
  setBidder: () => {},
};

FormulaInput.propTypes = {
  bidder: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  formulaKey: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.number,
  placeholder: PropTypes.string,
  setBidder: PropTypes.func,
};
