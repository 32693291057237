import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Dropdown, Form } from 'semantic-ui-react';

class MultiSelector extends Component {
  constructor(props) {
    super(props);
    this.getOptions = this.getOptions.bind(this);
    this.handelClickSelected = this.handelClickSelected.bind(this);
    this.handelClick = this.handelClick.bind(this);
  }

  getSelected(name, options, currentValue) {
    let selectedOptions = [];
    if (currentValue.length > 0 && options.length > 0) {
      selectedOptions = currentValue
        .map((item) => {
          const opt = options.find(el => el.value === item) || {};
          if (isEmpty(opt)) {
            opt.text = item;
            opt.value = item;
          }
          return {
            text: ` ${opt.text}`,
            value: `${opt.value}-disabled`,
            icon: 'check',
            onClick: () => this.handelClickSelected(name, opt.value, currentValue),
          };
        });
    }
    return selectedOptions;
  }

  getOptions(name, options, currentValue) {
    const { selectAll } = this.props;
    const selectedOptions = this.getSelected(name, options, currentValue);

    let list = options.concat(selectedOptions)
      .sort((a, b) => {
        const aText = a.text.toLowerCase();
        const bText = b.text.toLowerCase();

        if (aText > bText) {
          return 1;
        }
        if (aText < bText) {
          return -1;
        }
        return 0;
      });

    if (selectAll) {
      if (currentValue.length === options.length) {
        list = [{ key: 'none', value: 'none', text: 'Unselect all' }, ...list];
      } else {
        list = [{ key: 'all', value: 'all', text: 'Select All' }, ...list];
      }
    }
    return list;
  }

  handelClickSelected(name, value, state) {
    const newOptions = {
      name,
      value: state.filter(option => option !== value),
    };
    const { onChange } = this.props;
    onChange(newOptions);
  }

  handelClick(event, data) {
    const { name, options } = this.props;
    const optionsSelected = {
      name,
      value: data.value,
    };

    if (data.value.includes('all')) {
      optionsSelected.value = options.map(item => item.value);
    } else if (data.value.includes('none')) {
      optionsSelected.value = [];
    }
    this.props.onChange(optionsSelected);
  }

  render() {
    const {
      value,
      placeholder,
      label,
      name,
      options,
      disabled,
      required,
    } = this.props;
    const deviceSegmentsList = this.getOptions(name, options, value);

    return (
      <Form.Field>
        <b className={required ? 'required-label' : ''}>{label}</b>
        <Dropdown
          placeholder={placeholder}
          renderLabel={() => false}
          onChange={this.handelClick}
          name={name}
          value={value}
          options={deviceSegmentsList}
          text={value.length > 0 ? `${value.length} selected` : 'Select...'}
          fluid
          multiple
          search
          selection
          closeOnBlur
          disabled={disabled}
        />
      </Form.Field>
    );
  }
}
MultiSelector.defaultProps = {
  label: '',
  disabled: false,
  selectAll: false,
  required: false,
};

MultiSelector.propTypes = {
  value: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  selectAll: PropTypes.bool,
  required: PropTypes.bool,
};

export default MultiSelector;
