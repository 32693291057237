/* eslint-disable no-underscore-dangle */
import { createSelector } from 'reselect';
import { yieldState, isLoading, selectConfig } from '../shared/helpers';

// Flexbids

const selectFlexbids = createSelector(yieldState, state => state.flexbids);
const selectFlexbidsReadyState = createSelector(selectFlexbids, flexbids => flexbids.readyState);
export const selectAllFlexbids = createSelector(
  selectFlexbids,
  flexbids => flexbids.data || [],
);
export const selectIsLoadingFlexbids = createSelector(selectFlexbidsReadyState, isLoading);
export const selectFlexbid = id => createSelector(
  selectAllFlexbids,
  flexbids => flexbids.find(flexbid => flexbid._id === id) || {},
);
export const selectFlexbidsConfig = createSelector(selectFlexbids, flexbids =>
  selectConfig(flexbids));
export const selectShowDeleted = createSelector(selectFlexbids, flexbids => flexbids.showDeleted);
