import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Loader, Checkbox, Button, Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import SearchInput from '../components/form/SearchInput';
import TableContainer from '../components/table/TableContainer';
import { retrieveFeatures, deleteFeatureFlags, retrieveFlags } from '../store/users/actions';
import '../styles/FeatureFlag.css';
import Users from '../components/Users';

class FeatureFlagOverview extends Component {
  static formatDate(date) { return new Date(date).toLocaleString(); }

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      sort_dir: null,
      sort_by: null,
      query: '',
      feature: '',
      featuresToDelete: [],
    };
    this.MaxRow = 11;
    this.columns = [
      { value: (<Checkbox />), id: 'delete', sort: false },
      { value: 'Company', id: 'company', sort: true },
      { value: 'Id', id: 'company_id', sort: true },
      { value: 'Feature', id: 'feature', sort: true },
      { value: 'Created', id: 'date_created', sort: true },
      { value: 'Added By', id: 'added_by', sort: true },
    ];

    this.ascending = 'asc';
    this.descending = 'desc';
  }

  componentDidMount() {
    const { getFlags, flags } = this.props;
    this.fetchFeatures();

    if (isEmpty(flags)) {
      getFlags();
    }
  }

  getFlagsOptionList() {
    const { flags } = this.props;
    const featureFlags = flags.map(flag => (
      { key: flag, value: flag, text: flag }
    ));
    featureFlags.unshift({ key: 'All', value: 'All', text: 'All' });
    return featureFlags;
  }

  getDeleteOptions() {
    const { featuresToDelete } = this.state;
    let actionButton;
    if (featuresToDelete.length > 0) {
      actionButton = {
        basic: true,
        content: `Delete ${featuresToDelete.length} Flag${featuresToDelete.length > 1 ? 's' : ''}`,
        floated: 'right',
        icon: 'trash alternate outline',
        labelPosition: 'left',
        className: 'delete',
        onClick: () => this.handleDeleteFlags(),
        primary: true,
      };
    }
    return actionButton;
  }

  fetchFeatures() {
    const { getFeatures } = this.props;
    const { activePage, sort_by: column, sort_dir: direction, query, feature } = this.state;
    const opt = {
      limit: this.MaxRow,
      page: activePage,
    };

    if (!isEmpty(column)) {
      opt.sort_by = column === 'id' ? '_id' : column;
      opt.sort_dir = direction;
    }

    if (!isEmpty(query)) {
      opt.query = query;
    }

    if (!isEmpty(feature)) {
      opt.feature = feature;
    }

    getFeatures(opt);
  }

  fetchFeaturesWhenEmpty() {
    const { features } = this.props;
    if (features.length < 1) {
      this.fetchFeatures();
    }
  }

  clearToDelete() {
    const { featuresToDelete } = this.state;
    const { features } = this.props;
    const toDelete = featuresToDelete.filter(featureId => (
      features.some(f => (
        f._id === featureId
      ))
    ));
    this.setState({ featuresToDelete: toDelete }, this.fetchFeaturesWhenEmpty);
  }

  showNextPage(data) {
    this.setState({ activePage: data.activePage }, this.fetchFeatures);
  }

  handleSelectFeature(data) {
    let { featuresToDelete } = this.state;

    if (featuresToDelete.includes(data.value)) {
      featuresToDelete = featuresToDelete.filter(value => value !== data.value);
    } else {
      featuresToDelete.push(data.value);
    }

    this.setState({ featuresToDelete });
  }

  selectAll(data) {
    const { features } = this.props;
    let featuresToDelete = [];
    if (data.value) {
      featuresToDelete = features.map(feature => feature._id);
    }

    this.setState({ featuresToDelete });
  }

  formatFeaturesForTable() {
    const { features } = this.props;
    const { featuresToDelete } = this.state;

    const data = features.map((feature) => {
      const toDelete = featuresToDelete.includes(feature._id) ? 1 : 0;

      return {
        id: feature._id,
        row: {
          delete: (<Checkbox checked={toDelete} name="delete" value={feature._id} onChange={(e, value) => this.handleSelectFeature(value)} />),
          company: feature.company_name ? feature.company_name : feature.user_name,
          company_id: feature.user,
          feature: feature.name,
          created: FeatureFlagOverview.formatDate(feature.date_created * 1000),
          added_by: feature.admin_user,
        },
      };
    });
    return data;
  }

  selectFeatureNameFilteringOption(e, { value }) {
    if (value === 'All') {
      this.setState({ feature: '' });
    } else {
      this.setState({ feature: value });
    }
    this.handleSearch();
  }

  updateQueryState(query) {
    this.setState({ query });
  }

  handleSearch() {
    this.setState({ activePage: 1 }, this.fetchFeatures);
  }

  handleSort(clickedColumn) {
    const { sort_by: column } = this.state;
    let { sort_dir: direction } = this.state;

    if (column !== clickedColumn || direction !== this.ascending) {
      direction = this.ascending;
    } else {
      direction = this.descending;
    }

    this.setState({
      column: clickedColumn,
      direction,
    });
    this.setState({
      sort_by: clickedColumn,
      sort_dir: direction,
      activePage: 1,
    }, this.fetchFeatures);
  }

  async handleDeleteFlags() {
    const { deleteFeatureFlags: deleteFlags } = this.props;
    const { featuresToDelete } = this.state;
    await deleteFlags(featuresToDelete);
    this.clearToDelete();
  }

  render() {
    const { loading, total, location } = this.props;
    const { sort_by: column, sort_dir: direction, featuresToDelete } = this.state;
    const { activePage, query } = this.state;
    const selectAll = this.MaxRow === featuresToDelete.length;
    this.columns[0].value = (<Checkbox checked={selectAll} value={!selectAll ? 1 : 0} name="delete" onChange={(e, data) => this.selectAll(data)} />);
    const actionButton = this.getDeleteOptions();

    return (
      <Users location={location}>
        <div className="featureFlags">
          {loading ?
            <Loader active /> :
            <div>
              <div className="filters">
                <SearchInput
                  query={query}
                  updateQueryState={value => this.updateQueryState(value)}
                  searchFunc={() => this.handleSearch()}
                  placeholder="Search by Company ID or Company Name..."
                />
                <div className="filters__left">
                  <Dropdown
                    placeholder="Filter by Feature Flag"
                    search
                    selection
                    defaultValue="All"
                    value={this.state.feature}
                    options={this.getFlagsOptionList()}
                    onChange={(e, value) => this.selectFeatureNameFilteringOption(e, value)}
                    className="featureFlagSelector"
                  />
                  <Button
                    as={Link}
                    content="Add Flag"
                    floated="right"
                    icon="plus"
                    labelPosition="right"
                    primary
                    to="new"
                  />
                </div>
              </div>
              <p className="warningFusionFlagMessage">Do not edit (add or remove) Fusion feature flags: alpha, beta, gamma! If you need to edit Fusion flag, first contact Product Team (Sunayana & Cela).</p>
              <TableContainer
                columns={this.columns}
                data={this.formatFeaturesForTable()}
                handelClickOption={() => { }}
                emptyState="No Feature Flags"
                tableOptions={{ stackable: true, striped: true, selectable: true, sortable: true }}
                activePage={activePage}
                maxRow={this.MaxRow}
                showNextPage={(e, data) => this.showNextPage(data)}
                dataLength={total}
                handleSort={clickedColumn => this.handleSort(clickedColumn)}
                column={column}
                direction={direction === this.ascending ? 'ascending' : 'descending'}
                deleteAction={this.handleDeleteFlags}
                actionButton={actionButton}
              />
            </div>}
        </div>
      </Users>
    );
  }
}

FeatureFlagOverview.defaultProps = {
  features: [],
  flags: [],
  location: {
    pathname: '/',
    hash: '',
  },
};

FeatureFlagOverview.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape({})),
  flags: PropTypes.arrayOf(PropTypes.string),
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  getFeatures: PropTypes.func.isRequired,
  getFlags: PropTypes.func.isRequired,
  deleteFeatureFlags: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hash: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  features: state.users.features.data,
  flags: state.users.flags,
  total: state.users.features.total,
  loading: state.users.loading,
});

const mapDispatchToProps = dispatch => ({
  getFeatures: opt => dispatch(retrieveFeatures(opt)),
  getFlags: () => dispatch(retrieveFlags()),
  deleteFeatureFlags: id => dispatch(deleteFeatureFlags(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureFlagOverview);
