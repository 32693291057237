import React from 'react';
import { Segment } from 'semantic-ui-react';

const DemandAggregatorNoData = () => {
  return (
    <Segment>
      <h3 style={{ textAlign: 'center' }}> Sorry, theres no data to show :( </h3>
    </Segment>
  );
};

export default DemandAggregatorNoData;
