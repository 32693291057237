import keyBy from 'lodash/keyBy';

export const AMAZON_PLATFORM = { id: 3, icon: 'amazon', key: 'amazon', name: 'Amazon' };
export const ANDROID_PLATFORM = { id: 2, icon: 'android', key: 'android', name: 'Android' };
export const IOS_PLATFORM = { id: 1, icon: 'apple', key: 'ios', name: 'iOS' };
export const UNKNOWN_PLATFORM = { id: 0, icon: 'question', key: 'unknown', name: 'Unknown' };

export const PLATFORMS = [AMAZON_PLATFORM, ANDROID_PLATFORM, IOS_PLATFORM, UNKNOWN_PLATFORM];
export const PLATFORMS_BY_ID = keyBy(PLATFORMS, 'id');
export const PLATFORMS_BY_KEY = keyBy(PLATFORMS, 'key');
