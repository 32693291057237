/**
 * @namespace Core/Actions
 */

import Utils from './utils';

const success = type => `${type}.SUCCESS`;
const failure = type => `${type}.FAILURE`;
const started = type => type;

/**
 * @function createMessages
 * @description creates the success and failure
 * messages for an async action
 * @returns {function} - dispatchable action
 * @memberof Core/Actions
 */

const createMessages = (verb, noun) => {
  if (verb.indexOf('-failure') > 0) {
    return { failure: [verb, noun] };
  }
  return {
    success: [`${verb}-success`, noun],
    failure: [`${verb}-failure`, noun],
  };
};

/**
 * @function createAsyncAction
 * @description creates an async action that when dispatched it first
 * notifies reducers that the async event has started, then triggers an
 * API request , then dispatches a success or failure event
 * @returns {function} - dispatchable action
 * @memberof Core/Actions
 */

const createAsyncAction = (type, args, fn, message) => (...values) => async (dispatch) => {
  let error = false;
  let payload = {};
  const meta = {};
  if (message && message.length === 2) {
    meta.messages = createMessages(message[0], message[1]);
  }
  // dispatch initial event to notify reducers of a starting async request
  const initialPayload = Utils.toPayload(values, args);
  dispatch({ type, ...initialPayload });
  try {
    // calls the api function
    const response = await fn(...values);
    payload = { ...initialPayload, response };
  } catch (e) {
    error = true;
    dispatch({ type: failure(type), payload: e.message, error, meta });
  }
  if (!error) {
    dispatch({ type: success(type), payload, meta });
  }
};

/**
 * @function createSimpleAction
 * @description creates a simple dispatchable action
 * @memberof Core/Actions
 */

const createSimpleAction = (type, args) => (...values) => ({
  type,
  payload: Utils.toPayload(values, args),
});

/**
 * @function create
 * @param object options - options describing action
 * @description  creates a dispatchable action
 * if options.awaits is supplied it creates an asynchronous action that
 * triggers the awaitable call, otherwise it creates a simple action
 * @returns {function} - dispatchable action
 * @memberof Core/Actions
 */

const create = (options = {}) => {
  let action;
  const { type, args, awaits, message } = options;

  if (!type) throw Error('action type is missing');

  if (!awaits) {
    action = createSimpleAction(type, args);
  } else {
    action = createAsyncAction(type, args, awaits, message);
  }
  return action;
};


const Actions = {
  create,
  success,
  failure,
  started,
};

export default Actions;
