import PropTypes from 'prop-types';
import React from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';

const PlayableValidationRow = (props) => {
  const { playable, selectPlayable } = props;
  const { account_owners } = playable;
  let rowStatus = '';
  let btnValidationText = '';
  let btnValidationColor = '';

  switch (playable.validation_status) {
    case 'WAITING_FOR_APPROVAL':
      rowStatus = 'warning';
      btnValidationText = 'Waiting for approval';
      btnValidationColor = 'yellow';
      break;
    case 'APPROVED':
      rowStatus = 'positive';
      btnValidationText = 'Approved';
      btnValidationColor = 'green';
      break;
    case 'REJECTED':
      rowStatus = 'negative';
      btnValidationText = 'Rejected';
      btnValidationColor = 'red';
      break;
    default:
      rowStatus = '';
      btnValidationText = 'Waiting for approval';
      btnValidationColor = 'yellow';
      break;
  }

  let platformIcon;
  let platformIconColor;

  switch (playable.platform_id) {
    case 1:
      platformIcon = 'apple';
      platformIconColor = 'grey';
      break;
    case 2:
      platformIcon = 'android';
      platformIconColor = 'green';

      if (playable.platform_name === 'amazon') {
        platformIcon = 'amazon';
        platformIconColor = 'yellow';
      }
      break;
    case 3:
      platformIcon = 'amazon';
      platformIconColor = 'yellow';
      break;
    default:
      platformIcon = '';
      platformIconColor = '';
      break;
  }
  const formatDate = date => new Date(date * 1000).toLocaleString();

  return (
    <Table.Row className={rowStatus}>
      <Table.Cell title={formatDate(playable.date_created)}>
        <b>{formatDate(playable.date_created)}</b>
      </Table.Cell>
      <Table.Cell title={playable.company_name}>{playable.company_name}</Table.Cell>
      <Table.Cell title={playable.company_id}>{playable.company_id}</Table.Cell>
      <Table.Cell title={playable.creative_id}>{playable.creative_id}</Table.Cell>
      <Table.Cell title={playable.creative_name}>{playable.creative_name}</Table.Cell>
      <Table.Cell title={playable.app_id}>{playable.app_id}</Table.Cell>
      <Table.Cell textAlign="center">
        {playable.is_mraid_end_card
          ? 'Yes'
          : 'No'
        }
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Button
          basic
          color={btnValidationColor}
          onClick={() => selectPlayable(playable.creative_id)}
        >
          {btnValidationText}
        </Button>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <a
          href={`https://creatives.chartboost.com/playable-preview/?creative_id=${playable.creative_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon size="large" color="black" name="play" />
        </a>
      </Table.Cell>
      <Table.Cell>
        <Icon size="large" color={platformIconColor} name={platformIcon} />
      </Table.Cell>
      <Table.Cell>
        {account_owners.am !== '-' ? account_owners.am : account_owners.ae}
      </Table.Cell>
      <Table.Cell>
        <a
          href={playable.adm}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon size="large" color="black" name="file code outline" />
        </a>
      </Table.Cell>
    </Table.Row>
  );
};

PlayableValidationRow.defaultProps = {};

PlayableValidationRow.propTypes = {
  playable: PropTypes.shape({}).isRequired,
  selectPlayable: PropTypes.func.isRequired,
};

export default PlayableValidationRow;
