import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';
import setter from 'lodash/set';
import { Button, Form, Input } from 'semantic-ui-react';
import { splitIds } from '../../../../shared/helpers';

class WhitelistedDeviceIds extends React.Component {
  debouncedSetBidder = debounce(this.props.setBidder, 500);

  render() {
    let allowedDevices;
    const { bidder } = this.props;
    const { request_validation, id } = bidder;

    const onChange = (event) => {
      let idArray, nextBidder, nextProps, path;
      nextBidder = Object.assign({}, bidder);
      idArray = splitIds(event.target.value);
      path = 'request_validation.allowed_devices';
      nextProps = setter(nextBidder, path, idArray);
      this.debouncedSetBidder(id, nextProps);
    };

    const removeAllButtonClick = () => {
      const idArray = [];
      const nextBidder = Object.assign({}, bidder);
      const path = 'request_validation.allowed_devices';
      const nextProps = setter(nextBidder, path, idArray);
      this.debouncedSetBidder(id, nextProps);
    }
    allowedDevices = request_validation ? request_validation.allowed_devices : null;
    const renderIds = (allowedDevices) => {
      const htmlArray = [];
      if (allowedDevices) {
        htmlArray.push(<h3>Currently whitelisting</h3>);
        allowedDevices.forEach((id) => {
          htmlArray.push(
            <p key={id} className="whitelisted_device"> {id} </p>,
          );
        });
      }
      return (
        <div id="currently_whitelisting_section">
          {htmlArray}
        </div>
      );
    };

    return (
      <div>
        {renderIds(allowedDevices)}
        <br />
        <Form.Field>
          <label htmlFor="device-ids-to-add">Note: any device ids here means all ad requests from other devices will be invalid!</label>
          <Input
            id="device-ids-to-add"
            onChange={onChange}
            placeholder="Comma-separated list of IDFAs or GAIDs to whitelist"
          />
        </Form.Field>
        <br />
        {
          allowedDevices ?
            <Form.Field>
              <Button onClick={removeAllButtonClick}>Remove All Devices From Whitelist</Button>
            </Form.Field>
          : null
        }
      </div>
    );
  }
}

export default WhitelistedDeviceIds;

WhitelistedDeviceIds.defaultProps = {
  bidder: {},
  path: '',
  setBidder: () => {},
};

WhitelistedDeviceIds.propTypes = {
  bidder: PropTypes.shape({}),
  path: PropTypes.string,
  setBidder: PropTypes.func,
};
