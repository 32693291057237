import debounce from 'lodash/debounce';
import getter from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import setter from 'lodash/set';
import toPath from 'lodash/toPath';
import { Input, Label } from 'semantic-ui-react';

import { BIDDER_DEFAULTS } from '../constants';
import { decimalToInt } from '../../shared/helpers';

class ThrottlingOption extends React.Component {
  constructor(props) {
    super(props);

    const { path } = props;

    const defaultThrottling = getter(BIDDER_DEFAULTS, path);
    const throttlingValue = getter(props.bidder, path, defaultThrottling);
    this.state = { throttlingValue };
  }

  onChange = (event, ownProps) => {
    const { value: throttlingValue } = ownProps;
    const { target: { validationMessage = '' } } = event;
    const { bidder, path } = this.props;

    const throttlingErrors = isEmpty(validationMessage)
      ? {}
      : setter({}, path, validationMessage);

    const nextAttrs = pick(bidder, head(toPath(path)));
    const parsedThrottlingValue = parseFloat(throttlingValue) / 100.0 || 0.0;
    setter(nextAttrs, path, parsedThrottlingValue);

    this.setState({ throttlingValue: parsedThrottlingValue });
    this.debouncedSetBidder(bidder.id, nextAttrs, throttlingErrors);
  };

  debouncedSetBidder = debounce(this.props.setBidder, 500);

  render() {
    const { errors, path, platform, bidder } = this.props;
    const { icon } = platform;
    const throttlingValue = getter(bidder, path, '');
    const throttlingError = getter(errors, path, '');

    return (
      <div>
        <Input
          autoComplete
          className="number"
          icon={icon}
          iconPosition="left"
          label={{ content: '%' }}
          labelPosition="right"
          max={100}
          min={0}
          onChange={this.onChange}
          type="number"
          value={decimalToInt(throttlingValue)}
        />
        {throttlingError ? <Label basic color="red" pointing>{throttlingError}</Label> : null}
      </div>
    );
  }
}

export default ThrottlingOption;

ThrottlingOption.defaultProps = {
  bidder: {},
  errors: {},
  path: '',
  platform: {},
  setBidder: () => {},
};

ThrottlingOption.propTypes = {
  bidder: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  path: PropTypes.string,
  platform: PropTypes.shape({}),
  setBidder: PropTypes.func,
};
