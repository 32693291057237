import { SET_CREATIVE_FILTERS } from '../actions';

const defaultState = {
  creativeId: '',
  bidderId: '',
  status: 'open',
};

const filters = (state = defaultState, action = {}) => {
  switch (action.type) {
    case SET_CREATIVE_FILTERS:
      return { ...state, ...action.creativeFilters };
    default:
      return state;
  }
};

export default filters;
