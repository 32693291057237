import dashapi from './dashapi';

export const getCompany = async (id) => {
  try {
    const { response } = await dashapi.get(`companies/${id}`);
    return response;
  } catch (error) {
    return {
      icon: false,
      icons: false,
      id,
      name: id,
      platforms: [],
      rank: '-',
    };
  }
};

export const getCompanies = async (query) => {
  try {
    const { response } = await dashapi.get(`admin/companies?${query}`);
    return response;
  } catch (error) {
    return [];
  }
};

export const getAdminCompanies = async () => {
  try {
    const { response } = await dashapi.get('admin/companies/admins');
    return response;
  } catch (error) {
    return [];
  }
};
