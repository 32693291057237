import keyBy from 'lodash/keyBy';

import {
  SEARCH_APP_ID_SUCCESS,
  SEARCH_APP_NAME_SUCCESS,
  SEARCH_BUNDLE_ID_SUCCESS,
} from '../actions';

const apps = (state = {}, action = {}) => {
  switch (action.type) {
    case SEARCH_APP_ID_SUCCESS:
    case SEARCH_APP_NAME_SUCCESS:
      return Object.assign({}, state, keyBy(action.response, 'bundle_id'));

    case SEARCH_BUNDLE_ID_SUCCESS:
      return Object.assign(
        {}, state,
        {
          [action.bundleId]: {
            ...action.response,
            bundle_id: action.bundleId,
            platform: action.platformId,
          },
        },
      );

    default:
      return state;
  }
};

export default apps;
