import querystring from 'querystring';

import { DASH_API } from '../../../shared/api';
import FusionAPI from '../../../services/fusionApi';
import { xhr } from '../../../shared/helpers';
import { selectCreativeFilters } from './../selectors';
import { TEMPLATE_NAMES } from './constants';

// Actions
export const FETCH_CREATIVE = 'CREATIVES.FETCH';
export const FETCH_CREATIVE_FAILURE = 'CREATIVES.FETCH_CREATIVE_FAILURE';
export const FETCH_CREATIVE_SUCCESS = 'CREATIVES.FETCH_CREATIVE_SUCCESS';
export const FETCH_RECENT_CREATIVES = 'CREATIVES.FETCH_RECENT_CREATIVES';
export const FETCH_RECENT_CREATIVES_SUCCESS = 'CREATIVES.FETCH_RECENT_CREATIVES_SUCCESS';
export const FETCH_RECENT_CREATIVES_FAILURE = 'CREATIVES.FETCH_RECENT_CREATIVES_FAILURE';

export const FETCH_TEMPLATE = 'CREATIVES.FETCH_TEMPLATE';
export const FETCH_TEMPLATE_FAILURE = 'CREATIVES.FETCH_TEMPLATE_FAILURE';
export const FETCH_TEMPLATE_SUCCESS = 'CREATIVES.FETCH_TEMPLATE_SUCCESS';
export const SET_PREVIEW_CREATIVE_ID = 'CREATIVES.SET_PREVIEW_CREATIVE_ID';
export const SET_CREATIVE_FILTERS = 'CREATIVES.SET_CREATIVE_FILTERS';
export const UPDATE_CREATIVE = 'CREATIVES.UPDATE_CREATIVE';
export const UPDATE_CREATIVE_FAILURE = 'CREATIVES.UPDATE_CREATIVE_FAILURE';
export const UPDATE_CREATIVE_SUCCESS = 'CREATIVES.UPDATE_CREATIVE_SUCCESS';

// Action Creators
/**
 * Selects a creative by ID to be previewed
 */
export const setPreviewCreative = creativeId => (dispatch) => {
  dispatch({ type: SET_PREVIEW_CREATIVE_ID, creativeId });
};

/**
 * Fetch recently shown creatives
 * @returns {Function} - Redux thunk that returns a promise
 */
export const fetchRecentCreatives = (params = {}) => async (dispatch) => {
  dispatch({ type: FETCH_RECENT_CREATIVES });

  try {
    const { creativeId = '', bidderId, status = '' } = params;
    const namedParams = { creative_id: creativeId, status };
    if (bidderId) { namedParams.bidder_id = bidderId; }

    const qs = querystring.stringify(namedParams);
    const { response } = await xhr(`${DASH_API}/exchange/creatives?${qs}`);
    dispatch({ type: FETCH_RECENT_CREATIVES_SUCCESS, creatives: response.creatives });
  } catch ({ message }) {
    dispatch({
      type: FETCH_RECENT_CREATIVES_FAILURE,
      error: message,
      message: ['fetch-failure', 'recent creatives'],
    });
  }
};

/**
 * Updates creative filters and uses the new filters to update the recent creatives
 * @param {Object} - creative filters to change
 */
export const setCreativeFilters = (creativeFilters = {}) => async (dispatch, getState) => {
  const allFilters = Object.assign(
    {},
    creativeFilters, (creativeFilters.bidderId === 'all' ? { bidderId: '' } : {}),
  );
  dispatch({ type: SET_CREATIVE_FILTERS, creativeFilters: allFilters });
  const params = selectCreativeFilters(getState());
  dispatch(fetchRecentCreatives(params));
};

/**
 * Fetches template based on template name and gets html doc from the base template
 * @param  None -[No params given]
 * @return {[AsyncFunc]}  -[Async Function that returns template htmls]
 */
export const fetchTemplate = () => async (dispatch) => {
  dispatch({ type: FETCH_TEMPLATE });

  try {
    const response = await FusionAPI.get('templates');

    [TEMPLATE_NAMES.VAST, TEMPLATE_NAMES.MRAID].forEach(async (templateName) => {
      const template = response.items.find(({ name }) => name === templateName);

      if (template) {
        const html = await xhr(template.url, null, { credentials: 'omit' });
        dispatch({ type: FETCH_TEMPLATE_SUCCESS, html, name: templateName });
      }
    });
  } catch ({ message }) {
    dispatch({
      type: FETCH_TEMPLATE_FAILURE,
      error: message,
      message: ['fetch-failure', 'template'],
    });
  }
};

/**
 * Updates an individual creative both in app state and on the server according to the
 * properties provided. Does not overwrite missing properties.
 * @param {Object} - Key-value pairs of values to update, including the creative's id
 * * @return {[AsyncFunc]}  - [Async Function]
 */
export const updateCreative = data => async (dispatch) => {
  dispatch({ type: UPDATE_CREATIVE });
  const { id, status } = data;
  const body = { status };
  const options = { method: 'PATCH' };
  try {
    const { response: creative } = await xhr(`${DASH_API}/exchange/creatives/${id}`, body, options);
    dispatch({
      type: UPDATE_CREATIVE_SUCCESS,
      data: creative,
      message: ['save-success', 'creative'],
    });
  } catch ({ message }) {
    dispatch({
      type: UPDATE_CREATIVE_FAILURE,
      error: message,
      message: ['save-failure', 'creative'],
    });
  }
};
