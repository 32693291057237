import { LOADED, LOADING } from '../../../shared/constants/readyStates';
import {
  RETRIEVED_FEATURES,
  FETCH_COMPANIES,
  DELETE_FEATURES,
  RETRIEVED_FLAGS,
  FETCH_ADMIN_COMPANIES,
  ADD_FLAG,
  RETRIEVED_COMPANIES,
  RETRIEVED_USERS,
} from './actions';
import { rehydrate } from '../../../store/localStorage';


const initState = {
  features: {
    data: [],
    total: 0,
    maxRow: 11,
    activePage: 1,
  },
  companies: {
    data: [],
    total: 0,
    maxRow: 50,
    activePage: 1,
  },
  adminCompanies: [],
  userPending: {
    users: [],
    loading: true,
  },
  flags: [],
  loading: true,
};

const reducer = (state = rehydrate('features', initState), action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };

    case LOADED:
      return { ...state, loading: false };

    case RETRIEVED_FEATURES: {
      const features = state.features;
      features.data = action.features;
      features.total = action.total;
      return { ...state, features };
    }

    case RETRIEVED_FLAGS:
      return { ...state, flags: [...action.flags] };

    case RETRIEVED_COMPANIES: {
      const companies = state.companies;
      companies.data = action.companies;
      companies.total = action.total;
      return { ...state, companies };
    }

    case FETCH_COMPANIES: {
      return { ...state, companies: [...action.companies] };
    }

    case FETCH_ADMIN_COMPANIES:
      return { ...state, adminCompanies: [...action.adminCompanies] };

    case DELETE_FEATURES: {
      const features = state.features;
      features.data = features.data.filter(feature => (
        !action.features.includes(feature._id)
      ));
      return { ...state, features };
    }

    case ADD_FLAG: {
      const features = state.features;
      features.data = [...state.features.data, action.features];
      return { ...state, features, total: features.length };
    }

    case RETRIEVED_USERS: {
      const userPending = state.userPending;
      userPending.users = action.users;
      return { ...state, userPending };
    }

    default:
      return state;
  }
};

export default reducer;

export const getCompany = (companies, id) => (
  companies.find(company => String(company._id) === String(id))
);
