const getStore = state => state.integrations.discrepancy;
const getData = state => getStore(state).data;
const getUniquePartners = state => getStore(state).uniquePartners;

const DiscrepancyStore = {
  getData,
  getUniquePartners
};

export default DiscrepancyStore;
