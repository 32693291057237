import { get, post } from '../../../shared/helpers';
import { DASH_API } from '../../../shared/api';
import { createAsyncAction, createAction, createMessages } from '../../../shared/actions';

export const FETCH_FLEXBIDS = 'YIELD.FLEXBIDS.FETCH';
export const FETCH_FLEXBID = 'YIELD.FLEXBID.FETCH';
export const ADD_FLEXBIDS = 'YIELD.FLEXBIDS.ADD';
export const SET_FLEXBIDS = 'YIELD.FLEXBIDS.SET';
export const RESET_FLEXBIDS = 'YIELD.FLEXBIDS.RESET';
export const SAVE_FLEXBIDS = 'YIELD.FLEXBIDS.SAVE';
export const PARSE_FLEXBID_TSV = 'YIELD.FLEXBIDS.PARSE_TSV';
export const TOGGLE_SHOW_DELETED = 'YIELD.FLEXBIDS.TOGGLE_SHOW_DELETED';

export const fetchFlexbids = createAsyncAction(
  FETCH_FLEXBIDS,
  [],
  async (showDeleted) => {
    const queryParams = new URLSearchParams();
    queryParams.append('show_deleted', !!showDeleted);
    const response = await get(`${DASH_API}/flexbids/all?${queryParams.toString()}`, null, { token: true });
    return response;
  },
  { messages: { failure: ['fetch-failure', 'flexbids'] } },
);

export const fetchFlexbid = createAsyncAction(
  FETCH_FLEXBID,
  [],
  async flexbidId => {
    const response = await get(`${DASH_API}/flexbids/${flexbidId}`, null, { token: true });
    return response;
  },
  { messages: { failure: ['fetch-failure', 'flexbid'] } },
);

export const addFlexbid = createAction(ADD_FLEXBIDS);

export const setFlexbid = createAction(
  SET_FLEXBIDS,
  'name',
  'value',
);

export const resetFlexbid = createAction(RESET_FLEXBIDS);

export const saveFlexbid = createAsyncAction(
  SAVE_FLEXBIDS,
  [],
  newFlexbidConfig => post(
    `${DASH_API}/flexbids`,
    newFlexbidConfig,
    { token: true },
  ),
  { messages: createMessages('save', 'flexbid') },
);

export const parseFlexbidTSV = createAction(PARSE_FLEXBID_TSV, 'files');

export const toggleShowDeleted = createAction(TOGGLE_SHOW_DELETED);
