import omit from 'lodash/omit';

import * as fromBidders from '../bidders/actions';
import * as fromExchange from '../shared/actions';
import * as fromGlobals from '../globals/actions';
import * as fromPublishers from '../publishers/actions';

import { getActionTitle, getErrorMessage } from '../shared/helpers';

const serverMessages = (state = {}, action = {}) => {
  switch (action.type) {
    case fromBidders.CREATE_BIDDER_SUCCESS:
      return omit(state, fromBidders.CREATE_BIDDER_FAILURE);

    case fromBidders.EXECUTE_DEPLOY_PUB_CONFIG_SUCCESS:
      return omit(state, fromBidders.EXECUTE_DEPLOY_PUB_CONFIG_FAILURE);

    case fromBidders.FETCH_BIDDER_SUCCESS:
      return omit(state, fromBidders.FETCH_BIDDER_FAILURE);

    case fromBidders.FETCH_BIDDERS_SUCCESS:
      return omit(state, fromBidders.FETCH_BIDDERS_FAILURE);

    case fromBidders.FETCH_DEVICE_LISTS_SUCCESS:
      return omit(state, fromBidders.FETCH_DEVICE_LISTS_FAILURE);

    case fromBidders.SEARCH_APP_ID_SUCCESS:
      return omit(state, fromBidders.SEARCH_APP_ID_FAILURE);

    case fromBidders.SEARCH_APP_NAME_SUCCESS:
      return omit(state, fromBidders.SEARCH_APP_NAME_FAILURE);

    case fromBidders.SEARCH_BUNDLE_ID_SUCCESS:
      return omit(state, fromBidders.SEARCH_BUNDLE_ID_FAILURE);

    case fromBidders.SYNC_BIDDER_SUCCESS:
      return omit(state, fromBidders.SYNC_BIDDER_FAILURE);

    case fromBidders.VALIDATE_BIDDER:
      return omit(state, fromExchange.SET_MESSAGE);

    case fromGlobals.SAVE_GLOBALS_SUCCESS:
      return omit(state, fromGlobals.SAVE_GLOBALS_FAILURE);

    case fromGlobals.FETCH_GLOBALS_SUCCESS:
      return omit(state, fromGlobals.FETCH_GLOBALS_FAILURE);

    case fromBidders.CREATE_BIDDER_FAILURE:
    case fromBidders.EXECUTE_DEPLOY_PUB_CONFIG_FAILURE:
    case fromBidders.FETCH_BIDDER_FAILURE:
    case fromBidders.FETCH_BIDDERS_FAILURE:
    case fromBidders.FETCH_DEVICE_LISTS_FAILURE:
    case fromBidders.SEARCH_APP_NAME_FAILURE:
    case fromBidders.SYNC_BIDDER_FAILURE:
    case fromGlobals.FETCH_GLOBALS_FAILURE:
    case fromGlobals.SAVE_GLOBALS_FAILURE:
    case fromPublishers.SAVE_PUBLISHER_FAILURE: {
      const { error = {} } = action;

      const ERROR_CODES = { 500: 'internal_error' };

      const errorCode = error.error_code || ERROR_CODES[error.code];
      const message = (errorCode && getErrorMessage(errorCode))
        || error.message
        || getErrorMessage('unknown_error');

      return Object.assign({}, state, {
        [action.type]: {
          errorCode,
          message,
          title: getActionTitle(action.type),
        },
      });
    }

    case fromExchange.SET_MESSAGE:
      return Object.assign({}, state, {
        [action.type]: {
          errorCode: action.errorCode,
          message: getErrorMessage(action.errorCode),
          title: getActionTitle(action.type),
        },
      });

    case fromExchange.REMOVE_MESSAGES:
    case fromPublishers.SAVE_PUBLISHER_SUCCESS:
      return {};

    default:
      return state;
  }
};

export default serverMessages;

export const getServerMessages = (state = {}) => state || {};
