import debounce from 'lodash/debounce';
import getter from 'lodash/get';
import head from 'lodash/head';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import setter from 'lodash/set';
import toPath from 'lodash/toPath';
import { Form, Input } from 'semantic-ui-react';
import { BIDDER_DEFAULTS } from '../constants';

class MaxBlockedApps extends React.Component {
  constructor(props) {
    super(props);

    const defaultMaxBlockedApps = getter(BIDDER_DEFAULTS, this.path);
    const maxBlockedApps = getter(props.bidder, this.path, defaultMaxBlockedApps);
    this.state = { maxBlockedApps };
  }

  onChange = (event, ownProps) => {
    const { value: maxBlockedApps } = ownProps;
    const { bidder } = this.props;

    const nextAttrs = pick(bidder, head(toPath(this.path)));
    const parsedMaxBlockedApps = parseInt(maxBlockedApps, 10) || 0;
    setter(nextAttrs, this.path, parsedMaxBlockedApps);

    this.setState({ maxBlockedApps: parsedMaxBlockedApps });
    this.debouncedSetBidder(bidder.id, nextAttrs);
  };

  debouncedSetBidder = debounce(this.props.setBidder, 500);

  path = 'request_processing.max_blocked_apps';

  render() {
    const { maxBlockedApps } = this.state;

    return (
      <Form.Field>
        <label htmlFor="bidder-max-blocked-apps">
          Maximum number of Publisher apps to send
        </label>
        <Input
          autoComplete
          className="number"
          id="bidder-max-blocked-apps"
          min={0}
          onChange={this.onChange}
          type="number"
          value={maxBlockedApps}
        />
        <em>{'This field is only used when behavior is "Limit"'}</em>
      </Form.Field>
    );
  }
}

export default MaxBlockedApps;

MaxBlockedApps.defaultProps = {
  bidder: {},
  setBidder: () => {},
};

MaxBlockedApps.propTypes = {
  bidder: PropTypes.shape({}),
  setBidder: PropTypes.func,
};
