import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'semantic-ui-react';

const PlatformButtons = ({ platform, setPlatform }) => (
  <Button.Group icon>
    <Button active={platform === 1} icon="apple" onClick={setPlatform(1)} />
    <Button active={platform === 2} icon="android" onClick={setPlatform(2)} />
    <Button active={platform === 3} icon="amazon" onClick={setPlatform(3)} />
  </Button.Group>
);

PlatformButtons.defaultProps = {
  platform: 1,
  setPlatform: () => {},
};

PlatformButtons.propTypes = {
  platform: PropTypes.number,
  setPlatform: PropTypes.func,
};

export default PlatformButtons;
