import dashapi from './dashapi';

export default async function getCountries() {
  try {
    const { response: countries } = await dashapi.get('countries');
    return countries;
  } catch (error) {
    throw error;
  }
}
