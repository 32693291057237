import * as fromBidders from '../actions';

const isSearchingAppName = (state = false, action = {}) => {
  switch (action.type) {
    case fromBidders.SEARCH_APP_NAME:
      return true;

    case fromBidders.SEARCH_APP_NAME_FAILURE:
    case fromBidders.SEARCH_APP_NAME_SUCCESS:
      return false;

    default:
      return state;
  }
};

export default isSearchingAppName;
