import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Divider, Input, Dropdown } from 'semantic-ui-react';
import { fetchTemplate, setCreativeFilters, setPreviewCreative } from '../actions';
import { fetchBidders } from '../../actions';
import { updateCreative } from '../actions';
import { ExchangeMenu } from '../../shared/components';
import { PageHeader, PageLoader } from '../../../../shared/components';
import { CreativePreviewSection, CreativeTable, StatusDropdown } from '../components';
import { selectCurrentTemplate, selectCreative, selectCreativeFilters, selectHasCreative, selectLoading,
  selectRecentCreatives, selectBidders, selectPreviewCreative, selectCreativeStatusFilter, selectCreativeIdFilter,
  selectCreativeBidderIdFilter } from '../../selectors';
import { STATUS_NAMES } from './../constants'

import './CreativeValidation.css';


class CreativeValidation extends Component {

  componentWillMount() {
    this.props.setCreativeFilters();
    this.props.fetchTemplate();
    this.props.fetchBidders();
  }

  closeModal = () => {
    this.props.setPreviewCreative();
    this.props.setCreativeFilters();
  }

  handlePreviewClicked = (creativeId) => {
    this.props.setPreviewCreative(creativeId);
  }

  getDropdownBidders = (rawBidders) => {
    const defaultBidders = [{ key: 'all', value: 'all', text: 'All Bidders' }];
    const formattedBidders = rawBidders.map(bidder => ({ key: bidder.id, value: bidder.user, text: bidder.name }));
    return defaultBidders.concat(formattedBidders);
  }

  render() {
    const { bidders, creativeBidderIdFilter, creativeIdFilter,
      creativeStatusFilter, creatives, currentTemplate, hasCreative, loading,
      previewCreative, setCreativeBidderFilter, setCreativeIdFilter,
      setCreativeStatusFilter, updateCreative } = this.props;

    const dropDownBidders = this.getDropdownBidders(bidders);

    return (
      <Container className="CreativeValidation">
        <PageLoader isLoading={loading} />

        <ExchangeMenu />
        <Divider />
        <PageHeader content="Creatives Validation" />

        <div className="CreativeValidation__Wrapper">
          {hasCreative &&
            <CreativePreviewSection
              creative={previewCreative}
              isOpen={hasCreative}
              loading={loading}
              onClose={this.closeModal}
              onSave={updateCreative}
              template={currentTemplate}
            />
          }
          <div className="CreativeValidation__Inputs">
            <Input
              className="CreativeValidation__Search"
              icon='search'
              value={creativeIdFilter}
              placeholder='Search by creative ID'
              onChange={setCreativeIdFilter}
            />
            <Dropdown
              className="CreativeValidation__Dropdown"
              onChange={setCreativeBidderFilter}
              options={dropDownBidders}
              value={creativeBidderIdFilter}
              placeholder='Select a Bidder'
              search
              selection
            />
            <StatusDropdown
              onChange={setCreativeStatusFilter}
              defaultValue={STATUS_NAMES[0]}
              status={creativeStatusFilter}
            />
            </div>
            <CreativeTable
              creatives={creatives}
              onPreviewClicked={this.handlePreviewClicked}
            />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  creative: selectCreative(state),
  filters: selectCreativeFilters(state),
  currentTemplate: selectCurrentTemplate(state),
  hasCreative: selectHasCreative(state),
  loading: selectLoading(state),
  creatives: selectRecentCreatives(state),
  previewCreative: selectPreviewCreative(state),
  bidders: selectBidders(state),
  creativeIdFilter: selectCreativeIdFilter(state),
  creativeStatusFilter: selectCreativeStatusFilter(state),
  creativeBidderIdFilter: selectCreativeBidderIdFilter(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTemplate: () => dispatch(fetchTemplate()),
  fetchBidders: () => dispatch(fetchBidders()),
  setCreativeFilters: () => dispatch(setCreativeFilters()),
  setPreviewCreative: creativeId => dispatch(setPreviewCreative(creativeId)),
  setCreativeIdFilter: (e, { value }) => dispatch(setCreativeFilters({ creativeId: value })),
  setCreativeBidderFilter: (e, { value }) => dispatch(setCreativeFilters({ bidderId: value })),
  setCreativeStatusFilter: (e, { value }) => dispatch(setCreativeFilters({ status: value })),
  updateCreative: data => dispatch(updateCreative(data),)
});

export default connect(mapStateToProps, mapDispatchToProps)(CreativeValidation);
