import * as fromBidders from '../actions';

const isDeployingPubConfigs = (state = false, action = {}) => {
  switch (action.type) {
    case fromBidders.EXECUTE_DEPLOY_PUB_CONFIG:
      return true;

    case fromBidders.EXECUTE_DEPLOY_PUB_CONFIG_SUCCESS:
    case fromBidders.EXECUTE_DEPLOY_PUB_CONFIG_FAILURE:
      return false;

    default:
      return state;
  }
};

export default isDeployingPubConfigs;
