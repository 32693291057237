import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dimmer, Loader, Segment, Table } from 'semantic-ui-react';

import TableRow from './TableRow';
import TableNoResults from './TableNoResults';
import PrivateMarketplacePagination from './PrivateMarketplacePagination';

import './styles/PrivateMarketplaceTable.css';

class OverviewTable extends Component {
  getPagination() {
    const { activePage, showNextPage, totalPages } = this.props;
    let paginationElement;

    if (totalPages) {
      paginationElement = (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="11" textAlign="center">
              <PrivateMarketplacePagination
                totalPages={totalPages}
                activePage={activePage}
                onPageChange={showNextPage}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      );
    }
    return paginationElement;
  }

  render() {
    const {
      rows,
      columns,
      noResultMessage,
      loading,
      attributes,
    } = this.props;

    return (
      <Table {...attributes}>
        <Table.Header>
          <Table.Row>
            {(
              columns.map(column => (
                <Table.HeaderCell
                  key={`${column.value}-header-cell`}
                  {...column.attr}
                >
                  {column.value}
                </Table.HeaderCell>
              ))
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows.length === 0 && !loading && (
            <TableNoResults message={noResultMessage} />
          )}
          {rows.length > 0 && !loading && (
            rows.map(row => (
              <TableRow row={row} key={`${row.key}-table-row`} />
            ))
          )}
          {loading && (
            <Table.Row>
              <Table.Cell colSpan={11} textAlign="center">
                <Segment basic>
                  <Dimmer active inverted>
                    <Loader />
                  </Dimmer>
                </Segment>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        {this.getPagination()}
      </Table>
    );
  }
}

OverviewTable.defaultProps = {
  totalPages: 0,
  activePage: 1,
  showNextPage: () => {},
  attributes: {},
  noResultMessage: 'No results found',
  loading: false,
};

OverviewTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalPages: PropTypes.number,
  activePage: PropTypes.number,
  showNextPage: PropTypes.func,
  attributes: PropTypes.shape({}),
  noResultMessage: PropTypes.string,
  loading: PropTypes.bool,
};

export default OverviewTable;
