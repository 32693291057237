import { DSP_API, INTEGRATIONS_API } from '../api';

export const DEFAULT_PAGE_SIZE = 50;

export const API_GATEWAY_SSP_SERVER_HEADER_NAME = 'X-ssp-server-authorization';

export const SSP_SERVICE_BASE_URLS = [
  DSP_API,
  process.env.REACT_APP_SSP_REPORTING_API,
  process.env.REACT_REACT_APP_SEGMENTS_API,
  INTEGRATIONS_API,
];
