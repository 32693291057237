import keyBy from 'lodash/keyBy';
import map from 'lodash/map';

export const CREATIVE_ATTRIBUTES = {
  audioAuto: { value: 1, text: 'Audio Ad (Auto-Play)' },
  audioUser: { value: 2, text: 'Audio Ad (User Initiated)' },
  expandAuto: { value: 3, text: 'Expandable (Automatic)' },
  expandUserClick: { value: 4, text: 'Expandable (User Initiated - Click)' },
  expandUserRollover: { value: 5, text: 'Expandable (User Initiated - Rollover)' },
  bannerVideoAuto: { value: 6, text: 'In-Banner Video Ad (Auto-Play)' },
  bannerVideoUser: { value: 7, text: 'In-Banner Video Ad (User Initiated)' },
  pop: { value: 8, text: 'Pop (e.g., Over, Under, or Upon Exit)' },
  provocative: { value: 9, text: 'Provocative or Suggestive Imagery' },
  extreme: { value: 10, text: 'Shaky, Flashing, Flickering, Extreme Animation, Smileys' },
  surveys: { value: 11, text: 'Surveys' },
  textOnly: { value: 12, text: 'Text Only' },
  userInteractive: { value: 13, text: 'User Interactive (e.g., Playable Games)' },
  windowsDialog: { value: 14, text: 'Windows Dialog or Alert Style' },
  audioButton: { value: 15, text: 'Has Audio On/Off Button' },
  skippable: { value: 16, text: 'Ad Can Be Skipped (e.g., Skip Button on Pre-Roll Video)' },
};

export const CREATIVE_ATTRIBUTE_OPTIONS = map(CREATIVE_ATTRIBUTES, attributes => attributes);

export const CREATIVE_ATTRIBUTES_BY_ID = keyBy(CREATIVE_ATTRIBUTES, 'value');
export default CREATIVE_ATTRIBUTES;
