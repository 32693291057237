import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { API_GATEWAY } from '../../../../shared/api';

const UserId = (props) => {
  const { user } = props;
  const userLink = (
    <a
      id="user-id"
      href={`${API_GATEWAY}/dashadmin/admin/login/${user}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      {user}
    </a>
  );

  const userInput = (
    <Input
      id="user-id"
      placeholder="Auto generated"
      readOnly
      transparent
      value={user || ''}
    />
  );

  const userComponent = user ? userLink : userInput;

  return (
    <Form.Field>
      <label htmlFor="user-id">User Id</label>
      {userComponent}
    </Form.Field>
  );
};

export default UserId;

UserId.defaultProps = {
  user: '',
};

UserId.propTypes = {
  user: PropTypes.string,
};
