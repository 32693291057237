import { isEqual, filter } from 'lodash';

const defaultEligibleBidderData = {
  name: '',
  banner: 0,
  mraid: 0,
  vast: 0,
  comment: '',
  new: true
};

const formatIncomingData = data => {
  const formatted_data = { ...data };
  Object.keys(formatted_data).forEach((key, index) => {
    formatted_data[key].debug_logging = !(formatted_data[key].logLevel === 'error');
    formatted_data[key].auction_sampling = formatted_data[key].auctionSummarySampling != null;
    formatted_data[key].new_dsp_name = '';
    formatted_data[key].ssp = key;
    formatted_data[key]['eligibleBidders'] = formatted_data[key]['eligibleBidders']
      ? formatted_data[key]['eligibleBidders']
      : [];
    const eligibleBidders = {};
    formatted_data[key]['eligibleBidders'].forEach((element, index) => {
      eligibleBidders[element.name] = element;
    });
    formatted_data[key]['eligibleBidders'] = eligibleBidders;
  });
  return formatted_data;
};

const formatPayload = (data, originalData) => {
  const payload = JSON.parse(JSON.stringify(data));

  // first get what changed (ssp level and dsp level)
  const changedData = filter(payload, function(sspInfo) {
    return !isEqual(sspInfo, originalData[sspInfo.ssp]);
  });

  // now loop through and filter the eligible bidders
  changedData.forEach((sspInfo, index) => {
    sspInfo['eligibleBidders'] = filter(sspInfo['eligibleBidders'], function(dspInfo) {
      return !isEqual(dspInfo, originalData[sspInfo.ssp]['eligibleBidders'][dspInfo.name]);
    });
  });

  // set empty mraid/vast/banner to 0
  changedData.forEach((sspInfo, index) => {
    sspInfo['eligibleBidders'].forEach((dsp, index) => {
      dsp['mraid'] = dsp['mraid'] === '' ? 0 : dsp['mraid'];
      dsp['vast'] = dsp['vast'] === '' ? 0 : dsp['vast'];
      dsp['banner'] = dsp['banner'] === '' ? 0 : dsp['banner'];
    });
  });

  console.log(Object.values(changedData));

  return {
    data: Object.values(changedData)
  };
};

const DemandAggregatorHelpers = {
  formatIncomingData,
  defaultEligibleBidderData,
  formatPayload
};

export default DemandAggregatorHelpers;
