import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';

class Email extends React.Component {
  constructor(props) {
    super(props);
    const { bidder: { email = '' } } = props;
    this.state = { email };
  }

  componentWillReceiveProps(nextProps) {
    const { bidder: { email } } = this.props;
    const { bidder: { email: nextEmail } } = nextProps;
    if (email !== nextEmail) {
      this.setState({ email: nextEmail });
    }
  }

  debouncedSetBidder = debounce(this.props.setBidder, 500);

  render() {
    const { bidder: { id }, errors } = this.props;

    const onChange = (event, ownProps) => {
      const { target: { validationMessage = '' } = {} } = event;
      const { value: email } = ownProps;
      this.setState({ email });
      this.debouncedSetBidder(id, { email }, { email: validationMessage });
    };

    return (
      <Form.Field>
        <label htmlFor="bidder-email">Email (DO NOT MODIFY ONCE SET)</label>
        <Input
          autoComplete="email"
          error={!isEmpty(errors.email)}
          id="bidder-email"
          label={{ tag: true, content: 'Required' }}
          labelPosition="right"
          onChange={onChange}
          placeholder="bidder-acme@chartboost.com"
          required
          type="email"
          value={this.state.email}
        />
        {errors.email ? <Label basic color="red" pointing>{errors.email}</Label> : null}
      </Form.Field>
    );
  }
}

export default Email;

Email.defaultProps = {
  bidder: {},
  errors: {},
  setBidder: () => {},
};

Email.propTypes = {
  bidder: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  setBidder: PropTypes.func,
};
