import { LOADED, LOADING } from '../../../shared/constants/readyStates';
import { getTrackingStatus, requestS2STracking } from '../../../services/s2sTracking';

export const FETCH_STATUS = 'S2STRACKING.FETCH_STATUS';
export const FETCH_STATUS_FAILURE = 'S2STRACKING.FETCH_STATUS_FAILURE';
export const REQUEST_TRACKING_SUCCESS = 'S2STRACKING.REQUEST_TRACKING_SUCCESS';
export const REQUEST_TRACKING_FAILURE = 'S2STRACKING.REQUEST_TRACKING_FAILURE';

export const fetchStatus = appID => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const trackingResponse = await getTrackingStatus(appID);
    if (trackingResponse.length !== 0) {
      dispatch({
        type: FETCH_STATUS,
        trackingResponse: trackingResponse[0],
      });
      dispatch({ type: LOADED });
      return trackingResponse[0];
    }
    dispatch({
      type: FETCH_STATUS_FAILURE,
      message: ['s2s-tracking-fetch-failure', appID],
    });
    dispatch({ type: LOADED });
    return null;
  } catch ({ message }) {
    dispatch({
      type: FETCH_STATUS_FAILURE,
      message: ['s2s-tracking-fetch-failure', appID],
    });
    dispatch({ type: LOADED });
    return null;
  }
};

export const requestTracking = appID => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const trackingResponse = await requestS2STracking(appID);
    dispatch({
      type: REQUEST_TRACKING_SUCCESS,
      trackingResponse,
      message: ['save-success', 'S2STracking'],
    });
    dispatch({ type: LOADED });
  } catch ({ message }) {
    dispatch({
      type: REQUEST_TRACKING_FAILURE,
      error: message,
      message: ['s2s-tracking-request-failure'],
    });
    dispatch({ type: LOADED });
  }
};
