import React from 'react';
import { Form } from 'semantic-ui-react';

import { GlobalPriceInput } from '.';

const GlobalPriceValues = ({ errors, globals, setGlobals }) => (
  <Form.Group widths="equal">
    <GlobalPriceInput
      error={errors.price_formula && errors.price_formula.dollars}
      label="¢"
      name="price_formula.dollars"
      placeholder="Cents"
      setGlobals={setGlobals}
      value={globals.price_formula.dollars}
    />
    <GlobalPriceInput
      error={errors.price_formula && errors.price_formula.percent}
      label="%"
      max="100"
      name="price_formula.percent"
      placeholder="Percentage"
      setGlobals={setGlobals}
      value={globals.price_formula.percent}
    />
  </Form.Group>
);

export default GlobalPriceValues;
