import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Label } from 'semantic-ui-react';

const PublishingAppCardAppStatusRejectedButton = (props) => {
  const { status, reReviewApp } = props;
  return (
    <Button as="div" labelPosition="right">
      <Button basic color="red" style={{ flex: 'auto' }}>
        <Icon name="close" />
        {status}
      </Button>
      <Label color="green" pointing="left" onClick={reReviewApp}>
        <Icon name="reply" />
        Re-review
      </Label>
    </Button>
  );
};

PublishingAppCardAppStatusRejectedButton.defaultProps = {};
PublishingAppCardAppStatusRejectedButton.propTypes = {
  status: PropTypes.string.isRequired,
  reReviewApp: PropTypes.func.isRequired,
};

export default PublishingAppCardAppStatusRejectedButton;
