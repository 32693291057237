import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import './Navbar.css';

const Navbar = ({ bundles, toggleNavbar, visible }) => (
  <Sidebar
    animation="overlay"
    as={Menu}
    className="Navbar"
    icon="labeled"
    inverted
    onClick={toggleNavbar}
    vertical
    visible={visible}
    width="thin"
  >
    {
    bundles.map((item) => {
      if (item.externalLink) {
        return (
          <a
            key={item.path}
            href={item.path}
          >
            <Menu.Item name={item.path}><Icon name={item.icon} />{item.label}</Menu.Item>
          </a>
        );
      }

      return (
        <Link
          key={item.path}
          to={item.path}
        >
          <Menu.Item name={item.path}><Icon name={item.icon} />{item.label}</Menu.Item>
        </Link>
      );
    })
  }
  </Sidebar>
);

Navbar.defaultProps = {
  bundles: [],
  toggleNavbar: () => {},
  visible: false,
};

Navbar.propTypes = {
  bundles: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    path: PropTypes.string,
  })),
  toggleNavbar: PropTypes.func,
  visible: PropTypes.bool,
};

export default Navbar;
