import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Icon, List } from 'semantic-ui-react';

import { PLATFORMS_BY_KEY } from '../../shared/constants';

const BlockDeviceListsList = (props) => {
  const {
    bidder,
    bidder: { request_validation: { blocked_device_lists: blockedDeviceLists } = {} },
    deviceLists,
    removeListItems,
  } = props;

  const listItems = map(blockedDeviceLists, (deviceListId) => {
    const deviceList = deviceLists[deviceListId] || {};

    const onClick = (event, ownProps) => {
      removeListItems(bidder, 'request_validation.blocked_device_lists', ownProps.value);
    };

    return (
      <List.Item key={`device-${deviceListId}`}>
        <Button
          basic
          floated="left"
          icon
          onClick={onClick}
          type="button"
          value={deviceListId}
        >
          <Icon name="minus" />
        </Button>
        <List.Content floated="left">
          <Icon name={PLATFORMS_BY_KEY[deviceList.platform || 'unknown'].icon} />
        </List.Content>
        <List.Content floated="left">
          <List.Header>{deviceList.name}</List.Header>
          <List.Description>{`${deviceList.values_count} devices`}</List.Description>
        </List.Content>
      </List.Item>
    );
  });

  return (
    <List size="large" verticalAlign="middle">
      {listItems}
    </List>
  );
};

export default BlockDeviceListsList;

BlockDeviceListsList.defaultProps = {
  bidder: {},
  deviceLists: {},
  removeListItems: () => {},
};

BlockDeviceListsList.propTypes = {
  bidder: PropTypes.shape({}),
  deviceLists: PropTypes.shape({}),
  removeListItems: PropTypes.func,
};
