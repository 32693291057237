import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Segment, Grid } from 'semantic-ui-react';

// views
import { PageHeader } from '../../../../../src/shared/components';
import IntegrationsLayout from '../../shared/views/IntegrationsLayout';
import DiscrepancyTable from './DiscrepancyTable';
import DiscrepancyMenu from './DiscrepancyMenu';

// modules
import Discrepancy from '../';

class DiscrepancyOverview extends Component {
  componentDidMount() {
    this.props.setPartnerAndLoad('');
  }

  render() {
    const { data, partners } = this.props;

    const onPartnerChange = (a, b) => {
      this.props.setPartnerAndLoad(b.value);
    };

    return (
      <IntegrationsLayout>
        <PageHeader>Discrepancy Tool</PageHeader>
        <Segment>
          <h3 style={{ textAlign: 'center' }}>
            Datadog Graphs:
            <a href="https://app.datadoghq.com/dashboard/983-2nr-mca/integrations-partner-reporting?fullscreen_end_ts=1647029189418&fullscreen_paused=false&fullscreen_section=overview&fullscreen_start_ts=1639253189418&fullscreen_widget=7589214203793260&from_ts=1644609976088&to_ts=1647029176088&live=true">
              Partner Reporting
            </a>
          </h3>
        </Segment>
        <Grid>
          <Grid.Column width={4}>
            <DiscrepancyMenu onPartnerChange={onPartnerChange} partners={partners} />
          </Grid.Column>
          <Grid.Column width={12}>
            <DiscrepancyTable data={data} />
          </Grid.Column>
        </Grid>
      </IntegrationsLayout>
    );
  }
}

DiscrepancyOverview.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  setPartnerAndLoad: PropTypes.func
};

DiscrepancyOverview.defaultProps = {
  data: [],
  setPartnerAndLoad: null
};

const mapStateToProps = state => {
  const data = Discrepancy.Store.getData(state);
  const partners = Discrepancy.Store.getUniquePartners(state);
  return {
    data,
    partners
  };
};

const mapDispatchToProps = {
  setPartnerAndLoad: Discrepancy.Actions.setPartnerAndLoad
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscrepancyOverview);
