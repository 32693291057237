import { LOADED, LOADING } from '../../shared/constants/readyStates';
import { RETRIEVED_APPS, LOADING_API_REQUEST, LOADED_API_REQUEST, APPROVED_APP, REJECTED_APP, REREVIEW_APP, RETRIEVED_APPS_ANALYTICS } from './actions';
import { rehydrate } from '../../store/localStorage';

const initState = {
  apps: [],
  loading: true,
  apiValidation: {
    isLoading: false,
    appId: null,
  },
};

const updateAppPublishingState = (app, reviewUser) => (publishingApp) => {
  if (publishingApp.app._id === app._id) {
    return {
      ...publishingApp,
      app,
      review_user: reviewUser,
    };
  }

  return publishingApp;
};

const updateAppMetrics = metrics => (app) => {
  const appMetrics = metrics.reduce(
    (selectedMetrics, metric) => {
      if (metric.appId === app.app._id) {
        const appMetric = {
          date: metric.dt,
          uniques: metric.uniques,
        };
        return [
          ...selectedMetrics,
          appMetric,
        ];
      }

      return selectedMetrics;
    },
    [],
  );

  return {
    ...app,
    metrics: appMetrics,
  };
};

const reducer = (state = rehydrate('publishing_apps', initState), action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };

    case LOADED:
      return { ...state, loading: false };

    case RETRIEVED_APPS:
      return { ...state, apps: action.apps };

    case RETRIEVED_APPS_ANALYTICS:
      return { ...state, apps: state.apps.map(updateAppMetrics(action.metrics)) };

    case LOADING_API_REQUEST:
      return { ...state, apiValidation: { isLoading: true, appId: action.appId } };

    case LOADED_API_REQUEST:
      return { ...state, apiValidation: { isLoading: false, appId: action.appId } };

    case APPROVED_APP:
      return {
        ...state,
        apps: state.apps.map(updateAppPublishingState(action.app, action.review_user)),
      };

    case REJECTED_APP:
      return {
        ...state,
        apps: state.apps.map(updateAppPublishingState(action.app, action.review_user)),
      };

    case REREVIEW_APP:
      return {
        ...state,
        apps: state.apps.map(updateAppPublishingState(action.app, action.review_user)),
      };

    default:
      return state;
  }
};

export default reducer;
