import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'semantic-ui-react';

const TableRow = (props) => {
  const { row } = props;
  return (
    <Table.Row key={row.row.id}>
      {Object.values(row.row).map(value => (
        <Table.Cell>
          {value}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

TableRow.propTypes = { row: PropTypes.shape({}).isRequired };

export default TableRow;
