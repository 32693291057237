import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import setter from 'lodash/set';
import xor from 'lodash/xor';
import { Checkbox, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react';

import BannerResize from './BannerResize';
import { DEFAULT_BANNER_SIZES } from '../constants';

class BannerResizeSet extends React.Component {
  constructor(props) {
    super(props);

    const {
      bidder: {
        request_processing: {
          change_banner_size: changeBannerSize = {},
        } = {},
      },
    } = props;

    this.state = {
      linked: true,
      size: Object.assign({}, DEFAULT_BANNER_SIZES, changeBannerSize),
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      bidder: {
        request_processing: {
          change_banner_size: changeBannerSize = {},
        } = {},
      },
    } = this.props;

    const {
      bidder: {
        request_processing: {
          change_banner_size: nextChangeBannerSize = {},
        } = {},
      },
    } = nextProps;

    if (!isEqual(changeBannerSize, nextChangeBannerSize)) {
      this.setState({ size: Object.assign({}, DEFAULT_BANNER_SIZES, nextChangeBannerSize) });
    }
  }

  /**
   * Adds or removes banner sizes to the bidder object
   * @param {String} bannerKey - The Chartboost resolution. E.g., '1024x768'
   */
  setBannerKey = bannerKey => () => {
    const { bidder: {
      request_processing: { change_banner_size: changeBannerSize = {} } = {},
    } } = this.props;
    const { size } = this.state;

    const nextKeys = xor(Object.keys(changeBannerSize), [bannerKey]);
    const nextChangeBannerSize = pick(size, nextKeys);
    this.debouncedSetBidder(nextChangeBannerSize);
  };

  /**
   * Sets the size of the banners
   * @param {String} bannerKey - The Chartboost resolution. E.g., '1024x768'
   * @param {Number} axis - 0 for x-axis, 1 for y-axis
   * @param {Number} value - New size of banner for that axis
   * @param {Object} errors - Any errors from the HTML DOM
   */
  setBannerSize = (bannerKey, axis, value, errors) => {
    const { bidder: {
      request_processing: { change_banner_size: changeBannerSize = {} } = {},
    } } = this.props;
    const { size } = this.state;

    const nextSize = Object.assign({}, size);
    nextSize[bannerKey][axis] = Number(value);

    this.setState({ size: nextSize }, () => {
      const nextChangeBannerSize = pick(this.state.size, Object.keys(changeBannerSize));
      this.debouncedSetBidder(nextChangeBannerSize, errors);
    });
  };

  /**
   * Gets the JSX for a banner
   * @param {String} bannerKey - The Chartboost resolution. E.g., '1024x768'
   * @param {Boolean} linked - True, if the banner is linked to another banner
   * @returns {jsx} - Banner to render the JSX
   */
  getBannerSegment = (bannerKey) => {
    const { bidder: {
      request_processing: { change_banner_size: changeBannerSize = {} } = {},
    } } = this.props;

    return (
      <Segment key={`banner-${bannerKey}`}>
        <Grid centered columns={2} verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={2}>
              <Checkbox
                checked={!!changeBannerSize[bannerKey]}
                onChange={this.setBannerKey(bannerKey)}
                size="large"
              />
            </Grid.Column>
            <Grid.Column width={14}>
              <BannerResize
                {...this.props}
                bannerKey={bannerKey}
                setBannerSize={this.setBannerSize}
                size={this.state.size[bannerKey]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  };

  path = 'request_processing.change_banner_size';

  debouncedSetBidder = debounce((nextChangeBannerSize, errors = {}) => {
    const { bidder, setBidder } = this.props;
    const pickedAttrs = pick(bidder, 'request_processing', {});
    const nextAttrs = setter(pickedAttrs, this.path, nextChangeBannerSize);
    setBidder(bidder.id, nextAttrs, errors);
  }, 100);

  render() {
    return (
      <Form.Field>
        <Header>
          <Icon name="mobile" size="big" />
          <Header.Content>
            Phone
          </Header.Content>
        </Header>
        <Segment basic>
          {this.getBannerSegment('320x480')}
          {this.getBannerSegment('480x320')}
        </Segment>

        <Header>
          <Icon name="tablet" size="big" />
          <Header.Content>
            Tablet
          </Header.Content>
        </Header>
        <Segment basic>
          {this.getBannerSegment('768x1024')}
          {this.getBannerSegment('1024x768')}
        </Segment>
      </Form.Field>
    );
  }
}

export default BannerResizeSet;

BannerResizeSet.defaultProps = {
  bidder: {},
  setBidder: () => {},
};

BannerResizeSet.propTypes = {
  bidder: PropTypes.shape({}),
  setBidder: PropTypes.func,
};
