import { DSP_API } from '../shared/api';
import { xhr } from '../shared/helpers';

class DSPApi {
  static get(path) {
    return xhr(`${DSP_API}/${path}`, null, { token: true });
  }
}

export default DSPApi;
