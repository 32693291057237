import {
  FETCH_TEMPLATE_FAILURE,
  FETCH_TEMPLATE_SUCCESS,
} from '../actions';

const templates = (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_TEMPLATE_FAILURE:
      return {
        ...state,
        [action.name]: { html: '' },
      };

    case FETCH_TEMPLATE_SUCCESS:
      return {
        ...state,
        [action.name]: { html: action.html },
      };

    default:
      return state;
  }
};

export default templates;
