import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, List } from 'semantic-ui-react';
import AppIdList from '../../../domain/AppIdList';
import AdomainList from '../../../domain/AdomainList';
import WarningTooltip from './WarningTooltip';

class ItemToAdd extends Component {
  isValid() {
    const { id, action, platform, editionOption } = this.props;
    const validate = editionOption === 'block';
    if (!validate) return true;

    if (action === 'bundle') {
      return AppIdList.isAppIdValid(platform, id);
    }

    return AdomainList.isAdomainValid(id);
  }

  render() {
    const { id, action, removeItemToAdd } = this.props;

    return (
      <List.Item>
        <List.Content floated="right">
          <Button
            icon="minus"
            className="button__icon"
            onClick={() => removeItemToAdd(id)}
          />
        </List.Content>
        <List.Content className="added-id">
          { !this.isValid() && (
            <WarningTooltip message={`Wrong format of ${action}`} />
          )}
          {id}
        </List.Content>
      </List.Item>
    );
  }
}

ItemToAdd.defaultProps = { platform: null };

ItemToAdd.propTypes = {
  id: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  editionOption: PropTypes.string.isRequired,
  platform: PropTypes.string,
  removeItemToAdd: PropTypes.func.isRequired,
};


export default ItemToAdd;
