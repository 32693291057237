/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Divider } from 'semantic-ui-react';
import {
  getCreativeById,
  getExchangeCreativeById,
  getCreativeCampaigns,
  getCreativeTemplate,
  initCreativesPage,
  toggleExchangeCreative,
} from '../actions';

import CreativeFinder from '../components/CreativeFinder';
import CreativeCard from '../components/CreativeCard';
import ExchangeCreative from '../components/ExchangeCreative';

export const CreativesContainer = (props) => {
  const {
    creative,
    creativeCampaigns,
    creativeCampaignsLoaded,
    creativeTemplate,
    creativeTemplateLoaded,
    creativeTemplateName,
    creativeTemplateS3,
    getCreativeById,
    getCreativeCampaigns,
    getCreativeTemplate,
    getExchangeCreativeById,
    initCreativesPage,
    isExchangeCreative,
    toggleExchangeCreative,
  } = props;

  return (
    <Container>
      <CreativeFinder
        getCreativeById={getCreativeById}
        getExchangeCreativeById={getExchangeCreativeById}
        initCreativesPage={initCreativesPage}
        isExchangeCreative={isExchangeCreative}
        toggleExchangeCreative={toggleExchangeCreative}
      />
      <Divider />
      { creative &&
        <Fragment>
          { isExchangeCreative
            ? <ExchangeCreative
              creativeTemplate={creativeTemplate}
              creativeTemplateLoaded={creativeTemplateLoaded}
              creativeTemplateName={creativeTemplateName}
              creativeTemplateS3={creativeTemplateS3}
              exchangeCreative={creative}
              getCreativeTemplate={getCreativeTemplate}
            />
            : <CreativeCard
              creative={creative}
              creativeCampaigns={creativeCampaigns}
              creativeCampaignsLoaded={creativeCampaignsLoaded}
              creativeTemplate={creativeTemplate}
              creativeTemplateLoaded={creativeTemplateLoaded}
              creativeTemplateName={creativeTemplateName}
              creativeTemplateS3={creativeTemplateS3}
              getCreativeCampaigns={getCreativeCampaigns}
              getCreativeTemplate={getCreativeTemplate}
              isExchangeCreative={isExchangeCreative}
            />
          }
        </Fragment>
      }
    </Container>
  );
};

CreativesContainer.defaultProps = {
  creative: null,
  isExchangeCreative: false,
};

CreativesContainer.propTypes = {
  creative: PropTypes.oneOfType([
    PropTypes.PropTypes.shape({}),
    PropTypes.PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  creativeCampaigns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  creativeCampaignsLoaded: PropTypes.bool.isRequired,
  creativeTemplate: PropTypes.string.isRequired,
  creativeTemplateLoaded: PropTypes.bool.isRequired,
  creativeTemplateName: PropTypes.string.isRequired,
  creativeTemplateS3: PropTypes.string.isRequired,
  getCreativeById: PropTypes.func.isRequired,
  getCreativeCampaigns: PropTypes.func.isRequired,
  getCreativeTemplate: PropTypes.func.isRequired,
  getExchangeCreativeById: PropTypes.func.isRequired,
  initCreativesPage: PropTypes.func.isRequired,
  isExchangeCreative: PropTypes.bool,
  toggleExchangeCreative: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  creative: state.creatives.creative,
  creativeCampaigns: state.creatives.creative_campaigns,
  creativeCampaignsLoaded: state.creatives.campaigns_loaded,
  creativeTemplate: state.creatives.creative_template,
  creativeTemplateLoaded: state.creatives.template_loaded,
  creativeTemplateName: state.creatives.creative_template_name,
  creativeTemplateS3: state.creatives.creative_template_s3,
  isExchangeCreative: state.creatives.is_exchange_creative,
});

const mapDispatchToProps = dispatch => ({
  getCreativeById: creativeId => dispatch(getCreativeById(creativeId)),
  getExchangeCreativeById: creativeID => dispatch(getExchangeCreativeById(creativeID)),
  getCreativeCampaigns: creativeID => dispatch(getCreativeCampaigns(creativeID)),
  getCreativeTemplate: creativeType => dispatch(getCreativeTemplate(creativeType)),
  initCreativesPage: () => dispatch(initCreativesPage()),
  toggleExchangeCreative: () => dispatch(toggleExchangeCreative()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreativesContainer);
