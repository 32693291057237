import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Loader, Dropdown } from 'semantic-ui-react';
import { retrieveCompanies } from '../store/users/actions';
import TableContainer from '../components/table/TableContainer';
import SearchInput from '../components/form/SearchInput';
import '../styles/UserManagement.css';
import Users from '../components/Users';
import { API_GATEWAY } from '../../shared/api';

class UserManagement extends Component {
  static formatDate(date) { return new Date(date).toLocaleString(); }

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      sort_dir: null,
      sort_by: null,
      query: '',
    };
    this.MaxRow = 11;
    this.columns = [
      { value: 'Company', id: 'company', sort: true },
      { value: 'Name', id: 'name', sort: true },
      { value: 'Created', id: 'date_created', sort: true },
      { value: 'Action', id: 'action' },
    ];

    this.ascending = 'asc';
    this.descending = 'desc';
  }

  componentDidMount() {
    this.fetchCompanies();
  }

  fetchCompanies() {
    const { getCompanies } = this.props;
    const { activePage, sort_by: column, sort_dir: direction, query } = this.state;
    const opt = {
      limit: this.MaxRow,
      page: activePage,
    };

    if (!isEmpty(column)) {
      opt.sort_by = column === 'id' ? '_id' : column;
      opt.sort_dir = direction;
    }

    if (!isEmpty(query)) {
      opt.fields = 'company';
      opt.query = query;
    }

    getCompanies(opt);
  }

  showNextPage(data) {
    this.setState({ activePage: data.activePage }, this.fetchCompanies);
  }

  formatCompaniesForTable() {
    const { companies } = this.props;

    const data = companies.map(company => ({
      id: company._id,
      row: {
        company: company.company,
        email: company.first_name,
        created: UserManagement.formatDate(company.date_created * 1000),
        action: (
          <Dropdown icon="setting">
            <Dropdown.Menu>
              <Dropdown.Item>
                <a href={`${API_GATEWAY}/dashadmin/admin/users/${company._id}`} target="_blank" rel="noopener noreferrer">View</a>
              </Dropdown.Item>
              <Dropdown.Item>
                <a href={`${API_GATEWAY}/dashadmin/admin/login/${company._id}`} target="_blank" rel="noopener noreferrer">Login</a>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>),
      },
    }));
    return data;
  }

  updateQueryState(query) {
    this.setState({ query });
  }

  handleSearch() {
    this.setState({ activePage: 1 }, this.fetchCompanies);
  }

  handleSort(clickedColumn) {
    const { sort_by: column } = this.state;
    let { sort_dir: direction } = this.state;

    if (column !== clickedColumn || direction !== this.ascending) {
      direction = this.ascending;
    } else {
      direction = this.descending;
    }

    this.setState({
      column: clickedColumn,
      direction,
    });
    this.setState({
      sort_by: clickedColumn,
      sort_dir: direction,
      activePage: 1,
    }, this.fetchCompanies);
  }

  render() {
    const { loading, total, location } = this.props;
    const { sort_by: column, sort_dir: direction } = this.state;
    const { activePage, query } = this.state;

    return (
      <Users location={location}>
        <div className="userManagement">
          {loading ?
            <Loader active /> :
            <div>
              <div className="filters">
                <SearchInput
                  query={query}
                  updateQueryState={value => this.updateQueryState(value)}
                  searchFunc={() => this.handleSearch()}
                  placeholder="Search by Company name..."
                />
              </div>
              <TableContainer
                columns={this.columns}
                data={this.formatCompaniesForTable()}
                handelClickOption={() => { }}
                emptyState="No Companies found"
                tableOptions={{ stackable: true, striped: true, selectable: true, sortable: true }}
                activePage={activePage}
                maxRow={this.MaxRow}
                showNextPage={(e, data) => this.showNextPage(data)}
                dataLength={total}
                handleSort={value => this.handleSort(value)}
                column={column}
                direction={direction === this.ascending ? 'ascending' : 'descending'}
              />
            </div>
          }
        </div>
      </Users>
    );
  }
}

UserManagement.defaultProps = {
  companies: [],
  location: {
    pathname: '/',
    hash: '',
  },
};

UserManagement.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  total: PropTypes.number.isRequired,
  getCompanies: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hash: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  companies: state.users.companies.data,
  total: state.users.companies.total,
  loading: state.users.loading,
});

const mapDispathToProps = dispatch => ({ getCompanies: opt => dispatch(retrieveCompanies(opt)) });

export default connect(mapStateToProps, mapDispathToProps)(UserManagement);
