import PropTypes from 'prop-types';
import React from 'react';
import { Input } from 'semantic-ui-react';

const S2STrackingInput = (props) => {
  const {
    appID,
    handleInputChange,
    handleKeyDown,
  } = props;

  return (
    <Input
      fluid
      icon="search"
      id="s2sAppID"
      name="s2sAppID"
      placeholder="App ID..."
      onChange={(e, data) => handleInputChange(e, data)}
      onKeyDown={e => handleKeyDown(e)}
      value={appID}
    />
  );
};

S2STrackingInput.propTypes = {
  appID: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
};


export default S2STrackingInput;
