import { LOADED, LOADING } from '../../../shared/constants/readyStates';
import { getFeatures, deleteFeature, getFlags, addFeature } from '../../../services/features';
import { getCompanies, getAdminCompanies } from '../../../services/companies';
import { getUsersPending, activateUser } from '../../../services/users';

export const RETRIEVED_FEATURES = 'USERS.RETRIEVED_FEATURES';
export const ERROR_RETRIEVING_FEATURES = 'USERS.ERROR_RETRIEVING_FEATURES';
export const RETRIEVED_COMPANIES = 'USERS.RETRIEVED_COMPANIES';
export const ERROR_RETRIEVING_COMPANIES = 'USERS.ERROR_RETRIEVING_COMPANIES';
export const FETCH_COMPANIES = 'USERS.FETCH_COMPANIES';
export const FETCH_ADMIN_COMPANIES = 'USERS.FETCH_ADMIN_COMPANIES';
export const DELETE_FEATURES = 'USERS.DELETE_FEATURES';
export const RETRIEVED_FLAGS = 'USERS.RETRIEVED_FLAGS';
export const ADD_FLAG = 'USERS.ADD_FLAG';
export const SUCCESS = 'USERS.SUCCESS';
export const SUCCESS_DELETING_FEATURES = 'USERS.SUCCESS_DELETING_FEATURES';
export const ERROR_DELETING_FEATURES = 'USERS.ERROR_DELETING_FEATURES';
export const ERROR_FETCH_ADMIN_COMPANIES = 'USERS.ERROR_FETCH_ADMIN_COMPANIES';
export const ERROR_RETRIEVED_FLAGS = 'USERS.ERROR_RETRIEVED_FLAGS';
export const ERROR_ADD_FLAG = 'USERS.ERROR_ADD_FLAG';
export const RETRIEVED_USERS = 'USERS.RETRIEVED_USERS';
export const ERROR_RETRIEVING_USERS = 'USERS.ERROR_RETRIEVING_USERS';

export const retrieveFeatures = (queryObject = {}) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const query = Object.keys(queryObject).map(key => `${key}=${queryObject[key]}`).join('&');
    const features = await getFeatures(query);
    dispatch({
      type: RETRIEVED_FEATURES,
      features: features.features,
      total: features.total,
    });
    dispatch({ type: LOADED });
  } catch (error) {
    dispatch({ type: ERROR_RETRIEVING_FEATURES, error });
    dispatch({ type: LOADED });
  }
};

export const deleteFeatureFlags = featuresIds => async (dispatch) => {
  try {
    let toDelete = [];
    const promises = featuresIds.map(featureId => (
      deleteFeature(featureId)
        .then(() => ({ id: featureId }))
        .catch(() => ({ id: featureId, error: true }))
    ));

    await Promise.all(promises)
      .then((response) => {
        const features = response.filter(el => !el.error);
        toDelete = features.map(el => el.id);
      })
      .catch(() => {
        dispatch({
          type: ERROR_DELETING_FEATURES,
          message: ['delete-failure', 'Feature Flags'],
        });
      });

    dispatch({
      type: DELETE_FEATURES,
      features: toDelete,
      message: ['delete-success', 'Feature Flags'],
    });

    if (toDelete.length !== featuresIds.length) {
      dispatch({
        type: ERROR_DELETING_FEATURES,
        message: ['delete-failure', 'Feature Flags'],
      });
    }
  } catch (error) {
    dispatch({
      type: ERROR_DELETING_FEATURES,
      error,
      message: ['delete-failure', 'Feature Flags'],
    });
  }
};

export const retrieveCompanies = (queryObject = {}) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const query = Object.keys(queryObject).map(key => `${key}=${queryObject[key]}`).join('&');
    const companies = await getCompanies(query);
    dispatch({
      type: RETRIEVED_COMPANIES,
      companies: companies.companies,
      total: companies.total,
    });
    dispatch({ type: LOADED });
  } catch (error) {
    dispatch({ type: ERROR_RETRIEVING_COMPANIES, error });
    dispatch({ type: LOADED });
  }
};

export const fetchCompanies = () => async (dispatch) => {
  try {
    const companies = await getCompanies();

    dispatch({ type: FETCH_COMPANIES, companies });
  } catch (error) {
    dispatch({ type: ERROR_RETRIEVING_COMPANIES, error });
  }
};

export const fetchAdminCompanies = () => async (dispatch) => {
  try {
    const adminCompanies = await getAdminCompanies();

    dispatch({ type: FETCH_ADMIN_COMPANIES, adminCompanies });
  } catch (error) {
    dispatch({ type: ERROR_FETCH_ADMIN_COMPANIES, error });
  }
};

export const retrieveFlags = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const flags = await getFlags();
    dispatch({
      type: RETRIEVED_FLAGS,
      flags,
    });
    dispatch({ type: LOADED });
  } catch (error) {
    dispatch({ type: ERROR_RETRIEVED_FLAGS, error });
    dispatch({ type: LOADED });
  }
};

export const addFeatures = (companiesToAdd, flag) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const toAdd = [];
    const promises = companiesToAdd.map((comapyId) => {
      const body = {
        user: comapyId,
        name: flag,
      };
      return addFeature(body)
        .then(response => (response))
        .catch(() => ({ id: comapyId, error: true }));
    });

    await Promise.all(promises)
      .then((response) => {
        if (!response.error) {
          toAdd.push(response);
        }
      })
      .catch(() => {
        dispatch({
          type: ERROR_ADD_FLAG,
          message: ['delete-failure', 'Feature Flags'],
        });
      });

    dispatch({
      type: ADD_FLAG,
      features: toAdd,
      message: ['save-success', 'Feature Flags'],
    });

    dispatch({ type: LOADED });
  } catch (error) {
    dispatch({ type: ERROR_ADD_FLAG, error, message: ['save-failure', 'Feature Flags'] });
    dispatch({ type: LOADED });
  }
};

export const retrieveUserPending = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await getUsersPending();
    dispatch({ type: RETRIEVED_USERS, users: response });
    dispatch({ type: LOADED });
  } catch (error) {
    console.log('Failed to retrieve users: ', error);
    dispatch({ type: ERROR_RETRIEVING_USERS, error });
  }
};

export const enableUser = token => async (dispatch) => {
  console.log('using token', token);
  try {
    await activateUser(token);
    dispatch(retrieveUserPending());
    dispatch({
      type: SUCCESS,
      message: ['save-success', 'Changes'],
    });
  } catch (error) {
    console.log('Failed to retrieve users: ', error);
    dispatch({ type: ERROR_RETRIEVING_USERS, error });
  }
};
