import PropTypes from 'prop-types';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Grid, List } from 'semantic-ui-react';
import { createCreativeVisualization } from '../helpers';

import './styles/CreativeVisualization.css';

const CreativeVisualization = ({ creative, template, templateName, templateS3 }) => {
  if (isEmpty(template)) {
    return (
      <p>There was a problem creating the visualization for the creative. Please try again.</p>
    );
  }

  const templateParsed = createCreativeVisualization(template, creative);

  if (isEmpty(templateParsed)) {
    return (
      <p>There are not valid assets for this creative.</p>
    );
  }

  return (
    <Grid stackable verticalAlign="middle" >
      <Grid.Row>
        <Grid.Column width={8}>
          <div className="CreativePreview__EmbedWrapper portrait">
            <iframe
              id="template-visor"
              scrolling="no"
              srcDoc={templateParsed}
              className="CreativePreview__Embed"
              title="Creative portrait visualization"
            />
          </div>
        </Grid.Column>
        <Grid.Column width={8}>
          <div className="CreativePreview__EmbedWrapper landscape">
            <iframe
              id="template-visor"
              scrolling="no"
              srcDoc={templateParsed}
              className="CreativePreview__Embed"
              title="Creative landscape visualization"
            />
          </div>
        </Grid.Column>
        {(templateName && templateS3) &&
          <Grid.Column width={16}>
            <List divided relaxed>
              <List.Item id="creative_templateName">
                <List.Icon name="code" size="large" verticalAlign="middle" />
                <List.Content>
                  <List.Header>Template name</List.Header>
                  <List.Description>{templateName}</List.Description>
                </List.Content>
              </List.Item>
              <List.Item id="creative_templateS3">
                <List.Icon name="server" size="large" verticalAlign="middle" />
                <List.Content>
                  <List.Header>Template URL</List.Header>
                  <List.Description>
                    <a href={templateS3}>{templateS3}</a>
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
        }
      </Grid.Row>
    </Grid>
  );
};

CreativeVisualization.propTypes = {
  creative: PropTypes.shape({}).isRequired,
  template: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  templateS3: PropTypes.string.isRequired,
};

export default CreativeVisualization;
