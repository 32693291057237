import difference from 'lodash/difference';
import getter from 'lodash/get';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import querystring from 'querystring';
import setter from 'lodash/set';
import toPath from 'lodash/toPath';
import union from 'lodash/union';
import urlParse from 'url-parse';
import xor from 'lodash/xor';

import { createAsyncAction } from '../../../shared/actions';
import { DASH_API } from '../../../shared/api';
import { xhr, axios } from '../../../shared/helpers';

import { ANDROID_PLATFORM, IOS_PLATFORM } from '../shared/constants';
import { parseIds } from '../shared/helpers';

export const ADD_BIDDER = 'EXCHANGE.BIDDER.ADD_BIDDER';

export const CREATE_BIDDER = 'EXCHANGE.BIDDER.CREATE_BIDDER';
export const CREATE_BIDDER_FAILURE = 'EXCHANGE.BIDDER.CREATE_BIDDER_FAILURE';
export const CREATE_BIDDER_SUCCESS = 'EXCHANGE.BIDDER.CREATE_BIDDER_SUCCESS';

export const EXECUTE_DEPLOY_PUB_CONFIG = 'EXCHANGE.BIDDER.EXECUTE_DEPLOY_PUB_CONFIG';
export const EXECUTE_DEPLOY_PUB_CONFIG_FAILURE = 'EXCHANGE.BIDDER.EXECUTE_DEPLOY_PUB_CONFIG_FAILURE';
export const EXECUTE_DEPLOY_PUB_CONFIG_SUCCESS = 'EXCHANGE.BIDDER.EXECUTE_DEPLOY_PUB_CONFIG_SUCCESS';

export const FETCH_BIDDER = 'EXCHANGE.BIDDER.FETCH_BIDDER';
export const FETCH_BIDDER_FAILURE = 'EXCHANGE.BIDDER.FETCH_BIDDER_FAILURE';
export const FETCH_BIDDER_SUCCESS = 'EXCHANGE.BIDDER.FETCH_BIDDER_SUCCESS';

export const FETCH_BIDDERS = 'EXCHANGE.BIDDERS.FETCH';
export const FETCH_BIDDERS_SUCCESS = 'EXCHANGE.BIDDERS.FETCH.SUCCESS';
export const FETCH_BIDDERS_FAILURE = 'EXCHANGE.BIDDERS.FETCH.FAILURE';

export const FETCH_BIDDERS_BUDGET = 'EXCHANGE.BIDDERS.BUDGET.FETCH';
export const FETCH_BIDDERS_BUDGET_SUCCESS = 'EXCHANGE.BIDDERS.BUDGET.FETCH.SUCCESS';
export const FETCH_BIDDERS_BUDGET_FAILURE = 'EXCHANGE.BIDDERS.BUDGET.FETCH.FAILURE';

export const FETCH_DEVICE_LISTS = 'EXCHANGE.BIDDER.FETCH_DEVICE_LISTS';
export const FETCH_DEVICE_LISTS_FAILURE = 'EXCHANGE.BIDDER.FETCH_DEVICE_LISTS_FAILURE';
export const FETCH_DEVICE_LISTS_SUCCESS = 'EXCHANGE.BIDDER.FETCH_DEVICE_LISTS_SUCCESS';

export const REMOVE_BIDDER = 'EXCHANGE.BIDDER.REMOVE_BIDDER';
export const REMOVE_ERRORS = 'EXCHANGE.BIDDER.REMOVE_ERRORS';

export const SEARCH_BUNDLE_ID = 'EXCHANGE.BIDDER.SEARCH_BUNDLE_ID';
export const SEARCH_BUNDLE_ID_FAILURE = 'EXCHANGE.BIDDER.SEARCH_BUNDLE_ID_FAILURE';
export const SEARCH_BUNDLE_ID_SUCCESS = 'EXCHANGE.BIDDER.SEARCH_BUNDLE_ID_SUCCESS';

export const SEARCH_APP_ID = 'EXCHANGE.BIDDER.SEARCH_APP_ID';
export const SEARCH_APP_ID_FAILURE = 'EXCHANGE.BIDDER.SEARCH_APP_ID_FAILURE';
export const SEARCH_APP_ID_SUCCESS = 'EXCHANGE.BIDDER.SEARCH_APP_ID_SUCCESS';

export const SEARCH_APP_NAME = 'EXCHANGE.BIDDER.SEARCH_APP_NAME';
export const SEARCH_APP_NAME_FAILURE = 'EXCHANGE.BIDDER.SEARCH_APP_NAME_FAILURE';
export const SEARCH_APP_NAME_SUCCESS = 'EXCHANGE.BIDDER.SEARCH_APP_NAME_SUCCESS';

export const SET_BIDDER = 'EXCHANGE.BIDDER.SET_BIDDER';

export const SYNC_BIDDER = 'EXCHANGE.BIDDER.SYNC_BIDDER';
export const SYNC_BIDDER_FAILURE = 'EXCHANGE.BIDDER.SYNC_BIDDER_FAILURE';
export const SYNC_BIDDER_SUCCESS = 'EXCHANGE.BIDDER.SYNC_BIDDER_SUCCESS';

export const VALIDATE_BIDDER = 'EXCHANGE.BIDDER.VALIDATE_BIDDER';
export const VALIDATE_BIDDER_FAILURE = 'EXCHANGE.BIDDER.VALIDATE_BIDDER_FAILURE';
export const ACTIVE_INDEX = 'EXCHANGE.BIDDER.ACTIVE_INDEX';

/**
 * Set attributes on a bidder
 * @param {String} bidderId - Alphanumeric bidder id
 * @param {Object} attrs - Bidder attributes to set
 * @param {Object} errors - Errors from the DOM
 * @returns {Action} Redux action
 */
export const setBidder = (bidderId = 'no_id', attrs = {}, errors = {}) => ({
  attrs,
  errors,
  type: SET_BIDDER,
  bidderId,
});

export const addBidder = (bidderId = 'new') => ({ type: ADD_BIDDER, bidderId });

/**
 * Validate a bidder attributes
 * @param {Object} bidder - Bidder attributes
 * @returns {Action} Redux action
 */
export const validateBidder = bidder => ({ type: VALIDATE_BIDDER, attrs: bidder });

/**
 * Add an item to a list
 * @param {Object} bidder - Bidder attributes
 * @param {String} path - The path to the list within the bidder object
 * @param {*|[*]} items - The item to add to the list
 * @returns {Action} Redux action
 */
export const addListItems = (bidder, path, items) => {
  const list = getter(bidder, path, []);
  const nextList = union(list, [].concat(items));
  const nextAttrs = setter(pick(bidder, head(toPath(path))), path, nextList);
  return setBidder(bidder.id, nextAttrs);
};

/**
 * Validates and creates a bidder
 * @param {Object} bidder - Bidder attributes
 * @returns {Function} Return thunk that returns a promise
 */
export const createBidder = bidder => async (dispatch) => {
  dispatch({ type: CREATE_BIDDER });
  try {
    const { data: { response } } = await axios.post(`${DASH_API}/exchange/bidders`, bidder);
    dispatch({ type: CREATE_BIDDER_SUCCESS, response, message: ['save-success', 'bidder'] });
  } catch ({ message }) {
    dispatch({ type: CREATE_BIDDER_FAILURE, error: message, message: ['save-failure', 'bidder'] });
  }
};

/**
 * Sent an RPC request to deploy the Publisher Configuration to an environment
 * @param {String} bidderId - Bidder id
 * @returns {Function} Return a thunk that returns a promise
 */
export const executeDeployPubConfig = bidderId => async (dispatch) => {
  dispatch({ type: EXECUTE_DEPLOY_PUB_CONFIG });
  try {
    const { response } = await xhr(`${DASH_API}/exchange/bidders/${bidderId}/deploy_test`, {}, { token: true });
    dispatch({ type: EXECUTE_DEPLOY_PUB_CONFIG_SUCCESS, bidderId, attrs: response });
  } catch ({ message }) {
    dispatch({ type: EXECUTE_DEPLOY_PUB_CONFIG_FAILURE, error: message });
  }
};

/**
 * Fetch a single bidder
 * @param {String} bidderId - Alphanumeric bidder id
 * @returns {Function} - Redux thunk that returns a promise
 */
export const fetchBidder = bidderId => async (dispatch) => {
  dispatch({ type: FETCH_BIDDER });
  try {
    const { response } = await xhr(`${DASH_API}/exchange/bidders/${bidderId}`, null, { token: true });
    dispatch({ type: FETCH_BIDDER_SUCCESS, response });
  } catch ({ message }) {
    dispatch({ type: FETCH_BIDDER_FAILURE, error: message, message: ['fetch-failure', 'bidder'] });
  }
};

/**
 * Fetch all the bidders
 * @returns {Function} - Redux thunk that returns a promise
 */

export const fetchBiddersBudgets = createAsyncAction(
  FETCH_BIDDERS_BUDGET,
  [],
  () => xhr(`${DASH_API}/exchange/bidders/bidders_budgets`, null, { token: true }),
  { messages: { failure: ['fetch-failure', 'bidders-budget'] } },
);

/**
 * Fetch all the bidders
 * @returns {Function} - Redux thunk that returns a promise
 */
export const fetchBidders = () => async (dispatch) => {
  dispatch({ type: FETCH_BIDDERS });
  try {
    const { response } = await xhr(`${DASH_API}/exchange/bidders`, null, { token: true });
    dispatch({ type: FETCH_BIDDERS_SUCCESS, response });
  } catch ({ message }) {
    dispatch({ type: FETCH_BIDDERS_FAILURE, error: message, message: ['fetch-failure', 'bidders'] });
  }
};

/**
 * Fetch all the device lists
 * @returns {Function} - Redux thunk that returns a promise
 */
export const fetchDeviceLists = () => async (dispatch) => {
  dispatch({ type: FETCH_DEVICE_LISTS });
  try {
    const { response } = await xhr(`${DASH_API}/lists`, null, { token: true });
    dispatch({
      type: FETCH_DEVICE_LISTS_SUCCESS,
      response: response.filter(list => list.type === 'devices'),
    });
  } catch ({ message }) {
    dispatch({ type: FETCH_DEVICE_LISTS_FAILURE, error: message });
  }
};

/**
 * Move the contents of a list from the `pathOrigin` to the `pathDest`.
 * The original list will be empty after the move.
 * @param {Object} bidder - Bidder attributes
 * @param {String} pathOrigin - A path to the contents  of the list
 * @param {String} pathDest - A path to the new home of the list
 * @returns {Action}
 */
export const moveList = (bidder, pathOrigin, pathDest) => {
  const list = getter(bidder, pathOrigin, []);
  const pathOriginRoot = pick(bidder, head(toPath(pathOrigin)));
  const pathDestRoot = pick(bidder, head(toPath(pathDest)));
  const nextAttrs = Object.assign({}, pathOriginRoot, pathDestRoot);
  setter(nextAttrs, pathDest, list);
  setter(nextAttrs, pathOrigin, []);
  return setBidder(bidder.id, nextAttrs);
};

export const removeBidder = (bidderId = 'no_id') => ({ type: REMOVE_BIDDER, bidderId });

/**
 * Remove all errors
 * @returns {Action} - Redux action
 */
export const removeErrors = () => ({ type: REMOVE_ERRORS });

/**
 * Remove an item from a list
 * @param {Object} bidder - Bidder attributes
 * @param {String} path - The path to the list within the bidder object
 * @param {*|[*]} items - The items to remove from the list
 * @returns {Action} Redux action
 */
export const removeListItems = (bidder, path, items) => {
  const list = getter(bidder, path, []);
  const nextList = difference(list, [].concat(items));
  const nextAttrs = setter(pick(bidder, head(toPath(path))), path, nextList);
  return setBidder(bidder.id, nextAttrs);
};

/**
 * Search for an app by it's bundle id
 * @param {String} bundleId - Bundle id of the app
 * @param {Number} platformId - Platform id
 * @returns {Function} Redux thunk that returns a promise
 */
export const searchBundleId = (bundleId, platformId) => async (dispatch) => {
  dispatch({ bundleId, type: SEARCH_BUNDLE_ID });
  try {
    const qs = querystring.stringify({ search_id: bundleId, type: platformId });
    const { response } = await xhr(`${DASH_API}/app_store_search?${qs}`, null, { token: true });
    dispatch({ type: SEARCH_BUNDLE_ID_SUCCESS, bundleId, platformId, response: head(response) });
  } catch ({ message }) {
    dispatch({ type: SEARCH_BUNDLE_ID_FAILURE, error: message });
  }
};

/**
 * Search for apps by their id. Results are directly added to the bidder object
 * @param {Object} bidder - Bidder attributes
 * @param {String[]} appIds - List of app ids to search
 */
export const searchAppId = (bidder = {}, appIds = []) => async (dispatch) => {
  dispatch({ type: SEARCH_APP_ID });
  try {
    const qs = querystring.stringify({ q: appIds.join(',').trim(), field: 'id' });
    const { response } = await xhr(`${DASH_API}/search/apps/?${qs}`, null, { token: true });

    const mappedResponse = response
      .filter(app => !!app.appstore_bundleId)
      .map(app => ({ bundle_id: app.appstore_bundleId, ...app }));
    const groupedApps = groupBy(mappedResponse, 'platform');
    const iosApps = groupedApps[IOS_PLATFORM.id] || [];
    const androidApps = groupedApps[ANDROID_PLATFORM.id] || [];

    const addIosItems = addListItems(
      bidder,
      'request_validation.blocked_apps.ios',
      iosApps.map(app => app.bundle_id),
    );
    const addAndroidItems = addListItems(
      bidder,
      'request_validation.blocked_apps.android',
      androidApps.map(app => app.bundle_id),
    );

    dispatch({ type: SEARCH_APP_ID_SUCCESS, response: androidApps.concat(iosApps) });
    dispatch(addIosItems);
    dispatch(addAndroidItems);
  } catch ({ message }) {
    dispatch({ type: SEARCH_APP_ID_FAILURE, error: message });
  }
};

/**
 * Search for an app by name
 * @todo Add ability to search by id
 * @param {String} query - Search query
 * @return {Function} Redux thunk that returns a promise
 */
export const searchAppName = (query = '') => (dispatch) => {
  dispatch({ type: SEARCH_APP_NAME });
  try {
    const qs = querystring.stringify({ q: query, active: true });
    const { response } = `${DASH_API}/search/apps/?${qs}`;
    const parsedResponse = response
      .filter(app => (
        [ANDROID_PLATFORM.id, IOS_PLATFORM.id].includes(app.platform)
        && app.appstore_bundleId
      ))
      .map(result => ({ bundle_id: result.appstore_bundleId, ...result }));

    dispatch({ type: SEARCH_APP_NAME_SUCCESS, response: parsedResponse });
  } catch ({ message }) {
    dispatch({ type: SEARCH_APP_NAME_FAILURE, error: message });
  }
};

/**
 * Set an endpoint
 * @param {Object} bidder - Bidder attributes
 * @param {String} endpointKey - The endpoint key setting. E.g. `endpoint_test`
 * @param {String} endpoint - Url form of the endpoint including protocol
 * @param {Object} errors - Errors from the DOM to evaluate
 * @returns {Action} Redux Action
 */

export const setEndpoint = (bidder = {}, endpointKey = '', endpoint = '', errors = {}) => {
  const { id, mode } = bidder;
  const { hostname, pathname, port, protocol, query } = urlParse(endpoint, false);
  const getPort = Number(port) || 80;
  const parsedEndpoint = {
    hostname,
    path: pathname + query,
    port: protocol === 'https:' ? 443 : getPort,
    protocol,
  };

  const currentEndpoint = endpointKey.includes(mode) ? { endpoint: parsedEndpoint } : {};
  const attrs = {
    ...currentEndpoint,
    mode,
    [endpointKey]: parsedEndpoint,
  };
  return setBidder(id, attrs, errors);
};

/**
 * Search for an app either by name or by id
 * @param {Object} bidder - Bidder attributes
 * @param {String} query - Query to search by app name or app id
 * @returns {Function} - Redux thunk that returns a promise
 */
export const searchApp = (bidder = {}, query = '') => (dispatch) => {
  const { searchQuery } = query;
  const appIds = parseIds(searchQuery);
  return isEmpty(appIds)
    ? dispatch(searchAppName(query))
    : dispatch(searchAppId(bidder, appIds));
};

/**
 * Either add or remove an item from a list
 * @param {Object} bidder - Bidder attributes
 * @param {String} path - Path to value in the object
 * @param {*} item - Item to add to the list
 * @returns {Object} Redux action
 */
export const setListItem = (bidder = {}, path = '', item = 0) => {
  const list = getter(bidder, path, []);
  const nextList = xor(list, [item]);
  const nextAttrs = setter(pick(bidder, head(toPath(path))), path, nextList);
  return setBidder(bidder.id, nextAttrs);
};

/**
 * Save a bidder to the server
 * @param {Object} bidder - Bidder attributes
 * @returns {Function} Redux thunk that returns a promise
 */
export const saveBidder = bidder => async (dispatch) => {
  dispatch({ type: SYNC_BIDDER });
  try {
    const { response } = await xhr(`${DASH_API}/exchange/bidders/${bidder.id}`, bidder, { method: 'PUT' });
    dispatch({ type: SYNC_BIDDER_SUCCESS, response, message: ['save-success', 'bidder'] });
  } catch ({ message }) {
    dispatch({ type: SYNC_BIDDER_FAILURE, error: message });
  }
};

/**
 * Set Active Index for Accordion
 * @param {number} activeIndex - ActiveIndex
 */
export const setActiveIndex = activeIndex => (dispatch) => {
  dispatch({ type: ACTIVE_INDEX, activeIndex });
};
