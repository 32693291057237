import React from 'react';
import PropTypes from 'prop-types';

import './PublishingAppCardManagers.css';

function PublishingAppCardManagers(props) {
  const { account_owners: accountOwners } = props.salesforce || {};

  const formatText = (text) => {
    if (!accountOwners || !accountOwners[text] || accountOwners[text] === '-') {
      return 'N/A';
    }

    return accountOwners[text];
  };

  return (
    <div className="app-card-managers">
      <span className="header">AM:</span> {formatText('am')}
      <span className="header">AE:</span> {formatText('ae')}
      <span className="header">PRM:</span> {formatText('prm')}
      <span className="header">BD:</span> {formatText('bd')}
      <span className="header">AM_EMAIL:</span> {formatText('am_email')}
      <span className="header">AE_EMAIL:</span> {formatText('ae_email')}
      <span className="header">PRM_EMAIL:</span> {formatText('prm_email')}
    </div>
  );
}

PublishingAppCardManagers.defaultProps = { salesforce: { account_owners: '' } };
PublishingAppCardManagers.propTypes = { salesforce: PropTypes.shape({}).isRequired };

export default PublishingAppCardManagers;
