import { SET_PREVIEW_CREATIVE_ID } from '../actions';

const previewCreative = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_PREVIEW_CREATIVE_ID:
      return action.creativeId ? { id: action.creativeId } : {};
    default:
      return state;
  }
};

export default previewCreative;
