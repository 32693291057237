import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox, Divider, Grid, Header, Form } from 'semantic-ui-react';

class DealBasicSettings extends Component {
  handleDealChange(data) {
    const { deal, onChange } = this.props;
    deal[data.name] = data.value;
    onChange({
      name: 'deal',
      value: deal,
    });
  }

  handleStatusChange(data) {
    const { deal, onChange } = this.props;
    deal.is_active = data.value === 1;
    onChange({
      name: 'deal',
      value: deal,
    });
  }

  render() {
    const {
      deal: {
        is_active: enabled,
        name,
        description,
      },
      colum1,
      colum2,
    } = this.props;
    const isActive = enabled ? 1 : 0;
    return (
      <div>
        <Header>
          <Header.Content>
            Basic Settings
          </Header.Content>
        </Header>
        <Divider />
        <Form>
          <Grid container columns={2} stackable doubling>
            <Grid.Column width={colum1}>
              <Form.Field>
                <Checkbox
                  checked={isActive === 1}
                  label="Enabled"
                  name="is_active"
                  value={!isActive ? 1 : 0}
                  onChange={(event, data) => this.handleStatusChange(data)}
                  toggle
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={colum2}>
              <Form.Input
                fluid
                label="Deal name"
                name="name"
                value={name}
                onChange={(event, data) => this.handleDealChange(data)}
                required
              />
              <Form.TextArea
                label="Description / Notes"
                name="description"
                value={description || ''}
                onChange={(event, data) => this.handleDealChange(data)}
              />
            </Grid.Column>
          </Grid>
        </Form>
      </div>
    );
  }
}

DealBasicSettings.propTypes = {
  deal: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  colum1: PropTypes.number.isRequired,
  colum2: PropTypes.number.isRequired,
};

export default DealBasicSettings;
