import PropTypes from 'prop-types';
import React from 'react';
import { List, Grid } from 'semantic-ui-react';
import { PLATFORM } from '../constants';
import { getAppType } from '../utils';

const PublishingAppCardAppDetails = (props) => {
  const { app } = props;
  const appType = getAppType(app);
  const sdkIntegrationType = app.sdk_integration_type ? app.sdk_integration_type : 'N/A';
  return (
    <Grid.Column>
      <h2>App details</h2>
      <List divided relaxed>
        <List.Item>
          <List.Icon name="id card" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>App name</List.Header>
            <List.Description>{app.name}</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="id badge" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>App ID</List.Header>
            <List.Description>{app._id}</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name={appType} size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>Platform</List.Header>
            <List.Description>{PLATFORM[appType]}</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="ambulance" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>SDK integration type</List.Header>
            <List.Description>{sdkIntegrationType}</List.Description>
          </List.Content>
        </List.Item>
      </List>
    </Grid.Column>
  );
};

PublishingAppCardAppDetails.defaultProps = {};

PublishingAppCardAppDetails.propTypes = { app: PropTypes.shape({}).isRequired };

export default PublishingAppCardAppDetails;
