import { getPlatformNameOfId } from './platforms';

export const MaxApps = 10000;

export default class AppIdList {
  static isAndroidAppIdValid(appId) {
    return appId.match(/^([aA-zZ]+[aA-zZ0-9-_]*(\.[aA-zZ0-9-_]+)+)$/) || appId.match(/^(?=.*[aA-zZ])[a-zA-Z0-9]{10}$/);
  }

  static isiOsAppIdValid(appId) {
    return appId.match(/^[0-9]{9,10}$/);
  }

  static isAppIdValid(platform, appId) {
    const isIOS = platform === '1';
    return isIOS ? AppIdList.isiOsAppIdValid(appId) : AppIdList.isAndroidAppIdValid(appId);
  }

  static isAppIdInAppList(appIdList, appId, platform) {
    const platformId = getPlatformNameOfId(platform);

    if (!appIdList.includes(appId)) {
      throw new Error(`${platformId} ID is not blocked`);
    }

    return true;
  }

  static isAppIdValidToAdd(appIdList, appId, platform, skipMaxList = false) {
    const platformId = getPlatformNameOfId(platform);

    if (!AppIdList.isAppIdValid(platform, appId)) {
      throw new Error(`Wrong format of ${platformId} ID`);
    }

    if (appIdList.includes(appId)) {
      throw new Error(`${platformId} ID already blocked`);
    }

    if (appIdList.length >= MaxApps && !skipMaxList) {
      throw new Error(`You can only block ${MaxApps} ${platformId} IDs!`);
    }
    return true;
  }

  static isAppIdListValid(appIdList, platform, skipMaxList) {
    const validAppIds = AppIdList.addAppIdsToList(appIdList, platform, [], true, skipMaxList);
    return validAppIds.length === appIdList.length && validAppIds.length > 0;
  }

  static addAppIdsToList(idsToAdd, platform, currentList = [], validate = true, skipMaxList) {
    const appIdList = [...currentList];
    idsToAdd.forEach((appId) => {
      if ((!validate
        && !appIdList.includes(appId))
      ) {
        appIdList.push(appId);
      } else {
        try {
          const isValid = AppIdList.isAppIdValidToAdd(appIdList, appId, platform, skipMaxList);
          if (isValid) {
            appIdList.push(appId);
          }
        } catch (error) {
          // is not valid to add
        }
      }
    });
    return appIdList;
  }
}
