import { LOADED, LOADING } from '../../../shared/constants/readyStates';
import { getPhases, putPhases } from '../../../services/phases';

export const FETCH_PHASES = 'PHASES.FETCH_PHASES';
export const FETCH_PHASES_FAILURE = 'PHASES.FETCH_PHASES_FAILURE';
export const UPDATE_PHASES_SUCCESS = 'PHASES.UPDATE_PHASES_SUCCESS';
export const UPDATE_PHASES_FAILURE = 'PHASES.UPDATE_PHASES_FAILURE';

const convertPhasesForApi = (phases) => {
  const converted = phases.map(phase => ({
    _id: phase.id,
    name: phase.name,
    group: phase.group === 'none' ? null : phase.group,
  }));
  return { phases: converted };
};

export const fetchPhases = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const phases = await getPhases();

    dispatch({ type: FETCH_PHASES, phases });
    dispatch({ type: LOADED });
  } catch ({ message }) {
    dispatch({ type: FETCH_PHASES_FAILURE, error: message, message: ['fetch-failure', 'Phases'] });
    dispatch({ type: LOADED });
  }
};

export const updatePhases = bodyPhases => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const convertedData = convertPhasesForApi(bodyPhases);
    const phases = await putPhases(convertedData);
    dispatch({
      type: UPDATE_PHASES_SUCCESS,
      phases,
      message: ['save-success', 'Phases'],
    });
    dispatch({ type: LOADED });
  } catch ({ message }) {
    dispatch({ type: UPDATE_PHASES_FAILURE, error: message, message: ['save-failure', 'Phases'] });
    dispatch({ type: LOADED });
  }
};
