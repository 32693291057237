import { combineReducers } from 'redux';

import app from '../app';
import user from '../user';
import publishingApps from '../bundles/publishing_apps/reducer';
import playablesList from '../bundles/playables_validation/reducer';
import pmpDeals from '../ui/store/pmpDeals/reducer';
import users from '../ui/store/users/reducer';
import integrations from '../bundles/integrations/reducer';
import exchange from '../bundles/exchange/reducer';
import yieldReducer from '../bundles/yield/reducer';
import creatives from '../bundles/creatives/reducer';
import apps from '../ui/store/apps/reducer';
import phases from '../ui/store/phases/reducer';
import s2sTracking from '../ui/store/s2sTracking/reducer';

export const createReducer = (reducers = {}) =>
  combineReducers({
    app,
    user,
    publishingApps,
    playablesList,
    pmpDeals,
    users,
    integrations,
    exchange,
    yield: yieldReducer,
    creatives,
    apps,
    phases,
    s2sTracking,
    ...reducers,
  });

export const injectReducer = store => (name, reducer) => {
  if (store.reducers[name]) { return; }
  const reducers = { ...store.reducers, [name]: reducer };
  store.replaceReducer(createReducer(reducers));
  store.reducers = reducers; // eslint-disable-line no-param-reassign
};
