import Api from '../../../../src/core/api';
import moment from 'moment';

let todayUTC = moment(new Date())
  .utc()
  .format(`YYYY-MM-DD`);

const getData = (partner = '') =>
  Api.get(
    `${Api.INTEGRATIONS_API}/discrepancy?partner=${encodeURIComponent(
      partner
    )}&date_max=${todayUTC}`,
    null,
    {
      token: true
    }
  );

const DiscrepancyApi = {
  getData
};

export default DiscrepancyApi;
