import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

import ThrottlingOption from './ThrottlingOption';
import { ANDROID_PLATFORM, IOS_PLATFORM } from '../../shared/constants';

const ThrottlingSet = (props) => {
  const { campaignType, label } = props;

  return (
    <Form.Group inline>
      <label>{label}</label>
      <Form.Field>
        <ThrottlingOption
          {...props}
          icon="android"
          path={`request_validation.granular_throttling.${campaignType}.android`}
          platform={ANDROID_PLATFORM}
        />
      </Form.Field>
      <Form.Field>
        <ThrottlingOption
          {...props}
          icon="apple"
          path={`request_validation.granular_throttling.${campaignType}.ios`}
          platform={IOS_PLATFORM}
        />
      </Form.Field>
    </Form.Group>
  );
};

export default ThrottlingSet;

ThrottlingSet.defaultProps = {
  campaignType: '',
  label: '',
};

ThrottlingSet.propTypes = {
  campaignType: PropTypes.string,
  label: PropTypes.string,
};
