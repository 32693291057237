import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Input } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import {
  loadPage as loadPageAction,
  selectPlayable as selectPlayableAction,
  unselectPlayable as unselectPlayableAction,
  savePlayableValidation as savePlayableValidationAction,
  getRejectReasons,
} from './actions';
import PlayableValidationTable from './components/PlayableValidationTable';
import PlayableValidationModal from './components/PlayableValidationModal';

const playablesPerPage = 15;

export const PlayablesValidationListContainer = (props) => {
  const {
    playables,
    loading,
    queryParam,
    loadPage,
    savePlayableValidation,
    selectPlayable,
    selectedPlayable,
    totalPlayables,
    unselectPlayable,
    location,
    history,
  } = props;

  const [page, setPage] = useState(1);
  const [saving, setSaving] = useState(false);
  const [rejectReasonsOptions, setRejectReasonsOptions] = useState([{
    key: 'none',
    value: 'none',
    text: 'Reject',
    type: 'item',
    selected: true,
  }]);
  const [query, setQuery] = useState(queryParam);


  useEffect(() => {
    loadPage(page, playablesPerPage, query);
  }, []);

  const getRejectReasonsList = async () => {
    const options = await getRejectReasons();
    const rejectReasons = options.map(reason => ({
      key: reason,
      value: reason,
      text: reason,
      type: 'item',
    }));
    setRejectReasonsOptions([...rejectReasonsOptions, ...rejectReasons]);
  };

  useEffect(() => {
    (async () => {
      await getRejectReasonsList();
      loadPage(page, playablesPerPage);
    })();
  }, []);

  const setPageNumber = (activePage, forceRequest) => {
    setPage(activePage);

    const urlParams = isEmpty(query) ? '' : `?query=${query}`;

    history.push(`${location.pathname}${urlParams}`);

    loadPage(activePage, playablesPerPage, query, forceRequest);
  };

  const handleKeyPress = (key) => {
    if (key === 'Enter') {
      setPageNumber(1, true);
    }
  };

  const save = async (notes, reason) => {
    if (selectedPlayable) {
      setSaving(true);
      await savePlayableValidation(
        selectedPlayable.creative_id,
        reason,
        new Date().toLocaleString('en-GB'),
        notes,
        page,
        playablesPerPage,
        query,
      );
      await getRejectReasonsList();
      setSaving(false);
    }
  };

  return (
    <Container fluid style={{ overflow: 'auto' }}>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Input
              iconPosition="left"
              icon="search"
              action={{
                onClick: () => setPageNumber(1, true),
                content: 'Search',
                color: 'green',
                id: 'search-app',
              }}
              placeholder="Creative ID, Name or App ID"
              onChange={({ target: { value } }) => setQuery(value)}
              onKeyPress={({ key }) => handleKeyPress(key)}
              id="input-app"
              value={query}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <PlayableValidationTable
        playables={playables}
        page={page}
        selectPlayable={(creativeId) => {
          selectPlayable(playables.find(playable => playable.creative_id === creativeId));
        }}
        setPageNumber={(_, { activePage }) => setPageNumber(activePage)}
        totalPages={Math.ceil(totalPlayables / playablesPerPage)}
        loading={loading}
      />
      {selectedPlayable ?
        <PlayableValidationModal
          close={unselectPlayable}
          save={save}
          selectedPlayable={selectedPlayable}
          rejectReasonsOptions={rejectReasonsOptions}
          saving={saving}
        />
        : null}
    </Container>
  );
};

PlayablesValidationListContainer.defaultProps = {
  selectedPlayable: null,
  queryParam: '',
  history: { push: () => {} },
  location: { search: '' },
};

PlayablesValidationListContainer.propTypes = {
  playables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalPlayables: PropTypes.number.isRequired,
  selectedPlayable: PropTypes.shape({}),
  loadPage: PropTypes.func.isRequired,
  savePlayableValidation: PropTypes.func.isRequired,
  selectPlayable: PropTypes.func.isRequired,
  unselectPlayable: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  queryParam: PropTypes.string,
  location: PropTypes.shape({}),
  history: PropTypes.shape({ push: PropTypes.func }),
};

const mapStateToProps = (state, ownProps) => {
  const queryParameters = new URLSearchParams(ownProps.location.search);
  return {
    playables: state.playablesList.playables,
    totalPlayables: state.playablesList.totalPlayables,
    selectedPlayable: state.playablesList.selectedPlayable,
    loading: state.playablesList.loading,
    queryParam: queryParameters.get('query') || '',
  };
};

const mapDispatchToProps = dispatch => ({
  loadPage: (page, itemsPerPage, query, forceRequest) => (
    dispatch(loadPageAction(page, itemsPerPage, query, forceRequest))
  ),
  selectPlayable: playable => dispatch(selectPlayableAction(playable)),
  unselectPlayable: () => dispatch(unselectPlayableAction()),
  savePlayableValidation: (
    creativeId,
    status,
    validationDate,
    validationNotes,
    page,
    itemsPerPage,
    query,
  ) => dispatch(savePlayableValidationAction(
    creativeId,
    status,
    validationDate,
    validationNotes,
    page,
    itemsPerPage,
    query,
  )),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayablesValidationListContainer);
