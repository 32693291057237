import PropTypes from 'prop-types';
import React from 'react';
import { Form, TextArea } from 'semantic-ui-react';

const PublishingAppCardNotes = (props) => {
  const {
    reviewerNotes,
    handleChangeNotes,
  } = props;
  return (
    <Form>
      <Form.Field
        control={TextArea}
        label="Notes"
        onChange={handleChangeNotes}
        value={reviewerNotes}
      />
    </Form>
  );
};

PublishingAppCardNotes.defaultProps = { reviewerNotes: '' };

PublishingAppCardNotes.propTypes = {
  reviewerNotes: PropTypes.string.isRequired,
  handleChangeNotes: PropTypes.func.isRequired,
};

export default PublishingAppCardNotes;
