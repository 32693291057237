import { LOADED, LOADING, UNLOADED } from '../../../../src/shared/constants/readyStates';
import Actions from '../../../../src/core/actions';

import DemandAggregatorActions from './actions';
import DemandAggregatorHelpers from './helpers';

const initState = {
  readyState: UNLOADED,
  data: {},
  originalData: {},
  filteredData: {},
  filters: {
    filter_ssp: '',
    filter_dsp: ''
  },
  message: '',
  isError: false,
  showMessage: false
};

const setData = (state, payload) => {
  const formattedData = DemandAggregatorHelpers.formatIncomingData(
    JSON.parse(JSON.stringify(payload.response))
  );
  return {
    ...state,
    readyState: LOADED,
    data: DemandAggregatorHelpers.formatIncomingData(JSON.parse(JSON.stringify(payload.response))),
    originalData: formattedData,
    filteredData: applyFilters(JSON.parse(JSON.stringify(formattedData)), { ...state.filters })
  };
};

const updateField = (state, payload) => {
  const data = { ...state.data };
  const filteredData = { ...state.filteredData };
  const dspData = data[payload.sspName];
  if (payload.dspName == null) {
    dspData[payload.field] = payload.value;
    filteredData[payload.sspName][payload.field] = payload.value;
  } else {
    dspData['eligibleBidders'][payload.dspName][payload.field] = payload.value;
    filteredData[payload.sspName]['eligibleBidders'][payload.dspName][payload.field] =
      payload.value;
  }
  return {
    ...state,
    readyState: LOADED,
    data,
    filteredData
  };
};

const addNewEligibleBidder = (state, payload) => {
  const data = { ...state.data };
  const dspData = data[payload.sspName];

  if (dspData.new_dsp_name === '') {
    alert('Name cannot be blank');
    return state;
  } else if (dspData.new_dsp_name in dspData['eligibleBidders']) {
    alert('Duplicate DSP Name, plz change');
    return state;
  } else {
    dspData['eligibleBidders'][dspData.new_dsp_name] = {
      ...JSON.parse(JSON.stringify(DemandAggregatorHelpers.defaultEligibleBidderData)),
      name: dspData.new_dsp_name
    };

    const filteredData = { ...state.filteredData };
    filteredData[payload.sspName]['eligibleBidders'][dspData.new_dsp_name] = {
      ...JSON.parse(JSON.stringify(DemandAggregatorHelpers.defaultEligibleBidderData)),
      name: dspData.new_dsp_name
    };

    dspData.new_dsp_name = '';
    filteredData[payload.sspName].new_dsp_name = '';

    return {
      ...state,
      readyState: LOADED,
      data,
      filteredData
    };
  }
};

const removeNewEligibleBidder = (state, payload) => {
  const data = { ...state.data };
  var dspData = data[payload.sspName];
  delete dspData['eligibleBidders'][payload.dspName];

  const filteredData = { ...state.filteredData };
  dspData = filteredData[payload.sspName];
  delete dspData['eligibleBidders'][payload.dspName];

  return {
    ...state,
    readyState: LOADED,
    data,
    filteredData
  };
};

const applyFilters = (data, filters) => {
  const filteredData = {};

  for (var ssp in data) {
    if (ssp.startsWith(filters.filter_ssp)) {
      // now loop through all of the eligible bidders and check if they start with filter_dsp
      filteredData[ssp] = JSON.parse(JSON.stringify(data[ssp]));
      for (var dsp in data[ssp]['eligibleBidders']) {
        if (!dsp.startsWith(filters.filter_dsp)) {
          delete filteredData[ssp]['eligibleBidders'][dsp];
        }
      }
    }
  }
  return filteredData;
};

const updateAndApplyFilters = (state, payload) => {
  const filters = { ...state.filters };
  filters[payload.filter_name] = payload.value;
  const filteredData = applyFilters(state.data, filters);
  return {
    ...state,
    readyState: LOADED,
    filters,
    filteredData
  };
};

const reducer = (state = initState, { payload, type }) => {
  switch (type) {
    case Actions.started(DemandAggregatorActions.SAVE_DATA):
    case Actions.started(DemandAggregatorActions.FETCH_DATA):
      return { ...state, readyState: LOADING };

    case Actions.success(DemandAggregatorActions.FETCH_DATA):
      return setData(state, payload);

    case DemandAggregatorActions.UPDATE_FIELD:
      return updateField(state, payload);

    case DemandAggregatorActions.ADD_NEW_DSP:
      return addNewEligibleBidder(state, payload);

    case DemandAggregatorActions.REMOVE_NEW_DSP:
      return removeNewEligibleBidder(state, payload);

    case DemandAggregatorActions.UPDATE_FILTER:
      return updateAndApplyFilters(state, payload);

    case DemandAggregatorActions.HIDE_MESSAGE:
      return { ...state, showMessage: false };

    case Actions.failure(DemandAggregatorActions.FETCH_DATA):
      return {
        ...state,
        readyState: UNLOADED,
        message: 'Failed to load data.',
        isError: true,
        showMessage: true
      };

    case Actions.failure(DemandAggregatorActions.SAVE_DATA):
      return {
        ...state,
        readyState: LOADED,
        message: 'Failed to save data',
        isError: true,
        showMessage: true
      };

    case Actions.success(DemandAggregatorActions.SAVE_DATA):
      return {
        ...state,
        readyState: LOADED,
        message: 'Saved Successfuly!',
        isError: false,
        showMessage: true
      };

    default:
      return state;
  }
};

export default reducer;
