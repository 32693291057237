import keyBy from 'lodash/keyBy';

export const CREATIVE_MODE_OFF = { text: 'OFF', value: 'off', icon: 'toggle off' };
export const CREATIVE_MODE_COMPETE = { text: 'COMPETE', value: 'compete', icon: 'fighter jet' };
export const CREATIVE_MODE_BACKFILL = { text: 'BACKFILL', value: 'backfill', icon: 'cube' };

export const CREATIVE_MODES = keyBy([CREATIVE_MODE_OFF, CREATIVE_MODE_COMPETE, CREATIVE_MODE_BACKFILL], 'value');

export const CREATIVE_MODE_OPTIONS = [
  CREATIVE_MODE_OFF,
  CREATIVE_MODE_COMPETE,
  CREATIVE_MODE_BACKFILL,
];

export default CREATIVE_MODE_OPTIONS;
