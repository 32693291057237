import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import QRCode from 'qrcode.react';

const PublishingAppCardStoreDetailsQR = (props) => {
  const { app } = props;
  const qrValue = app.market_url ? app.market_url : 'N/A';
  const qrLink = app.itunes_url ? app.itunes_url : qrValue;
  return (
    <Grid.Column style={{ textAlign: 'center' }}>
      <h2>QR Code</h2>
      <QRCode value={qrLink} renderAs="canvas" />
    </Grid.Column>
  );
};

PublishingAppCardStoreDetailsQR.defaultProps = {};

PublishingAppCardStoreDetailsQR.propTypes = { app: PropTypes.shape({}).isRequired };

export default PublishingAppCardStoreDetailsQR;
