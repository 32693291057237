import getter from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Flag, Icon, List } from 'semantic-ui-react';

import { CHARTBOOST_COUNTRIES, SEMANTIC_FLAGS_BY_ID } from '../../shared/constants';
import { objectComparator } from '../../shared/helpers';

const CountriesList = (props) => {
  const {
    bidder,
    path,
    removeListItems,
  } = props;

  const countriesList = getter(bidder, path, []);
  const sortedCountries = map(countriesList, countryCode => ({
    countryCode,
    name: (CHARTBOOST_COUNTRIES[countryCode] || { name: countryCode }).name,
  }))
    .sort(objectComparator('name'));

  const countryItems = sortedCountries.map((country) => {
    const { countryCode, name } = country;
    const semanticFlag = SEMANTIC_FLAGS_BY_ID[countryCode.toLowerCase()];

    const icon = semanticFlag
       ? <Flag name={semanticFlag.countryCode} />
       : <Icon name="question" />;

    const onClick = (event, ownProps) => {
      removeListItems(bidder, path, ownProps.value);
    };

    return (
      <List.Item key={`country-${countryCode}`}>
        <Button
          basic
          floated="left"
          icon
          onClick={onClick}
          type="button"
          value={countryCode}
        >
          <Icon name="minus" />
        </Button>
        <List.Content floated="left">{icon}</List.Content>
        <List.Content floated="left">{`[${countryCode}] ${name}`}</List.Content>
      </List.Item>
    );
  });

  const noCountryItems = (
    <List.Item key="country-no-countries">
      No countries in the list. All countries get served traffic.
    </List.Item>
  );

  const listItems = isEmpty(countryItems) ? noCountryItems : countryItems;

  const removeAllCountries = () => {
    removeListItems(bidder, path, countriesList);
  };

  return (
    <div>
      <Button type="button" onClick={removeAllCountries}>Remove all countries</Button>
      <List size="large" verticalAlign="middle">
        {listItems}
      </List>
    </div>
  );
};

export default CountriesList;

CountriesList.defaultProps = {
  bidder: {},
  path: '',
  removeListItemss: () => {},
};

CountriesList.propTypes = {
  bidder: PropTypes.shape({}),
  path: PropTypes.string,
  removeListItems: PropTypes.func,
};
