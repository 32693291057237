import { toast } from 'react-toastify';
import { API_GATEWAY } from '../shared/api';
import { xhr } from '../shared/helpers';

const getCookies = () => document.cookie.split(';').reduce((obj, cookie) => {
  const [key, value] = cookie.split('=');
  return { ...obj, [key.trim()]: value };
}, {});

export const getCookie = key => getCookies()[key] || '';

export const getUserInfo = async () => {
  try {
    return xhr(`${API_GATEWAY}/user`, null, { token: true });
  } catch (err) {
    console.error(err);
    toast.error('Failed to get user info');
    return {};
  }
};
/**
 * returns user ID
 * @returns {Promise<string>} userId
 */
export const getOperationUser = async () => {
  const userInfo = await getUserInfo();
  return userInfo && userInfo.id ? userInfo.id : '';
};

export const isLoggedIn = () => ['user_id', 'user_signature'].every(getCookie);

export const isAuthorized = (user) => {
  if (!user.roles.includes('admin')) {
    const isMatching = window.location.pathname.startsWith('/public');
    return !!isMatching;
  }
  return true;
};
