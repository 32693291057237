import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Message, Grid } from 'semantic-ui-react';


const MessageValidation = (props) => {
  const { action, maxRows, validate, appsLimitExceeded } = props;
  let appsLimitExceededMessage = `${maxRows} apps `;

  if (appsLimitExceeded.length <= 5) {
    appsLimitExceededMessage = '';
    appsLimitExceeded.forEach((app) => {
      appsLimitExceededMessage += `${isEmpty(appsLimitExceededMessage) ? '' : ', '}${app.nickname} (${app.id})`;
    });
  }

  if (isEmpty(appsLimitExceeded) || !validate) {
    return null;
  }

  return (
    <Grid.Row>
      <Grid.Column>
        <Message icon="warning sign" content={`${appsLimitExceededMessage} exceeds the limit of ${maxRows}. New ${action} added to the list won't be added to the app`} warning />
      </Grid.Column>
    </Grid.Row>
  );
};

MessageValidation.defaultProps = {
  idsToAdd: [],
  editionOption: null,

};

MessageValidation.propTypes = {
  appsLimitExceeded: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  maxRows: PropTypes.number.isRequired,
  validate: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
};

export default MessageValidation;
