import React from 'react';
import PropTypes from 'prop-types';
import { Form, Table, Button } from 'semantic-ui-react';
import DemandAggregator from '../';

const DemandAggregatorSspRows = ({ sspName, dspName, index, data, dispatch }) => {
  const changeDspField = (a, b) => {
    dispatch(DemandAggregator.Actions.updateField(sspName, dspName, b.name, b.value));
  };

  const removeEligibleBidder = (a, b) => {
    dispatch(DemandAggregator.Actions.removeNewDsp(sspName, dspName));
  };

  return (
    <Table.Row>
      <Table.Cell collapsing={true}>
        {data.new && (
          <div style={{ display: 'inline' }}>
            <Button basic negative icon="trash alternate" onClick={removeEligibleBidder} />
          </div>
        )}
        <label> {data.name} </label>
      </Table.Cell>
      <Table.Cell width="one">
        <Form.Input fluid name="banner" onChange={changeDspField} value={data.banner} />
      </Table.Cell>
      <Table.Cell width="one">
        <Form.Input fluid name="vast" onChange={changeDspField} value={data.vast} />
      </Table.Cell>
      <Table.Cell width="one">
        <Form.Input fluid name="mraid" onChange={changeDspField} value={data.mraid} />
      </Table.Cell>
      <Table.Cell>
        <Form.TextArea
          name="comment"
          rows={2}
          style={{ width: '100%' }}
          onChange={changeDspField}
          value={data.comment || ''}
        />
      </Table.Cell>
    </Table.Row>
  );
};

DemandAggregatorSspRows.propTypes = {
  sspName: PropTypes.string,
  dspName: PropTypes.string,
  index: PropTypes.number,
  data: PropTypes.object,
  dispatch: PropTypes.func
};

DemandAggregatorSspRows.defaultProps = {
  sspName: '',
  dspName: '',
  index: -1,
  data: {},
  dispatch: null
};

export default DemandAggregatorSspRows;
