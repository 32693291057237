import keyBy from 'lodash/keyBy';

export const UNKNOWN_CONNECTION = { id: 0, text: 'Unknown', icon: 'question' };
export const ETHERNET_CONNECTION = { id: 1, text: 'Ethernet', icon: 'fork' };
export const WIFI_CONNECTION = { id: 2, text: 'Wi-Fi', icon: 'wifi' };
export const CELLULAR_CONNECTION = { id: 3, text: 'Cellular', icon: 'signal' };
export const CELLULAR_2G_CONNECTION = { id: 4, text: '2G cellular', icon: 'signal' };
export const CELLULAR_3G_CONNECTION = { id: 5, text: '3G cellular', icon: 'signal' };
export const CELLULAR_4G_CONNECTION = { id: 6, text: '4G cellular', icon: 'signal' };

export const CONNECTION_TYPES = keyBy([
  WIFI_CONNECTION,
  CELLULAR_CONNECTION,
], 'id');

export const CONNECTION_TYPE_OPTIONS = [
  WIFI_CONNECTION,
  CELLULAR_CONNECTION,
].map(({ id, text, icon }) => ({ icon, text, value: id }));
