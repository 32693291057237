import React, { Component } from 'react';

// views
import { Grid } from 'semantic-ui-react';
import { PageHeader } from '../../../../../src/shared/components';
import IntegrationsLayout from '../../shared/views/IntegrationsLayout';
import OverviewLinkCard from '../../../overview/components/OverviewLinkCard';

const firstRowLinks = [
  { to: '/integrations/discrepancy', icon: 'dna', header: 'Partner Discrepancies' },
  { to: '/integrations/demand_aggregator', icon: 'dna', header: 'Demand Aggregator' }
];

class Overview extends Component {
  componentDidMount() {}
  render() {
    return (
      <IntegrationsLayout>
        <PageHeader>Integrations</PageHeader>
        <Grid>
          <Grid.Row columns={4}>
            {firstRowLinks.map(link => (
              <Grid.Column key={link.to}>
                <OverviewLinkCard link={link.to} icon={link.icon} header={link.header} />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </IntegrationsLayout>
    );
  }
}

export default Overview;
