import Actions from '../../../../src/core/actions';
import DemandAggregatorApi from './api';

const terms = {
  FETCH_DATA: 'INTEGRATIONS.DEMAND_AGGREGATOR.FETCH_DATA',
  UPDATE_FIELD: 'INTEGRATIONS.DEMAND_AGGREGATOR.UPDATE_FIELD',
  ADD_NEW_DSP: 'INTEGRATIONS.DEMAND_AGGREGATOR.ADD_NEW_DSP',
  REMOVE_NEW_DSP: 'INTEGRATIONS.DEMAND_AGGREGATOR.REMOVE_NEW_DSP',
  UPDATE_FILTER: 'INTEGRATIONS.DEMAND_AGGREGATOR.UPDATE_FILTER',
  SAVE_DATA: 'INTEGRATIONS.DEMAND_AGGREGATOR.SAVE_DATA',
  HIDE_MESSAGE: 'INTEGRATIONS.DEMAND_AGGREGATOR.HIDE_MESSAGE'
};

const fetchData = Actions.create({
  type: terms.FETCH_DATA,
  args: [],
  awaits: DemandAggregatorApi.getData,
  messages: ['fetch', 'demand_aggregator']
});

const updateField = Actions.create({
  type: terms.UPDATE_FIELD,
  args: ['sspName', 'dspName', 'field', 'value'],
  messages: ['fetch', 'demand_aggregator']
});

const addNewDsp = Actions.create({
  type: terms.ADD_NEW_DSP,
  args: ['sspName'],
  messages: ['fetch', 'demand_aggregator']
});

const removeNewDsp = Actions.create({
  type: terms.REMOVE_NEW_DSP,
  args: ['sspName', 'dspName'],
  messages: ['fetch', 'demand_aggregator']
});

const updateFilter = Actions.create({
  type: terms.UPDATE_FILTER,
  args: ['filter_name', 'value'],
  messages: ['fetch', 'demand_aggregator']
});

const saveDataAction = Actions.create({
  type: terms.SAVE_DATA,
  args: ['data', 'originalData'],
  awaits: DemandAggregatorApi.saveData,
  messages: { failure: ['save-failure', 'demand_aggregator'] }
});

const hideMessage = Actions.create({
  type: terms.HIDE_MESSAGE,
  args: [],
  messages: ['fetch', 'demand_aggregator']
});

export const saveData = () => async (dispatch, getState) => {
  try {
    const daStore = getState().integrations.demand_aggregator;
    const data = daStore.data;
    const originalData = daStore.originalData;
    await dispatch(saveDataAction(data, originalData));
    await dispatch(fetchData());
  } catch (e) {
    console.log('errors', e);
  }
};

const DemandAggregatorActions = {
  ...terms,
  fetchData,
  updateField,
  addNewDsp,
  removeNewDsp,
  updateFilter,
  saveData,
  hideMessage
};

export default DemandAggregatorActions;
