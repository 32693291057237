import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Button, Pagination, Table } from 'semantic-ui-react';

import PrivateMarketplaceRow from './TableRow';
import TableNoResults from './TableNoResults';
import './styles/TableContainer.css';

class TableContainer extends Component {
  getPagination() {
    const { activePage, dataLength, maxRow, showNextPage } = this.props;
    const totalPages = Math.ceil(dataLength / maxRow);
    let paginationElement;
    if (dataLength) {
      paginationElement = (
        <Pagination
          boundaryRange={0}
          activePage={activePage}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          prevItem={activePage >= 4 ? undefined : null}
          nextItem={totalPages > 4 && activePage < totalPages - 2 ? undefined : null}
          totalPages={totalPages}
          onPageChange={showNextPage}
        />
      );
    }
    return paginationElement;
  }

  getColumns() {
    const { column: columnState, direction, handleSort } = this.props;
    const { tableOptions, columns } = this.props;
    const sortable = tableOptions && tableOptions.sortable;

    return columns.map((column) => {
      if (column.sort && sortable) {
        return (
          <Table.HeaderCell
            singleLine
            onClick={() => handleSort(column.id)}
            sorted={columnState === column.id ? direction : null}
          >
            {column.value}
          </Table.HeaderCell>
        );
      }
      return (
        <Table.HeaderCell
          singleLine
          className="no-sort"
        >
          {column.value}
        </Table.HeaderCell>
      );
    });
  }

  getRows() {
    const { data } = this.props;
    const rows = data.map(rowData => (
      <PrivateMarketplaceRow row={rowData} />
    ));
    return rows;
  }

  getAction() {
    const { actionButton } = this.props;
    let action;
    if (!isEmpty(actionButton)) {
      action = (
        <Button
          {...actionButton}
        />
      );
    }
    return action;
  }


  render() {
    const { columns, data, tableOptions, emptyState } = this.props;
    return (
      <div>
        <div className="table_options">
          <div>
            {this.getAction()}
          </div>
          {this.getPagination()}
        </div>
        <div className="table_wrapper">
          <Table {...tableOptions} className="cb-table">
            <Table.Header>
              <Table.Row>
                {this.getColumns()}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.length === 0 ?
                <TableNoResults colSpan={columns.length} emptyState={emptyState} /> :
                this.getRows()}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}

TableContainer.defaultProps = {
  tableOptions: {},
  activePage: 1,
  maxRow: 10,
  direction: '',
  column: '',
  showNextPage: (() => { }),
  dataLength: 0,
  handleSort: (() => { }),
  actionButton: {},
};

TableContainer.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handelClickOption: PropTypes.func.isRequired,
  emptyState: PropTypes.string.isRequired,
  tableOptions: PropTypes.shape({}),
  activePage: PropTypes.number,
  maxRow: PropTypes.number,
  direction: PropTypes.string,
  column: PropTypes.string,
  dataLength: PropTypes.number,
  showNextPage: PropTypes.func,
  handleSort: PropTypes.func,
  actionButton: PropTypes.shape({}),
};

export default TableContainer;
