import Actions from '../../../../src/core/actions';
import IntegrationsApi from './api';

const terms = {
  FETCH_DATA: 'INTEGRATIONS.DISCREPANCY.DATA',
  SET_PARTNER: 'INTEGRATIONS.DISCREPANCY.SET.PARTNER'
};

const fetchData = Actions.create({
  type: terms.FETCH_DATA,
  args: ['partner'],
  awaits: IntegrationsApi.getData,
  messages: ['fetch', 'discrepancy']
});

const setPartner = Actions.create({
  type: terms.SET_PARTNER,
  args: ['partner'],
  messages: ['fetch', 'discrepancy']
});

export const setPartnerAndLoad = partner => async dispatch => {
  try {
    await dispatch(setPartner(partner));
    await dispatch(fetchData(partner));
  } catch (e) {
    console.log('errors', e);
  }
};

const DiscrepancyActions = {
  ...terms,
  fetchData,
  setPartner,
  setPartnerAndLoad
};

export default DiscrepancyActions;
