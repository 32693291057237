import { LOADED, LOADING } from '../../../shared/constants/readyStates';
import {
  LOADED_API_REQUEST,
  LOADING_API_REQUEST,
  FETCH_DEAL,
  FETCH_DSPS,
  SAVE_DEAL,
  RETRIEVED_DEALS,
  UPDATE_DEAL,
  FETCH_SEGMENTS,
  FETCH_COUNTRIES,
} from './actions';
import { rehydrate } from '../../../store/localStorage';

const initState = {
  deals: [],
  DSPs: [],
  loading: true,
  deviceSegments: [],
  appSegments: [],
  apiValidation: { isLoading: false },
  total: 0,
  countries: [],
};

const updateDeal = dealToUpdate => (deal) => {
  if (deal.id === dealToUpdate.id) {
    return dealToUpdate;
  }
  return deal;
};


const reducer = (state = rehydrate('pmp', initState), action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };

    case LOADED:
      return { ...state, loading: false };

    case RETRIEVED_DEALS:
      return { ...state, deals: [...action.data.deals], total: action.data.total };

    case LOADING_API_REQUEST:
      return { ...state, apiValidation: { isLoading: true } };

    case LOADED_API_REQUEST:
      return { ...state, apiValidation: { isLoading: false } };

    case SAVE_DEAL:
    case FETCH_DEAL: {
      const deals = [...state.deals, action.deal];
      return { ...state, deals, total: deals.length };
    }
    case UPDATE_DEAL:
      return {
        ...state,
        deals: state.deals.map(updateDeal(action.deal)),
      };

    case FETCH_DSPS:
      return { ...state, DSPs: [...action.dsps] };

    case FETCH_SEGMENTS:
      return {
        ...state,
        deviceSegments: [...action.segments.device],
        appSegments: [...action.segments.app],
      };

    case FETCH_COUNTRIES:
      return {
        ...state,
        countries: [...action.countries],
      };

    default:
      return state;
  }
};

export default reducer;

export const getPMPDeal = (deals, id) => deals.find(deal => String(deal.id) === String(id));
