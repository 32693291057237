import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { formatCreditOrAmount } from '../helpers';
import ProgressBar from './ProgressBar';

import './ProgressBar.css';

const BidderRow = ({ bidder = {} }) => {
  const {
    amount,
    credit_limit,
    id,
    name,
    company_name,
    request_validation: {
      granular_throttling: {
        static: { android: staticAndroid = 0, ios: staticIos = 0 } = {},
        video: { android: videoAndroid = 0, ios: videoIos = 0 } = {},
      } = {},
    } = {},
    user,
  } = bidder;

  return (
    <Table.Row>
      <Table.Cell><Link to={`/exchange/bidders/${id}`}>{name}</Link></Table.Cell>
      <Table.Cell>{company_name}</Table.Cell>
      <Table.Cell><code>{user}</code></Table.Cell>
      <Table.Cell>{formatCreditOrAmount(credit_limit)}</Table.Cell>
      <Table.Cell>{formatCreditOrAmount(amount)}</Table.Cell>
      <Table.Cell>
        <ProgressBar amount={amount} credit_limit={credit_limit}/>
      </Table.Cell>
      {
        [staticAndroid, staticIos, videoAndroid, videoIos].map((rate, i) => (
          <Table.Cell key={i} textAlign="right">
            {`${Math.round(rate * 100)}%`}
          </Table.Cell>
        ))
      }
    </Table.Row>
  );
};

export default BidderRow;
