import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Divider } from 'semantic-ui-react';

import { fetchBidders, fetchBiddersBudgets } from '../actions';
import { selectBidders, selectLoading } from '../../selectors';
import { BidderAddButton, BidderTable } from '../components';
import { ExchangeMenu } from '../../shared/components';
import { selectOnline } from '../../../../app/selectors';
import { PageHeader, PageLoader } from '../../../../shared/components';

class BidderList extends Component {
  componentDidMount() {
    const { fetchBidders, fetchBiddersBudgets, loading, online } = this.props;
    if (online && !loading) {
      fetchBidders().then(fetchBiddersBudgets);
    }
  }

  render() {
    const { bidders, loading } = this.props;

    return (
      <Container className="BidderList">
        <PageLoader isLoading={loading} />

        <ExchangeMenu />
        <BidderAddButton />

        <Divider />

        <PageHeader content="Bidders" />
        <BidderTable bidders={bidders} />

      </Container>
    );
  }
}

const mapStateToProps = state => ({
  bidders: selectBidders(state),
  loading: selectLoading(state),
  online: selectOnline(state),
});

const mapDispatchToProps = {
  fetchBidders,
  fetchBiddersBudgets,
};

export default connect(mapStateToProps, mapDispatchToProps)(BidderList);
