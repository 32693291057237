import { LOADED, LOADING, UNLOADED } from '../shared/constants/readyStates';
import { rehydrate, setAuthToken } from '../store/localStorage';
import { isAuthorized } from './helpers';
import {
  FETCH_EXPERIENCE,
  FETCH_EXPERIENCE_FAILURE,
  FETCH_EXPERIENCE_SUCCESS,
  FETCH_USER,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from './actions';

const initState = {
  features: [],
  isLoggedIn: true,
  readyState: UNLOADED,
  roles: [],
  settings: {},
};

const reducer = (state = rehydrate('user', initState), action = {}) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, isLoggedIn: true, loginError: false };

    case LOGIN_FAILURE:
      return { ...state, isLoggedIn: false, loginError: true };

    case LOGOUT_SUCCESS:
      return { ...state, isLoggedIn: false, loginError: false };

    case FETCH_EXPERIENCE:
      return { ...state, readyState: LOADING };

    case FETCH_EXPERIENCE_SUCCESS:
      if (!isAuthorized(action.response)) {
        return { ...state, isLoggedIn: false, readyState: LOADED };
      }
      if (action.response.token) { setAuthToken(action.response.token); }
      return { ...state, ...action.response, isLoggedIn: true, readyState: LOADED };

    case FETCH_EXPERIENCE_FAILURE:
      return { ...state, isLoggedIn: false, readyState: LOADED };

    case FETCH_USER:
      return { ...state, userInfo: action.userInfo || {} };

    default:
      return state;
  }
};

export default reducer;

export const getUserInfo = state => state.user.userInfo || {};
