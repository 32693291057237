import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import React, { Fragment, useState } from 'react';
import { Accordion, Button, Divider, Icon } from 'semantic-ui-react';

import { formatDate } from '../../utils';
import CreativeCard from './CreativeCard';

const CreativesList = ({
  creativeTemplate,
  creativeTemplateLoaded,
  creativeTemplateName,
  creativeTemplateS3,
  exchangeCreative,
  getCreativeTemplate,
}) => {
  const orderedCreatives = sortBy(exchangeCreative, [creative => creative.date_created]);
  const [activeIndex, setActiveIndex] = useState([0, orderedCreatives.length]);

  const handlePanelDropdown = index => (activeIndex.includes(index)
    ? setActiveIndex(activeIndex.filter(item => item !== index))
    : setActiveIndex([...activeIndex, index]));

  const setPanels = orderedCreatives.map((creative, index) => ({
    key: index,
    title: (
      <Accordion.Title key={creative.id} onClick={() => handlePanelDropdown(index)}>
        <Icon name="dropdown" />
        {`${creative.bidder_name} - ${formatDate(creative.date_created)}`}
      </Accordion.Title>
    ),
    content: (
      <Accordion.Content key={`${creative.id}{index}`}>
        {activeIndex.includes(index) &&
          <CreativeCard
            creative={creative}
            creativeTemplate={creativeTemplate}
            creativeTemplateLoaded={creativeTemplateLoaded}
            creativeTemplateName={creativeTemplateName}
            creativeTemplateS3={creativeTemplateS3}
            getCreativeTemplate={getCreativeTemplate}
          />
        }
      </Accordion.Content>
    ),
  }));

  return (
    <Fragment>
      <Button
        color="blue"
        onClick={() => setActiveIndex([])}
      >
        Collapse all items
      </Button>
      <Divider />
      <Accordion
        activeIndex={activeIndex}
        exclusive={false}
        fluid
        panels={setPanels}
        styled
      />
    </Fragment>
  );
};

CreativesList.propTypes = {
  creativeTemplate: PropTypes.string.isRequired,
  creativeTemplateLoaded: PropTypes.bool.isRequired,
  creativeTemplateName: PropTypes.string.isRequired,
  creativeTemplateS3: PropTypes.string.isRequired,
  exchangeCreative: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getCreativeTemplate: PropTypes.func.isRequired,
};

export default CreativesList;
