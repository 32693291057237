import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';

import DiscrepancyTableRow from './DiscrepancyTableRow';

const DiscrepancyTable = ({ data }) => (
  <Table size="small" striped fixed>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Partner</Table.HeaderCell>
        <Table.HeaderCell>Timezone</Table.HeaderCell>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.HeaderCell>CB Impressions</Table.HeaderCell>
        <Table.HeaderCell>Partner Impressions</Table.HeaderCell>
        <Table.HeaderCell>Impressions Diff</Table.HeaderCell>
        <Table.HeaderCell>Impressions %</Table.HeaderCell>
        <Table.HeaderCell>CB Money</Table.HeaderCell>
        <Table.HeaderCell>Partner Money</Table.HeaderCell>
        <Table.HeaderCell>Money Diff</Table.HeaderCell>
        <Table.HeaderCell>Money %</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {orderBy(data, ['dt'], ['desc']).map(row => (
        <DiscrepancyTableRow key={row.dt + row.partner} row={row} />
      ))}
    </Table.Body>
  </Table>
);

DiscrepancyTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
};

DiscrepancyTable.defaultProps = {
  data: []
};

export default DiscrepancyTable;
