import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';

const validator = (attrs = {}, errors = {}) => (
  pickBy(mapValues(attrs, (attr, key, keys) => {
    switch (key) {
      case 'debug': {
        const { debug = {} } = errors;
        if (!isEmpty(debug)) { return debug; }
        return null;
      }

      case 'email':
        if (errors[key]) { return errors[key]; }
        if (!attr) { return 'Please enter an email'; }
        return null;

      case 'endpoint_production':
        if (errors[key]) { return errors[key]; }
        if (!attr.hostname && key.includes(keys.mode)) { return 'Please enter a url'; }
        return null;

      case 'endpoint_test':
        if (errors[key]) { return errors[key]; }
        if (!attr.hostname && key.includes(keys.mode)) { return 'Please enter a url'; }
        return null;

      case 'name':
        if (errors[key]) { return errors[key]; }
        if (attr.length < 2) { return 'Minimum length of 2'; }
        if (attr.length > 25) { return 'Maximum length is 25'; }
        return null;

      case 'company_name':
        if (errors[key]) { return errors[key]; }
        if (!attr) { return 'Please enter the parent company name'; }
        if (attr.length < 2) { return 'Minimum length of 2'; }
        if (attr.length > 25) { return 'Maximum length is 25'; }
        return null;

      case 'price_formula': {
        const { percent } = attr;
        const { price_formula: { dollar: dollarError, percent: percentError } = {} } = errors;

        if (dollarError || percentError) { return { ...errors.price_formula }; }
        if (percent > 1) { return { percent: 'Must be less than or equal to 100' }; }

        return null;
      }

      case 'request_processing': {
        const { request_processing: { change_banner_size: changeBannerSize } = {} } = errors;
        if (changeBannerSize) { return { change_banner_size: changeBannerSize }; }
        return null;
      }

      case 'request_validation': {
        const { request_validation: { granular_throttling: granularThrottling } = {} } = errors;

        if (granularThrottling) {
          return { granular_throttling: granularThrottling };
        }

        return null;
      }

      default:
        return null;
    }
  }))
);

export default validator;
