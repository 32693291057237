import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Grid, Header, Icon, Menu, Segment } from 'semantic-ui-react';
import './styles/Users.css';

class Main extends Component {
  constructor(props) {
    super(props);
    this.routes = {
      userManagement: { name: 'userManagement', route: '/user-management' },
      userPenging: { name: 'userPenging', route: '/user_pending' },
      featureFlag: { name: 'featureFlag', route: '/feature-flag' },
      new: { name: 'featureFlag', route: '/new' },
    };

    const currentRoute = Object.keys(this.routes).find(key => (
      (props.location.pathname).includes(this.routes[key].route)
    ));
    this.state = { activeItem: this.routes[currentRoute] ? this.routes[currentRoute].name : 'userManagement' };
  }

  handleItemClick(e, { name }) {
    this.setState({ activeItem: name });
  }

  render() {
    const { children } = this.props;
    const { activeItem } = this.state;

    return (
      <Segment className="cb users-wraper">
        <Grid celled="internally">
          <Grid.Column width={4} className="sidebar">
            <Header as="h2" className="sidebar__title">User Management</Header>
            <Menu fluid vertical tabular className="sidebar__menu">
              <Menu.Item
                name="userManagement"
                active={activeItem === 'userManagement'}
                onClick={(e, value) => this.handleItemClick(e, value)}
                className="sidebar__item"
                to="/users"
                as={Link}
              >
                <Icon name="cog" />Manage Users
              </Menu.Item>
              <Menu.Item
                name="userPenging"
                active={activeItem === 'userPenging'}
                onClick={(e, value) => this.handleItemClick(e, value)}
                className="sidebar__item"
                to="/users/user_pending"
                as={Link}
              >
                <Icon name="clock outline" />User Pending
              </Menu.Item>
              <Menu.Item
                name="featureFlag"
                active={activeItem === 'featureFlag'}
                onClick={(e, value) => this.handleItemClick(e, value)}
                className="sidebar__item"
                to="/users/feature-flag"
                as={Link}
              >
                <Icon name="flag" />Feature Flag
              </Menu.Item>
            </Menu>
          </Grid.Column>
          <Grid.Column width={12} className="content">
            <Container>
              {children}
            </Container>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

Main.defaultProps = {
  location: {
    pathname: '/',
    hash: '',
  },
};

Main.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hash: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

export default Main;
