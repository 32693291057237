import dashapi from './dashapi';

export const getUsersPending = async () => {
  try {
    const { response: usersPending } = await dashapi.get('users/pending');
    return usersPending;
  } catch (error) {
    return [];
  }
};

export const activateUser = async (token) => {
  try {
    const { response } = await dashapi.post('users/activate', { token });
    return response;
  } catch (error) {
    return {};
  }
};
