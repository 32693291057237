import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'semantic-ui-react';

import PublishingAppCardGroup from './PublishingAppCardGroup';

const PublishingAppContainer = (props) => {
  const { apps, apiValidation, approveApp, rejectApp, reReviewApp } = props;
  return (
    <Container>
      <PublishingAppCardGroup
        apps={apps}
        approveApp={approveApp}
        rejectApp={rejectApp}
        reReviewApp={reReviewApp}
        apiValidation={apiValidation}
      />
    </Container>
  );
};

PublishingAppContainer.defaultProps = {};

PublishingAppContainer.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  apiValidation: PropTypes.shape({}).isRequired,
  approveApp: PropTypes.func.isRequired,
  rejectApp: PropTypes.func.isRequired,
  reReviewApp: PropTypes.func.isRequired,
};

export default PublishingAppContainer;
