import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Provider, connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import 'fomantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';

import isEmpty from 'lodash/isEmpty';
import ROUTES from './router-config';

import Shell from './containers/Shell';
import PublicShell from './containers/PublicShell';
import { ProtectedRoute } from '../shared/containers';
import { setTokenFromAuth0 } from '../store/localStorage';
import { getUserInfo } from '../user/helpers';
import { setUserInfo } from '../user/actions';

const getRoutes = (routesObject, parentPath = '') => {
  const routes = [];

  Object.keys(routesObject).forEach(key => {
    const route = routesObject[key];
    const routeConfig = {
      path: parentPath + routesObject[key].path,
      name: key,
      exact: true,
      key
    };
    const Component =
      Object.prototype.hasOwnProperty.call(route, 'protected') && route.protected
        ? ProtectedRoute
        : Route;
    if (!isEmpty(route.childPaths)) {
      const childPaths = getRoutes(route.childPaths, routeConfig.path);
      routes.push(...childPaths);
    }
    if (Object.prototype.hasOwnProperty.call(route, 'component')) {
      routeConfig.component = route.component;
      routes.push(<Component {...routeConfig} />);
    }
    if (Object.prototype.hasOwnProperty.call(route, 'redirectTo')) {
      routes.push(
        <Component {...routeConfig}>
          <Redirect to={route.redirectTo} />
        </Component>
      );
    }
  });
  return routes;
};

// eslint-disable-next-line react/prop-types
const Contents = ({ store, setUserInfo }) => {
  const { getAccessTokenSilently, isLoading, loginWithRedirect, isAuthenticated } = useAuth0();

  const signInAuth0 = async () => {
    try {
      const token = await getAccessTokenSilently();
      setTokenFromAuth0(token);
    } catch (error) {
      toast.error('Getting Auth0 access token failed', error);
      if (!isAuthenticated) {
        loginWithRedirect();
      }
    }

    try {
      const userInfo = await getUserInfo();
      setUserInfo(userInfo);
    } catch (error) {
      toast.error('Error getting user info', error);
    }
  };

  return (
    <Provider store={store}>
      <ToastContainer position="top-right" />
      <Switch>
        <Route path="/public">
          <PublicShell>
            <Switch>{getRoutes(ROUTES.PUBLIC).map(route => route)}</Switch>
          </PublicShell>
        </Route>
        <Route path="/">
          <Shell isAuth0Loading={isLoading} signInAuth0={signInAuth0}>
            <Switch>{getRoutes(ROUTES.ADMIN).map(route => route)}</Switch>
          </Shell>
        </Route>
      </Switch>
    </Provider>
  );
};

const mapDispatchToProps = dispatch => ({
  setUserInfo: userInfo => dispatch(setUserInfo(userInfo))
});
const ConnectedContents = connect(
  null,
  mapDispatchToProps
)(Contents);

const auth0Setting = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};
const App = ({ store }) => (
  <Auth0Provider
    domain={auth0Setting.domain}
    clientId={auth0Setting.clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: auth0Setting.audience
    }}
  >
    <ConnectedContents store={store} />
  </Auth0Provider>
);

App.propTypes = {
  store: PropTypes.shape({
    dispatch: PropTypes.func,
    getState: PropTypes.func
  }).isRequired
};

export default App;
