import React from 'react';
import { Card, Image } from 'semantic-ui-react';

import zero from '../media/zero.png';

const PublishingAppNoResults = () => (
  <Card style={{ background: 'transparent', boxShadow: 'none' }}>
    <Image src={zero} style={{ background: 'transparent' }} />
  </Card>
);

PublishingAppNoResults.defaultProps = {};

export default PublishingAppNoResults;
