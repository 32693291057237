import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const IntegrationsMenu = () => (
  <Menu inverted secondary stackable compact>
    <Menu.Item as={NavLink} to="/integrations/overview">
      Home
    </Menu.Item>
    <Menu.Item as={NavLink} to="/integrations/discrepancy">
      Discrepancy Tool
    </Menu.Item>
    <Menu.Item as={NavLink} to="/integrations/demand_aggregator">
      DA Dashboard
    </Menu.Item>
  </Menu>
);

export default IntegrationsMenu;
