import React from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import DemandAggregator from '../';

const DemandAggregatorHeader = ({ filters, dispatch }) => {
  const updateFilter = (a, b) => {
    dispatch(DemandAggregator.Actions.updateFilter(b.name, b.value));
  };

  const saveData = (a, b) => {
    dispatch(DemandAggregator.Actions.saveData());
  };

  return (
    <Form inverted>
      <Form.Group inline widths="five">
        <Form.Input
          fluid
          label="Filter by SSP"
          name="filter_ssp"
          value={filters.filter_ssp}
          onChange={updateFilter}
        />
        <Form.Input
          fluid
          label="Filter by DSP"
          name="filter_dsp"
          value={filters.filter_dsp}
          onChange={updateFilter}
        />
        <Form.Button
          content={'Save'}
          onClick={saveData}
          color="green"
          style={{ marginTop: '1.4rem' }}
        />
      </Form.Group>
    </Form>
  );
};

DemandAggregatorHeader.propTypes = {
  filters: PropTypes.object,
  dispatch: PropTypes.func
};

DemandAggregatorHeader.defaultProps = {
  filters: {},
  dispatch: null
};

export default DemandAggregatorHeader;
