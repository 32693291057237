import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown, Form } from 'semantic-ui-react';
import { PIPELINE_OPTIONS } from '../constants';

const PipelineType = ({ bidder, label, setBidder }) => (
  <Form.Group inline>
    <label htmlFor="bidder-pipeline-type">{label}</label>
    <Dropdown
      id="bidder-pipeline-type"
      placeholder="Select Pipeline Type"
      selection
      onChange={(event, ownProps) =>
        setBidder(bidder.id, { request_response_pipeline_type: ownProps.value })
      }
      options={PIPELINE_OPTIONS.map(opt => ({ text: opt.text, value: opt.value }))}
      value={bidder.request_response_pipeline_type || 'open_rtb'}
    />
  </Form.Group>
);

export default PipelineType;

PipelineType.defaultProps = {
  bidder: {},
  label: '',
  setBidder: () => {},
};

PipelineType.propTypes = {
  bidder: PropTypes.shape({}),
  label: PropTypes.string,
  setBidder: PropTypes.func,
};
