import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Label } from 'semantic-ui-react';

class CustomHeaders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldName: '',
      fieldNameErrorMessage: '',
      fieldValue: '',
      fieldValueError: '',
    };
  }

  onFieldNameChange = (event) => {
    const {
      target: {
        validationMessage,
        value,
      } = {},
    } = event;

    const fieldNameError = isEmpty(validationMessage)
      ? ''
      : 'Please enter only alphanumeric characters (A-Z, 0-9) separated by a dash (-)';

    this.setState({ fieldName: value, fieldNameError });
  };

  onFieldValueChange = (event) => {
    const {
      target: {
        validationMessage,
        value,
      } = {},
    } = event;

    const fieldValueError = isEmpty(validationMessage)
      ? ''
      : 'Please enter only visible characters';

    this.setState({ fieldValue: value, fieldValueError });
  };

  onKeyDown = (event) => {
    const { key } = event;
    if (key === 'Enter') {
      this.addCustomHeader();
    }
  };

  getCustomHeadersJsx = () => {
    const { bidder: { custom_headers: customHeaders = {} } } = this.props;
    const customHeadersJsx = map(customHeaders, (fieldValue, fieldName) => (
      <Form.Group key={`custom-header-${fieldName}`}>
        <Form.Field width={6} >
          <Form.Input
            readOnly
            transparent
            value={fieldName}
          />
        </Form.Field>
        <Form.Field width={6} >
          <Form.Input
            readOnly
            transparent
            value={fieldValue}
          />
        </Form.Field>
        <Form.Field width={2} >
          <Button
            onClick={this.removeCustomHeader(fieldName)}
            type="button"
          >
            Remove
          </Button>
        </Form.Field>
      </Form.Group>
    ));

    return customHeadersJsx;
  };

  addCustomHeader = () => {
    const {
      bidder: {
        custom_headers: customHeaders = {},
        id,
      },
      setBidder,
    } = this.props;
    const { fieldName, fieldValue } = this.state;

    const nextCustomHeaders = Object.assign({}, customHeaders, { [fieldName]: fieldValue });
    setBidder(id, { custom_headers: nextCustomHeaders });
    this.setState({ fieldName: '', fieldValue: '' });
  };

  removeCustomHeader = attr => () => {
    const {
      bidder: {
        custom_headers: customHeaders = {},
        id,
      },
      setBidder,
    } = this.props;

    setBidder(id, { custom_headers: omit(customHeaders, attr) });
  };

  render() {
    const {
      fieldName,
      fieldNameError,
      fieldValue,
      fieldValueError,
    } = this.state;

    const isButtonDisabled = isEmpty(fieldName)
      || !isEmpty(fieldNameError)
      || !isEmpty(fieldValueError);

    return (
      <div>
        {this.getCustomHeadersJsx()}
        <Form.Group>
          <Form.Field width={6}>
            <input
              inputMode="latin"
              onChange={this.onFieldNameChange}
              pattern="[a-zA-Z][a-zA-Z0-9-]*"
              placeholder="X-Custom-Header"
              title="Please enter alphanumeric characters separated by a dash (-)"
              type="text"
              value={fieldName}
            />
            {
              isEmpty(fieldNameError)
                ? null
                : <Label basic color="red" pointing>{fieldNameError}</Label>
            }
          </Form.Field>
          <Form.Field width={6}>
            <input
              inputMode="latin"
              onChange={this.onFieldValueChange}
              onKeyDown={this.onKeyDown}
              pattern="^[ \t]*(?:[\x20-\x7E\x80-\xFF](?:[ \t]+[\x20-\x7E\x80-\xFF])?)*[ \t]*$"
              placeholder="value"
              title="Please enter only visible characters"
              type="text"
              value={fieldValue}
            />
            {
              isEmpty(fieldValueError)
                ? null
                : <Label basic color="red" pointing>{fieldValueError}</Label>
            }
          </Form.Field>
          <Form.Field width={2}>
            <Button
              disabled={isButtonDisabled}
              onClick={this.addCustomHeader}
              type="button"
            >
              Add
            </Button>
          </Form.Field>
        </Form.Group>
      </div>
    );
  }
}

export default CustomHeaders;

CustomHeaders.defaultProps = {
  bidder: {},
  setBidder: () => {},
};

CustomHeaders.propTypes = {
  bidder: PropTypes.shape({}),
  setBidder: PropTypes.func,
};
