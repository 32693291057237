import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Grid, Header, Segment, List } from 'semantic-ui-react';
import SearchInput from '../components/form/SearchInput';
import CBModal from '../components/layout/CBModal';
import { retrieveFlags, addFeatures, fetchAdminCompanies, retrieveCompanies } from '../store/users/actions';
import { getCompany } from '../store/users/reducer';
import '../styles/AddFeatureFlag.css';
import Users from '../components/Users';

class AddFeatureFlag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      companiesToAdd: [],
      flag: '',
      newFlag: '',
      showModal: false,
    };
    this.selectFlag = this.selectFlag;
  }

  componentDidMount() {
    const {
      getFlags,
      getCompanies,
      getAdminCompanies,
      flags,
      companies,
      adminCompanies,
      companiesMaxRow,
    } = this.props;

    if (isEmpty(flags)) {
      getFlags();
    }
    if (isEmpty(companies) || companies.length !== companiesMaxRow) {
      getCompanies();
    }
    if (isEmpty(adminCompanies)) {
      getAdminCompanies();
    }
  }

  flagsOptionList() {
    const { flags } = this.props;
    const { newFlag } = this.state;
    if (!isEmpty(newFlag)) {
      flags.push(newFlag);
    }
    return flags.map(flag => (
      { key: flag, value: flag, text: flag }
    ));
  }

  updateQueryState(query) {
    this.setState({ query });
  }

  handleSearch() {
    const { getCompanies } = this.props;
    const { query } = this.state;
    const opt = {};
    if (!isEmpty(query)) {
      opt.query = query;
      opt.fields = '_id&fields=company';
    }
    getCompanies(opt);
  }

  companiesList() {
    let { companies } = this.props;
    const { companiesToAdd } = this.state;
    companies = companies.filter(company => (
      !companiesToAdd.includes(company._id)
    ));
    return (
      <List verticalAlign="middle">
        {companies.map(company => (
          <List.Item>
            <List.Content floated="right">
              <Button icon="add" onClick={() => this.selectCompany(company._id)} className="button__icon" />
            </List.Content>
            <List.Content>{company.company} (<b>{company._id}</b>)</List.Content>
          </List.Item>
        ))}
      </List>
    );
  }

  companiesToAddList() {
    const { companies } = this.props;
    const { companiesToAdd } = this.state;
    return (
      <List verticalAlign="middle">
        {companiesToAdd.map((companyId) => {
          const company = (getCompany(companies, companyId)
            || { _id: companyId, company: companyId });
          return (
            <List.Item>
              <List.Content floated="right">
                <Button icon="minus" onClick={() => this.selectCompany(company._id)} className="button__icon" />
              </List.Content>
              <List.Content>{company.company} (<b>{company._id}</b>)</List.Content>
            </List.Item>
          );
        })}
      </List>
    );
  }

  selectCompany(id) {
    let { companiesToAdd } = this.state;
    if (companiesToAdd.includes(id)) {
      companiesToAdd = companiesToAdd.filter(companyId => companyId !== id);
    } else {
      companiesToAdd.push(id);
    }

    this.setState({ companiesToAdd });
  }

  handleIncludeAdmins() {
    this.setState({ showModal: true });
  }

  includeAdmins(add) {
    this.setState({ showModal: false });
    if (add) {
      const { adminCompanies } = this.props;
      let { companiesToAdd } = this.state;

      const adminsToAdd = adminCompanies.filter(company => (
        !companiesToAdd.includes(company._id)
      )).map(company => company._id);

      if (!isEmpty(adminsToAdd)) {
        companiesToAdd = [...companiesToAdd, ...adminsToAdd];
      }

      this.setState({ companiesToAdd });
    }
  }

  selectFlag(flag) {
    this.setState({ flag });
  }

  handleAddFlags() {
    const { companiesToAdd, flag } = this.state;
    const { addFeatures: addFeaturesToCompanies } = this.props;
    addFeaturesToCompanies(companiesToAdd, flag);
  }

  addNewFlag(newFlag) {
    this.setState({ newFlag });
  }

  invalidToSave() {
    const { companiesToAdd, flag } = this.state;
    return isEmpty(companiesToAdd) || isEmpty(flag);
  }

  render() {
    const { location } = this.props;
    const { query, flag, showModal } = this.state;
    return (
      <Users location={location}>
        <div className="addFeatureFlags">
          <Grid centered>
            <CBModal
              title="Alert"
              message="Are you sure you want to add a Feature Flag to all CB Admins Users?"
              handleClickConfirm={() => { this.includeAdmins(true); }}
              handleClickCancel={() => { this.includeAdmins(false); }}
              open={showModal}
            />
            <Grid.Row columns={2} className="header">
              <Grid.Column floated="right">
                <Header as="h2" className="header__tittle">Add Feature Flag</Header>
              </Grid.Column>
              <Grid.Column>
                <Button
                  as={Link}
                  content="Add Flag"
                  floated="right"
                  icon="plus"
                  labelPosition="right"
                  primary
                  disabled={this.invalidToSave()}
                  onClick={() => this.handleAddFlags()}
                  to="feature-flag"
                  positive
                />
                <Button
                  as={Link}
                  content="Cancel"
                  floated="right"
                  to="feature-flag"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row verticalAlign="middle" columns={2} >
              <Grid.Column width={2} className="label" textAlign="right">
                <b>Feature Flag</b>
              </Grid.Column>
              <Grid.Column width={14} className="input" textAlign="left">
                <Dropdown
                  fluid
                  search
                  selection
                  scrolling
                  allowAdditions
                  options={this.flagsOptionList()}
                  value={flag}
                  placeholder="Select"
                  onAddItem={(e, { value }) => this.addNewFlag(value)}
                  onChange={(e, { value }) => this.selectFlag(value)}
                  className="flagSelector"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={2} />
              <Grid.Column width={14} textAlign="left">
                <p className="warningFusionFlagMessage">Do not edit (add or remove) Fusion feature flags: alpha, beta, gamma! If you need to edit Fusion flag, first contact Product Team (Sunayana & Cela).</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={2} className="label" textAlign="right">
                <b>Company</b>
              </Grid.Column>
              <Grid.Column width={14} className="input" textAlign="left">
                <Segment className="companiesSelector">
                  <SearchInput
                    placeholder="Search by Company name and ID..."
                    query={query}
                    updateQueryState={value => this.updateQueryState(value)}
                    searchFunc={() => this.handleSearch()}
                  />
                  <Grid columns={2} celled>
                    <Grid.Row>
                      <Grid.Column width={8} className="companiesList">
                        {this.companiesList()}
                      </Grid.Column>
                      <Grid.Column width={8} className="companiesList">
                        {this.companiesToAddList()}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <div className="footer">
                    <Button
                      content="Include all CB admins"
                      onClick={() => this.handleIncludeAdmins()}
                    />
                  </div>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid >
        </div >
      </Users>
    );
  }
}

AddFeatureFlag.defaultProps = {
  flags: [],
  companies: [],
  adminCompanies: [],
  location: {
    pathname: '/',
    hash: '',
  },
};

AddFeatureFlag.propTypes = {
  flags: PropTypes.arrayOf(PropTypes.string),
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  adminCompanies: PropTypes.arrayOf(PropTypes.shape({})),
  getFlags: PropTypes.func.isRequired,
  getCompanies: PropTypes.func.isRequired,
  addFeatures: PropTypes.func.isRequired,
  getAdminCompanies: PropTypes.func.isRequired,
  companiesMaxRow: PropTypes.number.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hash: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  flags: state.users.flags,
  companies: state.users.companies.data,
  adminCompanies: state.users.adminCompanies,
  companiesMaxRow: state.users.companies.maxRow,
});

const mapDispatchToProps = dispatch => ({
  getFlags: () => dispatch(retrieveFlags()),
  getCompanies: opt => dispatch(retrieveCompanies(opt)),
  addFeatures: (companies, flag) => dispatch(addFeatures(companies, flag)),
  getAdminCompanies: () => dispatch(fetchAdminCompanies()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFeatureFlag);
