import { LOADED, LOADING } from '../../shared/constants/readyStates';
import { RETRIEVED_PLAYABLES, SELECT_PLAYABLE, UNSELECT_PLAYABLE, TOTAL_PLAYABLES, PLAYABLES_PAGES } from './actions';
import { rehydrate } from '../../store/localStorage';

const initState = {
  loading: true,
  playables: [],
  playablesPages: {},
  selectedPlayable: null,
  totalPlayables: 0,
};

const reducer = (state = rehydrate('playables_validation', initState), action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };

    case LOADED:
      return { ...state, loading: false };

    case PLAYABLES_PAGES:
      return {
        ...state,
        playablesPages: {
          ...state.playablesPages,
          [action.page]: action.playables,
        },
      };

    case RETRIEVED_PLAYABLES:
      return {
        ...state,
        playables: action.playables,
      };

    case SELECT_PLAYABLE:
      return { ...state, selectedPlayable: action.playable };

    case UNSELECT_PLAYABLE:
      return { ...state, selectedPlayable: null };

    case TOTAL_PLAYABLES:
      return { ...state, totalPlayables: action.totalPlayables };
    default:
      return state;
  }
};

export default reducer;
