import Overview from '../bundles/overview/Overview';
// Integrations
import IntegrationsOverview from '../bundles/integrations/overview/views/Overview';
import Discrepancy from '../bundles/integrations/discrepancy/views/DiscrepancyOverview';
import DemandAggregator from '../bundles/integrations/demand_aggregator/views/DemandAggregatorOverview';
// Exchange
import { GlobalEdit } from '../bundles/exchange/globals/containers';
import { BidderEdit, BidderList } from '../bundles/exchange/bidders/containers';
import { PublisherEdit, PublisherList } from '../bundles/exchange/publishers/containers';
import { CreativeValidation } from '../bundles/exchange/creatives/containers';
// yield
import { FlexbidsList, FlexbidCreate } from '../bundles/yield/flexbids/containers';

import PublishingApps from '../bundles/publishing_apps';
import PlayablesValidation from '../bundles/playables_validation';
// PMP
import PrivateMarketplaceOverview from '../ui/views/PrivateMarketplaceOverview';
import PrivateMarketplaceEdit from '../ui/views/PrivateMarketplaceEdit';
// Users
import UserManagement from '../ui/views/UserManagement';
import FeatureFlagOverview from '../ui/views/FeatureFlag';
import AddfeatureFlag from '../ui/views/AddFeatureFlag';
import UserPendingListContainer from '../ui/views/UserPending';

// Creatives
import Creatives from '../bundles/creatives/containers/Creatives';

import CBExchangeOverview from '../ui/views/CBExchangeOverview';
import CBExchangeBlockView from '../ui/views/CBExchangeBlockView';
import CBExchangeSummaryView from '../ui/views/CBExchangeSummaryView';

// Phases
import Phases from '../ui/views/Phases';

// S2S Tracking
import S2STracking from '../ui/views/S2STracking';

// Not Found
import NotFoundView from '../bundles/notFound/containers';

const ROUTES = {
  PUBLIC: {
    pmp: {
      component: PrivateMarketplaceOverview,
      path: '/public/pmp',
    },
    pmpEdit: {
      component: PrivateMarketplaceEdit,
      path: '/public/pmp/:id',
    },
  },
  ADMIN: {
    overview: {
      component: Overview,
      path: '/',
      protected: true,
    },
    integrations: {
      redirectTo: '/integrations/overview',
      path: '/integrations',
      childPaths: {
        integrationsOverview: {
          component: IntegrationsOverview,
          path: '/overview',
        },
        demand_aggregator: {
          component: DemandAggregator,
          path: '/demand_aggregator',
        },
        discrepancy: {
          component: Discrepancy,
          path: '/discrepancy',
        },
      },
    },
    exchange: {
      redirectTo: '/exchange/bidders',
      path: '/exchange',
      protected: true,
      childPaths: {
        BidderList: {
          component: BidderList,
          path: '/bidders',
        },
        bidderEdit: {
          component: BidderEdit,
          path: '/bidders/:id',
        },
        publisherList: {
          component: PublisherList,
          path: '/publishers',
        },
        publisherEdit: {
          component: PublisherEdit,
          path: '/publishers/:id',
        },
        globalEdit: {
          component: GlobalEdit,
          path: '/global',
        },
        creativeValidation: {
          component: CreativeValidation,
          path: '/creatives',
        },
      },
    },
    yield: {
      redirectTo: '/yield/flexbids',
      path: '/yield',
      protected: true,
      childPaths: {
        flexbidsList: {
          component: FlexbidsList,
          path: '/flexbids',
          childPaths: {
            flexbidCreate: {
              component: FlexbidCreate,
              path: '/create',
            },
            flexbidEdit: {
              component: FlexbidCreate,
              path: '/:id',
            },
          },
        },
      },
    },
    templates: {
      component: null,
      path: `${process.env.REACT_APP_CREATIVES_DASHBOARD_URL}/templates`,
    },
    publishingApps: {
      component: PublishingApps,
      path: '/publishing_apps',
    },
    playablesValidation: {
      component: PlayablesValidation,
      path: `${process.env.REACT_APP_CREATIVES_DASHBOARD_URL}/playables-validation`,
    },
    pmp: {
      component: PrivateMarketplaceOverview,
      path: '/pmp',
    },
    pmpEdit: {
      component: PrivateMarketplaceEdit,
      path: '/pmp/:id',
    },
    users: {
      component: UserManagement,
      path: '/users',
      childPaths: {
        featureFlag: {
          component: FeatureFlagOverview,
          path: '/feature-flag',
        },
        newFeatureFlag: {
          component: AddfeatureFlag,
          path: '/new',
        },
        userManagement: {
          component: UserManagement,
          path: '/user-management',
        },
        userPending: {
          component: UserPendingListContainer,
          path: '/user_pending',
        },
      },
    },
    creatives: {
      component: Creatives,
      path: '/creatives',
      protected: true,
    },
    CBExchangeOverview: {
      component: CBExchangeOverview,
      path: '/cb-exchange',
      protected: true,
      childPaths: {
        cbExchangeBlockEdit: {
          component: CBExchangeBlockView,
          path: '/:action',
          childPaths: {
            cbExchangeSummary: {
              component: CBExchangeSummaryView,
              path: '/:editionOption/summary',
            },
          },
        },
      },
    },
    phases: {
      component: Phases,
      path: '/phases',
      protected: true,
    },
    s2sTracking: {
      component: S2STracking,
      path: '/s2stracking',
      protected: true,
    },
    notFound: {
      component: NotFoundView,
      path: '*',
    },
  },
};

export const PUBLIC_PATHS = Object.values(ROUTES.PUBLIC).map(route => route.path);

export default ROUTES;
