import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import union from 'lodash/union';

import * as actions from '../actions';
import validator from '../validator';

const errorsReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actions.SET_BIDDER:
    case actions.VALIDATE_BIDDER: {
      const errors = validator(action.attrs, action.errors);
      const stateKeys = Object.keys(state);
      const attrKeys = Object.keys(action.attrs);
      const errorKeys = Object.keys(errors);

      // Determine which errors to remove and which to keep
      const addKeys = intersection(attrKeys, errorKeys);
      const removeKeys = difference(attrKeys, errorKeys);
      const pickKeys = difference(union(stateKeys, addKeys), removeKeys);
      return pick(Object.assign({}, state, errors), pickKeys);
    }

    case actions.REMOVE_ERRORS:
      return {};

    default:
      return state;
  }
};

export default errorsReducer;
