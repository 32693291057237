import { LOADING } from '../../../../src/shared/constants/readyStates';

const getStore = state => state.integrations.demand_aggregator;
const getData = state => getStore(state).filteredData;
const getFilters = state => getStore(state).filters;
const getLoading = state => getStore(state).readyState === LOADING;
const getMessage = state => getStore(state).message;
const getIsError = state => getStore(state).isError;
const getShowMessage = state => getStore(state).showMessage;
const getOriginalData = state => getStore(state).originalData;

const DemandAggregatorStore = {
  getData,
  getFilters,
  getLoading,
  getMessage,
  getIsError,
  getShowMessage,
  getOriginalData
};

export default DemandAggregatorStore;
