import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Button, Grid, Message } from 'semantic-ui-react';
import CSVFile from '../../../domain/CSVFile';

class UploadCSVList extends Component {
  constructor(props) {
    super(props);

    this.state = { errorMessage: null };

    this.hiddenInput = null;
  }

  handleFileData(data) {
    const { addCSVList, maxRows, placeholder } = this.props;

    if (!data) {
      this.setState({ errorMessage: `Only CSV files with less than ${maxRows} ${placeholder}` });
    } else {
      this.setState({ errorMessage: null });
      addCSVList(data);
    }
  }

  handleUpload(e) {
    if (!isEmpty(e.target.files)) {
      const [fileInfo] = e.target.files;
      if (fileInfo.type !== 'text/csv') {
        this.setState({ errorMessage: 'The list should be a valid CSV file' });
      } else {
        CSVFile.readCSVFile(fileInfo, data => this.handleFileData(data));
      }
    }
  }

  render() {
    const { errorMessage } = this.state;
    return (
      <Grid>
        <Grid.Column floated="right" textAlign="right">
          <div>
            <Button
              onClick={() => this.hiddenInput.click()}
              content="Upload list"
            />
            <input
              hidden
              ref={(el) => {
                this.hiddenInput = el;
              }}
              type="file"
              onClick={(e) => {
                // Reset value for always trigger onChange event
                e.target.value = '';
              }}
              onChange={e => this.handleUpload(e)}
              accept=".csv"
            />
          </div>
          {
            errorMessage &&
            (<Message negative>{errorMessage}</Message>)
          }
        </Grid.Column>
      </Grid>);
  }
}

UploadCSVList.defaultProps = { placeholder: '' };

UploadCSVList.propTypes = {
  addCSVList: PropTypes.func.isRequired,
  maxRows: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
};

export default UploadCSVList;
