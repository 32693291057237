import uniqBy from 'lodash/uniqBy';
import capitalize from 'lodash/capitalize';

export const prepareData = partner => {
  return {
    partner
  };
};

export const getUniquePartners = data => {
  const partnerList = uniqBy(data, 'partner');
  const partners = partnerList.map(partnerObject => {
    return { text: capitalize(partnerObject.partner), value: partnerObject.partner };
  });
  return partners;
};

const DiscrepancyHelpers = {
  prepareData,
  getUniquePartners
};

export default DiscrepancyHelpers;
