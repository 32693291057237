import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

const PublishingAppCardAppStatusApprovedButton = (props) => {
  const { status } = props;
  return (
    <Button basic color="green">
      <Icon name="check" />
      {status}
    </Button>
  );
};

PublishingAppCardAppStatusApprovedButton.defaultProps = {};
PublishingAppCardAppStatusApprovedButton.propTypes = { status: PropTypes.string.isRequired };

export default PublishingAppCardAppStatusApprovedButton;
