/* eslint-disable no-shadow, jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Divider, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { fetchFlexbids, resetFlexbid, toggleShowDeleted } from '../actions';
import { PageLoader, PageHeader } from '../../../../shared/components';
import { YieldMenu } from '../../shared/components/';
import { FlexbidRows } from '../containers';
import { selectAllFlexbids, selectIsLoadingFlexbids, selectShowDeleted } from '../selectors';

class FlexbidsList extends Component {
  componentDidMount() {
    this.props.fetchFlexbids(this.props.showDeleted);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showDeleted !== this.props.showDeleted) {
      this.props.fetchFlexbids(this.props.showDeleted);
    }
  }

  render() {
    const { loading, flexbids, showDeleted, resetFlexbid, toggleShowDeleted } = this.props;
    return (
      <Container className="FlexbidsList">
        <PageLoader isLoading={loading} />
        <YieldMenu />
        <Button
          as={Link}
          content="New Flexbid"
          floated="right"
          icon="plus"
          labelPosition="left"
          primary
          to="/yield/flexbids/create"
          onClick={resetFlexbid}
        />
        <Divider />
        <PageHeader content="Flexbids" />
        <Checkbox
          label={<label style={{ color: 'white' }}>Show deleted Flexbids</label>}
          checked={showDeleted}
          onChange={toggleShowDeleted}
        />
        <FlexbidRows flexbids={flexbids} />
      </Container>
    );
  }
}

FlexbidsList.propTypes = {
  loading: PropTypes.bool.isRequired,
  flexbids: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchFlexbids: PropTypes.func.isRequired,
  resetFlexbid: PropTypes.func.isRequired,
  showDeleted: PropTypes.bool.isRequired,
  toggleShowDeleted: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loading: selectIsLoadingFlexbids(state),
  flexbids: selectAllFlexbids(state),
  showDeleted: selectShowDeleted(state),
});

const mapDispatchToProps = dispatch => ({
  fetchFlexbids: showDelete => dispatch(fetchFlexbids(showDelete)),
  resetFlexbid: () => dispatch(resetFlexbid()),
  toggleShowDeleted: () => dispatch(toggleShowDeleted()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlexbidsList);
