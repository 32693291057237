import PropTypes from 'prop-types';
import React from 'react';
import { API_GATEWAY } from '../../../shared/api';

const PublishingAppCardCategorization = (props) => {
  const { app } = props;

  return (
    <div className="app categorization" >
      <h2>App Categorization</h2>
      <a
        id="app-categorization"
        href={`${API_GATEWAY}/dashadmin/admin/app_category_audit/edit/?app-id=${app._id}&context=audit_page`}
        rel="noopener noreferrer"
        target="_blank"
      >
        App Categorization
      </a>
    </div>
  );
};

PublishingAppCardCategorization.defaultProps = {};

PublishingAppCardCategorization.propTypes = { app: PropTypes.shape({}).isRequired };

export default PublishingAppCardCategorization;
