import difference from 'lodash/difference';
import union from 'lodash/union';

import * as fromBidders from '../actions';

const searchingBundleIds = (state = [], action = {}) => {
  switch (action.type) {
    case fromBidders.SEARCH_BUNDLE_ID:
      return union(state, [action.bundleId]);

    case fromBidders.SEARCH_BUNDLE_ID_FAILURE:
    case fromBidders.SEARCH_BUNDLE_ID_SUCCESS:
      return difference(state, [action.bundleId]);

    default:
      return state;
  }
};

export default searchingBundleIds;
