import getter from 'lodash/get';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import setter from 'lodash/set';
import { Form } from 'semantic-ui-react';

import CampaignId from './CampaignId';

const CampaignOption = (props) => {
  const { bidder, path, setBidder } = props;

  const onClick = () => {
    const nextCampaigns = pick(bidder, 'campaigns');
    const enabled = !getter(nextCampaigns, `${path}.enabled`);
    setter(nextCampaigns, `${path}.enabled`, enabled);
    setBidder(bidder.id, nextCampaigns);
  };

  return (
    <Form.Checkbox
      className="campaign"
      onClick={onClick}
      label={<CampaignId {...props} />}
      style={{ width: '100%' }}
      value={path}
    />
  );
};

export default CampaignOption;

CampaignOption.defaultProps = {
  bidder: {},
  path: '',
  setBidder: () => {},
};

CampaignOption.propTypes = {
  bidder: PropTypes.shape({}),
  path: PropTypes.string,
  setBidder: PropTypes.func,
};
