import { get, isEmpty, uniqBy } from 'lodash';

import { DASH_API } from '../../shared/api';
import FusionAPI from '../../services/fusionApi';
import { LOADING, LOADED } from '../../shared/constants/readyStates';
import { isObjectId, axios } from '../../shared/helpers';

export const INIT_CREATIVES_PAGE = 'CREATIVES.INIT_CREATIVES_PAGE';
export const RETRIEVED_CREATIVE = 'CREATIVES.RETRIEVED_CREATIVE';
export const INVALID_CREATIVE_ID = 'CREATIVES.INVALID_CREATIVE_ID';
export const NOT_VIDEO_CREATIVE = 'CREATIVES.CREATIVE_NOT_VIDEO';
export const ERROR_RETRIEVING_CREATIVE = 'CREATIVES.ERROR_RETRIEVING_CREATIVE';
export const RETRIEVED_CREATIVE_CAMPAIGNS = 'CREATIVES.RETRIEVED_CREATIVE_CAMPAIGNS';
export const ERROR_FETCHING_CREATIVE_CAMPAIGNS = 'CREATIVES.ERROR_FETCHING_CREATIVE_CAMPAIGNS';
export const EMPTY_CREATIVE_CAMPAIGNS = 'CREATIVES.EMPTY_CREATIVE_CAMPAIGNS';
export const INVALID_CREATIVE_TYPE = 'INVALID_CREATIVE_TYPE';
export const ERROR_RETRIEVING_TEMPLATE = 'CREATIVES.ERROR_RETRIEVING_TEMPLATE';
export const EMPTY_CREATIVE_TEMPLATE = 'CREATIVES.EMPTY_CREATIVE_TEMPLATE';
export const ERROR_DOWNLOADING_TEMPLATE = 'ERROR_DOWNLOADING_TEMPLATE';
export const RETRIEVED_CREATIVE_TEMPLATE = 'CREATIVES.RETRIEVED_CREATIVE_TEMPLATE';
export const TOGGLE_EXCHANGE_CREATIVE = 'CREATIVES.TOGGLE_EXCHANGE_CREATIVE';

export const initCreativesPage = () => (dispatch) => {
  dispatch({ type: INIT_CREATIVES_PAGE });
};

export const toggleExchangeCreative = () => (dispatch) => {
  dispatch({ type: TOGGLE_EXCHANGE_CREATIVE });
};

export const getExchangeCreativeById = creativeID => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const response = await axios.get(`${DASH_API}/exchange/creatives?creative_id=${creativeID}`);

    // the dashapi when the creative not exists returns an empty response array
    if (get(response, 'data.response.creatives', []).length === 0) {
      dispatch({
        type: ERROR_RETRIEVING_CREATIVE,
        creative: null,
        message: ['not-exchange-creative'],
      });

      return;
    }

    dispatch({
      type: RETRIEVED_CREATIVE,
      creative: response.data.response.creatives,
    });

    dispatch({ type: LOADED });
  } catch (error) {
    dispatch({
      type: ERROR_RETRIEVING_CREATIVE,
      creative: null,
      error,
      message: ['find-creative-failure'],
    });
  }
};

export const getCreativeById = creativeId => async (dispatch) => {
  dispatch({ type: LOADING });

  if (!isObjectId(creativeId)) {
    dispatch({
      type: INVALID_CREATIVE_ID,
      creative: null,
      message: ['creative-invalid-id'],
    });

    return;
  }

  try {
    const response = await axios.get(`${DASH_API}/creatives/${creativeId}`);

    if (response.data.media_type !== 2) {
      dispatch({
        type: NOT_VIDEO_CREATIVE,
        creative: response.data,
      });
      return;
    }

    dispatch({
      type: RETRIEVED_CREATIVE,
      creative: response.data,
    });

    dispatch({ type: LOADED });
  } catch (error) {
    dispatch({
      type: ERROR_RETRIEVING_CREATIVE,
      creative: null,
      error,
      message: ['find-creative-failure'],
    });
  }
};

export const getCreativeCampaigns = creativeID => async (dispatch) => {
  try {
    const response = await axios.get(`${DASH_API}/creatives/${creativeID}/campaigns`);
    // get campaigns and filter by id to avoid repeat campaigns
    const filteredCampaigns = uniqBy(response.campaigns, 'campaign_id');

    if (isEmpty(filteredCampaigns)) {
      dispatch({ type: EMPTY_CREATIVE_CAMPAIGNS });
    } else {
      dispatch({
        type: RETRIEVED_CREATIVE_CAMPAIGNS,
        creative_campaigns: filteredCampaigns,
      });
    }
  } catch (error) {
    dispatch({
      type: ERROR_FETCHING_CREATIVE_CAMPAIGNS,
      error,
    });
  }
};

export const getCreativeTemplate = creativeType => async (dispatch) => {
  if (!creativeType) {
    dispatch({
      type: INVALID_CREATIVE_TYPE,
      creative_template: null,
      message: ['creative-invalid-type'],
    });

    return;
  }
  try {
    const response = await FusionAPI.get('templates');
    const creativeTemplate = response.items.filter(template => template.active
      && template.default
      && template.creative_type === creativeType)[0];
    const creativeTemplateS3Url = get(creativeTemplate, 'url', '');

    if (isEmpty(creativeTemplateS3Url)) {
      dispatch({
        type: EMPTY_CREATIVE_TEMPLATE,
        creative_template: null,
        message: ['creative-empty-template'],
      });
      return;
    }

    const html = await axios.get(creativeTemplateS3Url, { withCredentials: false });
    if (isEmpty(html)) {
      dispatch({
        type: ERROR_DOWNLOADING_TEMPLATE,
        creative_template: null,
        message: ['creative-error-downloading-template'],
      });
      return;
    }

    dispatch({
      type: RETRIEVED_CREATIVE_TEMPLATE,
      creative_template: html.data,
      creative_template_name: get(creativeTemplate, 'name', ''),
      creative_template_s3: creativeTemplateS3Url,
    });
  } catch (error) {
    dispatch({
      type: ERROR_RETRIEVING_TEMPLATE,
      error,
      message: ['fetch-creative-template-failure'],
    });
  }
};
