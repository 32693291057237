import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Input } from 'semantic-ui-react';

import './InlineEdit.css';

class InlineEdit extends Component {
  constructor() {
    super();
    this.handleRef = this.handleRef.bind(this);
  }

  handleRef(c) { this.ref = c; }

  render() {
    const { active, cancelable, cancelEdit, confirmEdit, edit, editable, value } = this.props;
    return active
      ? (
        <Input action fluid defaultValue={value} ref={this.handleRef}>
          <input />
          <Button icon="check" onClick={() => { confirmEdit(this.ref.inputRef.value); }} />
          {cancelable && <Button icon="cancel" onClick={cancelEdit} />}
        </Input>
      )
      : (
        <div>
          <div className="InlineEdit__value">{value}</div>
          {editable && <Button basic floated="right" icon="compose" onClick={edit} />}
        </div>
      );
  }
}

InlineEdit.defaultProps = {
  active: false,
  cancelable: false,
  cancelEdit: () => {},
  confirmEdit: () => {},
  edit: () => {},
  editable: false,
  value: null,
};

InlineEdit.propTypes = {
  active: PropTypes.bool,
  cancelable: PropTypes.bool,
  cancelEdit: PropTypes.func,
  confirmEdit: PropTypes.func,
  edit: PropTypes.func,
  editable: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default InlineEdit;
