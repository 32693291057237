import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input } from 'semantic-ui-react';

const SearchInput = (props) => {
  const { query, updateQueryState, searchFunc, placeholder } = props;

  return (
    <div className="search">
      <Form onSubmit={searchFunc}>
        <Form.Field inline>
          <Input
            className="prompt"
            type="text"
            icon="search"
            iconPosition="left"
            placeholder={placeholder}
            value={query}
            onChange={e => updateQueryState(e.target.value)}
          />
        </Form.Field>
      </Form>
    </div>
  );
};

SearchInput.defaultProps = { placeholder: 'Search...' };

SearchInput.propTypes = {
  searchFunc: PropTypes.func.isRequired,
  updateQueryState: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default SearchInput;
