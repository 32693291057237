/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Sidebar } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { fetchExperience } from '../../user/actions';
import {
  selectHasActiveRole,
  selectIsFetching,
  selectLoggedIn,
  selectShouldFetch
} from '../../user/selectors';
import { selectMessages, selectOnline } from '../selectors';

import './Shell.css';

class PublicShell extends Component {
  componentDidMount() {
    const { fetchExperience, online, shouldFetch } = this.props;
    if (online && shouldFetch) {
      fetchExperience();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (window.ga && nextProps.location !== this.props.location) {
      window.ga('set', 'page', nextProps.location.pathname);
      window.ga('send', 'pageview');
    }
  }

  render() {
    const { children, hasActiveRole, loading } = this.props;

    if (loading === 0 && !hasActiveRole) {
      window.location = '//dashboard.chartboost.com';
    }
    return (
      <Sidebar.Pushable className="Shell Public">
        <Container className="Container">{children}</Container>
      </Sidebar.Pushable>
    );
  }
}

PublicShell.defaultProps = {
  fetchExperience: () => {},
  hasActiveRole: true,
  loading: false,
  location: { pathname: '/' },
  online: true,
  shouldFetch: false
};

PublicShell.propTypes = {
  children: PropTypes.node.isRequired,
  fetchExperience: PropTypes.func,
  hasActiveRole: PropTypes.bool,
  loading: PropTypes.bool,
  location: PropTypes.shape({ pathname: PropTypes.string }),
  online: PropTypes.bool,
  shouldFetch: PropTypes.bool
};

const mapStateToProps = state => ({
  hasActiveRole: selectHasActiveRole(state),
  isLoggedIn: selectLoggedIn(state),
  isFetching: selectIsFetching(state),
  messages: selectMessages(state),
  online: selectOnline(state),
  shouldFetch: selectShouldFetch(state)
});

const mapDispatchToProps = dispatch => ({ fetchExperience: () => dispatch(fetchExperience()) });

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PublicShell)
);
