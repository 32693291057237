/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Card,
  List,
  Grid,
  Divider,
  Tab,
  Icon,
  Popup,
} from 'semantic-ui-react';
import truncate from 'lodash/truncate';
import isEmpty from 'lodash/isEmpty';
import Clipboard from 'clipboard';

import './styles/CreativeCard.css';
import { getCreativeTypeById, formatDate } from '../utils';
import CampaignsList from './CampaignsList';
import CreativeVisualization from './CreativeVisualization';
import CreativeAssets from './CreativeAssets';

const initClipboard = () => new Clipboard('#adm-copy');
class CreativeCard extends Component {
  constructor(props) {
    super(props);

    this.handleRef = this.handleRef.bind(this);
  }

  componentDidMount() {
    initClipboard();
  }

  handleRef(c) { this.ref = c; }

  render() {
    const {
      creative,
      creativeCampaigns,
      creativeCampaignsLoaded,
      creativeTemplate,
      creativeTemplateLoaded,
      creativeTemplateName,
      creativeTemplateS3,
      getCreativeCampaigns,
      getCreativeTemplate,
    } = this.props;
    const creativeType = getCreativeTypeById(creative.media_type);
    const metamarketsURL = `https://dash.metamarkets.com/chartboost/bi_bd#fs.0.k=creative_name&fs.0.v.0=${creative.name} (${creative._id})`;
    const panes = [
      {
        menuItem: 'Visualization',
        render: () => {
          if (isEmpty(creativeTemplate) && !creativeTemplateLoaded) {
            getCreativeTemplate(creative.media_type);
          }
          return (
            <Tab.Pane attached={false} loading={!creativeTemplateLoaded}>
              <CreativeVisualization
                creative={creative}
                template={creativeTemplate}
                templateName={creativeTemplateName}
                templateS3={creativeTemplateS3}
              />
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: 'Campaigns',
        render: () => {
          if (creativeCampaigns.length === 0 && !creativeCampaignsLoaded) {
            getCreativeCampaigns(creative._id);
          }

          return (
            <Tab.Pane attached={false} loading={!creativeCampaignsLoaded}>
              <CampaignsList
                campaigns={creativeCampaigns}
              />
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: 'Assets',
        render: () => (
          <Tab.Pane attached={false} loading={!creative}>
            <CreativeAssets
              creative={creative}
            />
          </Tab.Pane>
        ),
      },
    ];

    return (
      <Card fluid id="creativeCard">
        <Card.Content>
          <Card.Content>
            <Grid>
              <Grid.Column>
                <h2>Creative details</h2>
                <List divided relaxed>
                  <List.Item id="creative_id">
                    <List.Icon name="puzzle piece" size="large" verticalAlign="middle" />
                    <List.Content>
                      <List.Header>Creative ID</List.Header>
                      <List.Description>{creative._id}</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item id="creative_name">
                    <List.Icon name="newspaper outline" size="large" verticalAlign="middle" />
                    <List.Content>
                      <List.Header>Creative name</List.Header>
                      <List.Description>{creative.name}</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item id="creative_type">
                    <List.Icon name="search" size="large" verticalAlign="middle" />
                    <List.Content>
                      <List.Header>Creative type</List.Header>
                      <List.Description>{creativeType}</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item id="creative_date">
                    <List.Icon name="calendar outline" size="large" verticalAlign="middle" />
                    <List.Content>
                      <List.Header>Creation date</List.Header>
                      <List.Description><span className="date">{formatDate(creative.date_created)}</span></List.Description>
                    </List.Content>
                  </List.Item>
                  {creative.adm &&
                  <List.Item className="adm">
                    <List.Icon name="code" size="large" verticalAlign="middle" />
                    <List.Content>
                      <List.Header>Creative adm</List.Header>
                      <List.Description>
                        <span>{truncate(creative.adm, { length: 100 })}</span>
                        <Popup
                          content="adm copied!"
                          on="click"
                          position="top center"
                          trigger={<Icon name="copy" id="adm-copy" data-clipboard-text={creative.adm} />}
                        />
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  }
                </List>
              </Grid.Column>
            </Grid>
          </Card.Content>
          <Divider />
          <Card.Content>
            <Grid columns={2}>
              <Grid.Column>
                <h2>App details</h2>
                <List divided relaxed id="creative_appId">
                  <List.Item>
                    <List.Icon name="id badge" size="large" verticalAlign="middle" />
                    <List.Content>
                      <List.Header>App ID</List.Header>
                      <List.Description>{creative.app_id}</List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <h2>User details</h2>
                <List divided relaxed id="creative_userId">
                  <List.Item>
                    <List.Icon name="user" size="large" verticalAlign="middle" />
                    <List.Content>
                      <List.Header>User ID</List.Header>
                      <List.Description>{creative.user_id}</List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </Card.Content>
          <Button
            className="metamarkets-button"
            color="blue"
            onClick={() => window.open(metamarketsURL, '_blank')}
          >
            View creative in Metamarkets
          </Button>
        </Card.Content>
        <Card.Content>
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </Card.Content>
      </Card>
    );
  }
}

CreativeCard.propTypes = {
  creative: PropTypes.shape({}).isRequired,
  creativeCampaigns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  creativeCampaignsLoaded: PropTypes.bool.isRequired,
  creativeTemplate: PropTypes.string.isRequired,
  creativeTemplateLoaded: PropTypes.bool.isRequired,
  creativeTemplateName: PropTypes.string.isRequired,
  creativeTemplateS3: PropTypes.string.isRequired,
  getCreativeCampaigns: PropTypes.func.isRequired,
  getCreativeTemplate: PropTypes.func.isRequired,
};

export default CreativeCard;
