import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import { b64EncodeUnicode } from '../../shared/helpers';

const multiStringReplace = require('multi-string-replace');

export const ASSETS_URL = 'https://a2.chartboost.com';
const CB_APP_ICON = 'https://s3.amazonaws.com/chartboost/apps/icons/57cd281643150f3ec40ba0b1.57.png';
const CB_APP_NAME_PLACEHOLDER = 'App name';
const PORTRAIT_ASPECT_RATIOS = ['2:3', '9:16', '3:4'];
const LANDSCAPE_ASPECT_RATIOS = ['3:2', '16:9', '4:3'];
const VALID_ASSET_SIZES = ['tiny', 'small', 'medium', 'large'];
const VIDEO_VALID_ASPECT_RATIOS = ['16:9', '9:16'];
const VIDEO_VALID_ENCODING = ['hls'];

// Creative media types
export const STATIC_CREATIVE_MEDIA_TYPE = 1;
export const VIDEO_CREATIVE_MEDIA_TYPE = 2;
export const PLAYABLE_CREATIVE_MEDIA_TYPE = 6;
export const MRAID_CREATIVE_MEDIA_TYPE = 7;
export const VAST_CREATIVE_MEDIA_TYPE = 8;

const replaceLocalImagesInTemplate = (template) => {
  const templateImagesRegex = /images\//g;
  return template.replace(templateImagesRegex, '');
};

const getImageAsset = (creative, aspectRatio) => {
  const validAssets = Object.keys(creative.assets).reduce((filteredAssets, ratio) => {
    if (aspectRatio.includes(ratio)) {
      Object.keys(creative.assets[ratio]).reduce((finalAsset, assetSize) => {
        if (VALID_ASSET_SIZES.includes(assetSize)) {
          filteredAssets.push(creative.assets[ratio][assetSize]);
        }
        return finalAsset;
      }, []);
    }
    return filteredAssets;
  }, []);

  return (validAssets.length > 0 && validAssets[0].url)
    ? `${ASSETS_URL}${validAssets[0].url}`
    : '';
};

const prepareStaticCreative = (templateHTML, creative = {}) => {
  if (isEmpty(creative.assets)) {
    return '';
  }

  const staticTemplateParams = [
    { substr: '{% ad_portrait %}', to: getImageAsset(creative, PORTRAIT_ASPECT_RATIOS) },
    { substr: '{% ad_landscape %}', to: getImageAsset(creative, LANDSCAPE_ASPECT_RATIOS) },
    { substr: '{% app_icon %}', to: CB_APP_ICON },
    { substr: '{% app_name %}', to: CB_APP_NAME_PLACEHOLDER },
    { substr: '{% star_rating %}', to: '4.4' },
    { substr: '{% number_ratings %}', to: '3' },
    { substr: '{% device_id %}', to: 'zm8bqHjL6ZReD2D11PBGFiqSRcoHz7V1BezDi089RXDt3F8JJhKOkF+JNy4=' },
    { substr: '{% impression_id %}', to: '' },
    { substr: '{% publisher_app_id %}', to: '' },
    { substr: '{% template_settings %}', to: '' },
  ];

  const templateWithoutLocalImages = replaceLocalImagesInTemplate(templateHTML);
  const parsedTemplate = multiStringReplace(templateWithoutLocalImages, staticTemplateParams);

  return parsedTemplate ? `${parsedTemplate}<ins datetime="${new Date().toString()}" />` : '';
};

const preparePlayableCreative = (templateHTML, creative = {}) => {
  const playableTemplateParams = [
    { substr: '{% adm %}', to: creative.adm },
    { substr: '{% ad_type %}', to: creative.media_type },
    { substr: '{% app_name %}', to: CB_APP_NAME_PLACEHOLDER },
    { substr: '{% crid %}', to: '' },
    { substr: '{% device_id %}', to: '' },
    { substr: '{% impression_id %}', to: '' },
    { substr: '{% publisher_app_id %}', to: '' },
    { substr: '{% template_settings %}', to: '' },
  ];

  return multiStringReplace(templateHTML, playableTemplateParams);
};

const prepareExchangeCreative = (templateHTML, creative = {}) => {
  const base64Adm = b64EncodeUnicode(creative.adm);
  const base64Template = templateHTML.replace('{% encoding %}', 'base64');
  const vastMoatV2TemplateParams = [
    { substr: '{% adm %}', to: base64Adm },
    { substr: '{% ad_type %}', to: creative.media_type },
    { substr: '{% app_name %}', to: CB_APP_NAME_PLACEHOLDER },
  ];

  return multiStringReplace(base64Template, vastMoatV2TemplateParams);
};

const getVideoAssets = (creative) => {
  const validAssets = Object.keys(creative.video_assets).reduce((filteredAssets, ratio) => {
    if (VIDEO_VALID_ASPECT_RATIOS.includes(ratio)) {
      Object.keys(creative.video_assets[ratio]).reduce((finalAsset, assetSize) => {
        if (VIDEO_VALID_ENCODING.includes(assetSize)) {
          filteredAssets.push(creative.video_assets[ratio][assetSize]);
        }
        return finalAsset;
      }, []);
    }
    return filteredAssets;
  }, []);

  return validAssets.length > 0 && validAssets[0].url
    ? validAssets[0].url
    : '';
};

const prepareVideoCreative = (templateHTML, creative = {}) => {
  if (isEmpty(creative.video_assets)) {
    return '';
  }

  const videoTemplateParams = [
    { substr: '{% ad_type %}', to: '8' },
    { substr: '{% show_close_button %}', to: 'true' },
    { substr: '{% ad_portrait %}', to: getImageAsset(creative, PORTRAIT_ASPECT_RATIOS) },
    { substr: '{% ad_landscape %}', to: getImageAsset(creative, LANDSCAPE_ASPECT_RATIOS) },
    { substr: '{% language %}', to: 'en' },
    { substr: '{% video_landscape %}', to: getVideoAssets(creative) },
    { substr: '{% video_link %}', to: getVideoAssets(creative) },
    { substr: '{% app_icon %}', to: CB_APP_ICON },
    { substr: '{% app_name %}', to: CB_APP_NAME_PLACEHOLDER },
    { substr: '{% star_rating %}', to: '4.0' },
    { substr: '{% geo %}', to: 'US' },
    { substr: '{% number_ratings %}', to: '204' },
    { substr: '{% number_installs %}', to: '0' },
    { substr: '{% device_id %}', to: '' },
    { substr: '{% is_muted %}', to: true },
    { substr: '{% has_appsheet %}', to: '' },
    { substr: '{% sdkless_click_url %}', to: '' },
    { substr: '{% sdk_version %}', to: '' },
    { substr: '{% impression_id %}', to: '' },
    { substr: '{% template_settings %}', to: 'ewogICAgIndvcmRlZF9za2lwIjogdHJ1ZQp9' },
  ];

  const templateWithoutLocalImages = replaceLocalImagesInTemplate(templateHTML);
  /**
   * We are replacing part of the function imgPath:
   * imgPath=function(t){return t?"".concat("images","/").concat(t):null}
   * Since we are simulating the ad behaviour we need to use external images
   * instead of the local ones stored by the sdk inside the device once the ad gets rendered
   */
  const replaceImgPathFunc = templateWithoutLocalImages.replace('"images","/"', '');
  const parsedTemplate = multiStringReplace(replaceImgPathFunc, videoTemplateParams);
  return parsedTemplate ? `${parsedTemplate}<ins datetime="${new Date().toString()}" />` : '';
};

export const createCreativeVisualization = (template, creative = {}) => {
  switch (creative.media_type) {
    case STATIC_CREATIVE_MEDIA_TYPE:
      return prepareStaticCreative(
        template,
        creative,
      );

    case PLAYABLE_CREATIVE_MEDIA_TYPE:
      return preparePlayableCreative(
        template,
        creative,
      );

    case VIDEO_CREATIVE_MEDIA_TYPE:
      return prepareVideoCreative(
        template,
        creative,
      );

    case VAST_CREATIVE_MEDIA_TYPE:
    case MRAID_CREATIVE_MEDIA_TYPE:
      return prepareExchangeCreative(
        template,
        creative,
      );

    default: return '';
  }
};

export const filterCreativeAssets = (creative) => {
  if (isEmpty(creative.assets)) {
    return '';
  }

  return {
    portrait: pick(creative.assets, PORTRAIT_ASPECT_RATIOS),
    landscape: pick(creative.assets, LANDSCAPE_ASPECT_RATIOS),
    all: creative.assets,
  };
};

export const filterCreativeVideoAssets = (creative) => {
  if (isEmpty(creative.video_assets)) {
    return '';
  }

  return {
    portrait: pick(creative.video_assets, ['9:16']),
    landscape: pick(creative.video_assets, ['16:9']),
    all: creative.video_assets,
  };
};

export default prepareStaticCreative;
