import PropTypes from 'prop-types';
import React from 'react';
import { Table, Loader, Pagination } from 'semantic-ui-react';

import './PlayableValidationTable.css';
import PlayableValidationRow from './PlayableValidationRow';

const TableLoader = () => (
  <Table.Row>
    <Table.Cell colSpan={10}><Loader active inline="centered" /></Table.Cell>
  </Table.Row>
);

const PlayableValidationTable = (props) => {
  const { playables, selectPlayable, page, totalPages, setPageNumber, loading } = props;

  const listOfPlayables = () => (
    playables.length
      ? playables.map(playable => (
        <PlayableValidationRow
          key={playable.creative_id}
          playable={playable}
          selectPlayable={selectPlayable}
        />
      ))
      : (
        <Table.Row>
          <Table.Cell colSpan={10} textAlign="center">
            There are no playables matching this search criteria
          </Table.Cell>
        </Table.Row>
      )
  );

  return (
    <Table compact size="small" singleLine striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width="2">Created Date</Table.HeaderCell>
          <Table.HeaderCell width="2">Company Name</Table.HeaderCell>
          <Table.HeaderCell width="2">Company Id</Table.HeaderCell>
          <Table.HeaderCell width="2">Creative ID</Table.HeaderCell>
          <Table.HeaderCell width="2">Creative Name</Table.HeaderCell>
          <Table.HeaderCell width="2">App ID</Table.HeaderCell>
          <Table.HeaderCell width="1" textAlign="center">End Card</Table.HeaderCell>
          <Table.HeaderCell width="3" textAlign="center">Approval status</Table.HeaderCell>
          <Table.HeaderCell width="1" textAlign="center">Preview</Table.HeaderCell>
          <Table.HeaderCell width="1" textAlign="center">Platform</Table.HeaderCell>
          <Table.HeaderCell width="1" textAlign="center">Account Manager</Table.HeaderCell>
          <Table.HeaderCell width="1" textAlign="center">File</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading
          ? <TableLoader />
          : listOfPlayables()
        }
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="12" textAlign="right">
            <Pagination
              activePage={page}
              totalPages={totalPages}
              onPageChange={setPageNumber}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

PlayableValidationTable.defaultProps = { loading: false };

PlayableValidationTable.propTypes = {
  playables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectPlayable: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default PlayableValidationTable;
