import { toast } from 'react-toastify';
import messages from './messages.json';

let nextIndex = 0;
const dismissInterval = 5000;

const MESSAGE_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning'
};

const formatMessage = (message, args = []) => {
  nextIndex += 1;
  const content = message.content.replace(
    /{(\d+)}/g,
    (match, index) => args[index] || message.args[index]
  );
  return { ...message, content, index: nextIndex };
};

const getMessage = (message = '') => {
  const [key, ...args] = typeof message === 'string' ? [message] : message;
  return messages[key] ? formatMessage(messages[key], args) : null;
};

const displayToast = message => {
  const method = message.format || MESSAGE_TYPES.INFO;
  toast[method](message.content, {
    autoClose: method !== MESSAGE_TYPES.ERROR ? dismissInterval : false
  });
};

const messaging = store => next => action => {
  let message = null;
  if (action.message) {
    message = getMessage(action.message);
  }
  if (action.meta && action.meta.messages) {
    const { success, failure } = action.meta.messages;
    message = action.error ? getMessage(failure) : getMessage(success);
  }
  if (message) displayToast(message);
  return next(action);
};

export default messaging;
