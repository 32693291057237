import PropTypes from 'prop-types';
import React from 'react';

import { REJECT_REASONS } from '../constants';

import PublishingAppCardAppStatusRejectedButton from './PublishingAppCardAppStatusRejectedButton';
import PublishingAppCardAppStatusApprovedButton from './PublishingAppCardAppStatusApprovedButton';

const PublishingAppCardAppStatusReviewed = (props) => {
  const { publishing_application, reviewUser, reReviewApp } = props;
  const reviewerName = (reviewUser && reviewUser.first_name) ? reviewUser.first_name : 'Automatic Review';
  const reviewDate = publishing_application.reviewed_at ? new Date(publishing_application.reviewed_at * 1000) : 'the Skynet rising';
  const rejectReason = `${publishing_application.reject_reason}_${publishing_application.reject_detail}`;
  const rejectReasonText = REJECT_REASONS[rejectReason];
  let status = `${publishing_application.status} by ${reviewerName} at ${reviewDate.toUTCString()}`;
  if (rejectReasonText) status += ` because ${REJECT_REASONS[rejectReason]}`;
  return (
    <div className="ui two buttons" >
      {
        /approved/i.test(status) ?
          <PublishingAppCardAppStatusApprovedButton status={status} /> :
          <PublishingAppCardAppStatusRejectedButton
            status={status}
            reReviewApp={reReviewApp}
          />
      }
    </div>
  );
};

PublishingAppCardAppStatusReviewed.defaultProps = { reviewUser: {} };

PublishingAppCardAppStatusReviewed.propTypes = {
  publishing_application: PropTypes.shape({}).isRequired,
  reviewUser: PropTypes.shape({}),
  reReviewApp: PropTypes.func.isRequired,
};

export default PublishingAppCardAppStatusReviewed;
