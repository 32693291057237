import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Table } from 'semantic-ui-react';

function PublishingAppCardDau(props) {
  const { metrics } = props;
  metrics.sort((a, b) => {
    const dateA = a.date;
    const dateB = b.date;
    if (dateA < dateB) {
      return -1;
    }

    if (dateA > dateB) {
      return 1;
    }

    return 0;
  });
  const numberFormatter = Intl.NumberFormat('en', { notation: 'compact' });

  const formatDate = date => date.replace('-', ' ');

  return (
    <Grid.Column width={16}>
      <h2>DAU</h2>
      {metrics.length === 0
        ? 'N/A'
        : (
          <Table celled textAlign="center">
            <Table.Header>
              <Table.Row>
                {metrics.map(m => <Table.HeaderCell key={m.date}>{formatDate(m.date)}</Table.HeaderCell>)}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                {metrics.map(m => <Table.Cell key={m.date}>{numberFormatter.format(m.uniques)}</Table.Cell>)}
              </Table.Row>
            </Table.Body>
          </Table>
        )
      }
    </Grid.Column>
  );
}

PublishingAppCardDau.defaultProps = {};

PublishingAppCardDau.propTypes = { metrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired };

export default PublishingAppCardDau;
