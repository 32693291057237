import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Container, Divider, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { fetchGlobals, resetGlobals, saveGlobals, setGlobals } from '../actions';
import {
  selectGlobals,
  selectGlobalsErrors,
  selectGlobalsHasErrors,
  selectLoading,
} from '../../selectors';
import { GlobalEditForm } from '../components';
import { ExchangeMenu } from '../../shared/components';
import { selectOnline } from '../../../../app/selectors';
import { JsonPreview, PageHeader } from '../../../../shared/components';

class GlobalEdit extends Component {
  componentDidMount() {
    const { fetchGlobals, loading, online } = this.props;
    if (online && !loading) { fetchGlobals(); }
  }

  render() {
    const { globals, hasErrors, resetGlobals, saveGlobals } = this.props;
    return (
      <Container className="GlobalEdit">

        <ExchangeMenu />
        <Button.Group floated="right">
          <Button as={Link} secondary onClick={resetGlobals} to="/exchange">Cancel</Button>
          <Button primary disabled={hasErrors} onClick={() => saveGlobals(globals)}>Save</Button>
        </Button.Group>

        <Divider />

        <PageHeader content="Global Settings" />
        <Grid columns="equal" stackable>
          <Grid.Column><GlobalEditForm {...this.props} /></Grid.Column>
          <Grid.Column><JsonPreview toStringify={globals} /></Grid.Column>
        </Grid>

      </Container>
    );
  }
}

const mapStateToProps = state => ({
  errors: selectGlobalsErrors(state),
  globals: selectGlobals(state),
  hasErrors: selectGlobalsHasErrors(state),
  loading: selectLoading(state),
  online: selectOnline(state),
});

const mapDispatchToProps = {
  fetchGlobals,
  resetGlobals,
  setGlobals,
  saveGlobals,
};

GlobalEdit.propTypes = {
  globals: PropTypes.shape({}),
  hasErrors: PropTypes.bool,
  loading: PropTypes.bool,
  online: PropTypes.bool,
};

GlobalEdit.defaultProps = {
  globals: {},
  hasErrors: false,
  loading: false,
  online: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalEdit);
