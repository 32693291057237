export const getAppType = (app) => {
  if (app.type === 1) {
    return 'apple';
  } else if (app.type_android === 'amazon') {
    return 'amazon';
  }
  return 'android';
};

export { getAppType as default };
