import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, Header, Button, Grid, Form, TextArea } from 'semantic-ui-react';
import PlatformChecks from './PlatformChecks';
import PlayableValidationPendingRejectDropdown from './PlayableValidationPendingRejectDropdown';

class PlayableValidationModal extends Component {
  constructor(props) {
    super(props);

    const playable = this.props.selectedPlayable;

    this.state = {
      validationStatus: (playable.validation_status) ? playable.validation_status : 'WAITING_FOR_APPROVAL',
      validatorName: (playable.validation_result) ? playable.validation_result.validator_name : '-',
      validationDate: (playable.validation_result) ? playable.validation_result.validated_at : '-',
      platformId: (playable.platform_id) ? playable.platform_id : 0,
      platformName: (playable.platform_name) ? playable.platform_name : '-',
      validationNotes: (playable.validation_result) ? playable.validation_result.notes : '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveStatus = this.saveStatus.bind(this);
  }

  handleChange(e, data) {
    if (data.name) {
      this.setState({ validationNotes: data.value });
    }

    return true;
  }

  saveStatus(reason) {
    const { save } = this.props;
    save(this.state.validationNotes, reason);
  }

  render() {
    const { selectedPlayable, close } = this.props;
    let allowChangeValidationStatus = false;

    if (selectedPlayable.validation_status === null || selectedPlayable.validation_status === 'WAITING_FOR_APPROVAL') {
      allowChangeValidationStatus = true;
    }

    return (
      <Modal open>
        <Header icon="check circle outline" content={`Validation status for: ${selectedPlayable.creative_name} playable`} />
        <Modal.Content>
          <Form>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <h4>Validation status</h4>
                  <span>{this.state.validationStatus}</span>
                </Grid.Column>
                <Grid.Column>
                  <h4>Validated by</h4>
                  <span>
                    {this.state.validatorName}
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <h4>Validated at</h4>
                  <span>
                    {this.state.validationDate}
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <PlatformChecks
                  platformId={this.state.platformId}
                  platformName={this.state.platformName}
                />
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column >
                  {(this.state.validationNotes.length > 0 || allowChangeValidationStatus) &&
                    <h4>Notes</h4>
                  }
                  {allowChangeValidationStatus ? (
                    <TextArea onChange={this.handleChange} rows={3} placeholder="Validation notes" name="validationNotes" value={this.state.validationNotes} />
                    ) : (
                       this.state.validationNotes
                    )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {allowChangeValidationStatus &&
            <span>
              <Button
                basic
                onClick={() => this.saveStatus()}
                positive
                content="Approve"
              />
              <PlayableValidationPendingRejectDropdown
                onChange={rejectReason => this.saveStatus(rejectReason)}
                options={this.props.rejectReasonsOptions}
                loading={this.props.saving}
              />
            </span>
            }
          <Button
            onClick={close}
            negative
            icon="cancel"
            content="Close"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

PlayableValidationModal.defaultProps = { selectedPlayable: {} };

PlayableValidationModal.propTypes = {
  selectedPlayable: PropTypes.shape({}).isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  rejectReasonsOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  saving: PropTypes.bool.isRequired,
};

export default PlayableValidationModal;
