import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'semantic-ui-react';

const LayoutButtons = ({ layout, setLayout }) => (
  <Button.Group icon>
    <Button active={layout === 'grid'} icon="grid layout" onClick={setLayout('grid')} />
    <Button active={layout === 'list'} icon="list layout" onClick={setLayout('list')} />
  </Button.Group>
);

LayoutButtons.defaultProps = {
  layout: 'grid',
  setLayout: () => {},
};

LayoutButtons.propTypes = {
  layout: PropTypes.string,
  setLayout: PropTypes.func,
};

export default LayoutButtons;
