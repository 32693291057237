import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const PrivateMarketplaceMenu = () => (
  <Menu inverted secondary stackable compact>
    <Menu.Item as={NavLink} to="/pmp">PMP</Menu.Item>
  </Menu>
);

export default PrivateMarketplaceMenu;
