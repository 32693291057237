import PropTypes from 'prop-types';
import React from 'react';
import { List, Grid } from 'semantic-ui-react';

const PublishingAppCardUserDetails = (props) => {
  const { user, company, publishingApplication } = props;
  return (
    <Grid.Column>
      <h2>User details</h2>
      <List divided relaxed>
        <List.Item>
          <List.Icon name="user" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>User ID</List.Header>
            <List.Description>{user._id}</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="building" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>Company Name</List.Header>
            <List.Description>{company.company}</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="calendar alternate" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>Date and time requested</List.Header>
            <List.Description>{publishingApplication.created_at}</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="at" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>Email</List.Header>
            <List.Description>{user.email}</List.Description>
          </List.Content>
        </List.Item>
      </List>
    </Grid.Column>
  );
};

PublishingAppCardUserDetails.defaultProps = {};

PublishingAppCardUserDetails.propTypes = {
  user: PropTypes.shape({}).isRequired,
  company: PropTypes.shape({}).isRequired,
  publishingApplication: PropTypes.shape({}).isRequired,
};

export default PublishingAppCardUserDetails;
