/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { Container, Loader, Button } from 'semantic-ui-react';
import TableContainer from '../components/table/TableContainer';
import { retrieveUserPending, enableUser } from '../store/users/actions';
import SearchInput from '../components/form/SearchInput';
import '../styles/UserPending.css';
import Users from '../components/Users';

class UserPendingListContainer extends Component {
  static sortData(data, column) {
    return data.sort((row1, row2) => (
      row2[column] > row1[column] ? 1 : -1
    ));
  }

  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      activePage: 1,
      query: '',
      users: UserPendingListContainer.sortData(props.users, 'date_created'),
    };
    this.maxRow = 11;
    this.enableUser = this.enableUser.bind(this);
    this.columns = [
      { value: 'Name', id: 'name' },
      { value: 'Company', id: 'company' },
      { value: 'Email', id: 'email' },
      { value: 'Created', id: 'date_created' },
      { value: '', id: 'action' },
    ];
  }

  componentDidMount() {
    const { retrieveUserPending } = this.props;
    retrieveUserPending();
  }

  componentWillReceiveProps(newProps) {
    const { users: oldUsers } = this.props;
    const { users } = newProps;

    if (!isEqual(users, oldUsers)) {
      const sortUsers = UserPendingListContainer.sortData(users, 'date_created');
      this.setState({ users: sortUsers });
    }
  }

  enableUser(token) {
    this.props.enableUser(token);
  }

  updateQueryState(query) {
    this.setState({ query });
  }

  showNextPage(data) {
    this.setState({ activePage: data.activePage, index: this.maxRow * (data.activePage - 1) });
  }

  handleSearch() {
    const { query } = this.state;
    const { users } = this.props;
    let result = users;
    if (!isEmpty(query)) {
      result = users.filter(user => user.signup_info.first_name.toLowerCase().includes(query.toLowerCase())
        || user.signup_info.email.toLowerCase().includes(query.toLowerCase())
        || user.signup_info.company_name.toLowerCase().includes(query.toLowerCase()));
    }
    this.setState({ users: result, activePage: 1, index: 0 });
  }

  formatUsersForTable() {
    const { users } = this.state;
    const { index: from } = this.state;
    const to = from + this.maxRow > users.length ? users.length : from + this.maxRow;

    const data = users.slice(from, to).map((user) => {
      const creationDate = new Date(user.date_created * 1000).toLocaleString();

      return {
        id: user._id,
        row: {
          name: user.signup_info.first_name,
          company: user.signup_info.company_name,
          email: user.signup_info.email,
          created: creationDate,
          action: (<Button
            basic
            content="Approve"
            floated="right"
            icon="checkmark"
            labelPosition="left"
            onClick={() => this.enableUser(user.validation_token)}
            primary
          />),
        },
      };
    });
    return data;
  }

  render() {
    const { loading, location } = this.props;
    const { activePage, users, query } = this.state;

    return (
      <Users location={location}>
        <Container className="UserPendingList">
          {loading ?
            <Loader active /> :
            <div>
              <div className="filters">
                <SearchInput
                  query={query}
                  updateQueryState={query => this.updateQueryState(query)}
                  searchFunc={() => this.handleSearch()}
                  placeholder="Search by Name, Company and email"
                />
              </div>
              <TableContainer
                columns={this.columns}
                data={this.formatUsersForTable()}
                handelClickOption={() => { }}
                emptyState="No users found"
                tableOptions={{ stackable: true, striped: true, selectable: true, sortable: true }}
                activePage={activePage}
                maxRow={this.maxRow}
                showNextPage={(e, data) => this.showNextPage(data)}
                dataLength={users.length}
              />
            </div>
          }
        </Container>
      </Users>
    );
  }
}

UserPendingListContainer.defaultProps = {
  users: [],
  location: {
    pathname: '/',
    hash: '',
  },
};

UserPendingListContainer.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  retrieveUserPending: PropTypes.func.isRequired,
  enableUser: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hash: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  users: state.users.userPending.users,
  loading: state.users.loading,
});

const mapDispatchToProps = dispatch => ({
  retrieveUserPending: () => dispatch(retrieveUserPending()),
  enableUser: token => dispatch(enableUser(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPendingListContainer);
