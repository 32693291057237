import React from 'react';
import { Grid } from 'semantic-ui-react';

import OverviewLinkCard from './components/OverviewLinkCard';

const firstRowLinks = [
  { to: '/exchange', icon: 'exchange', header: 'Exchange' },
  {
    to: `${process.env.REACT_APP_CREATIVES_DASHBOARD_URL}/playables-validation`,
    icon: 'check',
    header: 'Playables validation',
    isExternalLink: true
  },
  { to: '/s2stracking', icon: 'flag checkered', header: 'S2S Tracking' },
  { to: '/yield', icon: 'line chart', header: 'Yield' }
];

const secondRowLinks = [
  { to: '/integrations', icon: 'internet explorer', header: 'Integrations' },
  { to: '/pmp', icon: 'gavel', header: 'PMP' },
  { to: '/users', icon: 'users', header: 'Users' },
  { to: '/creatives', icon: 'lightbulb outline', header: 'Creatives' },
];

const thirdRowLinks = [
  { to: '/cb-exchange', icon: 'exchange', header: 'CBExchange' },
];

const Overview = () => (
  <Grid>
    <Grid.Row columns={4}>
      {firstRowLinks.map(link => (
        <Grid.Column key={link.to}>
          <OverviewLinkCard link={link.to} icon={link.icon} header={link.header} isExternalLink={link.isExternalLink} />
        </Grid.Column>))}
    </Grid.Row>
    <Grid.Row columns={4}>
      {secondRowLinks.map(link => (
        <Grid.Column key={link.to}>
          <OverviewLinkCard link={link.to} icon={link.icon} header={link.header} />
        </Grid.Column>))}
    </Grid.Row>
    <Grid.Row columns={4}>
      {thirdRowLinks.map(link => (
        <Grid.Column key={link.to}>
          <OverviewLinkCard link={link.to} icon={link.icon} header={link.header} />
        </Grid.Column>))}
    </Grid.Row>
  </Grid>
);

export default Overview;
