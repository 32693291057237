import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

class CustomMessage extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: props.visible };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ visible: nextProps.visible });
  }

  render() {
    return this.state.visible ? (
      <Message
        onDismiss={this.props.handleDismiss}
        header={this.props.header}
        content={this.props.message}
        negative={this.props.isError}
        positive={!this.props.isError}
      />
    )
      :
      null;
  }
}

CustomMessage.defaultProps = {
  visible: false,
  message: '',
  header: '',
  isError: false,
  handleDismiss: null,
};

CustomMessage.propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.string,
  header: PropTypes.string,
  isError: PropTypes.bool,
  handleDismiss: PropTypes.func,
};

export default CustomMessage;
