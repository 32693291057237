const PLATFORM = {
  1: 'iOS',
  2: 'Android',
  3: 'Amazon',
};

export const PLATFORM_ID_NAME = {
  1: 'Store',
  2: 'Bundle',
  3: 'ASIN',
};

export function getPlatformNameOfId(platform) {
  return platform === 1 ? 'Store' : 'Bundle';
}

export function getPlatformId(platform) {
  return parseInt(Object.keys(PLATFORM).find(id => PLATFORM[id] === platform), 0);
}

export default PLATFORM;
