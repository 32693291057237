import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'semantic-ui-react';

import PrivateMarketplaceSearch from './PrivateMarketplaceSearch';
import PrivateMarketplaceTable from './PrivateMarketplaceTable';

const PrivateMarketplaceContainer = (props) => {
  const {
    deals,
    activePage,
    sortedBy,
    direction,
    onSortChange,
    showNextPage,
    totalPages,
    onSearch,
    updateQueryState,
    query,
    isAdminView,
  } = props;

  return (
    <Container>
      <PrivateMarketplaceSearch
        searchDeals={onSearch}
        updateQueryState={updateQueryState}
        query={query}
      />
      <PrivateMarketplaceTable
        deals={deals}
        handleSort={onSortChange}
        sortedBy={sortedBy}
        direction={direction}
        showNextPage={showNextPage}
        totalPages={totalPages}
        activePage={activePage}
        isAdminView={isAdminView}
      />
    </Container>
  );
};

PrivateMarketplaceContainer.defaultProps = { isAdminView: false };

PrivateMarketplaceContainer.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sortedBy: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  totalPages: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  showNextPage: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  updateQueryState: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  isAdminView: PropTypes.bool,
};

export default PrivateMarketplaceContainer;
