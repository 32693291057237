import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';

import PrivateMarketplaceRow from './PrivateMarketplaceRow';
import TableNoResults from './TableNoResults';
import PrivateMarketplacePagination from './PrivateMarketplacePagination';

import './styles/PrivateMarketplaceTable.css';

class PrivateMarketplaceTable extends Component {
  getPagination() {
    const {
      activePage,
      showNextPage,
      totalPages,
    } = this.props;
    let paginationElement;

    if (totalPages) {
      paginationElement = (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="11" textAlign="center">
              <PrivateMarketplacePagination
                totalPages={totalPages}
                activePage={activePage}
                onPageChange={showNextPage}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      );
    }
    return paginationElement;
  }

  render() {
    const {
      deals,
      direction,
      handleSort,
      sortedBy,
      isAdminView,
    } = this.props;

    return (
      <Table celled selectable striped structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell
              width="3"
              onClick={() => handleSort('id')}
              sorted={sortedBy === 'id' ? direction : null}
              className="sortable"
            >
              Deal ID
            </Table.HeaderCell>
            <Table.HeaderCell
              width="3"
              onClick={() => handleSort('deal_name')}
              sorted={sortedBy === 'deal_name' ? direction : null}
              className="sortable"
            >
              Deal Name
            </Table.HeaderCell>
            <Table.HeaderCell>Price</Table.HeaderCell>
            <Table.HeaderCell>Price Type</Table.HeaderCell>
            <Table.HeaderCell
              onClick={() => handleSort('date_created')}
              sorted={sortedBy === 'date_created' ? direction : null}
              className="sortable"
            >
              Create Date
            </Table.HeaderCell>
            <Table.HeaderCell
              onClick={() => handleSort('start_timestamp')}
              sorted={sortedBy === 'start_timestamp' ? direction : null}
              className="sortable"
            >
              Start Date
            </Table.HeaderCell>
            <Table.HeaderCell
              onClick={() => handleSort('end_date')}
              sorted={sortedBy === 'end_date' ? direction : null}
              className="sortable"
            >
              End Date
            </Table.HeaderCell>
            <Table.HeaderCell>Edit</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {deals.length === 0 ?
            <TableNoResults message="No PMP deal Found" /> :
            deals.map(deal => (
              <PrivateMarketplaceRow
                key={deal.id}
                deal={deal}
                isAdminView={isAdminView}
              />
            ))}
        </Table.Body>
        {this.getPagination()}
      </Table>
    );
  }
}

PrivateMarketplaceTable.defaultProps = { isAdminView: false };

PrivateMarketplaceTable.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSort: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  sortedBy: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  showNextPage: PropTypes.func.isRequired,
  isAdminView: PropTypes.bool,
};

export default PrivateMarketplaceTable;
