import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';

class CompanyName extends React.Component {
  constructor(props) {
    super(props);
    const { bidder: { company_name = '' } } = props;
    this.state = { company_name };
  }

  componentWillReceiveProps(nextProps) {
    const { bidder: { company_name } } = this.props;
    const { bidder: { company_name: nextCompanyName } } = nextProps;
    if (company_name !== nextCompanyName) {
      this.setState({ company_name: nextCompanyName });
    }
  }

  debouncedSetBidder = debounce(this.props.setBidder, 500);

  render() {
    const { bidder: { id }, errors } = this.props;

    const onChange = (event, ownProps) => {
      const { target: { validationMessage = '' } = {} } = event;
      const { value: company_name } = ownProps;
      this.setState({ company_name });
      this.debouncedSetBidder(id, { company_name }, { companyName: validationMessage });
    };

    return (
      <Form.Field>
        <label htmlFor="company-name">Company Name</label>
        <Input
          autoComplete="company_name"
          error={!isEmpty(errors.company_name)}
          id="company-name"
          inputMode="latin"
          label={{ tag: true, content: 'Required' }}
          labelPosition="right"
          onChange={onChange}
          placeholder="Parent Company"
          required
          type="text"
          value={this.state.company_name}
        />
        {errors.company_name ? <Label basic color="red" pointing>{errors.company_name}</Label> : null}
      </Form.Field>
    );
  }
}

export default CompanyName;

CompanyName.defaultProps = {
  bidder: {},
  errors: {},
  setBidder: () => {},
};

CompanyName.propTypes = {
  bidder: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  setBidder: PropTypes.func,
};
