import { LOADED, LOADING, UNLOADED } from '../../../../src/shared/constants/readyStates';
import Actions from '../../../../src/core/actions';

import IntegrationActions from './actions';
import IntegrationHelpers from './helpers';

const initState = {
  readyState: UNLOADED,
  data: [],
  uniquePartners: null,
  partner: null
};

const setData = (state, payload) => {
  const uniquePartners =
    state.uniquePartners === null
      ? IntegrationHelpers.getUniquePartners(payload.response)
      : state.uniquePartners;
  return {
    ...state,
    data: payload.response,
    uniquePartners: uniquePartners,
    readyState: LOADED
  };
};

const setPartner = (state, payload) => ({
  ...state,
  partner: payload.partner,
  readyState: LOADED
});

/**
 * Switch statement
 */

const reducer = (state = initState, { payload, type }) => {
  switch (type) {
    case Actions.started(IntegrationActions.FETCH_DATA):
    case Actions.started(IntegrationActions.SET_PARTNER):
      return { ...state, readyState: LOADING };

    case Actions.success(IntegrationActions.FETCH_DATA):
      return setData(state, payload);

    case Actions.success(IntegrationActions.SET_PARTNER):
      return setPartner(state, payload);

    case Actions.failure(IntegrationActions.FETCH_DATA):
    case Actions.failure(IntegrationActions.SET_PARTNER):
      return { ...state, readyState: LOADED };

    default:
      return state;
  }
};

export default reducer;
