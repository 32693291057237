import { createSelector } from 'reselect';
import uniqBy from 'lodash/uniqBy';

import bundles from '../shared/bundles';
import activeRoles from './activeRoles.json';
import { LOADING, UNLOADED } from '../shared/constants/readyStates';

const userState = state => state.user;

export const selectFeatures = createSelector(userState, user => user.features);
export const selectLoading = createSelector(userState, user => user.loading);
export const selectLoggedIn = createSelector(userState, user => user.isLoggedIn);
export const selectLoginError = createSelector(userState, user => user.loginError);
export const selectReadyState = createSelector(userState, user => user.readyState);
export const selectRoles = createSelector(userState, user => user.roles);
export const selectSettings = createSelector(userState, user => user.settings);

export const selectIsFetching = createSelector(selectReadyState, ready => ready === LOADING);
export const selectShouldFetch = createSelector(selectReadyState, ready => ready === UNLOADED);

export const selectBundles = createSelector(
  selectRoles,
  selectFeatures,
  (roles, features) => (uniqBy([
    ...bundles.filter(bundle => bundle.roles.some(role => roles.includes(role))),
    ...bundles.filter(bundle => bundle.features.some(feature => features.includes(feature))),
  ], 'name')),
);

export const selectHasActiveRole = createSelector(
  selectRoles,
  roles => roles.some(role => activeRoles.includes(role)),
);

export const selectIsAdmin = createSelector(
  selectRoles,
  roles => roles.indexOf('admin') >= 0,
);

export const userHasFeatureFlag = (feature) => (state) => {
  if (process.env.NODE_ENV !== 'production') return true;
  const features = selectFeatures(state);
  return features.includes(feature);
};
