import { toast } from 'react-toastify';
import { DASH_API } from '../shared/api';
import { xhr } from '../shared/helpers';

export const LOGIN = 'USER.LOGIN';
export const LOGIN_SUCCESS = 'USER.LOGIN.SUCCESS';
export const LOGIN_FAILURE = 'USER.LOGIN.FAILURE';

export const LOGOUT = 'USER.LOGOUT';
export const LOGOUT_SUCCESS = 'USER.LOGOUT.SUCCESS';
export const LOGOUT_FAILURE = 'USER.LOGOUT.FAILURE';

export const FETCH_EXPERIENCE = 'USER.FETCH_EXPERIENCE';
export const FETCH_EXPERIENCE_SUCCESS = 'USER.FETCH_EXPERIENCE.SUCCESS';
export const FETCH_EXPERIENCE_FAILURE = 'USER.FETCH_EXPERIENCE.FAILURE';

export const FETCH_USER = 'USER.FETCH_USER';

export const login = data => async (dispatch) => {
  dispatch({ type: LOGIN });
  try {
    const { response } = await xhr(`${DASH_API}/login`, data, { encode: true });
    dispatch({ type: LOGIN_SUCCESS, response });
  } catch ({ message }) {
    dispatch({ type: LOGIN_FAILURE, error: message });
  }
};

// TODO remove this function
export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
  try {
    const { response } = await xhr(`${DASH_API}/logout`);
    dispatch({ type: LOGOUT_SUCCESS, response });
  } catch ({ message }) {
    dispatch({ type: LOGOUT_FAILURE, error: message });
  }
};

export const fetchExperience = () => async (dispatch) => {
  dispatch({ type: FETCH_EXPERIENCE });
  try {
    const { response } = await xhr(`${DASH_API}/experience`, null, { token: true });
    dispatch({ type: FETCH_EXPERIENCE_SUCCESS, response });
  } catch ({ message }) {
    toast.error('Getting token from dashapi failed');
    dispatch({ type: FETCH_EXPERIENCE_FAILURE, error: message });
  }
};

export const setTokenFromDashApi = response => dispatch => {
  dispatch({ type: FETCH_EXPERIENCE_SUCCESS, response });
};

export const setUserInfo = userInfo => dispatch => {
  dispatch({ type: FETCH_USER, userInfo });
};

