import { shouldCache, throttle } from '../shared/helpers';

const NAMESPACE = 'cbpro';
const AUTH0_NAMESPACE = 'cbpro_auth0';

export const dehydrate = store => throttle(() => {
  if (!shouldCache) { return; }
  const state = store.getState();
  Object.keys(state).forEach((slice) => {
    delete state[slice].readyState;
    try {
      localStorage.setItem(`${NAMESPACE}.${slice}`, JSON.stringify(state[slice]));
    } catch (err) {
      // localStorage is at capacity, clear it (it will be repopulated by the next action)
      localStorage.clear();
    }
  });
});

export const rehydrate = (slice, initState = {}) => {
  if (!shouldCache) { return initState; }
  try {
    const [bundle, subreducer] = slice.split('.');
    let rehydratedState = JSON.parse(localStorage.getItem(`${NAMESPACE}.${bundle}`));
    if (subreducer) { rehydratedState = rehydratedState[subreducer]; }
    return { ...initState, ...rehydratedState };
  } catch (err) {
    return initState;
  }
};

export const setAuthToken = (token) => {
  try {
    return localStorage.setItem(`${NAMESPACE}.jwt`, token);
  } catch (err) {
    return false;
  }
};

export const getAuthToken = () => {
  try {
    return localStorage.getItem(`${NAMESPACE}.jwt`);
  } catch (err) {
    return '';
  }
};

export const setTokenFromAuth0 = token => {
  try {
    return localStorage.setItem(`${AUTH0_NAMESPACE}.jwt`, token);
  } catch (error) {
    return false;
  }
};

export const getTokenFromAuth0 = () => {
  try {
    return localStorage.getItem(`${AUTH0_NAMESPACE}.jwt`);
  } catch (error) {
    return '';
  }
};
export const removeTokenFromAuth0 = () => {
  localStorage.removeItem(`${AUTH0_NAMESPACE}.jwt`);
};
