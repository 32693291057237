/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Dimmer, Sidebar } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { appOnline, appResize, toggleNavbar } from '../actions';
import { Header, Navbar } from '../components';
import { setTokenFromDashApi } from '../../user/actions';
import {
  selectBundles,
  selectHasActiveRole,
  selectIsFetching,
  selectLoggedIn,
  selectShouldFetch
} from '../../user/selectors';
import { selectNavbarVisible, selectOnline } from '../selectors';

import './Shell.css';
import { xhr } from '../../shared/helpers';
import { API_GATEWAY } from '../../shared/api';

class Shell extends Component {
  componentDidMount() {
    const { appOnline, appResize } = this.props;
    window.addEventListener('online', appOnline(true), { capture: false, passive: true });
    window.addEventListener('offline', appOnline(false), { capture: false, passive: true });
    window.addEventListener('resize', appResize, { capture: false, passive: true });
  }

  componentWillReceiveProps(nextProps) {
    if (window.ga && nextProps.location !== this.props.location) {
      window.ga('set', 'page', nextProps.location.pathname);
      window.ga('send', 'pageview');
    }
  }

  componentDidUpdate(prevProps) {
    const { isAuth0Loading, online, shouldFetch } = this.props;

    if (online && shouldFetch && !isAuth0Loading && isAuth0Loading !== prevProps.isAuth0Loading) {
      this.setUpTokens();
    }
  }

  async setUpTokens() {
    await this.props.signInAuth0();
    try {
      const { response } = await xhr(`${API_GATEWAY}/dashapi/experience`, null, { token: true });
      if (response) {
        this.props.setTokenFromDashApi(response);
      }
    } catch (err) {
      toast.error('Getting auth token from dashapi failed');
    }
  }

  render() {
    const {
      bundles,
      children,
      closeNavbar,
      hasActiveRole,
      loading,
      navbarVisible,
      online,
      toggleNavbar
    } = this.props;

    if (loading === 0 && !hasActiveRole) {
      window.location = '//dashboard.chartboost.com';
    }
    return (
      <Sidebar.Pushable className="Shell">
        <Navbar bundles={bundles} toggleNavbar={toggleNavbar} visible={navbarVisible} />
        <Sidebar.Pusher dimmed={navbarVisible} onClick={navbarVisible ? closeNavbar : null}>
          {!online ? <Dimmer active>You appear to be offline.</Dimmer> : null}
          <Header toggleNavbar={toggleNavbar} />
          <Container className="Container">{children}</Container>
        </Sidebar.Pusher>
        <div className="Version">{process.env.REACT_APP_VERSION}</div>
      </Sidebar.Pushable>
    );
  }
}

Shell.defaultProps = {
  appOnline: () => {},
  appResize: () => {},
  bundles: [],
  closeNavbar: () => {},
  setTokenFromDashApi: () => {},
  hasActiveRole: true,
  loading: false,
  location: { pathname: '/' },
  navbarVisible: false,
  online: true,
  shouldFetch: false,
  toggleNavbar: () => {},
  signInAuth0: () => {},
  isAuth0Loading: false
};

Shell.propTypes = {
  appOnline: PropTypes.func,
  appResize: PropTypes.func,
  bundles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  ),
  children: PropTypes.node.isRequired,
  closeNavbar: PropTypes.func,
  setTokenFromDashApi: PropTypes.func,
  hasActiveRole: PropTypes.bool,
  loading: PropTypes.bool,
  location: PropTypes.shape({ pathname: PropTypes.string }),
  navbarVisible: PropTypes.bool,
  online: PropTypes.bool,
  shouldFetch: PropTypes.bool,
  toggleNavbar: PropTypes.func,
  signInAuth0: PropTypes.func,
  isAuth0Loading: PropTypes.bool
};

const mapStateToProps = state => ({
  bundles: selectBundles(state),
  hasActiveRole: selectHasActiveRole(state),
  isLoggedIn: selectLoggedIn(state),
  isFetching: selectIsFetching(state),
  navbarVisible: selectNavbarVisible(state),
  online: selectOnline(state),
  shouldFetch: selectShouldFetch(state)
});

const mapDispatchToProps = dispatch => ({
  appOnline: status => () => dispatch(appOnline(status)),
  appResize: () => dispatch(appResize()),
  closeNavbar: () => dispatch(toggleNavbar(false)),
  setTokenFromDashApi: response => dispatch(setTokenFromDashApi(response)),
  toggleNavbar: () => dispatch(toggleNavbar())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Shell)
);
