import PropTypes from 'prop-types';
import React from 'react';
import { Card, Image, Grid } from 'semantic-ui-react';
import PublishingAppCardUserDetails from './PublishingAppCardUserDetails';
import PublishingAppCardAppDetails from './PublishingAppCardAppDetails';
import PublishingAppCardStatus from './PublishingAppCardAppStatus';
import PublishingAppCardStoreDetailsLinks from './PublishingAppCardStoreDetailsLinks';
import PublishingAppCardStoreDetailsQR from './PublishingAppCardStoreDetailsQR';
import PublishingAppCardDau from './PublishingAppCardDau';
import PublishingAppCardCategorization from './PublishingAppCardCategorization';

const PublishingAppCard = (props) => {
  const { app, apiValidation, approveApp, rejectApp, reReviewApp } = props;

  return (
    <Card>
      <Card.Content>
        <Image floated="left" size="mini" src={app.app.icon} />
        <Card.Header>{app.app.name}</Card.Header>
        <Card.Meta>{app.app.appstore_company}</Card.Meta>
        <Card.Description>
          <Grid columns={2}>
            <PublishingAppCardUserDetails
              user={app.user}
              company={app.company}
              publishingApplication={app.app.publishing_application}
            />
            <PublishingAppCardAppDetails app={app.app} />
            <PublishingAppCardStoreDetailsLinks app={app.app} />
            <PublishingAppCardStoreDetailsQR app={app.app} />
            <PublishingAppCardDau metrics={app.metrics || []} />
            {app.app.publishing_application.status === 'Approved' && (
              <PublishingAppCardCategorization app={app.app} />
            )}
          </Grid>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <PublishingAppCardStatus
          publishingApplication={app.app.publishing_application}
          apiValidation={apiValidation}
          reviewUser={app.review_user}
          appId={app.app._id}
          approveApp={approveApp}
          rejectApp={rejectApp}
          reReviewApp={reReviewApp}
          salesforce={app.company.salesforce}
        />
      </Card.Content>
    </Card>
  );
};

PublishingAppCard.defaultProps = {};

PublishingAppCard.propTypes = {
  app: PropTypes.shape({}).isRequired,
  apiValidation: PropTypes.shape({}).isRequired,
  approveApp: PropTypes.func.isRequired,
  rejectApp: PropTypes.func.isRequired,
  reReviewApp: PropTypes.func.isRequired,
};

export default PublishingAppCard;
