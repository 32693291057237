import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Confirm, Divider, Grid, Form, Header, Radio, Input, Label } from 'semantic-ui-react';
import { isArray, isEmpty } from 'lodash';
import {
  PLATFORM,
  STATES_US,
  DEMAND_SUB_TYPES,
} from '../../domain/pmpDeals/constants';
import AD_TYPE from '../../domain/pmpDeals/adType';
import PLACEMENT_TYPE from '../../domain/pmpDeals/placement_type';
import MultiSelector from './form/MultiSelector';
import './styles/DealDetailsSettings.css';
import WhitelistField from './form/WhitelistField';

const range = (start, stop, step) =>
  Array.from({ length: ((stop - start) / step) + 1 }, (_, i) => ({
    key: start + i,
    text: (start + i) * step,
    value: (start + i) * step,
  }));

const getPriorityLevelRange = (demandSubType) => {
  switch (demandSubType) {
    case 'private_auction':
      return range(31, 40, 1);
    case 'inventory_package':
      return range(41, 50, 1);
    default:
    case 'preferred_deal':
      return range(21, 30, 1);
  }
};

class DealDetailsSettings extends Component {
  static statesOptions() {
    return Object.keys(STATES_US).map(key => (
      { key, value: STATES_US[key].name.toLowerCase(), text: STATES_US[key].name }
    ));
  }

  static formatDate(value) {
    if (value) {
      const date = new Date(value);
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
      const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      return `${year}-${month}-${day}`;
    }
    return '';
  }

  constructor(props) {
    super(props);

    this.state = {
      priceError: false,
      placementTypeList: PLACEMENT_TYPE,
      confirmDeleteOpen: false,
    };

    this.handleSkip = this.handleSkip.bind(this);
    this.handleDealChange = this.handleDealChange.bind(this);
    this.handleDimensionChange = this.handleDimensionChange.bind(this);
    this.handleAddTypeChange = this.handleAddTypeChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleWhitelistCompanyChange = this.handleWhitelistCompanyChange.bind(this);
    this.handleWhitelistAppChange = this.handleWhitelistAppChange.bind(this);
    this.handleWhitelistAdLocationChange = this.handleWhitelistAdLocationChange.bind(this);
    this.handleIABCategories = this.handleIABCategories.bind(this);

  }

  getDSPList() {
    const { DSPs } = this.props;
    return DSPs.map(dsp => (
      { key: dsp.id, value: dsp.id, text: dsp.name }
    ));
  }

  getCountriesList() {
    const { countries } = this.props;
    return countries.map(country => (
      { key: country.code, value: country.code, text: country.name }
    ));
  }

  getAppSegmentsList() {
    const { appSegmentsList } = this.props;
    return appSegmentsList.map(segment => (
      { key: segment.id, value: segment.id, text: segment.name }
    ));
  }

  getDeviceSegmentsList() {
    const { deviceSegmentsList } = this.props;
    return deviceSegmentsList.map(segment => (
      { key: segment.id, value: segment.id, text: segment.name }
    ));
  }

  getPublisherAppsList() {
    const { userApps } = this.props;
    return userApps.map(app => (
      { key: app.id, value: app.id, text: app.name || app.nickname }
    ));
  }

  handleSkip(add, value) {
    const { deal, onChange } = this.props;

    if (add) {
      deal.dimensions.skippable.push(value);
    } else {
      deal.dimensions.skippable = deal.dimensions.skippable.filter(e => e !== value);
    }
    onChange({
      name: 'deal',
      value: deal,
    });
  }

  handleDealChange(data) {
    const { deal, onChange } = this.props;
    deal[data.name] = data.value;

    onChange({
      name: 'deal',
      value: deal,
    });
  }

  handleDimensionChange(data) {
    const { deal, onChange } = this.props;
    deal.dimensions[data.name] = data.value;
    onChange({
      name: 'deal',
      value: deal,
    });
  }

  handleAddTypeChange(data) {
    const { deal, onChange } = this.props;
    let disabled = false;
    let { placementTypeList } = this.state;
    deal.dimensions.ad_type = data.value;
    if (!data.value.includes(0)) {
      deal.dimensions.placement_type = deal.dimensions.placement_type.filter(e => e !== 'banner');
      disabled = true;
    }

    placementTypeList = placementTypeList.map((type) => {
      const newType = type;
      if (type.value === 'banner') {
        newType.disabled = disabled;
      }
      return newType;
    });
    this.setState({ placementTypeList });
    onChange({
      name: 'deal',
      value: deal,
    });
  }

  handleDateChange(e, data) {
    const date = new Date(data.value).getTime();
    this.handleDealChange({ name: data.name, value: date || '' });
  }

  handlePriceChange(data) {
    if (isNaN(data.value)) {
      this.setState({ priceError: true });
    } else {
      this.handleDealChange(data);
      this.setState({ priceError: false });
    }
  }

  handleWhitelistCompanyChange(companyIds) {
    this.handleDimensionChange({ name: 'publisher_companies', value: companyIds });
  }

  handleWhitelistAppChange(appIds) {
    this.handleDimensionChange({ name: 'publisher_apps', value: appIds });
  }

  handleWhitelistAdLocationChange(adLocations) {
    this.handleDimensionChange({ name: 'ad_location', value: adLocations });
  }

  handleIABCategories(categories) {
    const { deal, onChange } = this.props;
    deal.dimensions.iab_categories_v2 = categories;
    onChange({
      name: 'deal',
      value: deal,
    });
  }

  closeConfirmDelete = () => this.setState({ confirmDeleteOpen: false });
  handleDeleteClick = () => this.setState({ confirmDeleteOpen: true });

  render() {
    const {
      id,
      deal: {
        eligible_bidders: eligibleBidder,
        deal_type: dealType,
        deal_price: dealPrice,
        start_timestamp: startTimestamp,
        end_timestamp: endTimestamp,
        demand_sub_type: demandSubType,
        priority_level: priorityLevel,
        dimensions: {
          country,
          platform,
          state,
          placement_type: placementType,
          ad_type: adType,
          skippable,
          publisher_companies: publisherCompanies,
          publisher_apps: publisherApps,
          ad_location: adLocations,
          // eslint-disable-next-line no-unused-vars
          iab_categories: iabCategories,
          iab_categories_v2: iabCategoriesV2,
        },
      },
      deviceSegments,
      appSegments,
      colum1,
      colum2,
      onChange,
      invalidToSave,
      save,
      isAdminView,
      duplicate,
      remove,
      loading,
    } = this.props;

    const { priceError, placementTypeList } = this.state;
    const countriesList = this.getCountriesList();
    const statesList = DealDetailsSettings.statesOptions();
    const dspList = this.getDSPList();
    const deviceSegmentsList = this.getDeviceSegmentsList();
    const appSegmentsList = this.getAppSegmentsList();
    const publisherAppsList = this.getPublisherAppsList();
    const skip = skippable ? skippable.includes(1) : false;
    const noskip = skippable ? skippable.includes(0) : false;
    const overviewLink = isAdminView ? '/pmp' : '/public/pmp';
    const iabCategoriesToDisplay = isArray(iabCategoriesV2) ? iabCategoriesV2.join(', ') : iabCategoriesV2;
    const iabSpecialCategories = [
      '8FD8nI', 'j9PaO9', 'bsr003', 'HxqYV1', 'XtODT3', 'MRkz4Q', 'bsr001',
      '6i4dB6', 'Z7rJBM', 'mm3UXx', 'Rm3SiT', 'bsr002', 'avbNf2', 'pg0WhF',
      'I4GWl6', 'bsr004', 'v9i3On'
    ];
    const specialCategoriesRegex = iabSpecialCategories.join('|');
    const regexIabCategories = new RegExp(`^(?:\\d+|${specialCategoriesRegex})(?:,\\s*(?:\\d+|${specialCategoriesRegex}))*$`, 'gm');

    return (
      <div>
        <Header>
          <Header.Content>
            Details
          </Header.Content>
        </Header>
        <Divider />
        <Form>
          <Grid container columns={2} stackable>
            <Grid.Column width={colum1}>
              Segment
            </Grid.Column>
            <Grid.Column width={colum2}>
              <Form.Group widths="equal">
                <MultiSelector
                  placeholder="Select Segment"
                  label="Device Segment"
                  name="deviceSegments"
                  onChange={onChange}
                  options={deviceSegmentsList}
                  value={deviceSegments}
                />
                <MultiSelector
                  placeholder="Select Segment"
                  label="App Segment"
                  name="appSegments"
                  onChange={onChange}
                  options={appSegmentsList}
                  value={appSegments}
                />
              </Form.Group>
            </Grid.Column>
          </Grid>
          <Grid container columns={2} stackable>
            <Grid.Column width={colum1}>
              IAB Categories v2.2
            </Grid.Column>
            <Grid.Column width={colum2}>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label="IAB Categories v2.2"
                  name="iab_categories_v2_2"
                  placeholder="format: 1, 14, 79"
                  value={iabCategoriesToDisplay || ''}
                  onChange={(event, data) => {
                    this.handleIABCategories(data.value);
                  }}
                  error={!regexIabCategories.test(iabCategoriesV2) && !isEmpty(iabCategoriesV2)}
                />
              </Form.Group>
            </Grid.Column>
          </Grid>
        </Form>
        <Divider />
        <Form>
          <Grid container columns={2} stackable divided="vertically">
            <Grid.Row>
              <Grid.Column width={colum1}>
                Filtering
              </Grid.Column>
              <Grid.Column width={colum2}>
                <Form.Group widths="equal">
                  <MultiSelector
                    placeholder="Select Countries"
                    label="Countries"
                    name="country"
                    onChange={this.handleDimensionChange}
                    options={countriesList}
                    value={country}
                    selectAll
                    required
                  />
                  <MultiSelector
                    placeholder="Select State"
                    label="States (US Only)"
                    name="state"
                    onChange={this.handleDimensionChange}
                    options={statesList}
                    value={state}
                    disabled={!country.includes('US')}
                    selectAll
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    label="Platform"
                    placeholder="Select Platform"
                    name="platform"
                    multiple
                    search
                    defaultValue={platform}
                    options={PLATFORM}
                    onChange={(event, data) => this.handleDimensionChange(data)}
                    required
                  />
                  <Form.Select
                    fluid
                    multiple
                    search
                    label="Placement Type"
                    placeholder="Select Placement Type"
                    value={placementType}
                    name="placement_type"
                    options={placementTypeList}
                    onChange={(event, data) => this.handleDimensionChange(data)}
                    required
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    multiple
                    search
                    label="Ad Type"
                    placeholder="Select Ad Type"
                    name="ad_type"
                    options={AD_TYPE}
                    defaultValue={adType}
                    onChange={(event, data) => this.handleAddTypeChange(data)}
                    required
                  />
                  <Form.Field inline required>
                    <b>Skippable</b>
                    <Form.Field
                      inline
                      label="Skippable"
                      control="input"
                      type="checkbox"
                      checked={skip}
                      name="skippable"
                      onChange={() => this.handleSkip(!skip, 1)}
                    />
                    <Form.Field
                      inline
                      label="Non-skippable"
                      control="input"
                      type="checkbox"
                      checked={noskip}
                      name="skippable"
                      onChange={() => this.handleSkip(!noskip, 0)}
                    />
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={colum1}>
                Inventory
              </Grid.Column>
              <Grid.Column width={colum2}>
                { isAdminView && (
                  <Form.Group widths="equal">
                    <WhitelistField
                      title="Companies"
                      whitelistedItems={publisherCompanies}
                      onChange={this.handleWhitelistCompanyChange}
                    />
                    <WhitelistField
                      title="Apps"
                      whitelistedItems={publisherApps}
                      onChange={this.handleWhitelistAppChange}
                    />
                  </Form.Group>
                )}
                {!isAdminView && (
                  <Form.Group widths="equal">
                    <MultiSelector
                      placeholder="Whitelist Publisher Apps"
                      label="Select Apps"
                      name="publisher_apps"
                      onChange={this.handleDimensionChange}
                      options={publisherAppsList}
                      value={publisherApps}
                    />
                    <WhitelistField
                      title="Ad-Locations"
                      whitelistedItems={adLocations}
                      validateObjectIds={false}
                      onChange={this.handleWhitelistAdLocationChange}
                    />
                  </Form.Group>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={colum1}>
                Scheduling
              </Grid.Column>
              <Grid.Column width={colum2}>
                <Form.Group widths="equal">
                  <Form.Input
                    placeholder="YYYY/MM/DD"
                    label="Start date"
                    name="start_timestamp"
                    type="date"
                    onChange={this.handleDateChange}
                    value={DealDetailsSettings.formatDate(startTimestamp)}
                  />
                  <Form.Input
                    placeholder="YYYY/MM/DD"
                    label="End date"
                    name="end_timestamp"
                    type="date"
                    onChange={this.handleDateChange}
                    value={DealDetailsSettings.formatDate(endTimestamp)}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={colum1}>
                Deal Type
              </Grid.Column>
              <Grid.Column width={colum2}>
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    search
                    name="demand_sub_type"
                    label="Deal Type"
                    placeholder="Select the deal type"
                    options={DEMAND_SUB_TYPES}
                    onChange={(event, data) => this.handleDealChange(data)}
                    value={demandSubType}
                    required
                  />
                  <Form.Select
                    fluid
                    search
                    name="priority_level"
                    label="Priority Level"
                    placeholder="Select the priority level"
                    options={getPriorityLevelRange(demandSubType)}
                    onChange={(event, data) => this.handleDealChange(data)}
                    value={priorityLevel}
                    required
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={colum1}>
                Pricing
              </Grid.Column>
              <Grid.Column width={colum2}>
                <Form.Group widths="equal">
                  <Form.Field required>
                    <b className="required-label">Pricing</b>
                    <Form.Field>
                      <Radio
                        label="Flat CPM"
                        name="deal_type"
                        value={3}
                        checked={dealType === 3}
                        onChange={(event, data) => this.handleDealChange(data)}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="CPM floor"
                        name="deal_type"
                        value={1}
                        checked={dealType === 1}
                        onChange={(event, data) => this.handleDealChange(data)}
                      />
                    </Form.Field>
                  </Form.Field>
                  <Form.Field>
                    <Form.Field
                      fluid
                      control={Input}
                      name="deal_price"
                      icon="dollar sign"
                      iconPosition="left"
                      label="Value"
                      value={dealPrice}
                      placeholder="0.5"
                      onChange={(e, data) => this.handlePriceChange(data)}
                      error={priceError}
                      required
                    />
                    {
                      priceError &&
                      (<Label basic color="red" pointing>
                        Value must be a number
                      </Label>)
                    }
                  </Form.Field>
                  <Divider />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={colum1}>
                DSPs
              </Grid.Column>
              <Grid.Column width={colum2}>
                <Form.Group widths="2">
                  <MultiSelector
                    label="DSPs"
                    name="eligible_bidders"
                    onChange={this.handleDealChange}
                    options={dspList}
                    placeholder="Select DSP"
                    required
                    selectAll
                    value={eligibleBidder}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="right" columns={1}>
              <Grid.Column>
                <Button.Group floated="right">
                  <Button as={Link} secondary to={overviewLink}>Cancel</Button>
                  <Button
                    primary
                    disabled={invalidToSave}
                    onClick={() => save()}
                    loading={loading}
                  >
                    Save
                  </Button>
                  {id !== 'new' && (
                    <React.Fragment>
                      <Button as={Link} color="teal" onClick={() => duplicate()} to={overviewLink}>
                        Copy Deal
                      </Button>
                      <Button as={Link} color="red" onClick={this.handleDeleteClick}>
                        Delete Deal
                      </Button>
                      <Confirm
                        open={this.state.confirmDeleteOpen}
                        onCancel={this.closeConfirmDelete}
                        onConfirm={remove}
                        content="Are you sure you want to delete this deal?"
                        confirmButton={{ color: 'red', content: 'Delete', primary: false }}
                      />
                    </React.Fragment>
                  )}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

DealDetailsSettings.defaultProps = {
  id: null,
  startTimestamp: null,
  endTimestamp: null,
  dealType: null,
  dealPrice: null,
  userApps: [],
};

DealDetailsSettings.propTypes = {
  id: PropTypes.string,
  deal: PropTypes.shape({}).isRequired,
  DSPs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  appSegmentsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deviceSegmentsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deviceSegments: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  appSegments: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  colum1: PropTypes.number.isRequired,
  colum2: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  invalidToSave: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  isAdminView: PropTypes.bool.isRequired,
  userApps: PropTypes.arrayOf(PropTypes.shape({})),
  duplicate: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DealDetailsSettings;
